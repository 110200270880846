import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { User } from 'stores/auth';

export const columns: MRT_ColumnDef<User>[] = [
  {
    accessorKey: 'givenName',
    header: i18n.t('common.firstName'),
    grow: false,
  },
  {
    accessorKey: 'familyName',
    header: i18n.t('common.lastName'),
    grow: false,
  },
  {
    accessorKey: 'email',
    header: i18n.t('common.email'),
  },
];
