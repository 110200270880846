import { useMemo } from 'react';
import { Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { CustomerRouteParams, ROUTES } from 'routes/routes.config';
import { UserMgmtNavLink, testIds as navLinkTestIds } from './UserMgmtNavLink';
import classes from './UserMgmtLayout.module.css';

interface UserMgmtNavbarProps {
  groupCount: number;
  policyCount: number;
  userCount: number;
}

export const testIds = {
  list: 'user-mgmt-navbar',
  link: navLinkTestIds,
  usersLink: 'user-mgmt-navbar-users-link',
  groupsLink: 'user-mgmt-navbar-groups-link',
  policiesLink: 'user-mgmt-navbar-policies-link',
};

enum Tabs {
  USERS = 'users',
  GROUPS = 'groups',
  POLICIES = 'policies',
}

export const UserMgmtNavbar = ({ groupCount, policyCount, userCount }: UserMgmtNavbarProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { customerId } = useParams() as CustomerRouteParams;

  const active = useMemo(() => {
    if (pathname.includes(ROUTES.USER_MGMT(customerId).USERS)) return Tabs.USERS;
    if (pathname.includes(ROUTES.USER_MGMT(customerId).GROUPS)) return Tabs.GROUPS;
    if (pathname.includes(ROUTES.USER_MGMT(customerId).POLICIES)) return Tabs.POLICIES;
    return '';
  }, [pathname]);

  return (
    <Stack className={classes.navbar} gap='tn' data-testid={testIds.list}>
      <UserMgmtNavLink
        active={active === Tabs.USERS}
        count={userCount}
        label={t('userMgmt.navbar.users')}
        to={ROUTES.USER_MGMT(customerId).USERS}
        data-testid={testIds.usersLink}
      />
      <UserMgmtNavLink
        active={active === Tabs.GROUPS}
        count={groupCount}
        label={t('userMgmt.navbar.groups')}
        to={ROUTES.USER_MGMT(customerId).GROUPS}
        data-testid={testIds.groupsLink}
      />
      <UserMgmtNavLink
        active={active === 'policies'}
        count={policyCount}
        label={t('userMgmt.navbar.policies')}
        to={ROUTES.USER_MGMT(customerId).POLICIES}
        data-testid={testIds.policiesLink}
      />
    </Stack>
  );
};
