import { Flex, Group, Pagination, Text } from '@mantine/core';
import { IconFocusCentered, IconScreenshot, IconZoomIn, IconZoomOut } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import { getZoomLabel, zoomStep } from './AnnotatorToolbar.utils';

interface AnnotatorToolbarProps {
  drawState: boolean;
  imageCount: number;
  zoom: number;
  onDraw: () => void;
  onPaginate: (index: number) => void;
  onRecenter: () => void;
  onZoom: (zoom: number) => void;
}

export const testIds = {
  wrapper: 'annotator-toolbar',
  pagination: 'annotator-pagination',
  recenter: {
    button: 'annotator-recenter-button',
    icon: 'annotator-recenter-icon',
  },
  draw: {
    button: 'annotator-draw-button',
    icon: 'annotator-draw-icon',
  },
  zoom: {
    in: 'annotator-zoom-in',
    inIcon: 'annotator-zoom-in-icon',
    out: 'annotator-zoom-out',
    outIcon: 'annotator-zoom-out-icon',
    value: 'annotator-zoom-value',
  },
};

export const AnnotatorToolbar = ({
  drawState,
  imageCount,
  zoom,
  onDraw,
  onPaginate,
  onRecenter,
  onZoom,
}: AnnotatorToolbarProps) => {
  const { t } = useTranslation();

  return (
    <Flex
      className='border-0 border-b border-solid'
      align='center'
      p='xs'
      w='100%'
      justify='space-between'
      gap='xs'
      data-testid={testIds.wrapper}
    >
      <Group gap='xs'>
        <ActionButton
          label={t('wizard.steps.labeling.annotator.toolbar.recenterButton')}
          size={32}
          variant='default'
          radius='sm'
          onClick={onRecenter}
          data-testid={testIds.recenter.button}
        >
          <IconFocusCentered data-testid={testIds.recenter.icon} />
        </ActionButton>
        <ActionButton
          label={t('wizard.steps.labeling.annotator.toolbar.drawButton')}
          variant={drawState ? 'primary' : 'default'}
          size={32}
          radius='sm'
          onClick={onDraw}
          data-testid={testIds.draw.button}
        >
          <IconScreenshot data-testid={testIds.draw.icon} />
        </ActionButton>
      </Group>
      <Pagination
        boundaries={0}
        siblings={1}
        total={imageCount}
        onChange={onPaginate}
        data-testid={testIds.pagination}
      />
      <Group gap='tn'>
        <ActionButton
          label={t('wizard.steps.labeling.annotator.toolbar.zoomOut')}
          onClick={() => onZoom(zoom - zoomStep)}
          data-testid={testIds.zoom.out}
        >
          <IconZoomOut stroke={1.5} data-testid={testIds.zoom.outIcon} />
        </ActionButton>
        <Text className='text-center' w={55} data-testid={testIds.zoom.value}>
          {getZoomLabel(zoom)}
        </Text>
        <ActionButton
          label={t('wizard.steps.labeling.annotator.toolbar.zoomIn')}
          onClick={() => onZoom(zoom + zoomStep)}
          data-testid={testIds.zoom.in}
        >
          <IconZoomIn stroke={1.5} data-testid={testIds.zoom.inIcon} />
        </ActionButton>
      </Group>
    </Flex>
  );
};
