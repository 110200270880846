import { ModelType } from '@ai-platform/common-types';
import { Box, Flex, Space, Stack, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import dayjs from 'dayjs';
import { AiModel } from 'stores/aiPlatform';

interface ModelSelectOptionProps extends AiModel<ModelType> {
  placeholder?: boolean;
  checked?: boolean;
}

export const testIds = {
  icon: 'model-select-option-icon',
  user: 'model-select-option-user',
  date: 'model-select-option-date',
  getOptionTestSettingsTip: (id: string) => `model-select-option-tip--${id}`,
  getOptionTestId: (id: string) => `model-select-option--${id}`,
};

export const ModelSelectOption = ({ id, createdAt, checked, placeholder }: ModelSelectOptionProps) => (
  <Flex align='center' data-testid={testIds.getOptionTestId(id)}>
    {!placeholder && (
      <Box w={18} mx='tn'>
        {checked && <IconCheck size={18} data-testid={testIds.icon} />}
      </Box>
    )}
    <Stack gap={0}>
      <Text size='sm' data-testid={testIds.user}>{`${id}`}</Text>
      <Text size='xs' c='dark.2' data-testid={testIds.date}>
        {dayjs(createdAt).format(CLASSIC_DATE_TIME_FORMAT)}
      </Text>
    </Stack>
    <Space className='grow' />
  </Flex>
);
