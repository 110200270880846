import { HTMLPropsRef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { AiDataVoiceSample } from 'stores/aiPlatform';
import { dataGenVoiceSamplesTableDefs } from './DataGenVoiceSamplesTable.defs';
import { getDataGenVoiceColumns } from './DataGenVoiceSamplesTable.columns';

interface DataGenVoiceSamplesTableProps {
  samples: AiDataVoiceSample[];
  isLoading?: boolean;
  width: number;
}

export const testIds = {
  table: 'data-gen-voice-samples-table',
  getRowId: (id: string) => `data-gen-voice-samples-table-row-${id}`,
};

export const DataGenVoiceSamplesTable = ({ samples, isLoading = false, width }: DataGenVoiceSamplesTableProps) => {
  const table = useMantineReactTable({
    data: samples,
    columns: getDataGenVoiceColumns(),
    ...dataGenVoiceSamplesTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: '',
      'data-testid': testIds.getRowId(row.id),
    }),
    mantineTableProps: {
      'data-testid': testIds.table,
    } as HTMLPropsRef<HTMLTableElement>,
    mantineLoadingOverlayProps: () => ({ visible: false, overlayProps: {} }),
    mantineSkeletonProps: () => ({
      size: 'medium',
      color: 'gray',
    }),
    state: { isLoading },
    mantineTableContainerProps: {
      style: { width, paddingBottom: 350, height: '100%' },
    },
    mantinePaperProps: { style: { height: '90%' } },
  });

  return <MantineReactTable table={table} />;
};
