import { Box, Button, Group, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import i18n from 'services/i18n';
import { useFlow, useFlowStore } from 'stores/flows';
import { OpenGlobalModalProps, useModal } from 'stores/globalModal';

interface CreateFolderModalProps {
  flowId: string;
}

export const testIds = {
  container: 'delete-flow-modal',
  submit: 'delete-flow-submit-button',
  cancel: 'delete-flow-cancel-button',
  message: 'delete-flow-message',
  warning: 'delete-flow-warning',
};

export const DeleteFlowModal = ({ flowId }: CreateFolderModalProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const { close } = useModal();
  const flow = useFlow(flowId);
  const [loading, setLoading] = useState(false);
  const { deleteFlow } = useFlowStore(['deleteFlow']);

  const onSubmit = async () => {
    setLoading(true);
    const response = await deleteFlow(customerId, flowId);
    if (response) {
      notifications.show({
        color: 'red',
        title: t('flowsPage.modals.deleteFlow.successMessage', { name: flow?.name }),
        message: null,
        icon: <IconTrash />,
      });
      close();
    } else {
      notifications.show({
        color: 'red',
        title: t('flowsPage.modals.deleteFlow.errorMessage', { name: flow?.name }),
        message: t('common.tryAgain'),
        icon: <IconTrash />,
      });
    }
    setLoading(false);
  };

  return (
    <Box data-testid={testIds.container}>
      <Text c='gray.6' data-testid={testIds.message}>
        {t('flowsPage.modals.deleteFlow.message', { name: flow?.name })}
      </Text>
      <Text c='black' fw={700} data-testid={testIds.warning}>
        {t('flowsPage.modals.deleteFlow.warning')}
      </Text>
      <Group justify='end' mt='lg'>
        <Button variant='subtle' onClick={close} disabled={loading} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} loading={loading} data-autofocus data-testid={testIds.submit}>
          {t('flowsPage.modals.deleteFlow.submit')}
        </Button>
      </Group>
    </Box>
  );
};

export const deleteFlowGlobalModalProps = (flowId: string): OpenGlobalModalProps => ({
  title: i18n.t('flowsPage.modals.deleteFlow.title'),
  children: <DeleteFlowModal flowId={flowId} />,
  variant: 'warning',
});
