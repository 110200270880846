import { InfoTip } from 'components';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { FlowMetadataField } from 'stores/wizard';

export const testIds = {
  info: 'metadata-table-unique-id-button-info',
};

export const UniqueIdentifierHeader: MRT_ColumnDef<FlowMetadataField>['Header'] = () => {
  const { t } = useTranslation();

  return (
    <InfoTip data-testid={testIds.info}>
      {t('wizard.steps.settings.metadata.table.uniqueIdentifierField.columnTooltip')}
    </InfoTip>
  );
};
