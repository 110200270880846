import i18n from 'services/i18n';
import { UserDetailsPanel } from './components';

export enum UserPanel {
  DETAILS = 'details',
  GROUPS = 'groups',
  POLICIES = 'policies',
}

export const panels = [
  {
    key: UserPanel.DETAILS,
    title: i18n.t('userMgmt.users.userDetails.panels.details.title'),
    panel: <UserDetailsPanel />,
  },
  {
    key: UserPanel.GROUPS,
    title: i18n.t('userMgmt.users.userDetails.panels.groups.title'),
    disabled: true,
    panel: <div />,
  },
  {
    key: UserPanel.POLICIES,
    title: i18n.t('userMgmt.users.userDetails.panels.policies.title'),
    disabled: true,
    panel: <div />,
  },
];
