import { FlowMetadata, UpsertFlowMetadataRequest } from '@jargonic/flow-metadata-types';
import { HermesStatusCode } from '@aiola/frontend';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { getDefaultMetadata } from './flowSettings.utils';

const flowUrl = (customerId: string, flowId: string) => `${config.jargonicApiUrl}/${customerId}/flowMetadata/${flowId}`;

export const flowSettingsApi = {
  getMetadata: async (customerId: string, flowId: string): Promise<FlowMetadata | undefined> => {
    const { data, status } = await hermes.get<FlowMetadata>(flowUrl(customerId, flowId));
    return status === HermesStatusCode.NotFound ? getDefaultMetadata(flowId) : data;
  },

  updateMetadata: async (
    customerId: string,
    flowId: string,
    metadata: UpsertFlowMetadataRequest,
  ): Promise<FlowMetadata | undefined> => {
    const { data } = await hermes.patch<FlowMetadata>(flowUrl(customerId, flowId), metadata);
    return data;
  },
};
