import { useCallback, useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useParams, useSearchParams } from 'react-router-dom';
import { GroupRouteParams } from 'routes/routes.config';
import { UpdateGroupPayload, useUserMgmtStore } from 'stores/userMgmt';
import { useUserArray } from 'stores/userMgmt/userMgmt.selectors';
import cn from 'classnames';
import { columns } from './UsersTable.columns';
import {
  UsersTableActions,
  UsersTableEmptyState,
  UsersTableRowActions,
  actionsTestIds,
  emptyStateTestIds,
} from './components';
import classes from './UsersTable.module.css';
import { UsersTableState } from './UsersTable.types';
import { usersTableDefs } from './UsersTable.defs';

export const testIds = {
  getRowTestId: (id: string) => `users-table-row-${id}`,
  table: 'users-table',
  emptyState: emptyStateTestIds,
  actions: actionsTestIds,
};

export const UsersTable = () => {
  const { customerId, groupId } = useParams() as GroupRouteParams;
  const [params, setParams] = useSearchParams();
  const { users, groups, upsertGroup } = useUserMgmtStore(['users', 'groups', 'upsertGroup']);
  const search = params.get('search') || '';
  const group = groups[groupId];
  const usersArray = useUserArray();
  const data = useMemo(() => group.users.map((user) => users[user.userId]), [group, users]);

  const onSearch = (value: string) => {
    setParams((prev) => {
      prev.set('search', value);
      return prev;
    });
  };

  const onChangeUsers: UsersTableState['onChangeUsers'] = useCallback(
    async (userIds) => {
      const payload: UpdateGroupPayload = {
        ...group,
        users: userIds,
        policies: group.policies.map((policy) => policy.id),
      };
      const response = await upsertGroup(customerId, { action: 'update', groupId, payload });
      return Boolean(response);
    },
    [customerId, groups],
  );

  const table = useMantineReactTable({
    data,
    columns,
    ...usersTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: cn(classes.row, 'cursor-pointer'),
      'data-testid': testIds.getRowTestId(row.id),
    }),
    renderEmptyRowsFallback: (props) => <UsersTableEmptyState {...props} />,
    renderTopToolbar: (props) => <UsersTableActions {...props} />,
    renderRowActions: (props) => <UsersTableRowActions {...props} />,
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    state: {
      globalFilter: search,
      users: usersArray,
      group,
      onChangeUsers,
      onSearch,
    } as UsersTableState,
  });

  return <MantineReactTable table={table} data-testid={testIds.table} />;
};
