import { Checkbox, Flex, Highlight } from '@mantine/core';
import { UserAvatar } from 'components';
import { User, UserId } from 'stores/auth';

interface GroupUsersMappingUserRowProps {
  user: User;
  selected: boolean;
  search: string;
  onChange: (checked: boolean) => void;
}

export const testIds = {
  getRowTestId: (userId: UserId) => `group-mapping-user-row-${userId}`,
  getAvatarTestId: (userId: UserId) => `group-mapping-user-row-avatar-${userId}`,
  checkbox: 'group-mapping-user-row-checkbox',
  name: 'group-mapping-user-row-name',
};

export const GroupUsersMappingUserRow = ({ user, selected, search, onChange }: GroupUsersMappingUserRowProps) => (
  <Flex align='center' gap='md' data-testid={testIds.getRowTestId(user.userId)}>
    <UserAvatar user={user} data-testid={testIds.getAvatarTestId(user.userId)} />
    <Highlight className='grow' highlight={search} data-testid={testIds.name}>
      {`${user.givenName} ${user.familyName}`}
    </Highlight>
    <Checkbox
      size='md'
      checked={selected}
      onChange={(event) => onChange(event.currentTarget.checked)}
      data-testid={testIds.checkbox}
    />
  </Flex>
);
