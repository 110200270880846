import { Accordion, Box, Loader, ScrollArea, Stack, Text } from '@mantine/core';
import { FlowPageTitle } from 'components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WizardRouteParams } from 'routes/routes.config';
import { useFlow } from 'stores/flows';
import { useFlowSettingStore } from 'stores/wizard';
import { WizardStep, useStepLoader } from 'layouts';
import { panels } from './FlowSettingsPage.const';

export const testIds = {
  loader: 'flow-settings-page-loader',
  wrapper: 'flow-settings-page-wrapper',
  getAccordionControlTestId: (key: string) => `flow-settings-page-accordion-${key}`,
  getAccordionItemTestId: (key: string) => `flow-settings-page-accordion-${key}-item`,
};

const panelKeys = panels.map(({ key }) => key);

export const FlowSettingsPage = () => {
  const { t } = useTranslation();
  const { flowId } = useParams() as WizardRouteParams;
  const flow = useFlow(flowId);
  const loading = useStepLoader(WizardStep.SETTINGS);
  const { startDraft, endDraft } = useFlowSettingStore(['startDraft', 'endDraft']);

  useEffect(() => {
    if (!loading) startDraft();
    return endDraft;
  }, [loading]);

  return loading || !flow ? (
    <Loader type='bars' m='auto' data-testid={testIds.loader} />
  ) : (
    <Stack className='flex-1' gap='xl' mx='xl' data-testid={testIds.wrapper}>
      <FlowPageTitle flow={flow} title={t('flowPage.applications.flowSettings')} />
      <Box className='flex-1' pos='relative'>
        <ScrollArea type='auto' className='absolute w-full h-full top-0 bottom-0 left-0 right-0'>
          <Accordion defaultValue={panelKeys} multiple>
            {panels.map(({ key, title, content }) => (
              <Accordion.Item key={key} value={key} data-testid={testIds.getAccordionItemTestId(key)}>
                <Accordion.Control data-testid={testIds.getAccordionControlTestId(key)}>
                  <Text fw={600}>{title}</Text>
                </Accordion.Control>
                <Accordion.Panel>{content}</Accordion.Panel>
              </Accordion.Item>
            ))}
          </Accordion>
        </ScrollArea>
      </Box>
    </Stack>
  );
};
