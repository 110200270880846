import { MRT_ColumnDef } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { Flex, TagsInput } from '@mantine/core';
import { FlowMetadataField, FlowMetadataFieldType } from 'stores/wizard';
import { MetadataTableState } from '../../MetadataTable.types';
import { FieldValuesCSVUpload, testIds as csvUploadTestIds } from './FieldValuesCSVUpload';

export const testIds = {
  input: 'metadata-table-field-values',
  uploadCsv: csvUploadTestIds,
};

export const FieldValuesCell: MRT_ColumnDef<FlowMetadataField>['Cell'] = ({ row, table }) => {
  const { t } = useTranslation();

  const isSelectionField =
    row.original.type === FlowMetadataFieldType.Dropdown ||
    row.original.type === FlowMetadataFieldType.MultiselectDropdown;
  if (!isSelectionField) return null;

  const { onChange, onUploadCSV } = table.getState() as MetadataTableState;

  const onUpload = async (file: File) => onUploadCSV(row.id, file);

  return (
    <Flex gap='sm'>
      <TagsInput
        value={row.original.options}
        label={t('wizard.steps.settings.metadata.table.fieldValues.inputTitle')}
        onChange={(options) => onChange(row.id, { options })}
        data-testid={testIds.input}
      />
      <FieldValuesCSVUpload onUpload={onUpload} />
    </Flex>
  );
};
