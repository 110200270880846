import { Button, Divider, Group, Tooltip } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useModal } from 'stores/globalModal';
import { deleteChildlessEventDefGlobalModalProps, deleteEventDefGlobalModalProps } from '../modals';

interface EventDefActionsProps {
  addChildEnabled: boolean;
  hasChildren: boolean;
  onDelete: (withChildren: boolean) => void;
  onAddChild: () => void;
}

export const testIds = {
  addChild: {
    button: 'event-def-settings-add-child-button',
    icon: 'event-def-settings-add-child-icon',
    tooltip: 'event-def-settings-add-child-tooltip',
  },
  delete: {
    button: 'event-def-settings-delete-button',
    icon: 'event-def-settings-delete-icon',
  },
};

export const EventDefActions = ({ addChildEnabled, hasChildren, onDelete, onAddChild }: EventDefActionsProps) => {
  const { t } = useTranslation();
  const { open } = useModal();

  const onClickDelete = () => {
    if (hasChildren) open(deleteEventDefGlobalModalProps({ onDelete }));
    else open(deleteChildlessEventDefGlobalModalProps({ onDelete: () => onDelete(false) }));
  };

  return (
    <Group justify='end' gap='xs'>
      <Tooltip
        disabled={addChildEnabled}
        label={t('wizard.steps.reportedEvents.generalSettings.actions.addChildButton.disabled')}
        data-testid={testIds.addChild.tooltip}
      >
        <Button
          disabled={!addChildEnabled}
          leftSection={<IconPlus data-testid={testIds.addChild.icon} />}
          size='xs'
          variant='subtle'
          data-testid={testIds.addChild.button}
          onClick={onAddChild}
        >
          {t('wizard.steps.reportedEvents.generalSettings.actions.addChildButton.title')}
        </Button>
      </Tooltip>
      <Divider orientation='vertical' />
      <ActionButton
        label={t('wizard.steps.reportedEvents.generalSettings.actions.delete')}
        size='sm'
        data-testid={testIds.delete.button}
        onClick={onClickDelete}
      >
        <IconTrash data-testid={testIds.delete.icon} />
      </ActionButton>
    </Group>
  );
};
