import { noop, Paper, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useTrainingSteps, TrainingStepStatus } from 'stores/aiPlatform';
import { TrainingPipelineRouteParams } from 'routes/routes.config';
import { StepCommonContent, testIds as commonContentTestIds } from '../StepCommonContent/StepCommonContent';
import classes from '../../TrainingPipelinePage.module.css';

export const testIds = {
  completedContent: 'warmup-content-completed',
  commonContent: commonContentTestIds,
};

export const WarmupContent = () => {
  const { t } = useTranslation();
  const { modelId } = useParams() as TrainingPipelineRouteParams;
  const { warmup } = useTrainingSteps(modelId) ?? {};

  switch (warmup?.status) {
    case TrainingStepStatus.IN_PROGRESS:
      return (
        <StepCommonContent
          key={TrainingStepStatus.IN_PROGRESS}
          title={t('trainingPipelinePage.steps.warmup.inProgressTitle')}
          message={t('trainingPipelinePage.steps.warmup.inProgressMessage')}
          className={classes.greenBorder}
          onAbort={noop}
          onPause={noop}
        />
      );
    case TrainingStepStatus.PAUSED:
      return (
        <StepCommonContent
          key={TrainingStepStatus.PAUSED}
          title={t('trainingPipelinePage.steps.pausedTitle')}
          message={t('trainingPipelinePage.steps.contentMessage')}
          className={classes.greenBorder}
          onAbort={noop}
          onResume={noop}
        />
      );

    case TrainingStepStatus.FAILED:
      return (
        <StepCommonContent
          key={TrainingStepStatus.FAILED}
          title={t('trainingPipelinePage.steps.failedTitle')}
          message={t('trainingPipelinePage.steps.failedMessage')}
          className={classes.redBorder}
          onAbort={noop}
          onReset={noop}
        />
      );
    case TrainingStepStatus.COMPLETED:
      return (
        <Paper p='lg' withBorder className={classes.greenBorder} data-testid={testIds.completedContent}>
          <Text fw={600} className='text-center'>
            {t('trainingPipelinePage.steps.warmup.completedMessage')}
          </Text>
        </Paper>
      );
    default:
      return null;
  }
};
