import { EventDefElement, EventDefType } from './eventDefs.types';

export const EVENT_DEFINITION_SESSION_STORAGE_KEY = 'jargonic-event-definitions';
export const eventTypeElementMap: Record<EventDefType, EventDefElement> = {
  [EventDefType.FREE_TEXT]: EventDefElement.STRING,
  [EventDefType.APPEND_TEXT_EVENT]: EventDefElement.APPENDABLE_STRING,
  [EventDefType.NUMBER]: EventDefElement.NUMERIC,
  [EventDefType.DATE]: EventDefElement.DATE,
  [EventDefType.TIME_OF_DAY]: EventDefElement.TIME_OF_DAY,
  [EventDefType.LIST_OF_VALUES_SINGLE_SELECTION]: EventDefElement.BUTTONS,
  [EventDefType.LIST_OF_VALUES_MULTI_SELECTION]: EventDefElement.BUTTONS,
};
