import { CustomerId } from 'stores/customers';
import { userMgmtStore } from 'stores/userMgmt/userMgmt.store';
import { PolicySliceActions, PolicySliceState, UpsertPolicyArgs, UserMgmtSlice } from '../userMgmt.slices.types';
import { policyApi } from './policies.api';

export const policiesInitialState: PolicySliceState = {
  loadingPolicies: false,
  policies: {},
};

async function upsertPolicy(customerId: CustomerId, { action, policyId, payload }: UpsertPolicyArgs) {
  switch (action) {
    case 'create': {
      return policyApi.createPolicy(customerId, payload);
    }
    case 'update':
      return policyApi.updatePolicy(customerId, policyId, payload);
    default:
      return undefined;
  }
}

export const policySlice: UserMgmtSlice<PolicySliceState, PolicySliceActions> = (set, get) => ({
  ...policiesInitialState,
  fetchPolicies: async (customerId) => {
    set({ loadingPolicies: true });
    const policies = await policyApi.getPolicies(customerId);
    if (policies) {
      const policiesMap = policies.reduce((acc, policy) => ({ ...acc, [policy.id]: policy }), {});
      set({ policies: policiesMap });
    }
    set({ loadingPolicies: false });
    return !!policies;
  },
  upsertPolicy: async (customerId, payload) => {
    const policy = await upsertPolicy(customerId, payload);
    if (policy) {
      userMgmtStore.getState().fetchGroups(customerId);
      userMgmtStore.getState().fetchUsers(customerId);
      set(({ policies }) => {
        policies[policy.id] = policy;
      });
    }
    return policy;
  },
  deletePolicy: async (customerId, userId) => {
    const success = await policyApi.deletePolicy(customerId, userId);
    if (success) {
      userMgmtStore.getState().fetchGroups(customerId);
      userMgmtStore.getState().fetchUsers(customerId);
      set(({ policies }) => {
        delete policies[userId];
      });
    }
    return success;
  },
  upsertPermission: async (customerId, { action, payload, permissionId, policyId }) => {
    const policy = get().policies[policyId];
    const permissions =
      action === 'create'
        ? [...policy.permissions, payload]
        : policy.permissions.map((permission) => (permission.id === permissionId ? payload : permission));
    const updatePolicyPayload = { ...policy, permissions };
    return get().upsertPolicy(customerId, { action: 'update', policyId, payload: updatePolicyPayload });
  },
  deletePermission: async (customerId, policyId, permissionId) => {
    const policy = get().policies[policyId];
    const permissions = policy.permissions.filter((permission) => permission.id !== permissionId);
    const updatePolicyPayload = { ...policy, permissions };
    const response = await get().upsertPolicy(customerId, { action: 'update', policyId, payload: updatePolicyPayload });
    return !!response;
  },
});
