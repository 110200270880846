import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { Container } from 'stores/wizard';

export const testIds = {
  title: { input: 'container-table-title-input' },
};

export function useContainerTableColumns(onChange: (id: string, values: Partial<Container>) => void) {
  return useMemo<MRT_ColumnDef<Container>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        mantineEditTextInputProps: ({ row }) => ({
          required: true,
          onBlur: (e) => onChange(row.id, { name: e.currentTarget.value }),
          'data-testid': testIds.title.input,
        }),
      },
    ],
    [],
  );
}
