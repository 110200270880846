import { MRT_TableOptions } from 'mantine-react-table';
import { Group } from 'stores/userMgmt';
import classes from './GroupTable.module.css';

export const groupTableDefs = {
  getRowId: (original) => original.id,
  enableSorting: true,
  enableFilterMatchHighlighting: true,
  enablePagination: false,
  enableBottomToolbar: false,
  enableStickyHeader: true,
  enableColumnActions: false,
  enableRowActions: true,
  positionActionsColumn: 'last',
  layoutMode: 'grid',
  mantineTableBodyCellProps: { py: 'xs' },
  displayColumnDefOptions: {
    'mrt-row-actions': { header: '', grow: false, mantineTableBodyCellProps: { onClick: (e) => e.stopPropagation() } },
  },
  mantinePaperProps: { className: classes.paper },
  mantineTableContainerProps: { className: classes.container },
} satisfies Partial<MRT_TableOptions<Group>>;
