import { Button, Tooltip } from '@mantine/core';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { AiModel, TrainingStepStatus } from 'stores/aiPlatform/aiModels';
import { useDisclosure } from '@mantine/hooks';
import { EvaluationResultsModal, testIds as modalTestIds } from './EvaluationResultsModal';

export const testIds = {
  viewButton: {
    button: 'ai-model-table-row-action-view-button',
    tooltip: 'ai-model-table-row-action-view-tooltip',
  },
  modal: modalTestIds,
};

export const AiModelTableRowActions: NonNullable<MRT_TableOptions<AiModel>['renderRowActions']> = ({ row }) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();
  const { test } = row.original.trainingSteps;
  const isCompleted = test.status === TrainingStepStatus.COMPLETED;

  return (
    <>
      <Tooltip
        label={t('trainingPage.table.viewDisabledTooltip')}
        disabled={isCompleted}
        data-testid={testIds.viewButton.tooltip}
      >
        <Button variant='transparent' disabled={!isCompleted} onClick={open} data-testid={testIds.viewButton.button}>
          {t('trainingPage.table.view')}
        </Button>
      </Tooltip>
      {isCompleted && <EvaluationResultsModal opened={opened} testingStep={test} onClose={close} />}
    </>
  );
};
