import { ModalBase } from 'components';
import { useModalProps } from 'stores/globalModal';

export const testIds = {
  root: 'global-modal-root',
};

export const GlobalModal = () => {
  const { children, close, color, opened, title, variant } = useModalProps();

  return (
    <ModalBase title={title} onClose={close} variant={variant} opened={opened} color={color} data-testid={testIds.root}>
      {children}
    </ModalBase>
  );
};
