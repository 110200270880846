import { Navigate, createBrowserRouter } from 'react-router-dom';
import { LoginPage } from 'pages';
import { SpyProvider } from 'services/espionage';
import { ROUTES } from './routes.config';

export const publicRouter = createBrowserRouter([
  { path: ROUTES.ROOT, element: <Navigate to={ROUTES.LOGIN} /> },
  {
    element: <SpyProvider />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
    ],
  },
  { path: '*', element: <Navigate to={ROUTES.ROOT} /> },
]);
