import { MRT_TableOptions } from 'mantine-react-table';
import { Flow } from 'stores/flows';
import { Folder } from 'stores/folders';
import {
  FlowRowActions,
  testIds as flowActionsTestIds,
} from 'pages/FlowsPage/components/FlowTableV2/components/FlowRowActions/FlowRowActions';
import {
  FolderRowActions,
  testIds as folderActionsTestIds,
} from 'pages/FlowsPage/components/FlowTableV2/components/FolderRowActions/FolderRowActions';
import { isArchive, isFlow, isFolder } from '../../FlowTable.utils';
import { FlowTableState } from '../../FlowTable.types';

export const testIds = {
  flow: flowActionsTestIds,
  folder: folderActionsTestIds,
};

export const ActionsCell: MRT_TableOptions<Flow | Folder>['renderRowActions'] = ({ row, table }) => {
  if (isFlow(row.original)) return <FlowRowActions flow={row.original} />;
  if (isFolder(row.original) && !isArchive(row.original)) {
    const { onSelectFlow } = table.getState() as FlowTableState;
    return <FolderRowActions folder={row.original} onCreateFlow={onSelectFlow} />;
  }
  return null;
};
