import { ComponentProps } from 'react';
import { DndContext, DndContextProps, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove } from '@dnd-kit/sortable';
import { Stack } from '@mantine/core';
import { testIds as itemTestIds } from './DraggableValueItem';
import { DroppableValueList, testIds as listTestIds } from './DroppableValueList';
import { valueModalTestIds } from './ValueSettingsModal';

interface ValueListProps extends ComponentProps<typeof DroppableValueList> {
  rootClassName?: string;
  onReorder: (reorderedIds: string[]) => void;
}

export const testIds = {
  item: itemTestIds,
  list: listTestIds,
  modal: valueModalTestIds,
};

export const ValueList = ({ eventValues, rootClassName, onReorder, ...props }: ValueListProps) => {
  const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 5 } }));

  const handleDragEnd: DndContextProps['onDragEnd'] = (event) => {
    const oldIndex = eventValues.findIndex((value) => value.id === event.active.id);
    const newIndex = eventValues.findIndex((value) => value.id === event.over?.id);
    const reorderedValues = arrayMove(eventValues, oldIndex, newIndex);
    const reorderedIds = reorderedValues.map((value) => value.id);
    onReorder(reorderedIds);
  };

  return (
    <Stack className={rootClassName} gap='tiny' data-testid={testIds.list.list}>
      <DndContext
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        onDragEnd={handleDragEnd}
      >
        <DroppableValueList eventValues={eventValues} {...props} />
      </DndContext>
    </Stack>
  );
};
