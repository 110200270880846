import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroupProps, Divider, ElementProps, Flex, Input, Paper, ScrollArea, Text } from '@mantine/core';
import { InfoLabel } from 'components';
import { PermissionResourceList, testIds as listTestIds } from './PermissionResourceList';
import { PermissionResourceSearch, testIds as searchTestIds } from './PermissionResourceSearch';

interface PermissionResourceInputProps
  extends Omit<CheckboxGroupProps, 'children'>,
    ElementProps<'input', keyof CheckboxGroupProps> {
  resources: { id: string; name: string }[];
}

export const testIds = {
  label: 'permission-resource-input-label',
  count: 'permission-resource-input-count',
  search: searchTestIds,
  list: listTestIds,
};

export const PermissionResourceInput = ({
  resources,
  value = [],
  onChange,
  ...props
}: PermissionResourceInputProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const filteredResources = useMemo(() => {
    const lowerCaseSearch = search.toLowerCase();
    return resources.filter((resource) => resource.name.toLowerCase().includes(lowerCaseSearch));
  }, [resources, search]);

  return (
    <Input.Wrapper
      labelProps={{ w: '100%' }}
      label={
        <Flex align='center' justify='space-between'>
          <InfoLabel infoText={t('userMgmt.policies.editPermission.resourceInput.tooltip')} data-testid={testIds.label}>
            {t('userMgmt.policies.editPermission.resourceInput.label')}
          </InfoLabel>
          <Text c='gray.5' fz='sm' fw={400} data-testid={testIds.count}>
            {t('common.selectCount', { count: value.length })}
          </Text>
        </Flex>
      }
      {...props}
    >
      <Paper mt='xs' withBorder>
        <PermissionResourceSearch value={search} onChange={setSearch} />
        <Divider />
        <ScrollArea h={188}>
          <PermissionResourceList
            resources={filteredResources}
            value={value}
            search={search}
            onChange={onChange}
            {...props}
          />
        </ScrollArea>
      </Paper>
    </Input.Wrapper>
  );
};
