import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { LabelMetrics } from 'stores/aiPlatform/aiModels';
import { MetricCell } from './cells/MetricCell';

export const columns: MRT_ColumnDef<LabelMetrics>[] = [
  {
    accessorKey: 'label',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.type'),
    grow: 1,
  },
  {
    accessorKey: 'metrics.precision',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.precision'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.recall',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.recall'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.f1',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.f1'),
    Cell: MetricCell,
    grow: 0,
  },
  {
    accessorKey: 'metrics.support',
    header: i18n.t('trainingPipelinePage.metricsTable.headers.support'),
    grow: 0,
  },
];
