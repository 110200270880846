import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useFlowStore } from 'stores/flows';
import { CustomerRouteParams } from 'routes/routes.config';
import { useFolderStore } from 'stores/folders';

export const FlowsLayout = () => {
  const { customerId } = useParams() as CustomerRouteParams;
  const { fetchFlows, reset: resetFlows } = useFlowStore(['fetchFlows', 'reset']);
  const { fetchFolders, reset: resetFolders } = useFolderStore(['fetchFolders', 'reset']);

  useEffect(() => {
    fetchFlows(customerId);
    fetchFolders(customerId);
    return () => {
      resetFlows();
      resetFolders();
    };
  }, [customerId]);

  return <Outlet />;
};
