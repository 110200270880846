import {
  GetDataGenerationQuery,
  GetDataGenerationQueryVariables,
  ListDataGenerationsInput,
  ListDataGenerationsQuery,
  ListDataGenerationsQueryVariables,
  ModelType,
} from '@ai-platform/common-types';
import {
  GetLatestDataSourceResponse,
  GetSamplesResponse,
  StartDataGenerationRequest,
  StartDataGenerationResponse,
  TextDataSourceSample,
  VoiceDataSourceSample,
} from '@ai-platform/data-generation-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { deleteNullAndUnderscoreAttributes } from 'utils';
import {
  AbortDataGenerationRequest,
  DeclineDataGenerationRequest,
  GraphQLResponse,
} from '../../aiPlatformBackendTypes';
import { dataSourceFromApi, dataSourceFromDataGeneration } from '../aiDataSources.adapters';
import { AiDataSourceUIModel } from '../aiDataSources.types';
import { getDataGenerationQuery, listDataGenerationsQuery } from './aiDataSources.gql';

export const POLLING_INTERVAL = 5000;

export const aiDataSourcesApi = {
  getAiDataSource: async (executionId: string): Promise<AiDataSourceUIModel | undefined> => {
    const query = getDataGenerationQuery;
    const variables: GetDataGenerationQueryVariables = {
      executionId,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<GetDataGenerationQuery>>(
      `${config.aipApiHost}/appsync/query`,
      {
        query,
        variables,
      },
      { fallback: { data: {} } },
    );
    return dataSourceFromApi(data.getDataGeneration) ?? undefined;
  },

  getAiDataSources: async (
    listDataGenerationsInput: ListDataGenerationsInput,
  ): Promise<AiDataSourceUIModel[] | undefined> => {
    const query = listDataGenerationsQuery;
    const variables: ListDataGenerationsQueryVariables = {
      listDataGenerationsInput,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<ListDataGenerationsQuery>>(
      `${config.aipApiHost}/appsync/query`,
      {
        query,
        variables,
      },
      { fallback: { data: {} } },
    );

    return data?.listDataGenerations?.dataGenerations
      ?.map(dataSourceFromApi)
      .filter((dataSource): dataSource is AiDataSourceUIModel => dataSource !== null);
  },

  getLatestAiDataSourcesByType: async (
    listDataGenerationsInput: ListDataGenerationsInput,
    dataSourceType?: ModelType,
  ): Promise<AiDataSourceUIModel[] | undefined> => {
    const request = dataSourceType
      ? { listDataGenerationsInput: { ...listDataGenerationsInput, dataSourceType } }
      : { listDataGenerationsInput };
    const url = `${config.aipApiHost}/data-generation/get-latest-data-generation`;
    const { data } = await hermes.post<GetLatestDataSourceResponse>(url, request);
    return data?.dataGeneration
      .map(dataSourceFromDataGeneration)
      .filter((dataSource): dataSource is AiDataSourceUIModel => dataSource !== null);
  },

  getDataSourceSamples: async (
    executionId: string,
    modelType: ModelType,
  ): Promise<TextDataSourceSample[] | VoiceDataSourceSample[] | undefined> => {
    const url = `${config.aipApiHost}/data-generation/get-samples/${modelType}?executionId=${executionId}`;
    const { data } = await hermes.get<GetSamplesResponse>(url);
    return data?.samples;
  },

  subscribeToAiDataSource: (id: string, callback: (aiModel: AiDataSourceUIModel) => void) =>
    setInterval(async () => {
      const dataSource = await aiDataSourcesApi.getAiDataSource(id);
      if (dataSource) callback(dataSource);
    }, POLLING_INTERVAL),

  startDataSourceGeneration: async (input: StartDataGenerationRequest): Promise<StartDataGenerationResponse> => {
    const cleanInput = deleteNullAndUnderscoreAttributes<StartDataGenerationRequest>(input);
    const url = `${config.aipApiHost}/data-generation/start`;
    const { data } = await hermes.post<StartDataGenerationResponse>(url, cleanInput);
    return data;
  },
  abortGeneration: async (input: AbortDataGenerationRequest): Promise<boolean> => {
    const url = `${config.aipApiHost}/data-generation/abort`;
    const { data } = await hermes.post(url, input);
    // Successful response is empty
    return !data;
  },
  declineGeneration: async (input: DeclineDataGenerationRequest): Promise<boolean> => {
    const url = `${config.aipApiHost}/data-generation/decline`;
    const { data } = await hermes.post(url, input);
    // Successful response is empty
    return !data;
  },
  /**
   * Downloads data source samples for a given execution ID and model type.
   *
   * @param executionId - The ID of the execution for which to download samples.
   * @param modelType - The type of the model for which to download samples.
   * @returns A promise that resolves when the download is complete.
   * @throws {DownloadError} If the download fails.
   */
  async downloadDataSourceSamples(executionId: string, modelType: ModelType): Promise<void> {
    const url = `${config.aipApiHost}/data-generation/download-samples/${executionId}/${modelType}`;
    try {
      const response = await fetch(url); // Hermes does not expose the headers, so we use fetch instead
      if (!response.ok) {
        throw new DownloadError(response.statusText, response.status);
      }

      // Read the response as a Blob
      const blob = await response.blob();
      const contentType = response.headers.get('Content-Type');
      const fileExtension = contentType?.split('/')[1];
      const fileName = `${executionId}-${modelType}-samples-${new Date().toISOString()}.${fileExtension}`;

      // Create an object URL for the Blob and trigger the download
      const a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Clean up
      a.remove();
      URL.revokeObjectURL(a.href);
    } catch (error) {
      console.error(`Failed to download samples for execution ${executionId} and model type ${modelType}:`, error);
      if (error instanceof DownloadError) {
        throw error;
      } else {
        throw new DownloadError('something went wrong', 500);
      }
    }
  },
};

export class DownloadError extends Error {
  public readonly name: string;
  public readonly statusCode: number;

  constructor(message: string, statusCode = 500) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype); // Error behaves a bit differently in than regular classes
    this.name = this.constructor.name;
    this.statusCode = statusCode;
    Error.captureStackTrace(this);
  }
}
