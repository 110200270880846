import { EmptyState } from 'components';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { User } from 'stores/auth';

export const testIds = { wrapper: 'user-table-empty-state' };

export const UserTableEmptyState: NonNullable<MRT_TableOptions<User>['renderEmptyRowsFallback']> = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('userMgmt.users.table.emptyState.title')}
      message={t('userMgmt.users.table.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
