import { Box, Button, Divider, Flex, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { ActionButton, ModalBase, modalBaseTestIds } from 'components';
import { useTranslation } from 'react-i18next';
import { ReportedEventDefValue } from 'stores/wizard';
import { useEffect } from 'react';
import {
  ValueNameInput,
  ValueSynonymsInput,
  ValueValidityToggle,
  nameInputTestIds,
  synonymsTestIds,
  validityTestIds,
} from './components';

interface ValueSettingsModalProps {
  eventValue: ReportedEventDefValue;
  opened: boolean;
  onClose: () => void;
  onDelete: () => void;
  onSubmit: (payload: Partial<ReportedEventDefValue>) => boolean;
}

export const testIds = {
  modal: 'value-settings-modal',
  title: modalBaseTestIds.title,
  cancelButton: 'value-settings-modal-cancel-button',
  applyButton: 'value-settings-modal-apply-button',
  deleteButton: {
    button: 'value-settings-modal-delete-button',
    icon: 'value-settings-modal-delete-button-icon',
  },
  name: nameInputTestIds,
  validity: validityTestIds,
  synonyms: synonymsTestIds,
};

export const ValueSettingsModal = ({ eventValue, opened, onClose, onDelete, onSubmit }: ValueSettingsModalProps) => {
  const { t } = useTranslation();
  const form = useForm<ReportedEventDefValue>({
    initialValues: eventValue,
  });

  const onApply = (values: ReportedEventDefValue) => {
    const response = onSubmit(values);
    if (!response)
      form.setErrors({ name: t('wizard.steps.reportedEvents.typeSettings.types.select.duplicateValueError') });
    else onClose();
  };

  useEffect(() => {
    if (opened) form.setValues(eventValue);
  }, [opened]);

  return (
    <ModalBase
      opened={opened}
      title={t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.title')}
      onClose={onClose}
      data-testid={testIds.modal}
    >
      <Box component='form' onSubmit={form.onSubmit(onApply)}>
        <Stack gap='lg'>
          <Flex gap='xl'>
            <ValueNameInput className='grow' {...form.getInputProps('name')} />
            <ValueValidityToggle value={form.values.valid} onChange={(value) => form.setFieldValue('valid', value)} />
          </Flex>
          <Divider />
          <ValueSynonymsInput {...form.getInputProps('synonyms')} />
          <Group justify='end'>
            <ActionButton
              mr='auto'
              label={t('common.delete')}
              onClick={onDelete}
              data-testid={testIds.deleteButton.button}
            >
              <IconTrash data-testid={testIds.deleteButton.icon} />
            </ActionButton>
            <Button variant='subtle' onClick={onClose} data-testid={testIds.cancelButton}>
              {t('common.cancel')}
            </Button>
            <Button type='submit' data-testid={testIds.applyButton}>
              {t('common.apply')}
            </Button>
          </Group>
        </Stack>
      </Box>
    </ModalBase>
  );
};
