import { useMemo } from 'react';
import { Avatar, AvatarGroupProps, ElementProps, Tooltip } from '@mantine/core';
import { MiniUser } from 'stores/auth';
import { UserAvatar } from '../UserAvatar/UserAvatar';

interface UserGroupAvatarsProps extends AvatarGroupProps, ElementProps<'div', keyof AvatarGroupProps> {
  showCount?: number;
  users?: MiniUser[];
}

export const testIds = {
  avatarRegex: /user-group-avatars-avatar/,
  getAvatarTestId: (userId: string) => `user-group-avatars-avatar-${userId}`,
  group: 'user-group-avatars',
  noUsers: 'user-group-avatars-no-users',
  rest: {
    tooltip: 'user-group-avatars-rest-tooltip',
    avatar: 'user-group-avatars-rest-avatar',
  },
};

const REST_TEXT = (count: number) => `+${count}`;

function getAvatarUsers(users?: MiniUser[], showCount = 3) {
  if (!users) return { shown: [], rest: [] };
  if (users.length <= showCount) return { shown: users, rest: [] };
  const shown = users.slice(0, showCount);
  const rest = users.slice(showCount);
  const tooltip = rest.map((user) => `${user.givenName} ${user.familyName}`).join(', ');
  return { shown, rest, tooltip };
}

export const UserGroupAvatars = ({ showCount, users, ...props }: UserGroupAvatarsProps) => {
  const { shown, rest, tooltip } = useMemo(() => getAvatarUsers(users, showCount), [users, showCount]);

  if (!shown.length) return <UserAvatar size='sm' data-testid={testIds.noUsers} />;

  return (
    <Avatar.Group data-testid={testIds.group} {...props}>
      {shown.map((user) => (
        <UserAvatar key={user.userId} size='sm' user={user} data-testid={testIds.getAvatarTestId(user.userId)} />
      ))}
      {rest.length > 0 && (
        <Tooltip label={tooltip} openDelay={1000} data-testid={testIds.rest.tooltip}>
          <Avatar ml='tiny' size='sm' data-testid={testIds.rest.avatar}>
            {REST_TEXT(rest.length)}
          </Avatar>
        </Tooltip>
      )}
    </Avatar.Group>
  );
};
