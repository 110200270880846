import { EditableFlowValues, EditableMetadataValues } from './flowSettings.types';

export const FLOW_SETTINGS_SESSION_STORAGE_KEY = 'jargonic-flow-settings';
export const initialFlowState: EditableFlowValues = {
  dateFormat: 'DD/MM/YYYY',
  maxInspectors: 0,
  name: '',
  singleInstance: false,
  supportedCommonEvents: [],
};

export const initialMetadataState: EditableMetadataValues = {
  title: '',
  postInspectionFields: [],
  preInspectionFields: [],
  uniqueIdentifierFieldId: '',
};
