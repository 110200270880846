import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { AiDataVoiceSample } from 'stores/aiPlatform';
import { useRef } from 'react';
import { AudioPlayerCell } from './AudioPlayerCell';

export const getDataGenVoiceColumns: () => MRT_ColumnDef<AiDataVoiceSample>[] = () => {
  const audioRef = useRef<HTMLAudioElement>();

  return [
    {
      accessorKey: 'recordingURI',
      header: i18n.t('dataGenerationPage.sampleTable.voice.audioHeader'),
      size: 350,
      Cell: ({ cell }) => <AudioPlayerCell cell={cell} globalAudioRef={audioRef} />,
      grow: 0,
    },
    {
      accessorKey: 'transcript',
      header: i18n.t('dataGenerationPage.sampleTable.voice.transcriptHeader'),
      grow: 1,
    },
  ];
};
