import { ModelType, State } from '@ai-platform/common-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { AiPublishedModel, TrainExecutionId } from 'stores/aiPlatform';

export type AiPublishedDataDto = {
  tenantId: string;
  activeVersion: string;
  modelType: ModelType;
  flowId: string;
  mainLocale: string;
};

export type AiPublishedDataRequest = {
  modelId: string;
  paginationToken: string;
};

export const POLLING_INTERVAL = 5000;

export const aiPublishedModelsApi = {
  getPublishedAiModels: async ({
    modelId,
    paginationToken,
  }: AiPublishedDataRequest): Promise<{
    aiPublishedModels: Record<string, AiPublishedModel<ModelType>> | Record<string, unknown>;
    paginationToken: string;
  }> => {
    const url = `${config.aipApiHost}/publish/get-models?modelId=${modelId}${paginationToken ? `&paginationToken=${paginationToken}` : ''}`;

    const { data } = await hermes.get<{
      publishExecutions: Record<string, AiPublishedModel<ModelType>> | Record<string, unknown>;
      paginationToken: string;
    }>(url, {
      fallback: {
        publishExecutions: {},
        paginationToken: '',
      },
      onFailure: () => ({
        publishExecutions: {},
        paginationToken: '',
      }),
    });
    return { aiPublishedModels: data.publishExecutions, paginationToken: data.paginationToken };
  },

  getPublishedModel: async (executionId: string): Promise<{ publishExecution: AiPublishedModel<ModelType> | null }> => {
    const url = `${config.aipApiHost}/publish/get-model?executionId=${executionId}`;

    const { data } = await hermes.get<{ publishExecution: AiPublishedModel<ModelType> | null }>(url);

    return data;
  },
  publishModel: async (executionId: string, envName: string): Promise<string | undefined | null> => {
    const { data } = await hermes.post<{
      status: State;
      executionId: TrainExecutionId;
      prefectMonitorUrl: string;
    }>(`${config.aipApiHost}/publish/start`, {
      executionId,
      envName,
    });

    return data?.executionId;
  },
  subscribeToPublishedModel: (
    id: string,
    callback: (publishExecutionData: AiPublishedModel<ModelType> | null) => void,
  ) =>
    setInterval(async () => {
      const data = await aiPublishedModelsApi.getPublishedModel(id);

      if (!data) {
        return;
      }

      if (data.publishExecution) callback(data.publishExecution);
    }, POLLING_INTERVAL),
};
