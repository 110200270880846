import { TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { InfoLabel } from 'components';

interface EventDefNameInputProps {
  duplicateError?: boolean;
  value: string;
  rootClassName?: string;
  onChange: (value: string) => void;
}

export const testIds = {
  input: 'event-def-settings-name-input',
  label: 'event-def-settings-name-label',
};

export const EventDefNameInput = ({ duplicateError, value, rootClassName, onChange }: EventDefNameInputProps) => {
  const { t } = useTranslation();

  const error = (duplicateError && t('wizard.steps.reportedEvents.generalSettings.eventName.duplicateError')) || !value;

  return (
    <TextInput
      classNames={{ root: rootClassName }}
      error={error}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.generalSettings.eventName.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.generalSettings.eventName.title')}
        </InfoLabel>
      }
      placeholder={t('wizard.steps.reportedEvents.generalSettings.eventName.placeholder')}
      value={value}
      required
      onChange={(e) => onChange(e.currentTarget.value)}
      data-testid={testIds.input}
    />
  );
};
