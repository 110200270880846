import { Stack, Tabs } from '@mantine/core';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES, UserRouteParams } from 'routes/routes.config';
import { useUserMgmtStore } from 'stores/userMgmt';
import { UserDetails } from 'components';
import { userDetailsTestIds } from './components';
import { UserPanel, panels } from './UserPage.panels';

export const testIds = {
  getTabTestId: (panel: UserPanel) => `user-page-tab-${panel}`,
  panels: {
    [UserPanel.DETAILS]: userDetailsTestIds,
    [UserPanel.GROUPS]: { wrapper: 'user-groups-panel' },
    [UserPanel.POLICIES]: { wrapper: 'user-policies-panel' },
  },
};

export const UserPage = () => {
  const [params, setParams] = useSearchParams();
  const { customerId, userId } = useParams() as UserRouteParams;
  const { users } = useUserMgmtStore(['users', 'upsertUser']);
  const user = users[userId];
  const activePanel = (params.get('panel') as UserPanel) || UserPanel.DETAILS;

  const onChangePanel = (panel: string | null) => setParams({ panel: panel as UserPanel });

  return user ? (
    <Stack>
      <UserDetails user={user} />
      <Tabs value={activePanel} onChange={onChangePanel}>
        <Tabs.List>
          {panels.map((panel) => (
            <Tabs.Tab
              key={panel.key}
              value={panel.key}
              disabled={panel.disabled}
              data-testid={testIds.getTabTestId(panel.key)}
            >
              {panel.title}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {panels.map((panel) => (
          <Tabs.Panel key={panel.key} value={panel.key}>
            {panel.panel}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Stack>
  ) : (
    <Navigate to={ROUTES.USER_MGMT(customerId).USERS} replace />
  );
};
