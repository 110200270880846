import { useCallback, useMemo } from 'react';
import { Flow, FlowId } from 'stores/flows';
import { Folder, FolderId } from 'stores/folders';
import { orderPredicate } from 'utils';
import { getFakeArchiveFolder, isFolder, isFreeFlow } from '../FlowTable.utils';

export function useFlowTableData(flows: Record<FlowId, Flow>, folders: Record<FolderId, Folder>) {
  const orderedFolders = useMemo(() => Object.values(folders).sort(orderPredicate), [folders]);
  const fakeArchiveFolder = useMemo(() => getFakeArchiveFolder(Object.values(flows)), [flows, orderedFolders.length]);
  const orderedFreeFlows = useMemo(() => Object.values(flows).filter(isFreeFlow).sort(orderPredicate), [flows]);

  const getSubRows = useCallback(
    (original: Flow | Folder) => {
      if (!isFolder(original)) return undefined;
      const foundFlows = original.flowIds.map((flowId) => flows[flowId]);
      const existingFoundFlows = foundFlows.filter(Boolean);
      return existingFoundFlows.sort(orderPredicate);
    },
    [flows],
  );

  const hasData = orderedFolders.length > 0 || Object.values(flows).length > 0;

  return {
    data: [...orderedFreeFlows, ...orderedFolders, ...(hasData ? [fakeArchiveFolder] : [])],
    getSubRows,
  };
}
