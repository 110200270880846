import { ComboboxItem, Select } from '@mantine/core';
import { InputType } from '@jargonic/event-definition-types';
import { IconPlus } from '@tabler/icons-react';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';
import i18n from 'services/i18n';
import { validationInputType } from 'stores/wizard';
import './NewValidationTypeSelect.module.css';

interface NewValidationTypeSelectProps {
  rootClassName?: string;
  onValidationTypeChange: (type: InputType) => void;
}

export const testIds = {
  select: 'new-validation-type-select',
  label: 'new-validation-select-label',
};

export const validationTypesOptions: ComboboxItem[] = [
  {
    value: InputType.BOUNDS,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.mainTypes.boundary'),
  },
  {
    value: InputType.LOGICAL,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.mainTypes.businessLogic'),
  },
];

export const NewValidationTypeSelect = ({ rootClassName, onValidationTypeChange }: NewValidationTypeSelectProps) => {
  const { t } = useTranslation();

  const handleTypeChange = (value: string | null) => onValidationTypeChange(value as validationInputType);

  return (
    <Select
      classNames={{ root: rootClassName }}
      data={validationTypesOptions}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.types.number.newValidationSelect.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.types.number.newValidationSelect.title')}
        </InfoLabel>
      }
      placeholder={t('wizard.steps.reportedEvents.typeSettings.types.number.newValidationSelect.placeholder')}
      value={null}
      onChange={handleTypeChange}
      rightSection={<IconPlus size={16} />}
      data-testid={testIds.select}
    />
  );
};
