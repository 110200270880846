import { nanoid } from 'nanoid';
import { Annotation, Coordinates } from './annotations.types';

export function generateAnnotationId() {
  return `annotation-${nanoid(8)}`;
}

export function getNewAnnotation(fileId: string, { width, height, x = 0, y = 0 }: Coordinates): Annotation {
  return {
    id: generateAnnotationId(),
    fileId,
    x: Math.max(x, 0),
    y: Math.max(y, 0),
    width: Math.max(width, 10),
    height: Math.max(height, 10),
  };
}

export async function cropImage(fileUrl: string, annotation: Annotation) {
  const { x, y, width, height } = annotation;
  const image = new Image();
  image.crossOrigin = 'anonymous';
  const promise = new Promise<Blob>((resolve, reject) => {
    image.onerror = reject;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d')!;
      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, x, y, width, height, 0, 0, width, height);
      try {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else reject();
        });
      } catch (e) {
        console.error(e);
        reject();
      }
    };
  });
  image.src = fileUrl;
  return promise;
}
