import { PropsWithChildren, useMemo } from 'react';
import { Box, Popover } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Container, ContainerId, Label, LabelId } from 'stores/wizard';
import { CreateContainerSubmit, testIds as submitTestIds } from './CreateContainerSubmit';
import { CreateContainerNameInput, testIds as nameInputTestIds } from './CreateContainerNameInput';
import { CreateContainerParentSelect, testIds as parentSelectTestIds } from './CreateContainerParentSelect';
import { CreateContainerLabelSelect, testIds as labelSelectTestIds } from './CreateContainerLabelSelect';

interface CreateContainerMenuProps extends PropsWithChildren {
  containers: Container[];
  labels: Label[];
  opened: boolean;
  onClose: () => void;
  onCreate: (payload: { name: string; labelId: LabelId; parentId?: ContainerId }) => void;
}

interface CreateContainerFormState {
  name: string;
  labelId: LabelId;
  parentId?: ContainerId;
}

export const testIds = {
  menu: 'create-container-menu',
  labelSelect: labelSelectTestIds,
  parentSelect: parentSelectTestIds,
  nameInput: nameInputTestIds,
  submit: submitTestIds,
};

export const CreateContainerMenu = ({
  children,
  containers,
  labels,
  opened,
  onClose,
  onCreate,
}: CreateContainerMenuProps) => {
  const form = useForm<CreateContainerFormState>({
    initialValues: { name: '', labelId: '', parentId: '' },
  });
  const containerOptions = useMemo(() => containers.map((c) => ({ value: c.id, label: c.name })), [containers]);

  const onSubmit = (values: CreateContainerFormState) => {
    onCreate({ ...values, parentId: values.parentId || undefined });
    form.reset();
    onClose();
  };

  return (
    <Popover opened={opened} position='left' onClose={onClose}>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown data-testid={testIds.menu}>
        <Box className='flex flex-col gap-2' component='form' onSubmit={form.onSubmit(onSubmit)}>
          <CreateContainerLabelSelect labels={labels} {...form.getInputProps('labelId')} />
          <CreateContainerParentSelect data={containerOptions} {...form.getInputProps('parentId')} />
          <CreateContainerNameInput {...form.getInputProps('name')} />
          <CreateContainerSubmit disabled={!form.values.labelId} />
        </Box>
      </Popover.Dropdown>
    </Popover>
  );
};
