import { Card, ComboboxItem, Flex, Select, Title, useComputedColorScheme } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import {
  Container,
  EvenDefValidationId,
  EventDefNumericValidation,
  EventDefType,
  NumericValidationType,
  validationInputType,
} from 'stores/wizard';
import i18n from 'services/i18n';
import { InputType } from '@jargonic/event-definition-types';
import { useState } from 'react';
import { ValidationItemInput, testIds as componentTestIds, ValidationMapping } from './components';

interface validationItemProps {
  containerPool: Container[];
  type: EventDefType.NUMBER | EventDefType.DATE | EventDefType.TIME_OF_DAY;
  validation: EventDefNumericValidation;
  isConflicting: boolean;
  onChange: (values: Partial<EventDefNumericValidation>) => void;
  onDelete: () => void;
}

export const testIds = {
  getCardTestId: (validationId: EvenDefValidationId) => `validation-card-${validationId}`,
  title: 'validation-title',
  delete: {
    button: 'delete-button',
    icon: 'delete-icon',
  },
  switch: 'validation-switch',
  switchLabel: 'validation-switch-label',
  ...componentTestIds,
};

const { GREATER_THEN, LESS_THEN, IS_BETWEEN, IS_NOT_BETWEEN } = NumericValidationType;

export const options: ComboboxItem[] = [
  {
    value: LESS_THEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.lessThan'),
  },
  {
    value: GREATER_THEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.greaterThan'),
  },
  {
    value: IS_BETWEEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.between'),
  },
  {
    value: IS_NOT_BETWEEN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.notBetween'),
  },
];

export const validationTypesTitles: Record<validationInputType, string> = {
  [InputType.BOUNDS]: i18n.t(
    'wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.mainTypes.boundary',
  ),
  [InputType.LOGICAL]: i18n.t(
    'wizard.steps.reportedEvents.typeSettings.types.number.validationTypes.mainTypes.businessLogic',
  ),
};

export const ValidationItem = ({
  containerPool,
  validation,
  isConflicting,
  type,
  onChange,
  onDelete,
}: validationItemProps) => {
  const { t } = useTranslation();
  const colorScheme = useComputedColorScheme('light');
  const [selectedNumericValue, setSelectedNumericValue] = useState(validation.validationType);

  const onChangeNumericType = (value: string | null) => {
    setSelectedNumericValue(value as NumericValidationType);
    onChange({ validationType: value as NumericValidationType, name: value as NumericValidationType });
  };

  const isLogicalItemConflicting = isConflicting && validation.type === InputType.LOGICAL;

  return (
    <Card
      bg={colorScheme === 'light' ? 'gray.0' : 'dark.8'}
      style={isLogicalItemConflicting ? { border: 'solid 1px var(--mantine-color-red-filled)' } : {}}
      data-testid={testIds.getCardTestId(validation.id)}
    >
      <Flex align='center' gap='tn' mb='md'>
        <Title className='grow' order={5} data-testid={testIds.title}>
          {validationTypesTitles[validation.type]}
        </Title>
        <ValidationMapping
          containers={containerPool}
          selectedIds={validation.containerIds}
          onChange={(containerIds) => onChange({ containerIds })}
        />
        <ActionButton label={t('common.delete')} onClick={onDelete} data-testid={testIds.delete.button}>
          <IconTrash data-testid={testIds.delete.icon} />
        </ActionButton>
      </Flex>
      <Flex align='center' gap='tn' justify='space-between'>
        <Select
          w='30%'
          data={options}
          placeholder={t('wizard.steps.reportedEvents.typeSettings.types.number.newValidationSelect.placeholder')}
          value={selectedNumericValue}
          onChange={(value) => onChangeNumericType(value)}
        />
        <ValidationItemInput type={type} validation={validation} onChange={onChange} />
      </Flex>
    </Card>
  );
};
