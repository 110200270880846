import { MRT_TableOptions } from 'mantine-react-table';
import { AiDataTextSample } from 'stores/aiPlatform';

export const dataGenTextSamplesTableDefs = {
  getRowId: (original) => original.id,
  enableSorting: false,
  enablePagination: false,
  enableBottomToolbar: false,
  enableStickyHeader: true,
  enableColumnActions: false,
  enableTopToolbar: false,
  enableRowActions: false,
  mantineTableBodyCellProps: { py: 'md' },
} satisfies Partial<MRT_TableOptions<AiDataTextSample>>;
