import { Group } from '@jargonic/customer-types';
import { Checkbox, Flex, Highlight } from '@mantine/core';
import { isUser } from 'pages/userMgmt/PolicyPage/components/EntitiesPanel/EntitiesPanel.utils';
import { ReactNode } from 'react';
import { User, UserId } from 'stores/auth';

interface PolicyMappingRowProps {
  item: User | Group;
  selected: boolean;
  search: string;
  label: string;
  avatar: ReactNode;
  onChange: (checked: boolean) => void;
}

export const testIds = {
  getRowTestId: (userId: UserId) => `policy-mapping-row-${userId}`,
  getAvatarTestId: (userId: UserId) => `policy-mapping-row-avatar-${userId}`,
  checkbox: 'policy-mapping-row-checkbox',
  name: 'policy-mapping-row-name',
};

export const PolicyMappingRow = ({ item, selected, search, label, avatar, onChange }: PolicyMappingRowProps) => (
  <Flex align='center' gap='md' data-testid={testIds.getRowTestId(isUser(item) ? item.userId : item.id)}>
    {avatar}
    <Highlight className='grow' highlight={search} data-testid={testIds.name}>
      {label}
    </Highlight>
    <Checkbox
      size='md'
      checked={selected}
      onChange={(event) => onChange(event.currentTarget.checked)}
      data-testid={testIds.checkbox}
    />
  </Flex>
);
