import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, CloseButton, Divider, Flex, Group, ScrollArea, Stack, Text, TextInput } from '@mantine/core';
import { UserId } from 'stores/auth';
import { AllCheckbox, ModalBase, modalBaseTestIds, UserAvatar } from 'components';
import { StepsCounter } from 'components/StepsCounter/StepsCounter';
import {
  PolicyMappingRow,
  testIds as mappingRowTestIds,
} from 'pages/userMgmt/components/PolicyMappingModal/PolicyMappingRow';
import { IconSearch } from '@tabler/icons-react';
import { useUserArray } from 'stores/userMgmt';
import { useCreateGroupForm } from './GroupTableCreateAction';
import { CREATE_GROUP_STEPS, CREATE_GROUP_USERS_STEP } from './GroupTableCreateAction.const';

interface AddGroupUsersModalProps {
  opened: boolean;
  loading: boolean;
  onClose: () => void;
  onBack: () => void;
  onNext: () => void;
  onSubmit: () => void;
}

export const testIds = {
  title: modalBaseTestIds.title,
  stepName: 'add-group-users-modal',
  search: 'add-group-users-modal-search',
  clearSearch: 'add-group-users-modal-clear-search',
  getUserTestId: mappingRowTestIds.getRowTestId,
  all: 'add-group-users-modal-all-checkbox',
  checkbox: mappingRowTestIds.checkbox,
  avatar: mappingRowTestIds.getAvatarTestId,
  stepper: 'add-group-users-modal-stepper',
  nextButton: 'add-group-users-modal-next-button',
  backButton: 'add-group-users-modal-back-button',
  createButton: 'add-group-users-modal-create-button',
};

export const AddGroupUsersModal = ({ opened, loading, onClose, onBack, onNext, onSubmit }: AddGroupUsersModalProps) => {
  const { t } = useTranslation();
  const { getValues, setValues } = useCreateGroupForm();
  const users = useUserArray();
  const [search, setSearch] = useState('');
  const [selectedSet, setSelectedSet] = useState<Set<UserId>>(new Set(getValues().userIds));

  useEffect(() => setValues({ userIds: Array.from(selectedSet) }), [selectedSet, setValues]);

  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => setSearch(event.currentTarget.value);
  const onClear = () => setSearch('');

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          user.givenName.toLowerCase().includes(search.toLowerCase()) ||
          user.familyName.toLowerCase().includes(search.toLowerCase()),
      ),
    [users, search],
  );

  const allSelected = filteredUsers.length === selectedSet.size;
  const someSelected = selectedSet.size > 0;
  const onToggleAll = (allChecked: boolean) =>
    setSelectedSet(allChecked ? new Set(filteredUsers.map((user) => user.userId)) : new Set());

  const handleChangeSelected = (userId: UserId) => (checked: boolean) =>
    setSelectedSet((set) => {
      const newSet = new Set(set);
      if (checked) newSet.add(userId);
      else newSet.delete(userId);
      return newSet;
    });

  return (
    <ModalBase
      opened={opened}
      title={t('userMgmt.groups.table.actions.create.title')}
      headerProps={{ mb: 'none', pb: '0' }}
      bodyProps={{ p: 'zero' }}
      onClose={onClose}
    >
      <Text size='sm' fw={400} c='dark.2' data-testid={testIds.stepName} mb='md' px='lg'>
        {t('userMgmt.groups.table.actions.create.addUsersModal.subtitle')}
      </Text>
      <Box pt='tiny'>
        <Flex px='lg' gap='lg'>
          <TextInput
            flex={1}
            placeholder={t('userMgmt.groups.table.actions.create.addUsersModal.searchInput.placeholder')}
            rightSection={search && <CloseButton onClick={onClear} data-testid={testIds.clearSearch} />}
            leftSection={<IconSearch size={18} />}
            value={search}
            onChange={onSearch}
            data-testid={testIds.search}
          />
          <AllCheckbox all={allSelected} some={someSelected} onChange={onToggleAll} data-testid={testIds.all} />
        </Flex>
        <ScrollArea h={288}>
          <Stack p='lg'>
            {filteredUsers.map((user) => (
              <PolicyMappingRow
                key={user.userId}
                item={user}
                label={`${user.givenName} ${user.familyName}`}
                avatar={<UserAvatar user={user} />}
                selected={selectedSet.has(user.userId)}
                search={search}
                onChange={handleChangeSelected(user.userId)}
              />
            ))}
          </Stack>
        </ScrollArea>
      </Box>
      <Divider />
      <Group p='lg' justify='end'>
        <StepsCounter
          currentStep={CREATE_GROUP_USERS_STEP}
          numberOfSteps={CREATE_GROUP_STEPS}
          mr='auto'
          data-testid={testIds.stepper}
        />
        <Button variant='subtle' disabled={loading} onClick={onBack} data-testid={testIds.backButton}>
          {t('common.back')}
        </Button>
        <Button
          variant='outline'
          type='submit'
          loading={loading}
          disabled={loading}
          onClick={onSubmit}
          data-testid={testIds.createButton}
        >
          {t('common.create')}
        </Button>
        <Button disabled={loading} onClick={onNext} data-testid={testIds.nextButton}>
          {t('common.next')}
        </Button>
      </Group>
    </ModalBase>
  );
};
