import { Checkbox, NumberInput, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFlowSettingStore } from 'stores/wizard';
import { InfoLabel } from 'components';

export const testIds = {
  userLimit: {
    input: 'general-settings-users-input',
    label: 'general-settings-users-label',
    capInput: 'general-settings-users-cap-input',
  },
  instanceLimit: {
    input: 'general-settings-instance-input',
    label: 'general-settings-instance-label',
  },
};

export const ExecutionProperties = () => {
  const { t } = useTranslation();
  const { values, update } = useFlowSettingStore((state) => ({
    values: state.data.flow,
    update: state.updateGeneralSettings,
  }));

  return (
    <Stack gap='md'>
      <Checkbox
        checked={values.singleInstance}
        label={
          <InfoLabel
            infoText={t('wizard.steps.settings.generalSettings.instanceLimit.info')}
            data-testid={testIds.instanceLimit.label}
          >
            {t('wizard.steps.settings.generalSettings.instanceLimit.title')}
          </InfoLabel>
        }
        onChange={(e) => update({ singleInstance: e.target.checked })}
        data-testid={testIds.instanceLimit.input}
      />
      <Checkbox
        checked={values.maxInspectors > 0}
        label={
          <>
            <InfoLabel
              infoText={t('wizard.steps.settings.generalSettings.userLimit.info')}
              data-testid={testIds.userLimit.label}
            >
              {t('wizard.steps.settings.generalSettings.userLimit.title')}
            </InfoLabel>
            <NumberInput
              hideControls={false}
              value={values.maxInspectors}
              min={1}
              disabled={values.maxInspectors === 0}
              onChange={(value) => update({ maxInspectors: Number(value) })}
              data-testid={testIds.userLimit.capInput}
            />
          </>
        }
        onChange={(e) => update({ maxInspectors: e.target.checked ? 1 : 0 })}
        data-testid={testIds.userLimit.input}
      />
    </Stack>
  );
};
