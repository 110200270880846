import { KeyboardEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Stack, TextInput, Title } from '@mantine/core';
import { NiceResponse } from 'consts';
import { User } from 'stores/auth';

interface UserPersonalDataProps {
  user: User;
  onChange: (key: keyof User, value: string) => Promise<NiceResponse>;
}

export const testIds = {
  wrapper: 'user-personal-data-panel',
  title: 'user-personal-data-title',
  firstNameInput: 'user-personal-data-first-name-input',
  lastNameInput: 'user-personal-data-last-name-input',
  usernameInput: 'user-personal-data-username-input',
  emailInput: 'user-personal-data-email-input',
};

export const UserPersonalData = ({ user, onChange: _ }: UserPersonalDataProps) => {
  const { t } = useTranslation();

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) =>
    event.key === 'Enter' && event.currentTarget.blur();

  return (
    <Card p='lg' shadow='zero' data-testid={testIds.wrapper}>
      <Stack>
        <Title order={6} data-testid={testIds.title}>
          {t('userMgmt.users.userDetails.panels.details.personalData.title')}
        </Title>
        <Flex gap='md' w='100%'>
          <TextInput
            classNames={{ root: 'grow' }}
            defaultValue={user?.givenName}
            label={t('common.firstName')}
            disabled
            data-testid={testIds.firstNameInput}
          />
          <TextInput
            classNames={{ root: 'grow' }}
            defaultValue={user?.familyName}
            label={t('common.lastName')}
            disabled
            data-testid={testIds.lastNameInput}
          />
        </Flex>
        <TextInput
          label={t('common.username')}
          defaultValue={user?.username}
          disabled
          onKeyDown={onKeyDown}
          data-testid={testIds.usernameInput}
        />
        <TextInput
          label={t('common.email')}
          defaultValue={user?.email}
          disabled
          type='email'
          data-testid={testIds.emailInput}
        />
      </Stack>
    </Card>
  );
};
