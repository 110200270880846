import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Flex, Stack, TextInput, Title, Divider, Text, Grid, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconUser, IconUserOff } from '@tabler/icons-react';
import { CLASSIC_DATE_TIME_FORMAT, NiceResponse } from 'consts';
import { UserAvatar } from 'components';
import { Group, User } from '@jargonic/customer-types';
import dayjs from 'dayjs';

interface GroupPersonalDataProps {
  group: Group;
  user: User;
  onChange: (key: keyof Group, value: string) => Promise<NiceResponse>;
}

export const testIds = {
  wrapper: 'group-personal-data-panel',
  title: 'group-personal-data-title',
  name: 'group-personal-data-name-input',
  createdBy: 'group-personal-data-created-by',
  createdAt: 'group-personal-data-created-at',
};

export const GroupPersonalData = ({ group, user, onChange }: GroupPersonalDataProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleChange = (key: keyof Group) => async (value: string) => {
    if (value === group.name) return;

    setLoading(true);
    const response = await onChange(key, value);
    setLoading(false);

    if (response === NiceResponse.OK) {
      notifications.show({
        color: 'green',
        icon: <IconUser />,
        title: t('userMgmt.groups.groupDetails.panels.updateGroupSuccess.title'),
        message: null,
      });
    } else if (response === NiceResponse.ERROR) {
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.groupDetails.panels.updateGroupError.title'),
        message: t('userMgmt.groups.groupDetails.panels.updateGroupError.message'),
        icon: <IconUserOff />,
      });
    }
  };

  const onChangeName: ChangeEventHandler<HTMLInputElement> = (event) => handleChange('name')(event.target.value);

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) =>
    event.key === 'Enter' && event.currentTarget.blur();

  return (
    <Card p='lg' shadow='zero' data-testid={testIds.wrapper}>
      <Stack>
        <Title order={6} data-testid={testIds.title}>
          {t('userMgmt.groups.groupDetails.panels.details.personalData.title')}
        </Title>
        <Flex gap='md' w='100%'>
          <TextInput
            classNames={{ root: 'grow' }}
            defaultValue={group?.name}
            label={t('common.name')}
            readOnly={loading}
            disabled={loading}
            rightSection={loading ? <Loader size='xs' /> : null}
            onBlur={onChangeName}
            onKeyDown={onKeyDown}
            data-testid={testIds.name}
          />
        </Flex>
        <Divider />
        <Flex gap='md' w='100%'>
          <Title order={6}>{t('common.creationAndUpdates')}</Title>
        </Flex>
        <Flex gap='md' w='100%' bg='var(--mantine-color-gray-0)'>
          <Grid style={{ width: '100%', padding: '15px' }}>
            <Grid.Col span={6}>
              <Text mb={5} size='sm' fw={600}>
                {t('common.createdBy')}
              </Text>
              <Grid align='center'>
                <Grid.Col span={1}>
                  <UserAvatar size='sm' user={user} />
                </Grid.Col>
                <Grid.Col span={11}>
                  <Text
                    data-testid={testIds.createdBy}
                    ml={5}
                    size='sm'
                    fw={500}
                  >{`${group.metadata.createdBy.givenName} ${group.metadata.createdBy.familyName}`}</Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
            <Grid.Col span={6}>
              <Text mb={5} size='sm' fw={600}>
                {t('common.creationTime')}
              </Text>
              <Text size='sm' fw={500} data-testid={testIds.createdAt}>
                {dayjs(group.metadata.createdAt).format(CLASSIC_DATE_TIME_FORMAT)}
              </Text>
            </Grid.Col>
          </Grid>
        </Flex>
      </Stack>
    </Card>
  );
};
