import { Box, Button } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import { useFlowStore } from 'stores/flows';

export const testIds = {
  container: 'delete-non-empty-folder-error-content',
  button: 'delete-non-empty-folder-error-content-reload-button',
};

export const NonEmptyErrorNotificationContent = () => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const { fetchFlows } = useFlowStore(['fetchFlows']);

  const onClickReload = () => {
    notifications.clean();
    fetchFlows(customerId);
  };

  return (
    <Box data-testid={testIds.container}>
      {t('flowsPage.modals.deleteFolder.nonEmptyFolder.errorMessage')}
      <Button variant='default' onClick={onClickReload} data-testid={testIds.button}>
        {t('common.reload')}
      </Button>
    </Box>
  );
};
