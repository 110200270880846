import { Box, Button, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useFocusTrap } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle, IconFolder } from '@tabler/icons-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import i18n from 'services/i18n';
import { Flow, useFlowStore } from 'stores/flows';
import { useFolder } from 'stores/folders';
import { OpenGlobalModalProps, useModal } from 'stores/globalModal';

interface CreateFlowModalProps {
  folderId?: string | null;
  onCreate: (flow: Flow) => void;
}

export const testIds = {
  container: 'create-flow-modal',
  input: 'create-flow-name-input',
  submit: 'create-flow-submit-button',
  cancel: 'create-flow-cancel-button',
  message: 'create-flow-message',
};

interface CreateFlowFormState {
  flowName: string;
}

export const CreateFlowModal = ({ folderId = null, onCreate }: CreateFlowModalProps) => {
  const { t } = useTranslation();
  const focusTrap = useFocusTrap();
  const { flows, upsertFlow } = useFlowStore(['flows', 'upsertFlow']);
  const folder = useFolder(folderId ?? '');
  const flowNameSet = useMemo(
    () => new Set(Object.values(flows).map((flow) => flow.name.toLowerCase().trim())),
    [flows],
  );
  const form = useForm<CreateFlowFormState>({
    initialValues: { flowName: '' },
    validateInputOnBlur: true,
    validate: {
      flowName: (value) => {
        const isDuplicate = flowNameSet.has(value.toLowerCase().trim());
        return isDuplicate ? t('common.noDuplicates') : null;
      },
    },
  });
  const { customerId } = useParams() as CustomerRouteParams;
  const { close } = useModal();
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ flowName }: CreateFlowFormState) => {
    setLoading(true);
    const order = Object.values(flows).filter((flow) => flow.folderId === folderId).length;
    const response = await upsertFlow({
      action: 'create',
      customerId,
      payload: { name: flowName, folderId, order },
    });
    if (response) {
      notifications.show({
        color: 'green',
        title: t('flowsPage.modals.createFlow.successMessage', { name: flowName }),
        icon: <IconFolder />,
        message: null,
      });
      onCreate(response);
      close();
    } else {
      notifications.show({
        color: 'red',
        title: t('flowsPage.modals.createFlow.errorMessage', { name: flowName }),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    }
    setLoading(false);
  };

  return (
    <Box ref={focusTrap} component='form' onSubmit={form.onSubmit(onSubmit)} data-testid={testIds.container}>
      <TextInput
        label={t('flowsPage.modals.createFlow.inputLabel')}
        required
        placeholder={t('flowsPage.modals.createFlow.inputPlaceholder')}
        data-autofocus
        {...form.getInputProps('flowName')}
        data-testid={testIds.input}
      />
      <Text c='gray.6' my='lg' data-testid={testIds.message}>
        {folder
          ? t('flowsPage.modals.createFlow.withFolder.message', { name: folder.name })
          : t('flowsPage.modals.createFlow.withoutFolder.message')}
      </Text>
      <Group justify='end' mt='lg'>
        <Button type='button' variant='subtle' onClick={close} data-testid={testIds.cancel} disabled={loading}>
          {t('common.cancel')}
        </Button>
        <Button type='submit' loading={loading} data-testid={testIds.submit}>
          {t('flowsPage.modals.createFlow.submit')}
        </Button>
      </Group>
    </Box>
  );
};

export const createFlowGlobalModalProps = (props: CreateFlowModalProps): OpenGlobalModalProps => ({
  title: i18n.t('flowsPage.modals.createFlow.title'),
  children: <CreateFlowModal {...props} />,
  variant: 'creation',
});
