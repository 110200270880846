import { Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { EventDefType } from 'stores/wizard';
import { useMemo } from 'react';
import { options, testIds } from './EventDefTypeSelect.const';
import classes from '../../EventDefTypeSettings.module.css';

interface EventDefTypeSelectProps {
  value?: EventDefType;
  onChange: (value: EventDefType) => void;
}

export const EventDefTypeSelect = ({ value, onChange }: EventDefTypeSelectProps) => {
  const { t } = useTranslation();
  const selectedOption = useMemo(() => {
    const currentValue = value === EventDefType.FREE_TEXT ? EventDefType.APPEND_TEXT_EVENT : value;
    return options.find((option) => option.value === currentValue);
  }, [value]);
  const onSelect = (option: string | null) => option !== value && onChange(option as EventDefType);

  return (
    <Select
      allowDeselect={false}
      classNames={{ root: classes.typeSelect }}
      data={options.filter((option) => option.value !== EventDefType.FREE_TEXT)}
      label={t('wizard.steps.reportedEvents.typeSettings.typeSelect.label')}
      leftSection={selectedOption?.icon}
      placeholder={t('wizard.steps.reportedEvents.typeSettings.typeSelect.placeholder')}
      value={selectedOption?.value}
      onChange={onSelect}
      data-testid={testIds.select}
    />
  );
};
