import { Box, Button, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useFocusTrap } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle, IconFolder } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import i18n from 'services/i18n';
import { Folder, useFolderStore } from 'stores/folders';
import { OpenGlobalModalProps, useModal } from 'stores/globalModal';

interface CreateFolderModalProps {
  onCreate?: (newFolder: Folder) => void;
}

export const testIds = {
  container: 'create-folder-modal',
  input: 'create-folder-name-input',
  submit: 'create-folder-submit-button',
  cancel: 'create-folder-cancel-button',
  message: 'create-folder-message',
};

interface CreateFolderFormState {
  name: string;
}

export const CreateFolderModal = ({ onCreate: afterSubmit }: CreateFolderModalProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const focusTrap = useFocusTrap();
  const { close } = useModal();
  const [loading, setLoading] = useState(false);
  const form = useForm<CreateFolderFormState>({ initialValues: { name: '' } });
  const { folders, upsertFolder } = useFolderStore(['folders', 'upsertFolder']);

  const onSubmit = async (values: CreateFolderFormState) => {
    setLoading(true);

    const folderName = values.name || t('flowsPage.modals.createFolder.defaultValue');

    const order = Object.keys(folders).length;

    const existingFolderNames = Object.values(folders).map((folder) => folder.name.trim().toLowerCase());

    if (existingFolderNames.includes(folderName.trim().toLowerCase())) {
      notifications.show({
        color: 'red',
        title: t('flowsPage.modals.createFolder.duplicateErrorMessage', { name: folderName }),
        icon: <IconAlertTriangle />,
        message: null,
      });
      setLoading(false);
      return;
    }

    const response = await upsertFolder({ action: 'create', customerId, payload: { name: folderName, order } });

    if (response) {
      notifications.show({
        color: 'green',
        title: t('flowsPage.modals.createFolder.successMessage', { name: folderName }),
        icon: <IconFolder />,
        message: null,
      });
      close();
      afterSubmit?.(response);
    } else {
      notifications.show({
        color: 'red',
        title: t('flowsPage.modals.createFolder.errorMessage', { name: folderName }),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    }

    setLoading(false);
  };

  return (
    <Box ref={focusTrap} component='form' onSubmit={form.onSubmit(onSubmit)} data-testid={testIds.container}>
      <TextInput
        label={t('flowsPage.modals.createFolder.inputLabel')}
        placeholder={t('flowsPage.modals.createFolder.inputPlaceholder')}
        data-autofocus
        {...form.getInputProps('name')}
        data-testid={testIds.input}
      />
      <Text c='gray.6' my='lg' data-testid={testIds.message}>
        {t('flowsPage.modals.createFolder.message')}
      </Text>
      <Group justify='end' mt='lg'>
        <Button variant='subtle' onClick={close} data-testid={testIds.cancel} disabled={loading}>
          {t('common.cancel')}
        </Button>
        <Button type='submit' disabled={!form.values.name.trim()} loading={loading} data-testid={testIds.submit}>
          {t('flowsPage.modals.createFolder.submit')}
        </Button>
      </Group>
    </Box>
  );
};

export const createFolderGlobalModalProps = (props: CreateFolderModalProps): OpenGlobalModalProps => ({
  title: i18n.t('flowsPage.modals.createFolder.title'),
  children: <CreateFolderModal {...props} />,
  variant: 'creation',
});
