import { Checkbox } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';

export interface ExtractEventsCheckboxProps {
  checked?: boolean;
  rootClassName?: string;
  onChange: (checked: boolean) => void;
}

export const testIds = {
  label: 'extract-values-label',
  checkbox: 'extract-values-checkbox',
};

export const ExtractEventsCheckbox = ({ checked = false, rootClassName, onChange }: ExtractEventsCheckboxProps) => {
  const { t } = useTranslation();

  return (
    <Checkbox
      classNames={{ root: rootClassName }}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.extractEventsCheckbox.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.extractEventsCheckbox.title')}
        </InfoLabel>
      }
      checked={Boolean(checked)}
      onChange={(event) => onChange(event.currentTarget.checked)}
      data-testid={testIds.checkbox}
    />
  );
};
