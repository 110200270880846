import { Container as ApiContainer, ContainerId, CreateContainerRequest } from '@jargonic/containers-types';
import { Container, ContainersData } from './containers.types';

export function containerFromApi(container: ApiContainer): Container {
  return {
    ...container,
    order: container.order ?? 0,
    childrenIds: container.childrenIds ?? [],
  };
}

export function containerToApi(container: Container): CreateContainerRequest {
  const { childrenIds: _cIds, parentId: _pId, ...rest } = container;
  return rest;
}
export function dataFromApi(containers: ApiContainer[]): ContainersData {
  return containers
    .map((container) => containerFromApi(container))
    .reduce<Record<ContainerId, Container>>((acc, container) => {
      acc[container.id] = container;
      return acc;
    }, {});
}
