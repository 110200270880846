export const getDataGenerationQuery = `
query GetDataGeneration($executionId: ID!) {
    getDataGeneration(executionId: $executionId) {
      currentStep {
        stepName
        executionId
      }
      executionId
      state
      startTimestamp
      endTimestamp
      triggeredBy
      onlyTextSamples
      onlyVoiceSamples
      sampleExecutionId
      initialSamplesCount
      settings {
        voiceGenSettings {
          noiseRatio
          sliceRatio
        }
        textGenSettings {
          asrAugRatio
          numberOfSamplesPerTemplate
          punctuationAugRatio
          structuredTextGen
        }
        customerConfigLocation
        customerConfigPromptsLocation
        customerPredefinedTemplateLocation
        externalTextFileLocation
        language
        samplesCount
      }
    }
  }
`;

export const listDataGenerationsQuery = `
  query ListDataGenerations($listDataGenerationsInput: ListDataGenerationsInput!) {
    listDataGenerations(listDataGenerationsInput: $listDataGenerationsInput) {
      dataGenerations {
        executionId
        startTimestamp
        state
        triggeredBy
        onlyTextSamples
        onlyVoiceSamples
        sampleExecutionId
        initialSamplesCount
        settings {
          voiceGenSettings {
            noiseRatio
            sliceRatio
          }
          textGenSettings {
            asrAugRatio
            numberOfSamplesPerTemplate
            punctuationAugRatio
            structuredTextGen
          }
          customerConfigLocation
          customerConfigPromptsLocation
          customerPredefinedTemplateLocation
          externalTextFileLocation
          language
          samplesCount
        }
      }
      paginationToken
    }
  }
`;
