import { Box, Flex, ThemeIcon, Title } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import { Group } from 'stores/userMgmt';

interface GroupDetailsProps {
  group: Group;
}

export const testIds = {
  wrapper: 'group-details',
  icon: 'group-icon',
  name: 'group-details-name',
};

export const GroupDetails = ({ group, ...props }: GroupDetailsProps) => (
  <Flex gap='sm' align='center' data-testid={testIds.wrapper} {...props}>
    <ThemeIcon variant='light' radius='xl' size='md' color='teal'>
      <IconUsers size={20} data-testid={testIds.icon} />
    </ThemeIcon>
    <Box>
      <Title order={5} fw={700} lh={1} data-testid={testIds.name}>
        {group.name}
      </Title>
    </Box>
  </Flex>
);
