export const testIds = {
  table: 'event-def-table-wrapper',
  getRowTestId: (id: string) => `event-def-table-row-${id}`,
  expandCell: 'event-def-table-expand-button',
  mainBadge: 'event-def-table-main-badge',
  emptyState: {
    container: 'event-def-table-empty-state',
    createButton: 'event-def-table-empty-state-create-button',
  },
  create: {
    button: 'event-def-table-create-button',
    icon: 'event-def-table-create-icon',
  },
};
