import { ReportedEventDefValue, ReportedEventDefValueId } from '@jargonic/event-definition-types';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { DraggableValueItem } from './DraggableValueItem';

interface DroppableValueListProps {
  eventValues: ReportedEventDefValue[];
  onChange: (valueId: ReportedEventDefValueId, partialValue: Partial<ReportedEventDefValue>) => boolean;
  onDelete: (valueId: ReportedEventDefValueId) => void;
}

export const testIds = {
  list: 'droppable-value-list',
};

export const DroppableValueList = ({ eventValues, onChange, onDelete }: DroppableValueListProps) => {
  const handleItemChange = (valueId: ReportedEventDefValueId) => (partialValue: Partial<ReportedEventDefValue>) =>
    onChange(valueId, partialValue);
  const handleItemDelete = (valueId: ReportedEventDefValueId) => () => onDelete(valueId);

  return (
    <SortableContext items={eventValues.map((value) => value.id)} strategy={verticalListSortingStrategy}>
      {eventValues.map((eventValue) => (
        <DraggableValueItem
          key={eventValue.id}
          eventValue={eventValue}
          onChange={handleItemChange(eventValue.id)}
          onDelete={handleItemDelete(eventValue.id)}
        />
      ))}
    </SortableContext>
  );
};
