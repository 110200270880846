import { Badge } from '@mantine/core';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportedEventDef } from 'stores/wizard';
import { testIds } from '../definitions';

export function useEventDefTableColumns() {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<ReportedEventDef>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        mantineTableBodyCellProps: ({ row }) => ({
          px: row.original.parentId ? 'lg' : undefined,
        }),
      },
      {
        accessorKey: 'isMainEvent',
        header: '',
        size: 65,
        grow: false,
        Cell: ({ row }) =>
          row.original.isMainEvent && (
            <Badge size='sm' data-testid={testIds.mainBadge}>
              {t('common.main')}
            </Badge>
          ),
      },
    ],
    [],
  );
}
