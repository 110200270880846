import { Flex, Text } from '@mantine/core';
import { MRT_ColumnDef } from 'mantine-react-table';
import { AiPublishedModel } from 'stores/aiPlatform/aiPublishedModels';

export const testIds = {
  avatar: 'published-model-table-created-by-avatar',
};

export const CreatedByCell: NonNullable<MRT_ColumnDef<AiPublishedModel>['Cell']> = ({ cell: { getValue } }) => {
  const createdBy = getValue<string>();
  return (
    <Flex align='center' gap='md'>
      <Text>{createdBy}</Text>
    </Flex>
  );
};
