import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components';
import { Policy } from '@jargonic/customer-types';
import { PoliciesTableState } from '../PoliciesTable.types';

export const testIds = { wrapper: 'policies-table-empty-state' };

export const PoliciesTableEmptyState: NonNullable<MRT_TableOptions<Policy>['renderEmptyRowsFallback']> = ({
  table,
}) => {
  const { t } = useTranslation();
  const { globalFilter } = table.getState() as PoliciesTableState;

  return (
    <EmptyState
      title={
        globalFilter
          ? t('userMgmt.groups.groupDetails.panels.policies.table.emptyState.emptySearch.title')
          : t('userMgmt.groups.groupDetails.panels.policies.table.emptyState.noItems.title')
      }
      message={
        globalFilter ? undefined : t('userMgmt.groups.groupDetails.panels.policies.table.emptyState.noItems.message')
      }
      data-testid={testIds.wrapper}
    />
  );
};
