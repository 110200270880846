import { Box, Checkbox } from '@mantine/core';
import { EmptyState } from 'components';
import { useTranslation } from 'react-i18next';
import { MappingContainer } from './ContainerMappingModal.types';
import classes from './ContainerMappingModal.module.css';

interface ContainerMappingGridProps {
  containers: MappingContainer[];
  onSelect: (containerId: string) => void;
}

export const testIds = {
  getCheckboxTestId: (containerId: string) => `container-mapping-modal-checkbox-${containerId}`,
};

export const ContainerMappingGrid = ({ containers, onSelect }: ContainerMappingGridProps) => {
  const { t } = useTranslation();

  const handleChangeSelected = (containerId: string) => () => onSelect(containerId);

  return containers.length ? (
    <Box className={classes.grid}>
      {containers.map((container) => (
        <Checkbox
          key={container.id}
          checked={container.selected}
          color='gray'
          label={container.name}
          maw={270}
          variant='outline'
          onChange={handleChangeSelected(container.id)}
          data-testid={testIds.getCheckboxTestId(container.id)}
        />
      ))}
    </Box>
  ) : (
    <EmptyState mt='md' title={t('wizard.steps.reportedEvents.generalSettings.mapping.modal.emptySearch.title')} />
  );
};
