import { ModelType, State } from '@ai-platform/common-types';
import { createStoreHook } from '@aiola/frontend';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { AiModelId, AiModelSubsciptionOptions, AiPublishedModel } from '../aiModels';
import { AiPublishedDataDto, aiPublishedModelsApi } from './api/aiPublishedModels.api';

interface AiPublishedModelsState {
  loading: boolean;
  aiPublishedModels: Record<AiModelId, AiPublishedModel>;
  subscriptions: Record<AiModelId, NodeJS.Timeout>;
  paginationToken: string;
}

interface AiPublishedModelsActions {
  fetchPublishedModels: (
    data: AiPublishedDataDto,
    paginationToken?: string,
  ) => Promise<{
    aiPublishedModels: Record<string, unknown> | Record<string, AiPublishedModel<ModelType>>;
    paginationToken?: string;
  }>;
  subscribeToAiModel: (modelId: AiModelId, options?: AiModelSubsciptionOptions) => () => void;
  startPublishExec: (
    trainingExecutionId: string,
    environment: string,
  ) => Promise<AiPublishedModel<ModelType> | undefined>;
  reset: () => void;
  internal: {
    unsubscribeFromAiModel: (modelId: AiModelId) => void;
  };
}

const initialState: AiPublishedModelsState = {
  loading: false,
  paginationToken: '',
  aiPublishedModels: {},
  subscriptions: {},
};

export const aiPublishedModelStore = create(
  immer<AiPublishedModelsState & AiPublishedModelsActions>((set, get) => ({
    ...initialState,
    reset: () => {
      Object.values(get().subscriptions).forEach(clearInterval);
      set(initialState);
    },
    fetchPublishedModels: async (modelDto: AiPublishedDataDto, paginationToken: string = '') => {
      set({ loading: true });
      const modelId = `${modelDto.tenantId}-${modelDto.flowId}-${modelDto.activeVersion}-${modelDto.modelType}-${modelDto.mainLocale}`;

      const dto = {
        modelId,
        paginationToken,
      };
      const { aiPublishedModels, paginationToken: newPaginationToken } =
        await aiPublishedModelsApi.getPublishedAiModels(dto);

      const prevState = get().aiPublishedModels;

      set(() => ({
        aiPublishedModels: { ...prevState, ...aiPublishedModels },
        loading: false,
        paginationToken: newPaginationToken,
      }));

      return {
        aiPublishedModels: { ...prevState, ...aiPublishedModels },
        paginationToken: newPaginationToken,
      };
    },
    subscribeToAiModel: (id: string, { onComplete, unsubscribeOnFinish }: AiModelSubsciptionOptions = {}) => {
      if (get().subscriptions[id]) {
        return () => null;
      }
      const interval = aiPublishedModelsApi.subscribeToPublishedModel(id, (publishExecutionData) => {
        if (!publishExecutionData) {
          return;
        }

        const publishedModel = get().aiPublishedModels[publishExecutionData.publishExecutionId];
        const prevStatus = publishedModel?.status;
        const { status } = publishExecutionData;
        // When status is completed/failed, we don't expect changes, so unsubscribe
        if (unsubscribeOnFinish && (status === State.DONE || status === State.FAILED)) {
          get().internal.unsubscribeFromAiModel(id);
        }
        if (onComplete && status === State.DONE && prevStatus !== status) {
          onComplete();
        }
        set((state) => {
          state.aiPublishedModels[publishExecutionData.publishExecutionId] = publishExecutionData;
        });
      });
      set((state) => {
        state.subscriptions[id] = interval;
      });
      return () => get().internal.unsubscribeFromAiModel(id);
    },
    startPublishExec: async (trainingExecutionId: string, env: string) => {
      const response = await aiPublishedModelsApi.publishModel(trainingExecutionId, env);
      if (!response) {
        return undefined;
      }
      const { publishExecution } = await aiPublishedModelsApi.getPublishedModel(response);
      if (!publishExecution) {
        return undefined;
      }
      set((state) => {
        state.aiPublishedModels[publishExecution.publishExecutionId] = publishExecution;
      });

      return publishExecution;
    },
    internal: {
      unsubscribeFromAiModel: (modelId: AiModelId) => {
        clearInterval(get().subscriptions[modelId]);
        set((state) => {
          delete state.subscriptions[modelId];
        });
      },
    },
  })),
);

export const useAiPublishedModelStore = createStoreHook<AiPublishedModelsState & AiPublishedModelsActions>({
  store: aiPublishedModelStore,
  useShallow,
});
