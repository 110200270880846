import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { FlowStatus, flowStore } from 'stores/flows';
import { CreateLabelPayload, Label, LabelId, UpdateLabelPayload, UpdateManyLabelsPayload } from './labels.types';
import { labelApi } from './labels.api';
import { getNewLabelPayload } from './labels.utils';

interface LabelState {
  loading: boolean;
  labels: Record<LabelId, Label>;
}

interface LabelActions {
  fetchData: (customerId: string, flowId: string) => Promise<boolean>;
  createLabel: (customerId: string, flowId: string, payload: CreateLabelPayload) => Promise<Label | undefined>;
  updateLabel: (
    customerId: string,
    flowId: string,
    labelId: string,
    payload: UpdateLabelPayload,
  ) => Promise<Label | undefined>;
  updateManyLabels: (
    customerId: string,
    flowId: string,
    payload: UpdateManyLabelsPayload,
  ) => Promise<Label[] | undefined>;
  deleteLabel: (customerId: string, flowId: string, labelId: string) => Promise<boolean>;
  reset: () => void;
}

const initialState: LabelState = {
  loading: false,
  labels: {},
};

export const labelStore = create(
  immer<LabelState & LabelActions>((set, get) => ({
    ...initialState,
    fetchData: async (customerId, flowId) => {
      set({ loading: true });
      const labels = await labelApi.getLabels(customerId, flowId);
      const labelObj = labels?.reduce((acc, label) => ({ ...acc, [label.id]: label }), {});
      set({ loading: false, labels: labelObj ?? {} });
      return Boolean(labels);
    },
    createLabel: async (customerId, flowId, payload) => {
      const { labels } = get();
      const newLabelPayload = getNewLabelPayload(payload, labels);
      const response = await labelApi.createLabel(customerId, flowId, newLabelPayload);
      if (response)
        set((state) => {
          state.labels[response.id] = response;
        });
      return response;
    },
    updateLabel: async (customerId, flowId, labelId, payload) => {
      const label = get().labels[labelId];
      const updatePayload = { ...label, ...payload };
      const response = await labelApi.updateLabel(customerId, flowId, labelId, updatePayload);
      if (response) {
        set((state) => {
          state.labels[labelId] = response;
        });
        flowStore.getState().updateFlows([{ id: flowId, status: FlowStatus.DRAFT }]);
      }
      return response;
    },
    updateManyLabels: async (customerId, flowId, payload) => {
      const { labels } = get();
      const payloads = payload.map(([labelId, partialLabel]) => {
        const label = structuredClone(labels[labelId]);
        Object.assign(label, partialLabel);
        return label;
      });
      const response = labelApi.bulkUpdateLabels(customerId, flowId, payloads);
      return response;
    },
    deleteLabel: async (customerId, flowId, labelId) => {
      const response = await labelApi.deleteLabel(customerId, flowId, labelId);
      if (response)
        set((state) => {
          delete state.labels[labelId];
        });
      return response;
    },
    reset: () => set(initialState),
  })),
);

export const useLabelStore = createStoreHook({ store: labelStore, useShallow });
