import { Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { PolicyTable, testIds as tableTestIds } from './PolicyTable/PolicyTable';

export const testIds = {
  title: 'policies-page-title',
  table: tableTestIds,
};

export const PoliciesPage = () => {
  const { t } = useTranslation();

  return (
    <Stack h='100%'>
      <Title order={5} h={40} className='flex items-center' fw={400} data-testid={testIds.title}>
        {t('userMgmt.navbar.policies')}
      </Title>
      <PolicyTable />
    </Stack>
  );
};
