import { Breadcrumbs, Chip, ChipVariant } from '@mantine/core';
import { IconCheck, IconChevronRight, IconSettings } from '@tabler/icons-react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { ROUTES, WizardRouteParams } from 'routes/routes.config';
import { WizardStep, wizardSteps } from '../../WizardLayout.steps';

export const testIds = {
  wrapper: 'wizard-stepper-wrapper',
  getChipTestId: (key: WizardStep) => `wizard-stepper-chip-${key}`,
  chipRegex: /wizard-stepper-chip-.*/,
  icons: {
    settings: 'wizard-stepper-settings-icon',
    check: 'wizard-stepper-check-icon',
  },
};

export const WizardStepper = () => {
  const { customerId, flowId } = useParams() as WizardRouteParams;
  const match = useMatch(ROUTES.WIZARD_STEP());
  const step = match?.params.step as WizardStep;
  const navigate = useNavigate();
  const currentStepIndex = wizardSteps.findIndex((s) => s.step === step);

  // TODO check for unsaved changes before navigating
  const onClickStep = (newStep: string) => navigate(ROUTES.WIZARD_STEP(customerId, flowId, newStep as WizardStep));
  const getStepIndex = (key: WizardStep) => wizardSteps.findIndex((s) => s.step === key);

  const getVariant = (key: WizardStep): ChipVariant => {
    if (key === step) return 'light';
    const newStepIndex = getStepIndex(key);
    if (newStepIndex > currentStepIndex) return 'outline';
    return 'filled';
  };

  const getIcon = (key: WizardStep) => {
    // TODO icon should change based on flow completed steps situation
    const newStepIndex = getStepIndex(key);
    return newStepIndex >= currentStepIndex ? (
      <IconSettings width={14} data-testid={testIds.icons.settings} />
    ) : (
      <IconCheck width={16} data-testid={testIds.icons.check} />
    );
  };

  return (
    <Breadcrumbs separator={<IconChevronRight />} data-testid={testIds.wrapper} mx='auto'>
      {wizardSteps.map(({ step: key, label }) => (
        <Chip
          key={key}
          variant={getVariant(key)}
          icon={getIcon(key)}
          checked
          value={key}
          onClick={() => onClickStep(key)}
          data-testid={testIds.getChipTestId(key)}
        >
          {label}
        </Chip>
      ))}
    </Breadcrumbs>
  );
};
