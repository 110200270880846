import { ComboboxItem, Select } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';

interface DecimalSelectProps {
  rootClassName?: string;
  value: number;
  onChange: (value?: number) => void;
}

export const testIds = {
  select: 'decimal-select',
  label: 'decimal-select-label',
};

const options = ['0', '0.0', '0.00', '0.000'].map<ComboboxItem>((value, index) => ({
  value: index.toString(),
  label: value,
}));

export const DecimalSelect = ({ value, rootClassName, onChange }: DecimalSelectProps) => {
  const { t } = useTranslation();

  const onSelect = (newValue: string | null) => {
    const newNumberValue = Number(newValue);
    onChange(newNumberValue);
  };

  return (
    <Select
      data={options}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.types.number.decimalInput.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.types.number.decimalInput.title')}
        </InfoLabel>
      }
      value={value?.toString()}
      allowDeselect={false}
      classNames={{ root: rootClassName }}
      placeholder={t('wizard.steps.reportedEvents.typeSettings.types.number.decimalInput.placeholder')}
      onChange={onSelect}
      data-testid={testIds.select}
    />
  );
};
