import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components';
import { User } from 'stores/auth';
import { UsersTableState } from '../UsersTable.types';

export const testIds = { wrapper: 'users-table-empty-state' };

export const UsersTableEmptyState: NonNullable<MRT_TableOptions<User>['renderEmptyRowsFallback']> = ({ table }) => {
  const { t } = useTranslation();
  const { globalFilter } = table.getState() as UsersTableState;

  return (
    <EmptyState
      title={
        globalFilter
          ? t('userMgmt.groups.groupDetails.panels.users.table.emptyState.emptySearch.title')
          : t('userMgmt.groups.groupDetails.panels.users.table.emptyState.noItems.title')
      }
      message={
        globalFilter ? undefined : t('userMgmt.groups.groupDetails.panels.users.table.emptyState.noItems.message')
      }
      data-testid={testIds.wrapper}
    />
  );
};
