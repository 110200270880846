import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { groupBy, parseCSV } from 'utils';
import { NumericValidationType } from '@jargonic/event-definition-types';
import { WizardStoreActions, WizardStoreState } from '../wizard.types';
import {
  EvenDefValidationId,
  EventDefType,
  EventValueCSVColumnName,
  EventValueCSVParsedPayload,
  EventValueCSVResponse,
  ReportedEventDef,
  ReportedEventDefValue,
  ReportedEventDefValueId,
  ReportedEventDefinitionId,
  ReportedEventsData,
  UpdatableEventDefFields,
  UpdateValidationPayload,
  UpdateValuePayload,
  validationInputType,
} from './eventDefs.types';
import { EVENT_DEFINITION_SESSION_STORAGE_KEY } from './eventDefs.const';
import { eventDefApi } from './eventDefs.api';
import {
  addContainerIds,
  findMainEvent,
  getChildEventDefs,
  getDefaultEventDef,
  getDefaultEventValidation,
  getDefaultEventValue,
  getEventValuesFromCSVPayload,
  getLabelEvents,
  getLabelMainEventUpdatePayload,
  getRootEventDefs,
  getSiblingEventDefs,
  removeContainerIds,
  softResetEventDef,
  updateEventDefContainers,
} from './eventDefs.utils';
import { createWizardStoreSlice } from '../wizard.slice';
import { LabelId, labelStore } from '../labels';
import { reportedEventFromApi, reportedEventToApi } from './eventDefs.adapters';
import { Container, ContainerId } from '../containers';
import { validateEventDefs } from './eventDefs.validation';

interface EventDefState extends WizardStoreState<ReportedEventsData> {}

interface EventDefActions extends WizardStoreActions {
  createEventDef: (
    labelId: LabelId,
    parentId?: ReportedEventDefinitionId,
    containerIds?: ContainerId[],
  ) => ReportedEventDef;
  updateEventDef: (id: ReportedEventDefinitionId, values: UpdatableEventDefFields) => void;
  updateMainEvent: (id: ReportedEventDefinitionId, labelId: LabelId) => void;
  updateEventDefType: (id: ReportedEventDefinitionId, type: EventDefType) => void;
  updateEventValue: (id: ReportedEventDefinitionId, payload: UpdateValuePayload) => void;
  /** Read values from CSV file. Expected column names are `name`, `synonyms`, and `valid` */
  createValuesFromFile: (id: ReportedEventDefinitionId, file: File) => Promise<EventValueCSVResponse>;
  updateEventValidation: (id: ReportedEventDefinitionId, payload: UpdateValidationPayload) => void;
  updateEventMapping: (id: ReportedEventDefinitionId, containerIds: ContainerId[]) => void;
  /** Remove given container IDs from any mapped events/validations */
  unmapContainers: (containers: Container[]) => void;
  repositionEventDef: (id: ReportedEventDefinitionId, insertBefore: ReportedEventDefinitionId) => boolean;
  deleteEventDef: (id: ReportedEventDefinitionId, shouldDeleteChildren: boolean) => void;
  internal: {
    deleteChildren: (parentId: string) => void;
    moveChildrenToRoot: (parentId: string) => void;
    createEventValue: (id: ReportedEventDefinitionId, name?: string) => void;
    updateEventValue: (
      id: ReportedEventDefinitionId,
      eventValueId: ReportedEventDefValueId,
      payload: Partial<ReportedEventDefValue>,
    ) => void;
    deleteEventValue: (id: ReportedEventDefinitionId, valueId: ReportedEventDefValueId) => void;
    reorderEventValues: (id: ReportedEventDefinitionId, valueIds: ReportedEventDefValueId[]) => void;
    replaceEventMapping: (id: ReportedEventDefinitionId, containerIds: ContainerId[]) => void;
    updateEventMapping: (
      id: ReportedEventDefinitionId,
      args: {
        idsToRemove?: ContainerId[];
        idsToAdd?: ContainerId[];
      },
    ) => void;
    createEventValidation: (
      id: ReportedEventDefinitionId,
      validationType: validationInputType,
      numericType: NumericValidationType,
    ) => void;
    updateEventValidation: (
      id: ReportedEventDefinitionId,
      validationId: string,
      payload: Partial<ReportedEventDefValue>,
    ) => void;
    deleteEventValidation: (id: ReportedEventDefinitionId, validationId: EvenDefValidationId) => void;
  };
}

export const eventDefStore = create(
  immer<EventDefState & EventDefActions>((set, get, ...args) => ({
    ...createWizardStoreSlice<ReportedEventsData>({
      initialData: {},
      sessionStorageKey: EVENT_DEFINITION_SESSION_STORAGE_KEY,
      fetchData: async (customerId, flowId) => {
        const apiEventDefs = await eventDefApi.getEventDefs(customerId, flowId);
        const eventDefs = apiEventDefs?.map((apiEventDef) =>
          reportedEventFromApi(apiEventDef, labelStore.getState().labels),
        );
        return eventDefs && eventDefs.reduce((acc, event) => ({ ...acc, [event.id]: event }), {});
      },
      saveData: async (customerId, flowId, data) => {
        const { labels } = labelStore.getState();
        const labelsToUpdate = getLabelMainEventUpdatePayload(Object.values(labels), data);
        await labelStore.getState().updateManyLabels(customerId, flowId, labelsToUpdate);
        const apiEventDefs = Object.values(data).map(reportedEventToApi);
        const updatedEvents = await eventDefApi.upsertEventDefs(customerId, flowId, apiEventDefs);
        return Boolean(updatedEvents);
      },
      validateData: (data) => {
        const { labels } = labelStore.getState();
        return validateEventDefs(data, labels);
      },
      sliceArgs: [set, get, ...args],
    }),
    createEventDef: (labelId, parentId, containerIds = []) => {
      const { data } = get();
      const siblings = parentId ? getChildEventDefs(data, parentId) : getRootEventDefs(data);
      const siblingCount = siblings.length;
      const newEvent = getDefaultEventDef(data, labelId, parentId, containerIds, siblingCount);
      set((state) => {
        state.data[newEvent.id] = newEvent;
        state.dirty = true;
      });
      return newEvent;
    },
    updateEventDef: (id, values) => {
      set((state) => {
        const eventDef = state.data[id];
        if (state.data[id]) {
          Object.assign(eventDef, values);
          state.dirty = true;
        }
      });
    },
    repositionEventDef: (movedId, targetId) => {
      const { data } = get();
      const targetEventDef = data[movedId];
      const insertBeforeEventDef = data[targetId];
      const isMoveWithinScope = targetEventDef.parentId === insertBeforeEventDef.parentId;
      if (!isMoveWithinScope) return false;

      const siblingEventDefs = getSiblingEventDefs(data, targetEventDef);
      const indexToMove = siblingEventDefs.findIndex((event) => event.id === movedId);
      const [splicedTarget] = siblingEventDefs.splice(indexToMove, 1);
      const newIndex = siblingEventDefs.findIndex((event) => event.id === targetId);
      siblingEventDefs.splice(newIndex, 0, splicedTarget);
      set((state) => {
        siblingEventDefs.forEach(({ id }, index) => (state.data[id].order = index));
        state.dirty = true;
      });
      return true;
    },
    updateMainEvent: (id, labelId) => {
      const currentMainEvent = findMainEvent(get().data, labelId);
      set((state) => {
        if (currentMainEvent) state.data[currentMainEvent.id].isMainEvent = false;
        if (currentMainEvent?.id !== id) state.data[id].isMainEvent = true;
        state.dirty = true;
      });
    },
    updateEventDefType: (id, type) => {
      set((state) => {
        const eventDef = state.data[id];
        if (eventDef) softResetEventDef(eventDef, type);
        state.dirty = true;
      });
    },
    updateEventValue: (id, { action, payload }) => {
      const { internal } = get();
      switch (action) {
        case 'create':
          internal.createEventValue(id, payload);
          break;
        case 'update':
          internal.updateEventValue(id, payload.id, payload);
          break;
        case 'delete':
          internal.deleteEventValue(id, payload.id);
          break;
        case 'reorder':
          internal.reorderEventValues(id, payload);
          break;
        default:
          break;
      }
      set({ dirty: true });
    },
    createValuesFromFile: async (eventId, file) => {
      try {
        const parsed = await parseCSV<EventValueCSVParsedPayload>(file);
        if (!parsed.meta.fields?.includes(EventValueCSVColumnName.NAME)) return EventValueCSVResponse.MISSING_NAME;

        const newValues = getEventValuesFromCSVPayload(parsed.data);
        set((state) => {
          state.data[eventId].values?.push(...newValues);
          state.dirty = true;
        });
        const { values: updatedEventValues } = get().data[eventId];
        const updatedNameSet = new Set(updatedEventValues?.map((v) => v.name.toLowerCase()));
        const hasDuplicatedNewNames = updatedEventValues?.length !== updatedNameSet.size;
        return hasDuplicatedNewNames ? EventValueCSVResponse.DUPLICATE_NAME : EventValueCSVResponse.OK;
      } catch {
        return EventValueCSVResponse.ERROR;
      }
    },
    updateEventValidation: (id, { action, payload }) => {
      switch (action) {
        case 'create':
          get().internal.createEventValidation(id, payload, NumericValidationType.GREATER_THEN);
          break;
        case 'update':
          get().internal.updateEventValidation(id, payload.id, payload);
          break;
        case 'delete':
          get().internal.deleteEventValidation(id, payload);
          break;
        default:
          break;
      }
      set({ dirty: true });
    },
    deleteEventDef: (id, shouldDeleteChildren) => {
      const { data } = get();
      const siblingEvents = getSiblingEventDefs(data, data[id]);
      const siblingsToMove = siblingEvents.slice(siblingEvents.indexOf(data[id]) + 1);
      const siblingIdsToMove = siblingsToMove.map((event) => event.id);
      set((state) => {
        if (state.data[id]) {
          delete state.data[id];
          siblingIdsToMove.forEach((siblingId) => {
            state.data[siblingId].order -= 1;
          });
          state.dirty = true;
        }
      });
      const { deleteChildren, moveChildrenToRoot } = get().internal;
      if (shouldDeleteChildren) deleteChildren(id);
      else moveChildrenToRoot(id);
    },
    updateEventMapping: (eventId, containerIds) => {
      const { internal } = get();
      internal.replaceEventMapping(eventId, containerIds);
      set({ dirty: true });
    },
    unmapContainers: (containers) => {
      const { internal, data } = get();
      const labelContainersMap = groupBy(containers, 'typeId');
      Object.keys(labelContainersMap).forEach((labelId) => {
        const idsToRemove = labelContainersMap[labelId].map((container) => container.id);
        const labelEvents = getLabelEvents(labelId, data);
        labelEvents.map((event) => internal.updateEventMapping(event.id, { idsToRemove }));
      });
      set({ dirty: true });
    },
    internal: {
      deleteChildren: (parentId) => {
        set((state) => {
          Object.values(state.data)
            .filter((event) => event.parentId === parentId)
            .forEach((child) => {
              delete state.data[child.id];
            });
        });
      },
      moveChildrenToRoot: (parentId) => {
        const rootEventDefs = getRootEventDefs(get().data);
        const n = rootEventDefs.length;
        set((state) => {
          Object.values(state.data)
            .filter((event) => event.parentId === parentId)
            .forEach((child, index) => {
              child.parentId = undefined;
              child.order = n + index;
              delete child.bindingValues;
            });
        });
      },
      createEventValue: (eventDefId, name) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          if (!eventDef.values) eventDef.values = [];
          eventDef.values.push(getDefaultEventValue(name));
        });
      },
      updateEventValue: (eventDefId, valueId, payload) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          const value = eventDef.values!.find((v) => v.id === valueId);
          if (value) Object.assign(value, payload);
        });
      },
      deleteEventValue: (eventDefId, valueId) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          eventDef.values = eventDef.values!.filter((v) => v.id !== valueId);
          if (eventDef.default === valueId) eventDef.default = undefined;
        });
      },
      reorderEventValues: (eventDefId, valueIds) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          eventDef.values = valueIds.map((id) => eventDef.values!.find((v) => v.id === id)!);
        });
      },
      replaceEventMapping: (eventDefId, containerIds) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          const isRemovingFromParent = eventDef.containerIds.some((id) => !containerIds.includes(id));
          updateEventDefContainers(eventDef, containerIds);

          if (isRemovingFromParent) {
            const childEventDefs = getChildEventDefs(state.data, eventDefId);
            childEventDefs.forEach((child) => updateEventDefContainers(child, containerIds));
          }
        });
      },
      updateEventMapping: (eventDefId, containerArgs) => {
        const { idsToRemove = [], idsToAdd = [] } = containerArgs;
        set((state) => {
          const eventDef = state.data[eventDefId];
          eventDef.containerIds = removeContainerIds(eventDef.containerIds, idsToRemove);
          eventDef.containerIds = addContainerIds(eventDef.containerIds, idsToAdd);
          eventDef.validations?.forEach((validation) => {
            validation.containerIds = removeContainerIds(validation.containerIds, idsToRemove);
          });
        });
      },
      createEventValidation: (eventDefId, validationType, numericType) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          if (!eventDef.validations) eventDef.validations = [];
          eventDef.validations.push(getDefaultEventValidation(validationType, numericType, eventDef.containerIds));
        });
      },
      updateEventValidation: (eventDefId, validationId, payload) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          const validation = eventDef.validations!.find((v) => v.id === validationId);
          if (validation) Object.assign(validation, payload);
        });
      },
      deleteEventValidation: (eventDefId, validationId) => {
        set((state) => {
          const eventDef = state.data[eventDefId];
          eventDef.validations = eventDef.validations!.filter((v) => v.id !== validationId);
        });
      },
    },
  })),
);

export const useEventDefStore = createStoreHook({ store: eventDefStore, useShallow });
