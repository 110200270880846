import { Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UserMgmtBreadcrumbs, testIds as breadcrumbsTestIds } from './UserMgmtBreadcrumbs';
import classes from './UserMgmtLayout.module.css';

export const testIds = {
  title: 'user-mgmt-header-title',
  breadcrumbs: breadcrumbsTestIds,
};

export const UserMgmtHeader = () => {
  const { t } = useTranslation();

  return (
    <Stack className={classes.header} pt='sm'>
      <UserMgmtBreadcrumbs />
      <Title order={2} data-testid={testIds.title}>
        {t('userMgmt.title')}
      </Title>
    </Stack>
  );
};
