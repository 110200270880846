import { Select } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';
import i18n from 'services/i18n';
import { EventDefElement } from 'stores/wizard';

interface ElementSelectProps {
  value?: EventDefElement;
  rootClassName?: string;
  onChange: (value: EventDefElement) => void;
}

export const testIds = {
  label: 'select-type-element-select-label',
  select: 'select-type-element-select-input',
};

type Data = { value: EventDefElement; label: string };
const options: Data[] = [
  {
    value: EventDefElement.BUTTONS,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.select.elementSelect.buttonsOption'),
  },
  {
    value: EventDefElement.DROPDOWN,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.types.select.elementSelect.dropdownOption'),
  },
];

export const ElementSelect = ({ value, rootClassName, onChange }: ElementSelectProps) => {
  const { t } = useTranslation();

  const onSelect = (newValue: string | null) => {
    if (newValue) onChange(newValue as EventDefElement);
  };

  return (
    <Select
      allowDeselect={false}
      classNames={{ root: rootClassName }}
      data={options}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.types.select.elementSelect.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.types.select.elementSelect.title')}
        </InfoLabel>
      }
      value={value}
      onChange={onSelect}
      data-testid={testIds.select}
    />
  );
};
