import { FlowMetadataFieldType } from '@jargonic/flow-metadata-types';
import { ComboboxData } from '@mantine/core';
import {
  IconAB,
  IconCalendar,
  IconChevronDown,
  IconChevronsDown,
  IconHash,
  IconTextCaption,
} from '@tabler/icons-react';
import i18n from 'services/i18n';

export const fieldTypeIcons = {
  [FlowMetadataFieldType.Number]: IconHash,
  [FlowMetadataFieldType.Date]: IconCalendar,
  [FlowMetadataFieldType.Text]: IconTextCaption,
  [FlowMetadataFieldType.String]: IconAB,
  [FlowMetadataFieldType.Dropdown]: IconChevronDown,
  [FlowMetadataFieldType.MultiselectDropdown]: IconChevronsDown,
};

export const options: ComboboxData = [
  {
    label: i18n.t('wizard.steps.settings.metadata.table.fieldType.string'),
    value: FlowMetadataFieldType.String,
  },
  {
    label: i18n.t('wizard.steps.settings.metadata.table.fieldType.text'),
    value: FlowMetadataFieldType.Text,
  },
  {
    label: i18n.t('wizard.steps.settings.metadata.table.fieldType.number'),
    value: FlowMetadataFieldType.Number,
  },
  {
    label: i18n.t('wizard.steps.settings.metadata.table.fieldType.date'),
    value: FlowMetadataFieldType.Date,
  },
  {
    label: i18n.t('wizard.steps.settings.metadata.table.fieldType.dropdown'),
    value: FlowMetadataFieldType.Dropdown,
  },
  {
    label: i18n.t('wizard.steps.settings.metadata.table.fieldType.multiSelect'),
    value: FlowMetadataFieldType.MultiselectDropdown,
  },
];
