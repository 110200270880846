import { Button } from '@mantine/core';
import { EmptyState } from 'components';
import { useTranslation } from 'react-i18next';
import { testIds } from '../definitions';

interface EventDefEmptyStateProps {
  onCreate: () => void;
}

export const EventDefEmptyState = ({ onCreate }: EventDefEmptyStateProps) => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('wizard.steps.reportedEvents.eventTable.emptyState.title')}
      data-testid={testIds.emptyState.container}
    >
      <Button onClick={onCreate} data-testid={testIds.emptyState.createButton}>
        {t('wizard.steps.reportedEvents.eventTable.emptyState.createButton')}
      </Button>
    </EmptyState>
  );
};
