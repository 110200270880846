import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { flowStore } from 'stores/flows';
import { FlowStatus } from '@jargonic/flows-types';
import { CreateFolderRequest, UpdateFolderRequest } from '@jargonic/folder-types';
import { CustomerId } from '@jargonic/customer-types';
import { Folder, FolderId } from './folders.types';
import { foldersApi } from './folders.api';
import { getFolderReorderPayload } from './folders.utils';

interface FoldersState {
  loading: boolean;
  folders: Record<FolderId, Folder>;
}

type UpsertFolderArgs =
  | { customerId: CustomerId; folderId?: never; payload: Pick<CreateFolderRequest, 'name' | 'order'>; action: 'create' }
  | { customerId: CustomerId; folderId: FolderId; payload: Partial<UpdateFolderRequest>; action: 'update' };

async function upsertFolder(args: UpsertFolderArgs) {
  const { customerId, folderId, payload, action } = args;
  switch (action) {
    case 'create':
      return foldersApi.createFolder(customerId, payload.name);
    case 'update':
      return foldersApi.updateFolder(customerId, folderId, payload);

    default:
      return undefined;
  }
}

interface FoldersActions {
  fetchFolders: (customerId: string) => Promise<void>;
  fetchFolderById: (customerId: string, folderId: string) => Promise<Folder | undefined>;
  upsertFolder: (args: UpsertFolderArgs) => Promise<Folder | undefined>;
  reorderFolder: (customerId: string, folderId: string, order: number) => Promise<boolean>;
  deleteFolder: (customerId: string, folderId: string, includeFlows?: boolean) => Promise<boolean>;
  /** Locally update folders, without calling the server. */
  updateFolders: (folders: (Partial<Folder> & Required<Pick<Folder, 'id'>>)[]) => void;
  reset: () => void;
}

const initialState: FoldersState = {
  loading: false,
  folders: {},
};

export const folderStore = create(
  immer<FoldersState & FoldersActions>((set, get) => ({
    ...initialState,
    fetchFolders: async (customerId) => {
      set({ loading: true });
      const folders = await foldersApi.getFolders(customerId);
      set({ folders: folders.reduce((acc, folder) => ({ ...acc, [folder.id]: folder }), {}), loading: false });
    },
    fetchFolderById: async (customerId, folderId) => {
      const folder = await foldersApi.getFolderById(customerId, folderId);
      if (folder) {
        set((state) => {
          state.folders[folder.id] = folder;
        });
      }
      return folder;
    },
    upsertFolder: async (payload) => {
      const folder = await upsertFolder(payload);
      if (folder) {
        set((state) => {
          state.folders[folder.id] = folder;
        });
      }
      return folder;
    },
    reorderFolder: async (customerId, folderId, order) => {
      const { folders } = get();
      const response = await foldersApi.bulkUpdateFolders(
        customerId,
        getFolderReorderPayload(folders, folderId, order),
      );
      if (response) get().updateFolders(response);
      return Boolean(response);
    },
    deleteFolder: async (customerId, folderId, includeFlows) => {
      const folder = get().folders[folderId];
      const result = await foldersApi.deleteFolder(customerId, folderId, includeFlows);
      if (result) {
        set((state) => {
          delete state.folders[folderId];
        });
        flowStore.getState().updateFlows(
          folder.flowIds.map((flowId) => ({
            id: flowId,
            folderId: null,
            ...(includeFlows ? { status: FlowStatus.ARCHIVED } : {}),
          })),
        );
      }
      return result;
    },
    updateFolders: (folders) => {
      set((state) => {
        folders.forEach((folder) => {
          const existingFolder = state.folders[folder.id];
          if (existingFolder) Object.assign(existingFolder, folder);
        });
      });
    },
    reset: () => {
      set({ ...initialState });
    },
  })),
);

export const useFolderStore = createStoreHook<FoldersState & FoldersActions>({ store: folderStore, useShallow });
