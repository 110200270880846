import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';
import { CustomerId } from 'stores/customers';
import { ROUTES } from 'routes/routes.config';
import classes from './LocationTabs.module.css';

interface LocationTabsProps {
  className?: string;
  customerId: CustomerId;
  location: string;
  onChange: (location: string) => void;
}

export const testIds = {
  tabs: 'location-tabs',
  flowsTab: 'location-tabs-flow-tab',
  usersTab: 'location-tabs-users-tab',
  settingsTab: 'location-tabs-settings-tab',
};

export const LocationTabs = ({ className, customerId, location, onChange }: LocationTabsProps) => {
  const { t } = useTranslation();

  const selectedTab = useMemo(() => {
    if (location.includes(ROUTES.FLOWS(customerId))) return ROUTES.FLOWS();
    if (location.includes(ROUTES.USER_MGMT(customerId).ROOT)) return ROUTES.USER_MGMT().ROOT;
    return undefined;
  }, [location]);

  const onChangeTab = (value: string | null) => {
    if (value === ROUTES.FLOWS()) onChange(ROUTES.FLOWS(customerId));
    if (value === ROUTES.USER_MGMT().ROOT) onChange(ROUTES.USER_MGMT(customerId).USERS);
  };

  return (
    <Tabs className={className} value={selectedTab} onChange={onChangeTab} data-testid={testIds.tabs}>
      <Tabs.List className={classes.list} h='100%'>
        <Tabs.Tab value={ROUTES.FLOWS()} data-testid={testIds.flowsTab}>
          {t('header.tabs.flows')}
        </Tabs.Tab>
        <Tabs.Tab value={ROUTES.USER_MGMT().ROOT} data-testid={testIds.usersTab}>
          {t('header.tabs.users')}
        </Tabs.Tab>
        <Tabs.Tab value='settings' disabled data-testid={testIds.settingsTab}>
          {t('header.tabs.settings')}
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
};
