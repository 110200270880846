import cn from 'classnames';
import { Box, Text, Loader } from '@mantine/core';
import { IconAlertTriangleFilled, IconCheck, IconPlayerPauseFilled } from '@tabler/icons-react';
import { ReactNode } from 'react';
import { TrainingStepStatus } from 'stores/aiPlatform/aiModels';
import classes from './PipelineStepper.module.css';

interface PipelineStepperBulletProps {
  status: TrainingStepStatus;
  label: number;
}

export const testIds = {
  wrapper: 'pipeline-stepper-bullet-wrapper',
  inProgressLoader: 'pipeline-stepper-in-progress-loader',
  completedIcon: 'pipeline-stepper-completed-icon',
  pausedIcon: 'pipeline-stepper-paused-icon',
  failedIcon: 'pipeline-stepper-failed-icon',
  stepLabel: 'pipeline-stepper-step-label',
};

const statusClasses: Record<TrainingStepStatus, string> = {
  [TrainingStepStatus.COMPLETED]: classes.statusCompleted,
  [TrainingStepStatus.PAUSED]: classes.statusPaused,
  [TrainingStepStatus.FAILED]: classes.statusFailed,
  [TrainingStepStatus.IN_PROGRESS]: classes.statusInProgress,
  [TrainingStepStatus.PENDING]: classes.statusPending,
};

const statusIcons: Record<TrainingStepStatus, ReactNode> = {
  [TrainingStepStatus.COMPLETED]: <IconCheck size={24} data-testid={testIds.completedIcon} />,
  [TrainingStepStatus.PAUSED]: <IconPlayerPauseFilled size={24} data-testid={testIds.pausedIcon} />,
  [TrainingStepStatus.FAILED]: <IconAlertTriangleFilled size={40} data-testid={testIds.failedIcon} />,
  [TrainingStepStatus.IN_PROGRESS]: (
    <Loader size={40} className={classes.inProgressLoader} data-testid={testIds.inProgressLoader} />
  ),
  [TrainingStepStatus.PENDING]: null,
};

const statusesWithLabel: Partial<Record<TrainingStepStatus, boolean>> = {
  [TrainingStepStatus.PENDING]: true,
  [TrainingStepStatus.IN_PROGRESS]: true,
};

export const PipelineStepperBullet = ({ status, label }: PipelineStepperBulletProps) => (
  <Box className={cn(classes.iconWrapper, statusClasses[status])} data-testid={testIds.wrapper}>
    {statusIcons[status]}
    {statusesWithLabel[status] && (
      <Text c='dark.3' fw={600} pos='absolute' data-testid={testIds.stepLabel}>
        {label}
      </Text>
    )}
  </Box>
);
