import { useCallback, useMemo } from 'react';
import { nanoid } from 'nanoid';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useParams } from 'react-router-dom';
import { PolicyRouteParams } from 'routes/routes.config';
import { Permission, UpdatePolicyPayload, useUserMgmtStore } from 'stores/userMgmt';
import { useFlowStore } from 'stores/flows';
import { columns, testIds as columnsTestIds } from './PermissionTable.columns';
import { permissionTableDefs } from './PermissionTable.defs';
import { PermissionTableState } from './PermissionTable.types';
import {
  PermissionTableActions,
  PermissionTableEmptyState,
  PermissionTableRowActions,
  emptyStateTestIds,
  rowActionsTestIds,
  actionsTestIds,
} from './components';

export const testIds = {
  getRowTestId: (id: string) => `policy-table-row-${id}`,
  table: 'policy-table',
  emptyState: emptyStateTestIds,
  columns: columnsTestIds,
  actions: actionsTestIds,
  rowActions: rowActionsTestIds,
};

export const PermissionTable = () => {
  const { customerId, policyId } = useParams() as PolicyRouteParams;
  const { policies, upsertPolicy } = useUserMgmtStore(['policies', 'upsertPolicy', 'deletePolicy']);
  const { flows } = useFlowStore(['flows']);
  const policy = policies[policyId];

  const resourceMap: PermissionTableState['resourceMap'] = useMemo(
    () => Object.fromEntries(Object.entries(flows).map(([id, flow]) => [id, flow.name])),
    [flows],
  );

  const updatePolicyPermissions = async (permissions: Permission[]) => {
    const payload: UpdatePolicyPayload = { ...policy, permissions };
    const response = await upsertPolicy(customerId, { action: 'update', policyId, payload });
    return Boolean(response);
  };

  const onCreatePermission: PermissionTableState['onCreatePermission'] = useCallback(
    async (permissionPayload) => {
      const payload: Permission = { id: nanoid(), ...permissionPayload };
      const permissions = [...policy.permissions, payload];
      return updatePolicyPermissions(permissions);
    },
    [customerId, policy],
  );

  const onEditPermission: PermissionTableState['onEditPermission'] = useCallback(
    async (permissionId, permissionPayload) => {
      const permissions = policy.permissions.map((p) => (p.id === permissionId ? { ...p, ...permissionPayload } : p));
      return updatePolicyPermissions(permissions);
    },
    [customerId, policy],
  );

  const onDeletePermission: PermissionTableState['onDeletePermission'] = useCallback(
    async (permissionId) => {
      const permissions = policy.permissions.filter((p) => p.id !== permissionId);
      return updatePolicyPermissions(permissions);
    },
    [customerId, policy],
  );

  const table = useMantineReactTable({
    data: policy.permissions || [],
    columns,
    ...permissionTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: '',
      'data-testid': testIds.getRowTestId(row.id),
    }),
    renderEmptyRowsFallback: (props) => <PermissionTableEmptyState {...props} />,
    renderTopToolbar: (props) => <PermissionTableActions {...props} />,
    renderRowActions: (props) => <PermissionTableRowActions {...props} />,
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    state: {
      resourceMap,
      onCreatePermission,
      onEditPermission,
      onDeletePermission,
    } as PermissionTableState,
  });

  return <MantineReactTable table={table} data-testid={testIds.table} />;
};
