import { Group, Title } from '@mantine/core';
import { IconSquarePlus } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import { testIds } from '../definitions';

interface EventDefTableActionsProps {
  onCreate: () => void;
}

export const EventDefTableActions = ({ onCreate }: EventDefTableActionsProps) => {
  const { t } = useTranslation();

  return (
    <Group>
      <Title order={5} className='grow'>
        {t('wizard.steps.reportedEvents.eventTable.title')}
      </Title>
      <ActionButton
        label={t('wizard.steps.reportedEvents.eventTable.createButton')}
        onClick={onCreate}
        data-testid={testIds.create.button}
      >
        <IconSquarePlus data-testid={testIds.create.icon} />
      </ActionButton>
    </Group>
  );
};
