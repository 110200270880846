import { EventDefType } from 'stores/wizard';
import { TextTypeLayout, testIds as textTestIds } from './TextTypeLayout/TextTypeLayout';
import { SelectTypeLayout, testIds as selectTestIds } from './SelectTypeLayout/SelectTypeLayout';
import { NumberTypeLayout, testIds as numberTestIds } from './NumberTypeLayout/NumberTypeLayout';
import { DateTypeLayout, testIds as dateTestIds } from './DateTypeLayout/DateTypeLayout';
import { TimeOfDayTypeLayout, testIds as timeOfDayTestIds } from './TimeOfDayTypeLayout/TimeOfDayTypeLayout';

export const testIds = {
  text: textTestIds,
  select: selectTestIds,
  number: numberTestIds,
  date: dateTestIds,
  timeOfDay: timeOfDayTestIds,
};

export const typeToLayoutMap = {
  [EventDefType.FREE_TEXT]: TextTypeLayout,
  [EventDefType.APPEND_TEXT_EVENT]: TextTypeLayout,
  [EventDefType.NUMBER]: NumberTypeLayout,
  [EventDefType.DATE]: DateTypeLayout,
  [EventDefType.TIME_OF_DAY]: TimeOfDayTypeLayout,
  [EventDefType.LIST_OF_VALUES_MULTI_SELECTION]: SelectTypeLayout,
  [EventDefType.LIST_OF_VALUES_SINGLE_SELECTION]: SelectTypeLayout,
} satisfies Record<EventDefType, (props: { eventDefId: string }) => any>;
