import { GetCustomersResponse } from '@jargonic/customer-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { CreateCustomerPayload, Customer } from './customers.types';

export const customersApi = {
  getCustomers: async () => {
    const { data } = await hermes.get<GetCustomersResponse>(`${config.jargonicApiUrl}/customers`, {
      fallback: { items: [] },
    });
    return data.items;
  },

  createCustomer: async (customer: CreateCustomerPayload): Promise<Customer | undefined> => {
    const { data } = await hermes.post<Customer>(`${config.jargonicApiUrl}/customers`, customer);
    return data;
  },
};
