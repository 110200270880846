import { Box, Divider, ScrollArea, Stack } from '@mantine/core';
import { EventDefGeneralSettings, EventDefTypeSettings } from './components';

interface EventDefSettingsProps {
  eventDefId?: string;
}

export const testIds = {
  wrapper: 'event-def-settings-wrapper',
};

export const EventDefSettings = ({ eventDefId }: EventDefSettingsProps) =>
  !!eventDefId && (
    <Box className='grow' pos='relative' h='100%'>
      <ScrollArea type='auto' className='absolute w-full h-full top-0 bottom-0 left-0 right-0'>
        <Stack key={eventDefId} gap='lg' data-testid={testIds.wrapper}>
          <EventDefGeneralSettings eventDefId={eventDefId} />
          <Divider />
          <EventDefTypeSettings eventDefId={eventDefId} />
        </Stack>
      </ScrollArea>
    </Box>
  );
