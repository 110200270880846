import { State } from '@ai-platform/common-types';
import { Badge } from '@mantine/core';
import { AiModelStatus } from 'stores/aiPlatform/aiModels';
import { statusColors, statusLabels } from './AiModelStatusBadge.const';

interface AiModelStatusBadgeProps {
  status: AiModelStatus | State;
}

export const testIds = {
  badge: 'ai-model-status-badge',
};

export const AiModelStatusBadge = ({ status, ...props }: AiModelStatusBadgeProps) => (
  <Badge
    variant='dot'
    radius='sm'
    color={statusColors[status]}
    data-testid={testIds.badge}
    {...props}
    className={status === State.STARTED ? 'blinking-badge' : ''}
  >
    {statusLabels[status]}
  </Badge>
);
