import { CreateContainerTypeRequest } from '@jargonic/containerType-types';
import i18n from 'services/i18n';
import { CreateLabelPayload, Label, LabelId } from './labels.types';

export function getNewLabelPayload(
  { color, order, isDynamic }: CreateLabelPayload,
  labels: Record<LabelId, Label>,
): CreateContainerTypeRequest {
  return {
    name: getEnumeratedLabelName(labels),
    order,
    isDynamic,
    synonyms: [],
    attributes: { color },
  };
}

export function getEnumeratedLabelName(labels: Record<LabelId, Label>): string {
  return i18n.t('wizard.steps.labeling.labelManagement.newLabel.enumeratedNewName', {
    n: Object.keys(labels).length + 1,
  });
}
