import { Menu } from '@mantine/core';
import { IconDots, IconLicense, IconTrash, IconTrashOff, IconUsersGroup } from '@tabler/icons-react';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'components';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { Group } from 'stores/userMgmt';
import { compareArrays } from 'utils';
import {
  GroupPoliciesMappingModal,
  testIds as groupPoliciesMappingTestIds,
} from 'pages/userMgmt/components/GroupPoliciesMappingModal/GroupPoliciesMappingModal';
import { GroupTableState } from '../GroupTable.types';
import { DeleteGroupModal, testIds as deleteGroupTestIds } from './GroupTableActions/DeleteGroupModal';
import {
  GroupUsersMappingModal,
  testIds as groupUsersMappingTestIds,
} from '../../../components/GroupUsersMappingModal/GroupUsersMappingModal';

export const testIds = {
  getButtonTestId: (id: string) => `group-table-row-actions-button-${id}`,
  icon: 'group-table-row-actions-icon',
  options: {
    delete: 'group-table-row-actions-delete',
    users: 'group-table-row-actions-users',
    policies: 'group-table-row-actions-policies',
  },
  deleteModal: deleteGroupTestIds,
  usersModal: groupUsersMappingTestIds,
  policiesModal: groupPoliciesMappingTestIds,
};

export const GroupTableRowActions: NonNullable<MRT_TableOptions<Group>['renderRowActions']> = ({ row, table }) => {
  const { t } = useTranslation();
  const { users, policies } = table.getState() as GroupTableState;
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure();
  const [usersModalOpened, { open: openUsersModal, close: closeUsersModal }] = useDisclosure();
  const [policiesModalOpened, { open: openPoliciesModal, close: closePoliciesModal }] = useDisclosure();

  const onDelete = async () => {
    const { onDeleteGroup } = table.getState() as GroupTableState;
    const { name } = row.original;
    const response = await onDeleteGroup(row.id);
    if (response) {
      notifications.show({
        title: t('userMgmt.groups.table.rowActions.delete.success.title', { name }),
        message: null,
        icon: <IconTrash />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.table.rowActions.delete.error.title', { name }),
        message: t('common.tryAgain'),
        icon: <IconTrashOff />,
      });
    }
    return response;
  };

  const onChangePoliciesMapping = async (policiesIds: string[]) => {
    const existingPoliciesIds = row.original.policies.map((policy) => policy.id);
    const { isDiff, added, removed } = compareArrays(existingPoliciesIds, policiesIds);
    if (!isDiff) return true;

    const { onMapPoliciesToGroup } = table.getState() as GroupTableState;

    const response = await onMapPoliciesToGroup(row.id, policiesIds);
    const { name } = row.original;
    if (response) {
      notifications.show({
        color: 'blue',
        title: t('userMgmt.groups.table.rowActions.policies.success.title', { name }),
        message: t('userMgmt.groups.table.rowActions.policies.success.message', {
          added: added.length,
          removed: removed.length,
        }),
        icon: <IconLicense />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.table.rowActions.policies.error.title', { name }),
        message: t('common.tryAgain'),
        icon: <IconLicense />,
      });
    }
    return response;
  };

  const onChangeUsersMapping = async (userIds: string[]) => {
    const existingUserIds = row.original.users.map((user) => user.userId);
    const { isDiff, added, removed } = compareArrays(existingUserIds, userIds);
    if (!isDiff) return true;
    const { onMapUsersToGroup } = table.getState() as GroupTableState;
    const response = await onMapUsersToGroup(row.id, userIds);
    const { name } = row.original;
    if (response) {
      notifications.show({
        color: 'blue',
        title: t('userMgmt.groups.table.rowActions.users.success.title', { name }),
        message: t('userMgmt.groups.table.rowActions.users.success.message', {
          added: added.length,
          removed: removed.length,
        }),
        icon: <IconUsersGroup />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.table.rowActions.users.error.title', { name }),
        message: t('common.tryAgain'),
        icon: <IconUsersGroup />,
      });
    }
    return response;
  };

  return (
    <>
      <Menu>
        <Menu.Target>
          <ActionButton label={t('common.actions')} data-testid={testIds.getButtonTestId(row.id)}>
            <IconDots data-testid={testIds.icon} />
          </ActionButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openUsersModal} data-testid={testIds.options.users}>
            {t('common.users')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={openPoliciesModal} data-testid={testIds.options.policies}>
            {t('common.policies')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item onClick={openDeleteModal} data-testid={testIds.options.delete}>
            {t('common.delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <DeleteGroupModal
        opened={deleteModalOpened}
        group={row.original}
        onClose={closeDeleteModal}
        onDelete={onDelete}
      />
      <GroupUsersMappingModal
        opened={usersModalOpened}
        group={row.original}
        users={users}
        onClose={closeUsersModal}
        onSubmit={onChangeUsersMapping}
      />
      <GroupPoliciesMappingModal
        opened={policiesModalOpened}
        group={row.original}
        policies={policies}
        onClose={closePoliciesModal}
        onSubmit={onChangePoliciesMapping}
      />
    </>
  );
};
