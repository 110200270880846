import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconUsersGroup } from '@tabler/icons-react';
import { PolicyMappingModal, policyMappingTestIds } from 'pages/userMgmt/components';
import { useTranslation } from 'react-i18next';
import { User } from 'stores/auth';
import { Group, Policy } from 'stores/userMgmt';
import { compareArrays } from 'utils';

interface EntityTableManageActionProps {
  users: User[];
  groups: Group[];
  policy: Policy;
  onChangeEntities: (userIds: string[], groupIds: string[]) => Promise<boolean>;
}

export const testIds = {
  button: 'entity-table-manage-action',
  modal: policyMappingTestIds,
};

export const EntityTableManageAction = ({ users, groups, policy, onChangeEntities }: EntityTableManageActionProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();

  const onChangeMapping = async (userIds: string[], groupIds: string[]) => {
    const existingUserIds = policy.users.map((user) => user.userId);
    const existingGroupsIds = policy.groups.map((group) => group.id);
    const { isDiff, added, removed } = compareArrays(
      [...existingUserIds, ...existingGroupsIds],
      [...userIds, ...groupIds],
    );
    if (!isDiff) return true;
    const response = await onChangeEntities(userIds, groupIds);
    if (response) {
      notifications.show({
        color: 'blue',
        title: t('userMgmt.policies.policyDetails.panels.entities.table.actions.manage.success.title'),
        message: t('userMgmt.policies.policyDetails.panels.entities.table.actions.manage.success.message', {
          added: added.length,
          removed: removed.length,
        }),
        icon: <IconUsersGroup />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.policyDetails.panels.entities.table.actions.manage.error.title'),
        message: t('common.tryAgain'),
        icon: <IconUsersGroup />,
      });
    }
    return response;
  };

  return (
    <>
      <Button onClick={open} data-testid={testIds.button}>
        {t('userMgmt.policies.policyDetails.panels.entities.table.actions.manage.button')}
      </Button>
      <PolicyMappingModal
        opened={opened}
        users={users}
        groups={groups}
        policy={policy}
        onClose={close}
        onSubmit={onChangeMapping}
      />
    </>
  );
};
