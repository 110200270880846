import { Flex, Stack, Text } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { Policy } from '@jargonic/customer-types';
import { PoliciesTableSearch, testIds as searchTestIds } from './PoliciesTableSearch';
import { testIds as manageActionTestIds, PoliciesTableManageAction } from './PoliciesTableManageAction';
import { PoliciesTableState } from '../../PoliciesTable.types';

type PoliciesTableActionsProps = { table: MRT_TableInstance<Policy> };

export const testIds = {
  search: searchTestIds,
  manage: manageActionTestIds,
  count: 'policies-table-count',
};

export const PoliciesTableActions = ({ table }: PoliciesTableActionsProps) => {
  const { t } = useTranslation();
  const { globalFilter, onSearch, policies, group, onChangePolicies } = table.getState() as PoliciesTableState;
  const { data } = table.options;

  return (
    <Stack gap='xs' p='md'>
      <Flex justify='space-between' align='center'>
        <PoliciesTableSearch value={globalFilter} onChange={onSearch} />
        <PoliciesTableManageAction policies={policies} group={group} onChangePolicies={onChangePolicies} />
      </Flex>
      <Text size='xs' c='gray.5' data-testid={testIds.count}>
        {t('userMgmt.groups.groupDetails.panels.policies.table.policyCount', { count: data.length })}
      </Text>
    </Stack>
  );
};
