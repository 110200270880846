import i18n from 'services/i18n';
import { EntitiesPanel, PermissionsPanel } from './components';

export enum PolicyPanel {
  DETAILS = 'details',
  ENTITIES = 'entities',
  PERMISSIONS = 'permissions',
}

export const panels = [
  {
    key: PolicyPanel.DETAILS,
    title: i18n.t('userMgmt.policies.policyDetails.panels.details.title'),
    disabled: true,
    panel: <div />,
  },
  {
    key: PolicyPanel.ENTITIES,
    title: i18n.t('userMgmt.policies.policyDetails.panels.entities.title'),
    panel: <EntitiesPanel />,
  },
  {
    key: PolicyPanel.PERMISSIONS,
    title: i18n.t('userMgmt.policies.policyDetails.panels.permissions.title'),
    panel: <PermissionsPanel />,
  },
];
