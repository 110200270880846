import qs from 'qs';
import {
  Container,
  CreateContainerRequest,
  GetContainersRequest,
  GetContainersResponse,
} from '@jargonic/containers-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';

const flowUrl = (customerId: string, flowId: string) => `${config.jargonicApiUrl}/${customerId}/containers/${flowId}`;
const SINGLE_FETCH_LIMIT = 1000;

async function getContainersRecursively(
  customerId: string,
  flowId: string,
  nextToken?: string,
  containers: Container[] = [],
) {
  const query: GetContainersRequest = {
    limit: SINGLE_FETCH_LIMIT,
    nextToken,
  };
  const url = `${flowUrl(customerId, flowId)}?${qs.stringify(query)}`;
  const { data } = await hermes.get<GetContainersResponse>(url);
  if (!data) throw new Error();
  containers.push(...data.items);
  if (data.nextToken) await getContainersRecursively(customerId, flowId, data.nextToken, containers);
  return containers;
}

export const containersApi = {
  getContainers: async (customerId: string, flowId: string) => {
    try {
      const containers = await getContainersRecursively(customerId, flowId);
      return containers;
    } catch {
      return undefined;
    }
  },

  replaceContainers: async (customerId: string, flowId: string, containers: CreateContainerRequest[]) => {
    const url = `${flowUrl(customerId, flowId)}`;
    const { data } = await hermes.post(url, containers);
    return data;
  },
};
