import { MoveableManagerInterface, Able } from 'react-moveable';
import { Loader, ThemeIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { PolymorphicComponentProps } from '@mantine/core/lib/core/factory/create-polymorphic-component';

interface ProcessIndicatorProps extends PolymorphicComponentProps<typeof ThemeIcon> {
  processing?: boolean;
}

export const testIds = {
  loader: 'loader',
  icon: 'icon',
  check: 'check',
};

const ProcessIndicator = ({ processing, ...props }: ProcessIndicatorProps) =>
  processing === undefined ? null : (
    <ThemeIcon size='md' radius='xl' {...props} data-testid={testIds.icon}>
      {processing ? (
        <Loader color='white' size='sm' data-testid={testIds.loader} />
      ) : (
        <IconCheck data-testid={testIds.check} />
      )}
    </ThemeIcon>
  );

export interface ProcessableProps {
  processing?: boolean;
  inverseZoom?: number;
  processable: true;
}

/**
 * Custom Able to use as addon for Moveable.
 * https://daybrush.com/moveable/storybook/?path=/story/make-custom-able--custom-able-dimension-viewable
 */
export const Processable: Able = {
  name: 'processable',
  render(moveable: MoveableManagerInterface<ProcessableProps>) {
    const rect = moveable.getRect();
    const { processing, inverseZoom } = moveable.props;
    return (
      <ProcessIndicator
        // named key and class are required by Moveable
        key='processable-viewer'
        className='moveable-processable cursor-nesw-resize pointer-events-none origin-center z-[10]'
        pos='absolute'
        left={rect.width}
        processing={processing}
        style={{
          transform: `translate(-50%, -50%) scale(${inverseZoom})`,
        }}
        top={0}
      />
    );
  },
} satisfies Able<ProcessableProps>;
