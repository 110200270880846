import { FlowStatus } from '@jargonic/flows-types';
import { Group } from '@mantine/core';
import { IconArchive, IconArchiveOff, IconCopy, IconTrash, IconX } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import { Flow, useFlowStore } from 'stores/flows';
import { useModal } from 'stores/globalModal';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import { notifications } from '@mantine/notifications';
import { archiveFlowGlobalModalProps, deleteFlowGlobalModalProps } from '../../../modals';

interface FlowRowActionsProps {
  flow: Flow;
}

export const testIds = {
  group: 'flow-row-actions-group',
  delete: {
    button: 'flow-row-delete-button',
    icon: 'flow-row-delete-icon',
  },
  archive: {
    button: 'flow-row-archive-button',
    iconArchive: 'flow-row-archive-icon',
    iconUnarchive: 'flow-row-unarchive-icon',
  },
  duplicate: {
    button: 'flow-row-duplicate-button',
    icon: 'flow-row-duplicate-icon',
  },
};

export const FlowRowActions = ({ flow }: FlowRowActionsProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const { open } = useModal();
  const { upsertFlow } = useFlowStore(['upsertFlow']);
  const [loadingDuplication, setLoadingDuplication] = useState(false);

  const isArchived = flow.status === FlowStatus.ARCHIVED;

  const onClickDelete = () => open(deleteFlowGlobalModalProps(flow.id));
  const onClickArchive = () => open(archiveFlowGlobalModalProps(flow.id, !isArchived));
  const onClickDuplicate = async () => {
    setLoadingDuplication(true);
    const response = await upsertFlow({
      action: 'duplicate',
      customerId,
      flowId: flow.id,
    });
    if (!response) {
      notifications.show({
        color: 'red',
        title: t('flowsPage.duplicate.error.title'),
        message: t('common.tryAgain'),
        icon: <IconX />,
      });
    }
    setLoadingDuplication(false);
  };

  return (
    <Group gap='zero' justify='end' onClick={(e) => e.stopPropagation()} data-testid={testIds.group}>
      <ActionButton
        color='gray.7'
        label={isArchived ? t('flowsPage.table.flow.unarchive') : t('flowsPage.table.flow.archive')}
        onClick={onClickArchive}
        data-testid={testIds.archive.button}
      >
        {isArchived ? (
          <IconArchiveOff data-testid={testIds.archive.iconUnarchive} />
        ) : (
          <IconArchive data-testid={testIds.archive.iconArchive} />
        )}
      </ActionButton>
      {isArchived && (
        <ActionButton
          color='gray.7'
          label={t('flowsPage.table.flow.delete')}
          onClick={onClickDelete}
          data-testid={testIds.delete.button}
        >
          <IconTrash data-testid={testIds.delete.icon} />
        </ActionButton>
      )}
      {!isArchived && (
        <ActionButton
          color='gray.7'
          label={t('flowsPage.table.flow.duplicate')}
          onClick={onClickDuplicate}
          loading={loadingDuplication}
          data-testid={testIds.duplicate.button}
        >
          <IconCopy data-testid={testIds.duplicate.icon} />
        </ActionButton>
      )}
    </Group>
  );
};
