import { Flex, Image } from '@mantine/core';
import { useSetState } from '@mantine/hooks';
import cn from 'classnames';
import ConfettiExplosion from 'react-confetti-explosion';

export const testIds = {
  game: 'error-layout-game',
};

interface ErrorLayoutGameState {
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
}

function getInitialState(): ErrorLayoutGameState {
  return {
    first: true,
    second: false,
    third: true,
    fourth: false,
  };
}

export const ErrorLayoutGame = () => {
  const [lit, setLit] = useSetState(getInitialState());

  const values = Object.values(lit);

  const allValueTheSame = values.every((value) => value === values[0]);
  const allValuesAreLit = allValueTheSame && values[0];
  const confettiColor = allValuesAreLit ? 'var(--mantine-color-red-filled)' : 'var(--mantine-color-green-filled)';

  const handleClick = (key: keyof ErrorLayoutGameState) => () => {
    if (allValueTheSame) return;

    const keys = Object.keys(lit) as Array<keyof ErrorLayoutGameState>;
    const index = keys.indexOf(key);
    const newState: Partial<ErrorLayoutGameState> = { [key]: !lit[key] };

    if (index > 0) {
      // There is a key before the clicked key
      const keyBefore = keys[index - 1];
      newState[keyBefore] = !lit[keyBefore];
    }

    if (index < keys.length - 1) {
      // There is a key after the clicked key
      const keyAfter = keys[index + 1];
      newState[keyAfter] = !lit[keyAfter];
    }

    setLit(newState);
  };

  return (
    <Flex gap='sm' pos='relative' data-testid={testIds.game}>
      <Flex justify='center' pos='absolute' top={0} w='100%'>
        {allValueTheSame && (
          <ConfettiExplosion
            particleCount={500}
            zIndex={1000}
            colors={[confettiColor, 'var(--mantine-color-gray-7)', 'var(--mantine-color-gray-3)']}
          />
        )}
      </Flex>
      {Object.entries(lit).map(([name, isLit]) => (
        <Image
          key={name}
          src='/Loop.png'
          w={50}
          h={50}
          className={cn(
            'animate-[spin_3s_infinite_linear] grayscale-[40%] hover:grayscale-0 !transition-all cursor-pointer',
            {
              '-hue-rotate-180': isLit,
            },
          )}
          onClick={handleClick(name as keyof ErrorLayoutGameState)}
        />
      ))}
    </Flex>
  );
};
