import { DateInput, DateValue } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';

interface DefaultValueInputProps {
  required?: boolean;
  rootClassName?: string;
  value?: number;
  disabled?: boolean;
  onChange: (value?: number) => void;
}

export const testIds = {
  input: 'default-value-input',
  label: 'default-value-input-label',
};

export const DefaultValueInput = ({ required, rootClassName, value, disabled, onChange }: DefaultValueInputProps) => {
  const { t } = useTranslation();

  const onChangeValue = (newValue: DateValue) => {
    if (!newValue) onChange(undefined);
    else {
      const parsedValue = newValue.valueOf();
      onChange(parsedValue);
    }
  };

  return (
    <DateInput
      classNames={{ root: rootClassName }}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.title')}
        </InfoLabel>
      }
      clearable
      leftSection={<IconCalendar />}
      placeholder={t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.placeholder')}
      required={required}
      value={value ? new Date(value) : undefined}
      disabled={disabled}
      onChange={onChangeValue}
      data-testid={testIds.input}
    />
  );
};
