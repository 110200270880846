import { useMemo } from 'react';
import { useFlowStore } from 'stores/flows';
import { Folder } from '@jargonic/folder-types';
import { useFolderStore } from './folders.store';

export function useFolder(folderId: string): Folder | undefined {
  return useFolderStore((state) => state.folders[folderId]);
}

export function useFolderData(folderId: string) {
  const folder = useFolder(folderId);
  const flowMap = useFlowStore((state) => state.flows);
  const flows = useMemo(
    () => folder?.flowIds.map((flowId) => flowMap[flowId]).sort((a, b) => a.order - b.order) ?? [],
    [flowMap],
  );
  return { folder, flows };
}
