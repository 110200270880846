import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FileButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconFileOff, IconFilePlus } from '@tabler/icons-react';
import { Flow } from 'stores/flows';
import { PartialErrorResponse } from 'consts';

interface FlowTableImportActionProps {
  onUpload: (file: File) => Promise<Flow | PartialErrorResponse | undefined>;
}

export const testIds = {
  button: 'flow-table-import-action-button',
  input: 'flow-table-import-action-input',
};

const inputProps = {
  'data-testid': testIds.input,
} as any;

export const FlowTableImportAction = ({ onUpload }: FlowTableImportActionProps) => {
  const { t } = useTranslation();
  const resetRef = useRef<() => void>(null);
  const [loading, setLoading] = useState(false);

  const onSuccess = (name: string) =>
    notifications.show({
      title: t('flowsPage.import.success.title', { name }),
      message: null,
      icon: <IconFilePlus />,
    });

  const onError = (message: string) =>
    notifications.show({
      color: 'red',
      title: t('flowsPage.import.error.title'),
      message,
      icon: <IconFileOff />,
    });

  const isErrorResponse = (response: Flow | PartialErrorResponse): response is PartialErrorResponse =>
    'statusText' in response;

  const onImport = async (file: File | null) => {
    if (!file) return;
    setLoading(true);
    const response = await onUpload(file);

    if (!response) {
      setLoading(false);
      return;
    }

    if (isErrorResponse(response)) {
      if (response.statusText === 'error') {
        onError(response.message || t('flowsPage.import.error.message'));
      }
    } else {
      onSuccess(response.name);
    }

    resetRef.current?.();
    setLoading(false);
  };

  return (
    <FileButton accept='.json' inputProps={inputProps} onChange={onImport} resetRef={resetRef}>
      {(props) => (
        <Button
          loading={loading}
          variant='outline'
          leftSection={<IconFilePlus />}
          data-testid={testIds.button}
          {...props}
        >
          {t('flowsPage.import.button')}
        </Button>
      )}
    </FileButton>
  );
};
