import { MRT_ColumnDef } from 'mantine-react-table';
import { Flow } from 'stores/flows';
import { Folder } from 'stores/folders';
import { UserGroupAvatars } from 'components';
import { isFolder } from '../../FlowTable.utils';

export const testIds = {
  getAvatarGroupTestId: (id: string) => `editor-avatar-${id}`,
};

export const EditorCell: MRT_ColumnDef<Flow | Folder>['Cell'] = ({ row }) => {
  if (isFolder(row.original)) return null;
  return <UserGroupAvatars users={row.original.editors} data-testid={testIds.getAvatarGroupTestId(row.id)} />;
};
