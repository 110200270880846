import { Checkbox, CheckboxGroupProps, ElementProps, Highlight, Stack } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface PermissionResourceListProps
  extends Omit<CheckboxGroupProps, 'children'>,
    ElementProps<'input', keyof CheckboxGroupProps> {
  resources: { id: string; name: string }[];
  search: string;
}

export const testIds = {
  getResourceTestId: (resource: string) => `permission-resource-input-${resource}`,
  all: 'permission-resource-input-all',
};

export const PermissionResourceList = ({
  resources,
  value = [],
  search,
  onChange,
  ...props
}: PermissionResourceListProps) => {
  const { t } = useTranslation();
  const allResourceIds = useMemo(() => resources.map((resource) => resource.id), [resources]);

  const allResourcesSelected = resources.length === value.length;
  const someResourcesSelected = value.length > 0;
  const allHalfChecked = !allResourcesSelected && someResourcesSelected;
  const allChecked = resources.length === value.length;
  const onChangeAll = () => {
    if (allChecked) onChange?.([]);
    else onChange?.(allResourceIds);
  };

  return (
    <Stack p='md' gap='md'>
      <Checkbox
        label={t('common.all')}
        checked={allChecked}
        indeterminate={allHalfChecked}
        onChange={onChangeAll}
        data-testid={testIds.all}
      />
      <Checkbox.Group value={value} onChange={onChange} {...props}>
        <Stack gap='md' pl='xl'>
          {resources.map(({ id, name }) => (
            <Checkbox
              key={id}
              value={id}
              label={<Highlight highlight={search}>{name}</Highlight>}
              data-testid={testIds.getResourceTestId(id)}
            />
          ))}
        </Stack>
      </Checkbox.Group>
    </Stack>
  );
};
