import { Outlet, useParams } from 'react-router-dom';
import { useContainerStore, useEventDefStore, useFileStore, useFlowSettingStore, useLabelStore } from 'stores/wizard';
import { useEffect, useState } from 'react';
import { Loader, Stack } from '@mantine/core';
import { useFlowStore } from 'stores/flows';
import { WizardRouteParams } from 'routes/routes.config';
import { notifications } from '@mantine/notifications';
import { IconMoodSad } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { WizardHeader } from './WizardHeader/WizardHeader';

export const testIds = {
  loader: 'wizard-layout-loader',
};

export const WizardLayout = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as WizardRouteParams;
  const [fetched, setFetched] = useState(false);
  const { loading: loadingFlows, flows } = useFlowStore(['loading', 'flows']);
  const { reset: resetFlowSettings, fetchData: fetchFlowSettings } = useFlowSettingStore(['reset', 'fetchData']);
  const { reset: resetLabels, fetchData: fetchLabels } = useLabelStore(['reset', 'fetchData']);
  const { reset: resetFiles, fetchData: fetchFiles } = useFileStore(['reset', 'fetchData']);
  const { reset: resetContainers, fetchData: fetchContainers } = useContainerStore(['reset', 'fetchData']);
  const { reset: resetEventDefs, fetchData: fetchEventDefs } = useEventDefStore(['reset', 'fetchData']);

  const fetchData = async () => {
    const gotLabels = await fetchLabels(customerId, flowId);
    const gotWizardData = await Promise.all([
      fetchFlowSettings(customerId, flowId),
      fetchFiles(customerId, flowId),
      fetchContainers(customerId, flowId),
      fetchEventDefs(customerId, flowId),
    ]);

    if (!(gotLabels && gotWizardData.every(Boolean))) {
      notifications.show({
        icon: <IconMoodSad />,
        title: t('common.genericFetchError'),
        message: t('common.tryAgain'),
        color: 'red',
      });
    } else setFetched(true);
  };

  useEffect(() => {
    if (!fetched && !loadingFlows && Object.values(flows).length) fetchData();
  }, [loadingFlows, flows]);

  useEffect(
    () => () => {
      resetFlowSettings();
      resetLabels();
      resetFiles();
      resetContainers();
      resetEventDefs();
    },
    [],
  );

  return (
    <Stack pos='relative' w='100%' h='100%' gap='zero'>
      <WizardHeader />
      {loadingFlows ? <Loader type='bars' m='auto' data-testid={testIds.loader} /> : <Outlet />}
    </Stack>
  );
};
