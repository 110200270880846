import { MRT_ColumnDef } from 'mantine-react-table';
import { Policy } from 'stores/userMgmt';
import i18n from 'services/i18n';

export const columns: MRT_ColumnDef<Policy>[] = [
  {
    accessorKey: 'name',
    header: i18n.t('common.name'),
  },
];
