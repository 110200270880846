import {
  GetTrainingExecutionQuery,
  GetTrainingExecutionQueryVariables,
  ListTrainingExecutionsInput,
  ListTrainingExecutionsQuery,
  ListTrainingExecutionsQueryVariables,
} from '@ai-platform/common-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { GraphQLResponse, StartTrainRequest, StartTrainResponse } from 'stores/aiPlatform';
import { aiModelFromTrainingExecution } from '../aiModels.adapters';
import { POLLING_INTERVAL } from '../aiModels.const';
import { AiModel } from '../aiModels.types';
import { getTrainingExecutionQuery, listTrainingExecutionsQuery } from './aiModels.gql';

export const aiModelsApi = {
  getAiModel: async (executionId: string): Promise<AiModel | undefined> => {
    const query = getTrainingExecutionQuery;
    const variables: GetTrainingExecutionQueryVariables = {
      executionId,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<GetTrainingExecutionQuery>>(`${config.aipApiHost}/appsync/query`, {
      query,
      variables,
    });
    return aiModelFromTrainingExecution(data?.getTrainingExecution);
  },
  getAiModels: async (listTrainingExecutionsInput: ListTrainingExecutionsInput): Promise<AiModel[] | undefined> => {
    const query = listTrainingExecutionsQuery;
    const variables: ListTrainingExecutionsQueryVariables = {
      listTrainingExecutionsInput,
    };
    const {
      data: { data },
    } = await hermes.post<GraphQLResponse<ListTrainingExecutionsQuery>>(`${config.aipApiHost}/appsync/query`, {
      query,
      variables,
    });
    return data?.listTrainingExecutions?.trainingExecutions
      ?.map(aiModelFromTrainingExecution)
      .filter((model): model is AiModel => !!model);
  },
  subscribeToAiModel: (id: string, callback: (aiModel: AiModel) => void) =>
    setInterval(async () => {
      const model = await aiModelsApi.getAiModel(id);
      if (model) callback(model);
    }, POLLING_INTERVAL),
  startModelTraining: async (request: StartTrainRequest): Promise<StartTrainResponse | undefined> => {
    const url = `${config.aipApiHost}/train/start`;
    const { data } = await hermes.post<StartTrainResponse>(url, request);
    return data;
  },
};
