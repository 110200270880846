import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { Customer, CustomerId, CreateCustomerPayload } from './customers.types';
import { customersApi } from './customers.api';

interface CustomersState {
  loading: boolean;
  customers: Record<CustomerId, Customer>;
}

interface CustomersActions {
  fetchCustomers: () => Promise<void>;
  createCustomer: (payload: CreateCustomerPayload) => Promise<Customer | undefined>;
  reset: () => void;
}

const initialState: CustomersState = {
  loading: false,
  customers: {},
};

export const customerStore = create(
  immer<CustomersState & CustomersActions>((set) => ({
    ...initialState,
    createCustomer: async (payload) => {
      set({ loading: true });
      const newCustomer = await customersApi.createCustomer(payload);
      set((state) => {
        if (newCustomer) state.customers[newCustomer.id] = newCustomer;
        state.loading = false;
      });
      return newCustomer;
    },
    fetchCustomers: async () => {
      set({ loading: true });
      const customers = await customersApi.getCustomers();
      const customersObj = customers.reduce(
        (acc, customer) => {
          acc[customer.id] = customer;
          return acc;
        },
        {} as Record<CustomerId, Customer>,
      );
      set({ customers: customersObj, loading: false });
    },
    reset: () => {
      set({ ...initialState });
    },
  })),
);

export const useCustomerStore = createStoreHook<CustomersState & CustomersActions>({
  store: customerStore,
  useShallow,
});
