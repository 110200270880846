import { ComboboxData, Select } from '@mantine/core';
import { InfoLabel } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportedEventDefValue, ReportedEventDefValueId } from 'stores/wizard';

interface DefaultValueSelectProps {
  eventValues: ReportedEventDefValue[];
  value?: ReportedEventDefValueId;
  required?: boolean;
  rootClassName?: string;
  disabled?: boolean;
  onChange: (value?: ReportedEventDefValueId) => void;
}

export const testIds = {
  select: 'default-value-select',
  label: 'default-value-select-label',
};

export const DefaultValueSelect = ({
  eventValues,
  value,
  required,
  rootClassName,
  disabled,
  onChange,
}: DefaultValueSelectProps) => {
  const { t } = useTranslation();
  const options = useMemo<ComboboxData>(
    () => eventValues.map((eventValue) => ({ value: eventValue.id, label: eventValue.name })),
    [eventValues],
  );

  const onChangeSelected = (newValue: string | null) => onChange(newValue || undefined);

  return (
    <Select
      classNames={{ root: rootClassName }}
      data={options}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.title')}
        </InfoLabel>
      }
      placeholder={t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.placeholder')}
      value={value}
      required={required}
      disabled={disabled}
      onChange={onChangeSelected}
      data-testid={testIds.select}
    />
  );
};
