import {
  CreateContainerTypeRequest,
  GetContainerTypesResponse,
  UpdateContainerTypeRequest,
  UpsertContainerTypeRequest,
} from '@jargonic/containerType-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { Label } from './labels.types';

const flowUrl = (customerId: string, flowId: string) => `${config.jargonicApiUrl}/${customerId}/types/${flowId}`;

export const labelApi = {
  getLabels: async (customerId: string, flowId: string): Promise<Label[] | undefined> => {
    const url = flowUrl(customerId, flowId);
    const { data } = await hermes.get<GetContainerTypesResponse>(url);
    return data.items as Label[];
  },

  createLabel: async (
    customerId: string,
    flowId: string,
    payload: CreateContainerTypeRequest,
  ): Promise<Label | undefined> => {
    const url = flowUrl(customerId, flowId);
    const { data } = await hermes.post<Label>(url, payload);
    return data;
  },

  updateLabel: async (
    customerId: string,
    flowId: string,
    labelId: string,
    payload: UpdateContainerTypeRequest,
  ): Promise<Label | undefined> => {
    const url = `${flowUrl(customerId, flowId)}/${labelId}`;
    const { data } = await hermes.patch<Label>(url, payload);
    return data;
  },

  bulkUpdateLabels: async (
    customerId: string,
    flowId: string,
    payload: UpsertContainerTypeRequest[],
  ): Promise<Label[] | undefined> => {
    const url = `${flowUrl(customerId, flowId)}/bulk`;
    const { data } = await hermes.post<Label[]>(url, payload);
    return data;
  },

  deleteLabel: async (customerId: string, flowId: string, labelId: string) => {
    const url = `${flowUrl(customerId, flowId)}/${labelId}`;
    const { data } = await hermes.delete(url);
    return data;
  },
};
