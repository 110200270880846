import { EmptyState } from 'components';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { AiModel } from 'stores/aiPlatform/aiModels';

export const testIds = { wrapper: 'ai-model-table-empty-state' };

export const AiModelTableEmptyState: NonNullable<MRT_TableOptions<AiModel>['renderEmptyRowsFallback']> = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('trainingPage.emptyState.title')}
      message={t('trainingPage.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
