import { Badge, Button, Combobox, Group } from '@mantine/core';
import { IconAdjustmentsHorizontal } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface ParentFilterProps {
  selectedCount: number;
  onClear: () => void;
  onToggle: () => void;
}

export const testIds = {
  button: 'event-def-parent-filter-target-button',
  clear: 'event-def-parent-filter-clear',
  counter: 'event-def-parent-filter-counter',
};

export const ParentFilterControls = ({ selectedCount, onClear, onToggle }: ParentFilterProps) => {
  const { t } = useTranslation();

  return (
    <Group>
      <Combobox.Target>
        <Button
          color='gray.7'
          leftSection={<IconAdjustmentsHorizontal />}
          rightSection={
            <Badge radius='xl' size='xs' ml='xs' hidden={!selectedCount} data-testid={testIds.counter}>
              {selectedCount}
            </Badge>
          }
          onClick={onToggle}
          data-testid={testIds.button}
        >
          {t('wizard.steps.reportedEvents.generalSettings.mapping.modal.parentFilter.button')}
        </Button>
      </Combobox.Target>
      <Button color='gray.7' variant='white' onClick={onClear} data-testid={testIds.clear}>
        {t('common.clear')}
      </Button>
    </Group>
  );
};
