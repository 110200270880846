import { Button, Flex, NavLink, Text } from '@mantine/core';
import { ModalBase } from 'components';
import { CopyButton } from 'components/CopyButton/CopyButton';
import { metricsTableTestIds } from 'pages/aiPlatform/components';
import { testIds as tableHeaderTestIds } from 'pages/aiPlatform/TrainingPipelinePage/components/TestingContent/TestingContentHeader';
import { useParams } from 'react-router-dom';
import { AIPlatformRouteParams, ROUTES } from 'routes/routes.config';
import i18n from 'services/i18n';
import { PublishedModelTrainingData } from 'stores/aiPlatform/aiPublishedModels';
import { AiModelDataTable } from './AiModelDataTable/AiModelDataTable';

interface ModelDataModalProps {
  opened: boolean;
  data: PublishedModelTrainingData;
  onClose: () => void;
}

export const testIds = {
  modal: 'evaluation-results-modal',
  table: metricsTableTestIds,
  tableHeader: tableHeaderTestIds,
};

export const ModelDataModal = ({ opened, data, onClose }: ModelDataModalProps) => {
  const { customerId, flowId } = useParams() as AIPlatformRouteParams;
  const link = ROUTES.AI_PLATFORM(customerId, flowId).TRAINING_PIPELINE(data.trainingInfo.executionId);

  return (
    <ModalBase
      opened={opened}
      title=''
      size='auto'
      centered
      headerProps={{ mb: 0 }}
      bodyProps={{ px: 'lg', className: 'flex flex-col min-h-0' }}
      contentProps={{ className: 'flex flex-col' }}
      onClose={onClose}
      data-testid={testIds.modal}
    >
      <AiModelDataTable
        data={[data]}
        trainingInfo={data.trainingInfo}
        dataGenerationInfo={data.dataGenerationInfo}
        classNames={{
          paper: 'flex flex-col',
        }}
      />
      <Flex className='pt-4'>
        <Button variant='outline' className='mr-2'>
          <NavLink
            p={0}
            href={link}
            target='_blank'
            label={<Text fw={500}>Go to {i18n.t('publishPage.modelDataTable.headers.tooltip.trainingLinkText')}</Text>}
          />
        </Button>
        <CopyButton value={`${window.location.origin}${link}`} copyText='Copy Training Pipeline URL' />
      </Flex>
    </ModalBase>
  );
};
