import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { AiPublishedModel } from 'stores/aiPlatform/aiPublishedModels';
import { CreatedAtCell, StatusCell, createdByCellTestIds, envCellTestIds, statusCellTestIds } from './components';
import { EnvCell } from './components/cells/EnvCell';

export const testIds = {
  publishedBy: createdByCellTestIds,
  status: statusCellTestIds,
  env: envCellTestIds,
};

export const columns: MRT_ColumnDef<AiPublishedModel>[] = [
  {
    accessorKey: 'publishExecutionId',
    header: i18n.t('publishPage.table.headers.publishExecutionId'),
  },
  {
    accessorKey: 'status',
    header: i18n.t('publishPage.table.headers.status'),
    Cell: StatusCell,
  },
  {
    accessorKey: 'environment',
    header: i18n.t('publishPage.table.headers.environment'),
    Cell: EnvCell,
  },
  {
    accessorKey: 'publishedDate',
    header: i18n.t('publishPage.table.headers.publishedDate'),
    Cell: CreatedAtCell,
  },
  {
    accessorKey: 'publishedBy',
    header: i18n.t('publishPage.table.headers.publishedBy'),
    accessorFn: ({ publishedBy }) => publishedBy ?? '',
  },
];
