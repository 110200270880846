import { FileDefinitionType, UploadFileRequest } from '@jargonic/files-types';
import { config } from 'services/config';
import { FileWithUrl } from './files.types';

export function fileFromApi(file: FileDefinitionType, customerId: string, flowId: string): FileWithUrl {
  return {
    id: file.id,
    url: `${config.jargonicApiUrl}/${customerId}/files/${flowId}/${file.id}`,
  };
}

export async function filesToApi(files: File[]) {
  return Promise.all(
    files.map(
      (file) =>
        new Promise<UploadFileRequest>((resolve) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            const result = fileReader.result as string;
            resolve({
              content: result.split(',')[1],
              name: file.name,
              mimetype: file.type,
            });
          };
        }),
    ),
  );
}
