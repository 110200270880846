import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Group, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Customer } from '@jargonic/customer-types';
import { ModalBase, modalBaseTestIds } from 'components';
import { notifications } from '@mantine/notifications';
import { testIds as logoInputTestIds } from './CusomerLogoInput';

interface CustomerFormState {
  name: string;
  industry: string;
  logo?: string | File;
}
interface CustomerEditModalProps {
  customer?: Customer;
  opened: boolean;
  onClose: () => void;
  onSubmit: (customer: CustomerFormState) => Promise<boolean>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  nameInput: 'customer-edit-modal-name-input',
  industryInput: 'customer-edit-modal-industry-input',
  upload: logoInputTestIds,
  submitButton: 'customer-edit-modal-submit-button',
  cancelButton: 'customer-edit-modal-cancel-button',
};

function getDefaultValues(customer?: Customer): CustomerFormState {
  return {
    name: customer?.name ?? '',
    industry: customer?.industry ?? '',
    logo: customer?.logo,
  };
}

export const CustomerEditModal = ({ customer, opened, onClose, onSubmit }: CustomerEditModalProps) => {
  const { t } = useTranslation();
  const form = useForm<CustomerFormState>({ initialValues: getDefaultValues(customer) });
  const [loading, setLoading] = useState(false);

  const onClickSubmit = async (values: CustomerFormState) => {
    setLoading(true);
    const success = await onSubmit(values);
    setLoading(false);
    if (success) onClose();
    else
      notifications.show({
        title: customer
          ? t('customersPage.editCustomerModal.editError.title')
          : t('customersPage.editCustomerModal.createError.title'),
        message: customer
          ? t('customersPage.editCustomerModal.editError.message')
          : t('customersPage.editCustomerModal.createError.message'),
        color: 'red',
      });
  };

  useEffect(() => {
    form.setValues(getDefaultValues(customer));
  }, [customer]);

  return (
    <ModalBase
      closable={!loading}
      opened={opened}
      onClose={onClose}
      title={
        customer ? t('customersPage.editCustomerModal.editTitle') : t('customersPage.editCustomerModal.createTitle')
      }
    >
      <Box className='flex flex-col gap-4' component='form' onSubmit={form.onSubmit(onClickSubmit)}>
        <TextInput
          label={t('customersPage.editCustomerModal.nameInput.label')}
          placeholder={t('customersPage.editCustomerModal.nameInput.placeholder')}
          required
          {...form.getInputProps('name')}
          data-testid={testIds.nameInput}
        />
        <TextInput
          label={t('customersPage.editCustomerModal.industryInput.label')}
          placeholder={t('customersPage.editCustomerModal.industryInput.placeholder')}
          required
          {...form.getInputProps('industry')}
          data-testid={testIds.industryInput}
        />
        <Group justify='end'>
          <Button variant='subtle' disabled={loading} onClick={onClose} data-testid={testIds.cancelButton}>
            {t('common.cancel')}
          </Button>
          <Button type='submit' loading={loading} disabled={loading} data-testid={testIds.submitButton}>
            {t('common.submit')}
          </Button>
        </Group>
      </Box>
    </ModalBase>
  );
};
