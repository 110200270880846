import { EmptyState } from 'components';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { AiPublishedModel } from 'stores/aiPlatform/aiPublishedModels';

export const testIds = { wrapper: 'published-model-table-empty-state' };

export const PublishPageTableEmptyState: NonNullable<
  MRT_TableOptions<AiPublishedModel>['renderEmptyRowsFallback']
> = () => {
  const { t } = useTranslation();
  return (
    <EmptyState
      title={t('publishPage.emptyState.title')}
      message={t('publishPage.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
