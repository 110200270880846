import { Text } from '@mantine/core';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Permission } from 'stores/userMgmt';
import { PermissionTableState } from '../../PermissionTable.types';

export const testIds = {
  text: 'resources-cell',
};

export const ResourcesCell: NonNullable<MRT_ColumnDef<Permission>['Cell']> = ({ row, table }) => {
  const { t } = useTranslation();
  const { resourceMap } = table.getState() as PermissionTableState;

  const text = useMemo(() => {
    const noneSelected = row.original.pattern.length === 0;
    if (noneSelected) return t('common.none');
    const allSelected = row.original.pattern[0] === '*';
    if (allSelected) return t('common.all');
    return row.original.pattern.map((id) => resourceMap[id]).join(', ');
  }, [row.original.pattern]);

  return (
    <Text className='whitespace-pre-wrap' lineClamp={2} truncate size='sm' w='100%' data-testid={testIds.text}>
      {text}
    </Text>
  );
};
