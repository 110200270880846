import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { User } from './auth.types';

export const authApi = {
  getCurrentUser: async () => {
    const { data } = await hermes.get<User | null>(`${config.jargonicApiUrl}/users/me`, { fallback: null });
    return data;
  },
};
