import { EmptyState } from 'components';
import { useTranslation } from 'react-i18next';
import { MRT_TableOptions } from 'mantine-react-table';
import { Container } from 'stores/wizard';

export const testIds = {
  wrapper: 'container-table-empty-state',
};

export const ContainerTableEmptyState: NonNullable<MRT_TableOptions<Container>['renderEmptyRowsFallback']> = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('wizard.steps.labeling.containerTable.emptyState.title')}
      message={t('wizard.steps.labeling.containerTable.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
