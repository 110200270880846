import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Flex, Group as MantineGroup, ScrollArea, Stack } from '@mantine/core';
import { Group, Policy } from 'stores/userMgmt';
import { AllCheckbox, ModalBase, modalBaseTestIds } from 'components';
import { GroupPoliciesMappingPolicyRow, testIds as rowTestIds } from './GroupPoliciesMappingUserRow';
import { GroupPoliciesMappingSearch, testIds as searchTestIds } from './GroupPoliciesMappingSearch';

interface GroupPoliciesMappingModalProps {
  opened: boolean;
  group: Group;
  policies: Policy[];
  onClose: () => void;
  onSubmit: (policyIds: string[]) => Promise<boolean>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  search: searchTestIds,
  row: rowTestIds,
  apply: 'group-mapping-apply',
  all: 'group-mapping-all',
};

export const GroupPoliciesMappingModal = ({
  opened,
  group,
  policies,
  onClose,
  onSubmit,
}: GroupPoliciesMappingModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set(group.policies.map((policy) => policy.id)));

  const filteredPolicies = useMemo(
    () => policies.filter((policy) => policy.name.toLowerCase().includes(search.toLowerCase())),
    [policies, search],
  );

  const allSelected = filteredPolicies.length === selectedSet.size;
  const someSelected = selectedSet.size > 0;

  const onToggleAll = (allChecked: boolean) =>
    setSelectedSet(allChecked ? new Set(filteredPolicies.map((policy) => policy.id)) : new Set());

  const onSearch = (value: string) => setSearch(value);

  const handleChangeSelected = (policyId: string) => (checked: boolean) =>
    setSelectedSet((set) => {
      const newSet = new Set(set);
      if (checked) newSet.add(policyId);
      else newSet.delete(policyId);
      return newSet;
    });

  const onApply = async () => {
    setLoading(true);
    const success = await onSubmit(Array.from(selectedSet));
    setLoading(false);
    if (success) onClose();
  };

  useEffect(() => {
    if (opened) setSelectedSet(new Set(group.policies.map((policy) => policy.id)));
  }, [group, opened]);

  return (
    <ModalBase
      opened={opened}
      title={t('userMgmt.groups.managePolicies.title', { name: group.name })}
      bodyProps={{ p: 'zero' }}
      onClose={onClose}
    >
      <Box pt='tiny'>
        <Flex px='lg' gap='lg'>
          <GroupPoliciesMappingSearch value={search} onChange={onSearch} />
          <AllCheckbox all={allSelected} some={someSelected} onChange={onToggleAll} data-testid={testIds.all} />
        </Flex>
        <ScrollArea h={288}>
          <Stack p='lg'>
            {filteredPolicies.map((policy) => (
              <GroupPoliciesMappingPolicyRow
                key={policy.id}
                policy={policy}
                selected={selectedSet.has(policy.id)}
                search={search}
                onChange={handleChangeSelected(policy.id)}
              />
            ))}
          </Stack>
        </ScrollArea>
      </Box>
      <Divider />
      <MantineGroup p='lg' justify='end'>
        <Button loading={loading} onClick={onApply} data-testid={testIds.apply}>
          {t('common.apply')}
        </Button>
      </MantineGroup>
    </ModalBase>
  );
};
