import { Badge, BadgeProps } from '@mantine/core';
import { AiDataSourceStatus } from 'stores/aiPlatform';
import { statusColors, statusLabels } from './DataGenerationStatusBadge.const';
import './BlinkingBadge.css';

interface DataGenerationStatusBadgeProps extends BadgeProps {
  status: AiDataSourceStatus;
}

export const testIds = {
  badge: 'data-generation-status-badge',
};

export const DataGenerationStatusBadge = ({ status, ...props }: DataGenerationStatusBadgeProps) => (
  <Badge
    variant='dot'
    radius='sm'
    size='lg'
    color={statusColors[status]}
    {...props}
    className={status === AiDataSourceStatus.IN_PROGRESS ? 'blinking-badge' : ''}
  >
    {statusLabels[status]}
  </Badge>
);
