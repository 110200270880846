import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconUsersGroup } from '@tabler/icons-react';
import { GroupPoliciesMappingModal } from 'pages/userMgmt/components/GroupPoliciesMappingModal/GroupPoliciesMappingModal';
import { groupPoliciesMappingTestIds } from 'pages/userMgmt/GroupsPage/GroupTable/components';
import { useTranslation } from 'react-i18next';
import { Group, Policy } from 'stores/userMgmt';
import { compareArrays } from 'utils';

interface PoliciesTableManageActionProps {
  policies: Policy[];
  group: Group;
  onChangePolicies: (policiesIds: string[]) => Promise<boolean>;
}

export const testIds = {
  button: 'policies-table-manage-action',
  modal: groupPoliciesMappingTestIds,
};

export const PoliciesTableManageAction = ({ policies, group, onChangePolicies }: PoliciesTableManageActionProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();

  const onChangeMapping = async (policyIds: string[]) => {
    const existingPolicyIds = group.policies.map((policy) => policy.id);
    const { isDiff, added, removed } = compareArrays(existingPolicyIds, policyIds);

    if (!isDiff) return true;

    const response = await onChangePolicies(policyIds);

    if (response) {
      notifications.show({
        color: 'blue',
        title: t('userMgmt.groups.groupDetails.panels.policies.table.actions.manage.success.title'),
        message: t('userMgmt.groups.groupDetails.panels.policies.table.actions.manage.success.message', {
          added: added.length,
          removed: removed.length,
        }),
        icon: <IconUsersGroup />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.groupDetails.panels.policies.table.actions.manage.error.title'),
        message: t('common.tryAgain'),
        icon: <IconUsersGroup />,
      });
    }
    return response;
  };

  return (
    <>
      <Button onClick={open} data-testid={testIds.button}>
        {t('userMgmt.groups.groupDetails.panels.policies.table.actions.manage.button')}
      </Button>
      <GroupPoliciesMappingModal
        opened={opened}
        policies={policies}
        group={group}
        onClose={close}
        onSubmit={onChangeMapping}
      />
    </>
  );
};
