import { Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { NiceResponse } from 'consts';
import { useUserMgmtStore } from 'stores/userMgmt';
import { User } from 'stores/auth';
import { UserRouteParams } from 'routes/routes.config';
import { DeleteUserAction, UserPersonalData, deleteUserTestIds, personalDataTestIds } from './components';

export const testIds = {
  wrapper: 'user-details-panel',
  personalData: personalDataTestIds,
  deleteUser: deleteUserTestIds,
};

export const UserDetailsPanel = () => {
  const { customerId, userId } = useParams() as UserRouteParams;
  const { users, upsertUser, deleteUser } = useUserMgmtStore(['users', 'upsertUser', 'deleteUser']);
  const user = users[userId];

  const assertDuplicateEmail = (email: string) =>
    Object.values(users).some((u) => u.email === email && u.userId !== userId);

  const onChange = async (key: keyof User, value: string) => {
    if (!value) return NiceResponse.ERROR;
    if (user[key] === value) return NiceResponse.OK;
    if (key === 'email') {
      const isDuplicateEmail = assertDuplicateEmail(value);
      if (isDuplicateEmail) return NiceResponse.DUPLICATE;
    }
    const { givenName, familyName } = user;
    const response = await upsertUser(customerId, {
      action: 'update',
      userId,
      payload: { givenName, familyName, [key]: value },
    });
    return response ? NiceResponse.OK : NiceResponse.ERROR;
  };

  const onDelete = async () => deleteUser(customerId, userId);

  return (
    <Stack mt='lg' data-testid={testIds.wrapper}>
      <UserPersonalData user={user} onChange={onChange} />
      <DeleteUserAction user={user} onDelete={onDelete} />
    </Stack>
  );
};
