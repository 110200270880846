import { Button, Center, FileButton, Flex, Image, Input, Stack } from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconCloudUpload, IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface CustomerLogoInputProps {
  onChange: (logo: string | File) => void;
  value?: string | File;
}

export const testIds = {
  label: 'customer-edit-modal-upload-label',
  dropzone: 'customer-edit-modal-upload-dropzone',
  button: 'customer-edit-modal-upload-button',
  icon: 'customer-edit-modal-upload-icon',
  description: 'customer-edit-modal-upload-description',
  image: 'customer-edit-modal-upload-image',
};

const imageMimeTypeString = IMAGE_MIME_TYPE.join(',');

export const CustomerLogoInput = ({ onChange, value }: CustomerLogoInputProps) => {
  const { t } = useTranslation();

  const imageSrc = value instanceof File ? URL.createObjectURL(value) : value;

  const onDrop = (files: File | File[] | null) => {
    const file = Array.isArray(files) ? files[0] : files;
    if (file) onChange(file);
  };

  return (
    <Input.Wrapper required>
      <Input.Label required data-testid={testIds.label}>
        {t('customersPage.editCustomerModal.logoInput.label')}
      </Input.Label>
      <Flex gap='md'>
        <Dropzone
          accept={IMAGE_MIME_TYPE}
          classNames={{ inner: 'h-full' }}
          disabled
          w={88}
          h={88}
          multiple={false}
          onDrop={onDrop}
          data-testid={testIds.dropzone}
        >
          {imageSrc ? (
            <Image src={imageSrc} w='100%' h='100%' data-testid={testIds.image} />
          ) : (
            <Center h='100%'>
              <Dropzone.Accept>
                <IconUpload size={24} stroke={1.5} />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX size={24} stroke={1.5} />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconPhoto size={24} stroke={1.5} />
              </Dropzone.Idle>
            </Center>
          )}
        </Dropzone>
        <Stack justify='space-between'>
          <FileButton accept={imageMimeTypeString} disabled multiple={false} onChange={onDrop}>
            {(props) => (
              <Button
                disabled
                leftSection={<IconCloudUpload data-testid={testIds.icon} />}
                variant='outline'
                w='fit-content'
                data-testid={testIds.button}
                {...props}
              >
                {t('customersPage.editCustomerModal.logoInput.button')}
              </Button>
            )}
          </FileButton>
          <Input.Description data-testid={testIds.description}>
            {t('customersPage.editCustomerModal.logoInput.message')}
          </Input.Description>
        </Stack>
      </Flex>
    </Input.Wrapper>
  );
};
