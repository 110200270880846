import { Box, Button, Group, Radio, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import i18n from 'services/i18n';
import { useFolder, useFolderStore } from 'stores/folders';
import { OpenGlobalModalProps, useModal } from 'stores/globalModal';
import { NonEmptyErrorNotificationContent } from './NonEmptyErrorNotificationContent';

interface DeleteFolderModalProps {
  folderId: string;
}

type Action = 'keep' | 'archive';

export const testIds = {
  container: 'delete-folder-modal',
  submit: 'delete-folder-submit-button',
  cancel: 'delete-folder-cancel-button',
  message: 'delete-folder-message',
  options: {
    group: 'delete-folder-options-group',
    keep: 'delete-folder-option-delete',
    archive: 'delete-folder-option-archive',
    optionRootSelector: '.mantine-Radio-root',
  },
};

export const DeleteFolderModal = ({ folderId }: DeleteFolderModalProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const { close } = useModal();
  const [action, setAction] = useState<Action>('keep');
  const [loading, setLoading] = useState(false);
  const folder = useFolder(folderId);
  const { deleteFolder } = useFolderStore(['deleteFolder']);
  const isFolderEmpty = !folder?.flowIds.length;

  const onChange = (value: string) => setAction(value as Action);

  const getSuccessNotificationMessage = () => {
    if (isFolderEmpty) return null;
    return action === 'archive'
      ? t('flowsPage.modals.deleteFolder.nonEmptyFolder.optionArchiveFlows.successMessage')
      : t('flowsPage.modals.deleteFolder.nonEmptyFolder.optionKeepFlows.successMessage');
  };

  const onSubmit = async () => {
    if (!folder) return;
    setLoading(true);
    const shouldArchive = action === 'archive';
    const response = await deleteFolder(customerId, folder.id, shouldArchive);
    if (response) {
      notifications.show({
        color: 'red',
        title: t('flowsPage.modals.deleteFolder.successMessage', { name: folder.name }),
        message: getSuccessNotificationMessage(),
        icon: <IconTrash />,
      });
      close();
    } else {
      notifications.show({
        color: 'red',
        title: t('flowsPage.modals.deleteFolder.errorMessage', { name: folder.name }),
        message: isFolderEmpty ? null : <NonEmptyErrorNotificationContent />,
        icon: <IconTrash />,
      });
    }
    setLoading(false);
  };

  return (
    <Box data-testid={testIds.container}>
      <Text c='gray.6' data-testid={testIds.message}>
        {isFolderEmpty
          ? t('flowsPage.modals.deleteFolder.emptyFolder.message')
          : t('flowsPage.modals.deleteFolder.nonEmptyFolder.message')}
      </Text>
      {!isFolderEmpty && (
        <Radio.Group value={action} onChange={onChange} data-testid={testIds.options.group}>
          <Radio
            value='keep'
            py='tn'
            label={t('flowsPage.modals.deleteFolder.nonEmptyFolder.optionKeepFlows.title')}
            description={t('flowsPage.modals.deleteFolder.nonEmptyFolder.optionKeepFlows.message')}
            data-testid={testIds.options.keep}
          />
          <Radio
            value='archive'
            py='tn'
            label={t('flowsPage.modals.deleteFolder.nonEmptyFolder.optionArchiveFlows.title')}
            description={t('flowsPage.modals.deleteFolder.nonEmptyFolder.optionArchiveFlows.message')}
            data-testid={testIds.options.archive}
          />
        </Radio.Group>
      )}
      <Group justify='end' mt='lg'>
        <Button variant='subtle' onClick={close} disabled={loading} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} loading={loading} data-autofocus data-testid={testIds.submit}>
          {t('flowsPage.modals.deleteFolder.submit')}
        </Button>
      </Group>
    </Box>
  );
};

export const deleteFolderGlobalModalProps = (folderId: string): OpenGlobalModalProps => ({
  title: i18n.t('flowsPage.modals.deleteFolder.title'),
  children: <DeleteFolderModal folderId={folderId} />,
  variant: 'warning',
});
