import { createTheme } from '@mantine/core';
import { colorTheme } from './theme.colors';
import { componentTheme } from './theme.components';
import { fontTheme } from './theme.fonts';
import { sizeTheme } from './theme.sizes';

export const theme = createTheme({
  ...fontTheme,
  ...colorTheme,
  ...sizeTheme,
  ...componentTheme,
});
