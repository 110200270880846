import { Button, Group, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface NewLabelProps {
  allowDynamic: boolean;
  allowStatic: boolean;
  onCreate: (dynamic: boolean) => Promise<void>;
}

export const testIds = {
  dynamicButton: {
    button: 'new-label-dynamic-button',
    forbiddenTooltip: 'new-label-dynamic-forbidden-tooltip',
    icon: 'new-label-dynamic-icon',
  },
  staticButton: {
    button: 'new-label-static-button',
    forbiddenTooltip: 'new-label-static-forbidden-tooltip',
    icon: 'new-label-static-icon',
  },
};

export const NewLabelButtons = ({ allowDynamic, allowStatic, onCreate }: NewLabelProps) => {
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);

  const handleClick = (dynamic: boolean) => async () => {
    setCreating(true);
    await onCreate(dynamic);
    setCreating(false);
  };

  return (
    <Group grow>
      <Tooltip
        disabled={allowStatic}
        label={t('wizard.steps.labeling.labelManagement.newLabel.forbiddenTooltip')}
        data-testid={testIds.staticButton.forbiddenTooltip}
      >
        <Button
          disabled={!allowStatic}
          leftSection={<IconPlus data-testid={testIds.staticButton.icon} />}
          loading={allowStatic && creating}
          variant='outline'
          onClick={handleClick(false)}
          data-testid={testIds.staticButton.button}
        >
          {t('wizard.steps.labeling.labelManagement.newLabel.static')}
        </Button>
      </Tooltip>
      <Tooltip
        disabled={allowDynamic}
        label={t('wizard.steps.labeling.labelManagement.newLabel.forbiddenTooltip')}
        data-testid={testIds.dynamicButton.forbiddenTooltip}
      >
        <Button
          disabled={!allowDynamic}
          leftSection={<IconPlus data-testid={testIds.dynamicButton.icon} />}
          loading={allowDynamic && creating}
          variant='outline'
          onClick={handleClick(true)}
          data-testid={testIds.dynamicButton.button}
        >
          {t('wizard.steps.labeling.labelManagement.newLabel.dynamic')}
        </Button>
      </Tooltip>
    </Group>
  );
};
