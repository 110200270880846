import { Box, Button, Group, Paper, Text, ThemeIcon } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle, IconDownload, IconMail } from '@tabler/icons-react';
import { memo, useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiDataSourceSamplesType, AiDataSourceStatus } from 'stores/aiPlatform';

import { testIds as textSettingsModalTestIds } from '../../TextDataGenerationSettingsModal/TextDataGenerationSettingsModal';
import { testIds as voiceSettingsModalTestIds } from '../../VoiceDataGenerationSettingsModal/VoiceDataGenerationSettingsModal';
import { AbortGenerationModal, testIds as abortModalTestIds } from './AbortGenerationModal/AbortGenerationModal';
import {
  DeclineGenerationModal,
  testIds as declineModalTestIds,
} from './DeclineGenerationModal/DeclineGenerationModal';
import classes from './DataGenerationActions.module.css';

export interface DataGenerationActionsProps {
  status: AiDataSourceStatus;
  samplesType: AiDataSourceSamplesType;
  generationID: string;
  fullGenerationSamplesNumber: number | null;
  isSamplesGeneration: boolean;
  onFullDataGenFlow: () => void;
  onApproveAndGenerate: () => Promise<boolean>;
  onDownload: () => void;
}

export const testIds = {
  abort: 'data-generation-actions-abort',
  decline: 'data-generation-actions-decline',
  downloadSamples: 'data-generation-actions-download-samples',
  approveAndGenerate: 'data-generation-actions-approve-and-generate',
  fullGenerationNote: {
    icon: 'data-generation-actions-full-generation-note-icon',
    text: 'data-generation-actions-full-generation-note-text',
  },
  textSettingsModal: textSettingsModalTestIds,
  voiceSettingsModal: voiceSettingsModalTestIds,
  abortModal: abortModalTestIds,
  declineModal: declineModalTestIds,
};

export const DataGenerationActions = memo(
  ({
    status,
    samplesType,
    generationID,
    fullGenerationSamplesNumber,
    isSamplesGeneration,
    onApproveAndGenerate,
    onFullDataGenFlow,
    onDownload,
  }: DataGenerationActionsProps) => {
    const [submitting, setSubmitting] = useState(false);
    const { t } = useTranslation();

    const [abortModalOpened, abortModalHandlers] = useDisclosure();
    const [declineModalOpened, declineModalHandlers] = useDisclosure();

    const isInProgress = status === AiDataSourceStatus.IN_PROGRESS;
    const isFullGenerationCompleted = !isSamplesGeneration && status === AiDataSourceStatus.COMPLETED;
    const isSamplesCompleted = isSamplesGeneration && status === AiDataSourceStatus.COMPLETED;

    const handleGenerateClick = useCallback(() => {
      if (fullGenerationSamplesNumber) {
        approveAndGenerate();
      } else {
        onFullDataGenFlow();
      }
    }, [fullGenerationSamplesNumber, onFullDataGenFlow, onApproveAndGenerate]);

    const approveAndGenerate = useCallback(async () => {
      setSubmitting(true);
      const success = await onApproveAndGenerate();
      if (!success) {
        notifications.show({
          color: 'red',
          title: t('dataGenerationPage.generateModal.errorMessage'),
          icon: <IconAlertTriangle />,
          message: t('common.tryAgain'),
        });
      }
      setSubmitting(false);
    }, [onApproveAndGenerate, t]);

    const actionView = useMemo(() => {
      if (isFullGenerationCompleted) {
        return <div />;
      }

      return (
        <Paper className={classes.root}>
          {isInProgress && (
            <Button color='red' onClick={abortModalHandlers.open} data-testid={testIds.abort}>
              {t('dataGenerationPage.actions.abortButton')}
            </Button>
          )}

          {!isSamplesGeneration && isInProgress && (
            <Group align='center' gap='tiny' mx='auto'>
              <ThemeIcon c='teal.7' variant='white'>
                <IconMail size={20} data-testid={testIds.fullGenerationNote.icon} />
              </ThemeIcon>
              <Text c='gray.6' data-testid={testIds.fullGenerationNote.text}>
                {t('dataGenerationPage.actions.fullGenerationNote')}
              </Text>
            </Group>
          )}

          {isSamplesCompleted && (
            <>
              <Button color='red' onClick={declineModalHandlers.open} data-testid={testIds.decline}>
                {t('dataGenerationPage.actions.declineButton')}
              </Button>
              <Box flex={1} />
              <Button
                variant='transparent'
                color='grey.7'
                leftSection={<IconDownload />}
                onClick={onDownload}
                data-testid={testIds.downloadSamples}
              >
                {t('dataGenerationPage.actions.downloadSamplesButton')}
              </Button>
              <Button loading={submitting} onClick={handleGenerateClick} data-testid={testIds.approveAndGenerate}>
                {t('dataGenerationPage.actions.approveAndGenerateButton')}
              </Button>
            </>
          )}

          <AbortGenerationModal
            opened={abortModalOpened}
            samplesType={samplesType}
            generationId={generationID}
            close={abortModalHandlers.close}
          />
          <DeclineGenerationModal
            opened={declineModalOpened}
            samplesType={samplesType}
            generationId={generationID}
            close={declineModalHandlers.close}
          />
        </Paper>
      );
    }, [
      isFullGenerationCompleted,
      isInProgress,
      isSamplesGeneration,
      isSamplesCompleted,
      abortModalHandlers,
      declineModalHandlers,
      handleGenerateClick,
      submitting,
      samplesType,
      generationID,
      t,
    ]);

    return actionView;
  },
);
