import { ComponentProps, PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { Popover } from '@mantine/core';
import { ChildNavLink } from './ChildNavLink';
import classes from '../FlowLayout.module.css';

type ChildNavLinkElement = ReactElement<ComponentProps<typeof ChildNavLink>>;

interface ParentNavLinkMenuProps extends PropsWithChildren {
  links: ChildNavLinkElement | ChildNavLinkElement[];
  disabled?: boolean;
  linkDisabled?: boolean;
}

export const testIds = {
  menu: 'flow-sidebar-parent-nav-menu',
};

export const ParentNavLinkMenu = ({ children, links, disabled, linkDisabled }: ParentNavLinkMenuProps) => {
  const [isOpened, setOpened] = useState(false);

  const handleTargetClick = useCallback(() => {
    if (linkDisabled) {
      return;
    }
    setOpened((prev) => !prev);
  }, [linkDisabled]);

  const handleClosePopover = useCallback(() => setOpened(false), []);

  if (disabled) {
    return children;
  }

  return (
    <Popover position='right-start' offset={10} opened={isOpened} onChange={setOpened} disabled={linkDisabled}>
      <Popover.Target>
        <div onClick={handleTargetClick}>{children}</div>
      </Popover.Target>
      <Popover.Dropdown className={classes.navLinkPopover} data-testid={testIds.menu} onClick={handleClosePopover}>
        {links}
      </Popover.Dropdown>
    </Popover>
  );
};
