import { ComponentProps, ComponentType, ReactElement, useState } from 'react';
import { NavLink } from '@mantine/core';
import { ChildNavLink } from './ChildNavLink';
import { ParentNavLinkMenu, testIds as menuTestIds } from './ParentNavLinkMenu';
import classes from '../FlowLayout.module.css';

type ChildNavLinkElement = ReactElement<ComponentProps<typeof ChildNavLink>>;

interface ParentNavLinkProps {
  children: ChildNavLinkElement | ChildNavLinkElement[];
  Icon?: ComponentType<any>;
  label: string;
  active?: boolean;
  disabled?: boolean;
  sidebarExpanded?: boolean;
}

export const testIds = {
  link: 'flow-sidebar-parent-nav-link',
  menu: menuTestIds,
};

export const ParentNavLink = ({
  children,
  Icon,
  label,
  active,
  disabled,
  sidebarExpanded,
  ...props
}: ParentNavLinkProps) => {
  const [expanded, setExpanded] = useState(active);

  const onClick = () => {
    if (sidebarExpanded) setExpanded((state) => !state);
  };

  return (
    <ParentNavLinkMenu links={children} disabled={sidebarExpanded} linkDisabled={disabled}>
      <NavLink
        active={active}
        className={classes.navLink}
        disabled={disabled}
        label={label}
        leftSection={Icon && <Icon height={24} stroke={1} />}
        opened={sidebarExpanded && expanded}
        onClick={onClick}
        {...props}
      >
        {children}
      </NavLink>
    </ParentNavLinkMenu>
  );
};
