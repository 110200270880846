import { MantineThemeOverride, rem } from '@mantine/core';

export const fontTheme: Pick<MantineThemeOverride, 'fontFamily' | 'fontFamilyMonospace' | 'fontSizes' | 'headings'> = {
  fontFamily: 'Poppins, sans-serif',
  headings: {
    fontWeight: '500',
    sizes: {
      h1: { fontSize: rem(32) },
      h2: { fontSize: rem(26) },
      h3: { fontSize: rem(24) },
      h4: { fontSize: rem(22) },
      h5: { fontSize: rem(18) },
      h6: { fontSize: rem(14) },
    },
  },
};
