import { EmptyState } from 'components';
import { useTranslation } from 'react-i18next';

export const testIds = {
  wrapper: 'label-list-empty-state-wrapper',
};

export const LabelListEmptyState = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('wizard.steps.labeling.labelManagement.emptyState.title')}
      message={t('wizard.steps.labeling.labelManagement.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
