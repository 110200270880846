import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconUsersGroup } from '@tabler/icons-react';
import { GroupUsersMappingModal } from 'pages/userMgmt/components/GroupUsersMappingModal/GroupUsersMappingModal';
import { groupUsersMappingTestIds } from 'pages/userMgmt/GroupsPage/GroupTable/components';
import { useTranslation } from 'react-i18next';
import { User } from 'stores/auth';
import { Group } from 'stores/userMgmt';
import { compareArrays } from 'utils';

interface UsersTableManageActionProps {
  users: User[];
  group: Group;
  onChangeUsers: (userIds: string[]) => Promise<boolean>;
}

export const testIds = {
  button: 'users-table-manage-action',
  modal: groupUsersMappingTestIds,
};

export const UsersTableManageAction = ({ users, group, onChangeUsers }: UsersTableManageActionProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();

  const onChangeMapping = async (userIds: string[]) => {
    const existingUserIds = group.users.map((user) => user.userId);
    const { isDiff, added, removed } = compareArrays(existingUserIds, userIds);

    if (!isDiff) return true;

    const response = await onChangeUsers(userIds);

    if (response) {
      notifications.show({
        color: 'blue',
        title: t('userMgmt.groups.groupDetails.panels.users.table.actions.manage.success.title'),
        message: t('userMgmt.groups.groupDetails.panels.users.table.actions.manage.success.message', {
          added: added.length,
          removed: removed.length,
        }),
        icon: <IconUsersGroup />,
      });
    } else {
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.groupDetails.panels.users.table.actions.manage.error.title'),
        message: t('common.tryAgain'),
        icon: <IconUsersGroup />,
      });
    }
    return response;
  };

  return (
    <>
      <Button onClick={open} data-testid={testIds.button}>
        {t('userMgmt.groups.groupDetails.panels.users.table.actions.manage.button')}
      </Button>
      <GroupUsersMappingModal opened={opened} users={users} group={group} onClose={close} onSubmit={onChangeMapping} />
    </>
  );
};
