import { Badge, BadgeProps, ElementProps, Indicator } from '@mantine/core';
import { Flow, getFlowStatus, isFlowLive } from 'stores/flows';
import cn from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FlowStatus } from '@jargonic/flows-types';

interface FlowStatusSignProps extends BadgeProps, ElementProps<'div', keyof BadgeProps> {
  flow: Flow;
}

export const testIds = {
  badge: 'flow-status-sign-badge',
  dot: 'flow-status-sign-dot',
};

export const FlowStatusBadge = ({ flow, className, ...props }: FlowStatusSignProps) => {
  const { t } = useTranslation();
  const { badgeColor, badgeTitle, isLive } = useMemo(() => {
    const live = isFlowLive(flow);
    const flowStatus = getFlowStatus(flow);
    const [title, color] = (() => {
      switch (flowStatus) {
        case FlowStatus.PUBLISHED:
          return [t('common.flowStatus.published'), 'red.6'];
        case FlowStatus.ARCHIVED:
          return [t('common.flowStatus.archived'), 'violet.6'];
        case FlowStatus.DRAFT:
          return [t('common.flowStatus.draft'), 'gray'];
        default:
          return [t('common.flowStatus.live'), 'green'];
      }
    })();
    return { badgeTitle: title, badgeColor: color, isLive: live };
  }, [flow]);

  return (
    <Badge
      variant='default'
      className={cn({ '!border-valid': isLive }, className)}
      size='lg'
      leftSection={<Indicator size={6} mr='tn' color={badgeColor} zIndex={0} data-testid={testIds.dot} />}
      data-testid={testIds.badge}
      {...props}
    >
      {badgeTitle}
    </Badge>
  );
};
