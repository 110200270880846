import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseButton, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

interface GroupTableSearchProps {
  value: string;
  onChange: (value: string) => void;
}

export const testIds = {
  icon: 'group-table-search-icon',
  input: 'group-table-search-input',
  clear: 'group-table-search-clear',
};

export const GroupTableSearch = ({ value, onChange }: GroupTableSearchProps) => {
  const { t } = useTranslation();

  const onClear = () => onChange('');
  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => onChange(event.currentTarget.value);

  return (
    <TextInput
      placeholder={t('userMgmt.groups.table.actions.search.placeholder')}
      rightSection={<CloseButton hidden={!value} onClick={onClear} data-testid={testIds.clear} />}
      leftSection={<IconSearch size={18} data-testid={testIds.icon} />}
      value={value}
      onChange={onSearch}
      data-testid={testIds.input}
    />
  );
};
