import { FlowMetadata } from '@jargonic/flow-metadata-types';
import { Flow } from 'stores/flows';

export type EditableFlowValues = Pick<
  Flow,
  'dateFormat' | 'maxInspectors' | 'name' | 'singleInstance' | 'supportedCommonEvents' | 'expiration'
>;

export type EditableMetadataValues = Required<
  Pick<FlowMetadata, 'title' | 'postInspectionFields' | 'preInspectionFields' | 'uniqueIdentifierFieldId'>
>;

export interface FlowSettingsData {
  flow: EditableFlowValues;
  metadata: EditableMetadataValues;
}

export type MetadataType = 'pre' | 'post';
export { type FlowMetadataField, FlowMetadataFieldType } from '@jargonic/flow-metadata-types';

export enum FieldValuesValueCSVColumnName {
  VALUE = 'value',
}

export enum FieldValuesCSVResponse {
  OK,
  ERROR,
  MISSING_COLUMN,
  DUPLICATE_IMPORTED_NAME,
}

export type FieldValuesCSVParsedPayload = Record<FieldValuesValueCSVColumnName, string>;
