import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createStoreHook } from '@aiola/frontend';
import { useShallow } from 'zustand/react/shallow';
import { OpenGlobalModalProps } from './globalModal.types';

interface GlobalModalState extends OpenGlobalModalProps {
  opened: boolean;
}

interface GlobalModalActions {
  open: (args: OpenGlobalModalProps) => void;
  close: () => void;
  reset: () => void;
}

const initialState: GlobalModalState = {
  variant: 'free',
  title: '',
  color: undefined,
  children: null,
  opened: false,
};

export const globalModalStore = create(
  immer<GlobalModalState & GlobalModalActions>((set) => ({
    ...initialState,
    open: (args) => {
      const { variant = 'free', color = undefined, ...rest } = args;
      set({
        opened: true,
        variant,
        color,
        ...rest,
      });
    },
    close: () => set({ opened: false }),
    reset: () => set({ ...initialState }),
  })),
);

export const useGlobalModalStore = createStoreHook<GlobalModalState & GlobalModalActions>({
  store: globalModalStore,
  useShallow,
});
