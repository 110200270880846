import { MRT_Cell } from 'mantine-react-table';
import { MutableRefObject } from 'react';
import { AiDataVoiceSample } from 'stores/aiPlatform';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';

export const AudioPlayerCell = ({
  cell,
  globalAudioRef,
}: {
  globalAudioRef: MutableRefObject<HTMLAudioElement | undefined>;
  cell: MRT_Cell<AiDataVoiceSample, unknown>;
}) => <AudioPlayer src={cell.getValue<string>()} globalAudioRef={globalAudioRef} />;
