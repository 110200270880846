import { Policy } from '@jargonic/customer-types';
import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';

export const columns: MRT_ColumnDef<Policy>[] = [
  {
    accessorKey: 'name',
    header: i18n.t('common.name'),
  },
];
