import { Combobox, useCombobox } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useSet } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerId } from 'stores/wizard';
import { EmptyState } from 'components';
import { ParentFilterControls, testIds as controlsTestIds } from './ParentFilterControls';
import { ParentFilterOption, testIds as optionsTestIds } from './ParentFilterOption';

interface ParentFilterProps {
  containers: Container[];
  onChange: (selectedIds: ContainerId[]) => void;
}

export const testIds = {
  controls: controlsTestIds,
  options: optionsTestIds,
  emptyState: 'event-def-parent-filter-empty-state',
  search: {
    input: 'event-def-parent-filter-search',
  },
};

export const ParentFilter = ({ containers, onChange }: ParentFilterProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const selectedSet = useSet<ContainerId>();
  const parentContainers = useMemo(
    () => containers.filter((container) => container.childrenIds.length > 0),
    [containers],
  );
  const filteredParentContainers = useMemo(
    () => parentContainers.filter((container) => container.name.toLowerCase().includes(search.toLowerCase())),
    [parentContainers, search],
  );

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  const onClickTarget = () => combobox.toggleDropdown();
  const onChangeSelected = () => onChange(Array.from(selectedSet));
  const onOptionSubmit = (optionId: ContainerId) => {
    if (selectedSet.has(optionId)) selectedSet.delete(optionId);
    else selectedSet.add(optionId);
    onChangeSelected();
  };
  const onClear = () => {
    selectedSet.clear();
    onChangeSelected();
  };

  return (
    <Combobox store={combobox} width={450} position='bottom-start' onOptionSubmit={onOptionSubmit}>
      <ParentFilterControls selectedCount={selectedSet.size} onClear={onClear} onToggle={onClickTarget} />
      <Combobox.Dropdown>
        <Combobox.Search
          value={search}
          placeholder={t('common.searchPlaceholder')}
          leftSection={<IconSearch size={18} />}
          onChange={(event) => setSearch(event.currentTarget.value)}
          data-testid={testIds.search.input}
        />
        <Combobox.Empty hidden={Boolean(filteredParentContainers.length)}>
          <EmptyState
            title={
              search
                ? t('wizard.steps.reportedEvents.generalSettings.mapping.modal.parentFilter.emptyState.emptySearch')
                : t('wizard.steps.reportedEvents.generalSettings.mapping.modal.parentFilter.emptyState.noItems')
            }
            data-testid={testIds.emptyState}
          />
        </Combobox.Empty>
        <Combobox.Options mah={250} className='overflow-y-auto'>
          {filteredParentContainers.map((container) => (
            <ParentFilterOption
              key={container.id}
              container={container}
              selected={selectedSet.has(container.id)}
              search={search}
            />
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
