import { Button, Code, FileButton, Flex, Group, List, Text } from '@mantine/core';
import { IconCloudUpload } from '@tabler/icons-react';
import { ModalBase, Stick, modalBaseTestIds } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserCSVColumnName } from 'stores/userMgmt';

interface UserTableUploadCSVModalProps {
  opened: boolean;
  onClose: () => void;
  onUpload: (file: File) => Promise<void>;
}

export const testIds = {
  modal: 'user-table-upload-file-modal',
  title: modalBaseTestIds.title,
  uploadInput: 'user-table-upload-file-input',
  uploadButton: 'user-table-upload-file-submit',
  message: 'user-table-upload-file-message',
  list: 'user-table-upload-file-list',
};

export const UserTableUploadCSVModal = ({ opened, onClose, onUpload }: UserTableUploadCSVModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onUploadFile = async (file: File | null) => {
    if (!file) return;
    setLoading(true);
    await onUpload(file);
    setLoading(false);
    onClose();
  };

  return (
    <ModalBase
      title={t('userMgmt.users.table.actions.uploadFile.modal.title')}
      opened={opened}
      onClose={onClose}
      data-testid={testIds.modal}
    >
      <Text data-testid={testIds.message}>{t('userMgmt.users.table.actions.uploadFile.modal.message')}</Text>
      <Flex gap='sm' mt='sm'>
        <Stick color='green' />
        <List data-testid={testIds.list}>
          {Object.values(UserCSVColumnName).map((column) => (
            <List.Item key={column}>
              <Code>{column}</Code>
            </List.Item>
          ))}
        </List>
      </Flex>
      <Group justify='end'>
        <FileButton
          accept='text/csv'
          inputProps={{
            // @ts-ignore
            'data-testid': testIds.uploadInput,
          }}
          onChange={onUploadFile}
        >
          {(props) => (
            <Button leftSection={<IconCloudUpload />} loading={loading} data-testid={testIds.uploadButton} {...props}>
              {t('userMgmt.users.table.actions.uploadFile.modal.uploadButton')}
            </Button>
          )}
        </FileButton>
      </Group>
    </ModalBase>
  );
};
