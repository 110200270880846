import { Text } from '@mantine/core';
import { MRT_ColumnDef } from 'mantine-react-table';
import dayjs from 'dayjs';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import { Folder } from 'stores/folders';
import { Flow } from 'stores/flows';
import { isFolder } from '../../FlowTable.utils';

export const testIds = {
  wrapper: 'flow-table-timestamp-cell',
};

export const TimestampCell: MRT_ColumnDef<Flow | Folder>['Cell'] = ({ row }) =>
  isFolder(row.original) ? null : (
    <Text size='sm' span data-testid={testIds.wrapper}>
      {dayjs(row.original.updatedAt).format(CLASSIC_DATE_TIME_FORMAT)}
    </Text>
  );
