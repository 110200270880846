import { Badge, NavLink } from '@mantine/core';
import { Link } from 'react-router-dom';

interface UserMgmtNavLinkProps {
  active: boolean;
  count: number;
  disabled?: boolean;
  label: string;
  to: string;
}

export const testIds = {
  count: 'user-mgmt-nav-link-counter',
};

export const UserMgmtNavLink = ({ active, count, disabled, label, to, ...props }: UserMgmtNavLinkProps) => (
  <NavLink
    component={Link}
    to={to}
    active={active}
    className='rounded-md'
    disabled={disabled}
    label={label}
    h={40}
    c='green.9'
    rightSection={
      Boolean(count) && (
        <Badge variant='light' data-testid={testIds.count}>
          {count}
        </Badge>
      )
    }
    {...props}
  />
);
