import { MRT_ColumnDef } from 'mantine-react-table';
import { Text } from '@mantine/core';
import { FlowMetadataField } from 'stores/wizard';
import { useTranslation } from 'react-i18next';

export const testIds = {
  text: 'placeholder-cell',
  warning: 'placeholder-cell-warning',
};

export const PlaceholderCell: NonNullable<MRT_ColumnDef<FlowMetadataField>['Cell']> = ({ row }) => {
  const { t } = useTranslation();
  const { placeholder } = row.original;
  const warning = placeholder && placeholder.length > 20 && t('common.characterLimitRecommendation', { limit: 20 });
  return (
    <>
      <Text truncate size='sm' w='100%' data-testid={testIds.text}>
        {placeholder}
      </Text>
      {warning && (
        <Text c='orange.4' size='xs' data-testid={testIds.warning}>
          {warning}
        </Text>
      )}
    </>
  );
};
