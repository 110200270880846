import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import { useAuthStore } from 'stores/auth';
import { hermes } from 'services/hermes';
import { config } from 'services/config';
import { protectedRouter } from './routes.protected';
import { publicRouter } from './routes.public';

export const AppRoutes = () => {
  const { authenticated } = useAuthStore(['authenticated']);
  const [appInitialized, setAppInitialized] = useState(false);
  const router = authenticated ? protectedRouter : publicRouter;

  useEffect(() => {
    (async () => {
      await hermes.get(config.pingUrl);
      setAppInitialized(true);
    })();
  }, []);

  return appInitialized ? (
    <RouterProvider router={router} />
  ) : (
    <LoadingOverlay loaderProps={{ type: 'bars' }} visible />
  );
};
