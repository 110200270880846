import i18n from 'services/i18n';

export enum WizardStep {
  SETTINGS = 'settings',
  LABELING = 'labeling',
  EVENTS = 'events',
}

export const wizardSteps = [
  {
    step: WizardStep.SETTINGS,
    label: i18n.t('flowPage.applications.flowSettings'),
  },
  {
    step: WizardStep.LABELING,
    label: i18n.t('flowPage.applications.labeling'),
  },
  {
    step: WizardStep.EVENTS,
    label: i18n.t('flowPage.applications.events'),
  },
];
