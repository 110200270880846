import { Button, Flex, Stack, Text } from '@mantine/core';
import { IconSquareX, IconPlayerPause, IconPlayerPlay } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface TrainingContentHeaderProps {
  onAbort?: () => void;
  onPause?: () => void;
  onResume?: () => void;
}

export const testIds = {
  title: 'training-content-header-title',
  abortButton: 'training-content-header-abort-button',
  pauseButton: 'training-content-header-pause-button',
  resumeButton: 'training-content-header-resume-button',
};

export const TrainingContentHeader = ({ onAbort, onPause, onResume }: TrainingContentHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Flex p='md' align='center' justify='space-between'>
      <Stack gap='tiny'>
        <Text c='dark.3' fw={600} data-testid={testIds.title}>
          {t('trainingPipelinePage.steps.train.title')}
        </Text>
        {/* TODO: Check if we need description here, as in designs */}
      </Stack>
      <Flex gap='sm'>
        {onAbort && (
          <Button
            variant='filled'
            color='red'
            leftSection={<IconSquareX />}
            onClick={onAbort}
            data-testid={testIds.abortButton}
          >
            {t('common.abort')}
          </Button>
        )}
        {onPause && (
          <Button
            variant='filled'
            color='yellow'
            leftSection={<IconPlayerPause />}
            onClick={onPause}
            data-testid={testIds.pauseButton}
          >
            {t('common.pause')}
          </Button>
        )}
        {onResume && (
          <Button
            variant='filled'
            leftSection={<IconPlayerPlay />}
            onClick={onResume}
            data-testid={testIds.resumeButton}
          >
            {t('common.resume')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
