import { Center, SegmentedControl, SegmentedControlItem } from '@mantine/core';
import { IconLayoutGrid, IconLayoutList } from '@tabler/icons-react';
import { GridLayout } from './CustomerModal.types';

interface CustomerModalLayoutToggleProps {
  layout: GridLayout;
  onChange: (selected: GridLayout) => void;
}

export const testId = {
  layoutToggle: 'customer-modal-layout-toggle',
};

const data: SegmentedControlItem[] = [
  {
    value: 'grid',
    label: (
      <Center>
        <IconLayoutGrid width={16} height={16} />
      </Center>
    ),
  },
  {
    value: 'list',
    label: (
      <Center>
        <IconLayoutList width={16} height={16} />
      </Center>
    ),
  },
];

export const CustomerModalLayoutToggle = ({ layout, onChange }: CustomerModalLayoutToggleProps) => {
  const onChangeLayout = (value: string) => onChange(value as GridLayout);

  return <SegmentedControl data={data} value={layout} onChange={onChangeLayout} data-testid={testId.layoutToggle} />;
};
