import { Stack, Text } from '@mantine/core';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { ModalBase } from 'components';
import { useTranslation } from 'react-i18next';
import { AiModel } from 'stores/aiPlatform';
import classes from '../TrainingPipelinePage.module.css';

interface TrainingCompletedModalProps {
  aiModel: AiModel;
  opened: boolean;
  onClose: () => void;
}

export const testIds = {
  container: 'training-completed-modal',
  icon: 'training-completed-modal-icon',
};

export const TrainingCompletedModal = ({ aiModel, opened, onClose }: TrainingCompletedModalProps) => {
  const { t } = useTranslation();
  const modelType = t(`common.modelType.${aiModel.type}`);

  return (
    <ModalBase opened={opened} onClose={onClose} title='' data-testid={testIds.container}>
      <Stack align='center' gap={20} pb={80}>
        <IconCircleCheckFilled size={80} className={classes.completedIcon} data-testid={testIds.icon} />
        <Text fw={600}>{t('trainingPipelinePage.completedModal.message', { modelType })}</Text>
      </Stack>
    </ModalBase>
  );
};
