import { State } from '@ai-platform/common-types';
import { MantineColor } from '@mantine/core';
import i18n from 'services/i18n';
import { AiModelStatus } from 'stores/aiPlatform';

export const statusLabels: Record<State | AiModelStatus, string> = {
  [State.DONE]: i18n.t('common.modelStatus.completed'),
  [State.STARTED]: i18n.t('common.modelStatus.started'),
  [State.FAILED]: i18n.t('common.modelStatus.failed'),
  [State.ABORTED]: i18n.t('common.modelStatus.aborted'),
  [State.DECLINED]: i18n.t('common.modelStatus.declined'),
  [AiModelStatus.ABORTED]: i18n.t('common.modelStatus.aborted'),
  [AiModelStatus.DECLINED]: i18n.t('common.modelStatus.declined'),
  [AiModelStatus.FAILED]: i18n.t('common.modelStatus.failed'),
  [AiModelStatus.IN_TRAINING]: i18n.t('common.modelStatus.inTraining'),
  [AiModelStatus.PAUSED]: i18n.t('common.modelStatus.paused'),
  [AiModelStatus.COMPLETED]: i18n.t('common.modelStatus.completed'),
} as const;

export const statusColors: Record<State | AiModelStatus, MantineColor> = {
  [AiModelStatus.ABORTED]: 'orange',
  [AiModelStatus.FAILED]: 'red',
  [AiModelStatus.DECLINED]: 'gray',
  [AiModelStatus.IN_TRAINING]: 'blue',
  [AiModelStatus.PAUSED]: 'yellow',
  [AiModelStatus.COMPLETED]: 'green',
  [State.FAILED]: 'red',
  [State.STARTED]: 'blue',
  [State.ABORTED]: 'orange',
  [State.DECLINED]: 'green',
  [State.DONE]: 'green',
} as const;
