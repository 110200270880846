import {
  InputType,
  ListOfValuesValidation,
  ListOfValuesValidationType,
  UpsertEventDefinitionRequest,
  Validation,
} from '@jargonic/event-definition-types';
import { nanoid } from 'nanoid';
import {
  ApiReportedEventDefValue,
  ApiReportedEventDefinition,
  EventDefElement,
  EventDefNumericValidation,
  EventDefType,
  EventDefValidation,
  ReportedEventDef,
  ReportedEventDefValue,
} from './eventDefs.types';
import { Label, LabelId } from '../labels';
import {
  addTimezoneOffsetToDefaultValue,
  convertToLocalMinutes,
  isSelectionType,
  mapValidationsToApi,
  mapValidationsFromApi,
} from './eventDefs.utils';

function validatedValuesFromApi(
  validation?: EventDefValidation,
  values: ApiReportedEventDefValue[] = [],
): ReportedEventDefValue[] {
  if (!validation || validation.validationType !== ListOfValuesValidationType.VALUES) return values;
  const { validValues, invalidValues } = validation.expectedDef;
  return values.map((value) => {
    const isValid = validValues.includes(value.id);
    if (isValid) return { ...value, valid: true };
    const isInvalid = invalidValues.includes(value.id);
    if (isInvalid) return { ...value, valid: false };
    return value;
  });
}

export function reportedEventFromApi(
  apiEvent: ApiReportedEventDefinition,
  labels: Record<LabelId, Label>,
): ReportedEventDef {
  const {
    id,
    parentEvent,
    flowId: _,
    containerTypeId,
    decimalPrecision = 0,
    UI,
    validations,
    valueType,
    exposeFor,
    validationFor,
    mandatoryFor,
    timezone,
    ...rest
  } = apiEvent;
  const label = labels[containerTypeId];

  return {
    ...rest,
    id,
    title: UI?.title ?? '',
    placeholder: UI?.placeholder,
    elementType: UI?.elementType as EventDefElement,
    labelId: containerTypeId,
    parentId: parentEvent,
    isMainEvent: id === label?.mainEventId,
    decimalPrecision,
    valueType: valueType as EventDefType,
    values: validatedValuesFromApi(apiEvent.validations?.[0], apiEvent.values),
    exposed: Boolean(exposeFor),
    validated: Boolean(validationFor),
    mandatory: Boolean(mandatoryFor),
    timezone,
    default:
      UI?.elementType === EventDefElement.TIME_OF_DAY
        ? convertToLocalMinutes(apiEvent.default, apiEvent.timezone)
        : apiEvent.default,
    validations: mapValidationsFromApi(
      UI?.elementType as EventDefElement,
      validations as EventDefNumericValidation[],
      timezone,
    ) as Validation[],
  };
}

function selectionDefaultValidationToApi(event: ReportedEventDef): ListOfValuesValidation {
  const existingValidation =
    event.validations?.find((validation) => validation.validationType === ListOfValuesValidationType.VALUES) ??
    ({} as Partial<ListOfValuesValidation>);
  return {
    id: existingValidation.id ?? nanoid(),
    name: existingValidation.name ?? '',
    containerIds: event.containerIds,
    validationType: ListOfValuesValidationType.VALUES,
    type: InputType.LOGICAL,
    expectedDef: {
      validValues: event.values?.filter((value) => value.valid).map((value) => value.id) ?? [],
      invalidValues: event.values?.filter((value) => value.valid === false).map((value) => value.id) ?? [],
    },
  };
}

export function reportedEventToApi(event: ReportedEventDef): UpsertEventDefinitionRequest {
  const {
    parentId,
    labelId,
    title,
    placeholder,
    elementType,
    isMainEvent: _,
    validations,
    containerIds,
    exposed,
    mandatory,
    validated,
    timezone,
    ...rest
  } = event;
  const isSelection = isSelectionType(event.valueType);
  return {
    ...rest,
    containerIds,
    containerTypeId: labelId,
    parentEvent: parentId ?? undefined,
    validations:
      isSelection && event.values
        ? [selectionDefaultValidationToApi(event)]
        : (mapValidationsToApi(elementType, validations as EventDefNumericValidation[], timezone) as Validation[]),
    UI: {
      title,
      placeholder,
      elementType,
    },
    exposeFor: exposed ? containerIds : undefined,
    mandatoryFor: mandatory ? containerIds : undefined,
    validationFor: validated ? containerIds : undefined,
    default:
      elementType === EventDefElement.TIME_OF_DAY
        ? addTimezoneOffsetToDefaultValue(event.default, timezone)
        : event.default,
    timezone,
  };
}
