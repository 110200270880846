import { Box, InputWrapper } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FlowRouteParams } from 'routes/routes.config';
import { ApplicationCard } from '../ApplicationCard/ApplicationCard';
import classes from './ApplicationMenu.module.css';
import { applicationCardsProps } from './ApplicationMenu.const';

export const testIds = {
  wrapper: 'application-menu-wrapper',
  getCardTestId: (key: string) => `application-menu-card-${key}`,
};

export const ApplicationMenu = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as FlowRouteParams;
  const navigate = useNavigate();

  return (
    <InputWrapper label={t('flowPage.applicationsTitle')} w='100%' data-testid={testIds.wrapper}>
      <Box className={classes.grid}>
        {applicationCardsProps.map(({ navigateTo, ...option }) => (
          <ApplicationCard
            {...option}
            key={option.key}
            onClick={navigateTo && (() => navigate(navigateTo(customerId, flowId)))}
            data-testid={testIds.getCardTestId(option.key)}
          />
        ))}
      </Box>
    </InputWrapper>
  );
};
