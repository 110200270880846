import { BoxProps, Button, Flex, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface TestingContentHeaderProps extends BoxProps {
  failedSampleCount: number;
  failedSamplesLink?: string;
}

export const testIds = {
  title: 'testing-content-header-title',
  failedSampleMessage: 'testing-content-header-failed-sample-message',
  link: 'testing-content-header-link',
};

export const TestingContentHeader = ({ failedSampleCount, failedSamplesLink, ...props }: TestingContentHeaderProps) => {
  const { t } = useTranslation();
  return (
    <Flex p='md' align='center' justify='space-between' {...props}>
      <Stack gap='tiny'>
        <Text c='dark.3' fw={600} data-testid={testIds.title}>
          {t('trainingPipelinePage.steps.test.title')}
        </Text>
        {/* TODO: Check if we need description here, as in designs */}
      </Stack>
      <Stack gap='tiny' align='end'>
        {failedSampleCount > 0 ? (
          <Text size='sm' c='dark.2' data-testid={testIds.failedSampleMessage}>
            {t('trainingPipelinePage.steps.test.failedSamplesMessage', { count: failedSampleCount })}
          </Text>
        ) : null}
        {failedSamplesLink && (
          <Button p={0} variant='transparent' component={Link} to={failedSamplesLink} data-testid={testIds.link}>
            {t('trainingPipelinePage.steps.test.failedSamplesLink')}
          </Button>
        )}
      </Stack>
    </Flex>
  );
};
