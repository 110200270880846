import { Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { Permission } from 'stores/userMgmt';
import { notifications } from '@mantine/notifications';
import { IconDots, IconLicense, IconLicenseOff, IconTrash, IconTrashOff } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { EditPermissionModal, editPermissionTestIds } from 'pages/userMgmt/components';
import { PermissionTableState } from '../PermissionTable.types';
import { DeletePermissionModal, testIds as deleteModalTestIds } from './DeletePermissionModal';

export const testIds = {
  getButtonTestId: (id: string) => `permission-table-row-actions-button-${id}`,
  icon: 'permission-table-row-actions-icon',
  options: {
    delete: {
      button: 'permission-table-row-actions-delete',
      modal: deleteModalTestIds,
    },
    edit: {
      button: 'permission-table-row-actions-edit',
      modal: editPermissionTestIds,
    },
  },
};

export const PermissionTableRowActions: NonNullable<MRT_TableOptions<Permission>['renderRowActions']> = ({
  row,
  table,
}) => {
  const { t } = useTranslation();
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure();
  const [editPermissionOpened, { open: openEditPermission, close: closeEditPermission }] = useDisclosure();
  const { onDeletePermission, onEditPermission } = table.getState() as PermissionTableState;

  const onDelete = async () => {
    const response = await onDeletePermission(row.id);
    if (response)
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.policyDetails.panels.permissions.table.deletePermission.success.title'),
        message: null,
        icon: <IconTrash />,
      });
    else
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.policyDetails.panels.permissions.table.deletePermission.error.title'),
        message: t('common.tryAgain'),
        icon: <IconTrashOff />,
      });
  };

  const onEdit = async (permission: Permission) => {
    const { id, ...payload } = permission;
    const response = await onEditPermission(id, payload);
    if (response)
      notifications.show({
        color: 'blue',
        title: t('userMgmt.policies.policyDetails.panels.permissions.table.editPermission.success.title'),
        message: t('userMgmt.policies.policyDetails.panels.permissions.table.editPermission.success.message'),
        icon: <IconLicense />,
      });
    else
      notifications.show({
        color: 'red',
        title: t('userMgmt.policies.policyDetails.panels.permissions.table.editPermission.error.title'),
        message: t('common.tryAgain'),
        icon: <IconLicenseOff />,
      });
    return response;
  };

  return (
    <>
      <Menu>
        <Menu.Target>
          <ActionButton label={t('common.actions')} data-testid={testIds.getButtonTestId(row.id)}>
            <IconDots data-testid={testIds.icon} />
          </ActionButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openEditPermission} data-testid={testIds.options.edit.button}>
            {t('common.edit')}
          </Menu.Item>
          <Menu.Item onClick={openDeleteModal} data-testid={testIds.options.delete.button}>
            {t('common.delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <DeletePermissionModal opened={deleteModalOpened} onClose={closeDeleteModal} onDelete={onDelete} />
      <EditPermissionModal
        opened={editPermissionOpened}
        onClose={closeEditPermission}
        permission={row.original}
        onSubmit={onEdit}
      />
    </>
  );
};
