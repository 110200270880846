import { MRT_TableOptions } from 'mantine-react-table';
import { Container } from 'stores/wizard';
import classes from './ContainerTable.module.css';

export const tableTestIds = {
  table: 'container-table-wrapper',
  expandCell: 'container-table-expand-button',
};

export const tableStaticDefs = {
  getRowId: (container) => container.id,
  enableRowSelection: true,
  enableBottomToolbar: false,
  enablePagination: false,
  enableRowOrdering: true,
  enableEditing: true,
  editDisplayMode: 'cell',
  enableExpanding: true,
  enableColumnActions: false,
  enableSorting: false,
  enableExpandAll: false,
  filterFromLeafRows: true,
  mantineHighlightProps: { size: 'sm' },
  mantineSelectAllCheckboxProps: {
    size: 'xs',
  },
  mantineSelectCheckboxProps: {
    size: 'xs',
  },
  layoutMode: 'grid',
  enableRowActions: true,
  positionActionsColumn: 'last',
  mantinePaperProps: { pos: 'relative', h: '100%', w: '100%', radius: 'xs' },
  mantineTableContainerProps: {
    className: classes.container,
    pos: 'absolute',
    top: 'calc(var(--mrt-top-toolbar-height) * 1px)',
    left: 0,
    right: 0,
    mah: 'calc(100% - var(--mrt-top-toolbar-height) * 1px)',
    w: '100%',
  },
  mantineTableProps: {
    horizontalSpacing: 'tn',
    verticalSpacing: 'tn',
    w: '100%',
    // @ts-ignore
    'data-testid': tableTestIds.table,
  },
  mantineTableHeadCellProps: {
    className: classes.headCell,
  },
  displayColumnDefOptions: {
    'mrt-row-drag': {
      header: '',
      size: 0,
      minSize: 25,
      grow: false,
      mantineTableBodyCellProps: { pr: 0, pl: 'teeny' },
    },
    'mrt-row-expand': {
      header: '',
      size: 0,
      mantineTableBodyCellProps: {
        // indentation padding
        pl: 'calc(var(--mrt-row-depth, 0) * var(--mantine-spacing-xs))',
        w: 'calc(var(--mrt-row-depth, 0) * var(--mantine-spacing-xs) + 20px) !important',
        pr: 0,
        // @ts-ignore
        'data-testid': tableTestIds.expandCell,
      },
      mantineTableHeadCellProps: {
        w: 20,
      },
    },
    'mrt-row-select': {
      header: '',
      grow: false,
      size: 0,
      minSize: 20,
      mantineTableBodyCellProps: { px: 0 },
      mantineTableHeadCellProps: { px: 0 },
    },
    'mrt-row-actions': { header: '', grow: false },
  },
} satisfies Partial<MRT_TableOptions<Container>>;
