import { UpsertFolderRequest } from '@jargonic/folder-types';
import { orderPredicate } from 'utils';
import { Folder, FolderId } from './folders.types';

/** Reposition folder in array, in place */
function repositionFolder(folders: Folder[], from: number, to: number) {
  const folder = folders.splice(from, 1)[0];
  folders.splice(to, 0, folder);
}

/** Remove a folder from array, in place */
function removeFolder(folders: Folder[], index: number) {
  folders.splice(index, 1);
}

function listFolders(folders: Record<FolderId, Folder>) {
  return Object.values(folders).sort(orderPredicate);
}

function positionFolder(folders: Record<FolderId, Folder>, folderId: string, newOrder: number | null) {
  const folderArray = listFolders(folders);
  const currentFolderIndex = folderArray.findIndex((f) => f.id === folderId);
  const shouldRemoveFolder = newOrder === null;
  if (shouldRemoveFolder) removeFolder(folderArray, currentFolderIndex);
  else repositionFolder(folderArray, currentFolderIndex, newOrder);

  const reorderedFoldersStartIndex = shouldRemoveFolder ? currentFolderIndex : Math.min(currentFolderIndex, newOrder);
  const reorderedFlowsEndIndex = shouldRemoveFolder ? folderArray.length - 1 : Math.max(currentFolderIndex, newOrder);

  return folderArray
    .map((folder, index) => ({ ...folder, order: index }))
    .slice(reorderedFoldersStartIndex, reorderedFlowsEndIndex + 1);
}

function getBulkUpdatePayload(folder: Folder[]): UpsertFolderRequest[] {
  return folder.map(({ id, name, order }) => ({ id, name, order }));
}

export function getFolderReorderPayload(
  folders: Record<FolderId, Folder>,
  folderId: string,
  newOrder: number | null,
): UpsertFolderRequest[] {
  const folder = folders[folderId];

  const folderDidMove = folder.order !== newOrder;
  if (!folderDidMove) return [];

  return getBulkUpdatePayload(positionFolder(folders, folderId, newOrder));
}
