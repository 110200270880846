import { Timeline, Collapse } from '@mantine/core';
import { useEffect, useState } from 'react';
import { TrainingStepStatus } from 'stores/aiPlatform/aiModels';
import classes from './PipelineStepper.module.css';
import { PipelineStepperBullet, testIds as bulletTestIds } from './PipelineStepperBullet';
import { PipelineStep } from './PipelineStepper.types';

export const testIds = {
  getStepTestId: (id: string) => `pipeline-stepper-step-${id}`,
  bullet: bulletTestIds,
};

interface PipelineStepperProps {
  steps: PipelineStep[];
}

export const PipelineStepper = ({ steps }: PipelineStepperProps) => {
  const active = steps.reduce(
    (lastCompleted, step, index) => (step.status !== TrainingStepStatus.PENDING ? index : lastCompleted),
    0,
  );
  const [expanded, setExpanded] = useState(active);

  useEffect(() => {
    setExpanded(active);
  }, [active]);

  return (
    <Timeline active={active} bulletSize={40} lineWidth={2} classNames={classes}>
      {steps.map(({ id, status, title, content }, index) => (
        <Timeline.Item
          key={id}
          title={title}
          bullet={<PipelineStepperBullet status={status} label={index + 1} />}
          onClick={() => index <= active && setExpanded(index)}
          data-testid={testIds.getStepTestId(id)}
        >
          <Collapse in={expanded === index}>{content}</Collapse>
        </Timeline.Item>
      ))}
    </Timeline>
  );
};
