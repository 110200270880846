import { Menu } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FlowRouteParams } from 'routes/routes.config';
import { FlowStatus, generateFlowDownloadUrl, useFlow } from 'stores/flows';

export const testIds = {
  downloadButton: {
    button: 'download-button',
    icon: 'download-icon',
  },
  menu: {
    wrapper: 'menu-wrapper',
    draftOption: {
      button: 'draft-option',
    },
    liveOption: {
      button: 'live-option',
    },
  },
};

export const DownloadAction = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as FlowRouteParams;
  const flow = useFlow(flowId);
  const hasDraft = flow?.status === FlowStatus.DRAFT;
  const hasVersion = Boolean(flow?.activeVersion);

  return (
    <Menu>
      <Menu.Target>
        <ActionButton label={t('common.download')} data-testid={testIds.downloadButton.button}>
          <IconDownload data-testid={testIds.downloadButton.icon} />
        </ActionButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          component='a'
          href={generateFlowDownloadUrl(customerId, flowId)}
          disabled={!hasDraft}
          data-testid={testIds.menu.draftOption.button}
        >
          {t('flowPage.download.draftOption.title')}
        </Menu.Item>
        <Menu.Item
          component='a'
          href={generateFlowDownloadUrl(customerId, flowId, flow?.activeVersion)}
          disabled={!hasVersion}
          data-testid={testIds.menu.liveOption.button}
        >
          {t('flowPage.download.liveOption.title')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
