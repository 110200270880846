import { Select } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';
import { getOptionLabel } from './TimezoneSelect.utils';

export const testIds = {
  select: 'timezone-select',
  label: 'timezone-label',
};

interface TimezoneSelectProps {
  value?: string;
  onChange: (value: string) => void;
  rootClassName?: string;
}

// @ts-expect-error
// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const timezones = Intl.supportedValuesOf('timeZone').map((t: string) => ({ value: t, label: getOptionLabel(t) }));

export const TimezoneSelect = ({ value, onChange, rootClassName }: TimezoneSelectProps) => {
  const i18n = useTranslation();

  const handleOnChange = (newValue: string | null) => {
    if (newValue) {
      onChange(newValue);
    }
  };

  return (
    <Select
      required
      searchable
      allowDeselect={false}
      classNames={{ root: rootClassName }}
      data={timezones}
      label={
        <InfoLabel
          infoText={i18n.t('wizard.steps.reportedEvents.typeSettings.timezoneSelect.tooltip')}
          data-testid={testIds.label}
        >
          {i18n.t('wizard.steps.reportedEvents.typeSettings.timezoneSelect.label')}
        </InfoLabel>
      }
      placeholder={i18n.t('wizard.steps.reportedEvents.typeSettings.timezoneSelect.placeholder')}
      value={value}
      error={!value && i18n.t('wizard.steps.reportedEvents.typeSettings.timezoneSelect.error')}
      onChange={handleOnChange}
      data-testid={testIds.select}
    />
  );
};
