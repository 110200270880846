import { ModelType } from '@ai-platform/common-types';
import cn from 'classnames';
import { MRT_Row, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES, TrainingPipelineRouteParams } from 'routes/routes.config';
import { AiModel, useAiModelsByType, useLastTrainedModel } from 'stores/aiPlatform/aiModels';
import { columns, testIds as columnsTestIds } from './AiModelTable.columns';
import { aiModelTableDefs, initialSorting } from './AiModelTable.defs';
import classes from './AiModelTable.module.css';
import { AiModelTableEmptyState, AiModelTableRowActions, emptyStateTestIds, rowActionsTestIds } from './components';

export const testIds = {
  table: 'ai-model-table',
  getRowTestId: (id: string) => `ai-model-table-row-${id}`,
  rowRegex: /ai-model-table-row-[^-]+$/,
  emptyState: emptyStateTestIds,
  rowActions: rowActionsTestIds,
  columns: columnsTestIds,
};

export const AiModelTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerId, flowId } = useParams() as TrainingPipelineRouteParams;
  const [params] = useSearchParams();
  const modelType = params.get('modelType') as ModelType;
  const models = useAiModelsByType(modelType);
  const lastTrainedModel = useLastTrainedModel(modelType);

  const isLastTrained = (row: MRT_Row<AiModel>) => row.original.id === lastTrainedModel?.id;

  const table = useMantineReactTable({
    data: models,
    columns,
    ...aiModelTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: cn('cursor-pointer', { [classes.lastTrainedRow]: isLastTrained(row) }),
      onClick: () => navigate(ROUTES.AI_PLATFORM(customerId, flowId).TRAINING_PIPELINE(row.original.id)),
      'data-testid': testIds.getRowTestId(row.id),
      ...(isLastTrained(row) ? { 'data-last-trained-text': t('trainingPage.table.lastTrained') } : {}),
    }),
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    renderEmptyRowsFallback: (props) => <AiModelTableEmptyState {...props} />,
    renderRowActions: (props) => <AiModelTableRowActions {...props} />,
    initialState: { sorting: initialSorting },
  });

  return <MantineReactTable table={table} />;
};
