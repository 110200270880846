import { ModelType } from '@ai-platform/common-types';
import { exists } from '@aiola/frontend';
import { Box, Button, Group, Text } from '@mantine/core';
import { ModalBase, ModalCommonProps } from 'components';
import { memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiModel, AiModelId } from 'stores/aiPlatform';
import { EnvironmentSelect, testIds as environmentTestIds } from './components/EnvironmentSelect';
import { ModelSelect, testIds as modelTestIds } from './components/ModelSelect';
import { ENVIRONMENTS, getCurrentEnv, getPublishEnvOptions } from './PublishModal.utils';

interface TraingingModalProps extends ModalCommonProps {
  modelName: string;
  onStart: (aiDataSourceId: AiModelId, environment: string) => Promise<void>;
  trainingModels: AiModel<ModelType>[];
}

export const testIds = {
  title: 'train-model-title',
  ctaNegative: 'train-model-cancel-button',
  ctaPositive: 'train-model-train-button',
  message: 'train-model-message',
  environmentSelect: environmentTestIds,
  modelSelect: modelTestIds,
};

export const PublishModal = memo(({ modelName, onClose, onStart, trainingModels }: TraingingModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const currentEnvName = getCurrentEnv();
  const [selectedEnv, setSelectedEnv] = useState<ENVIRONMENTS | null>(currentEnvName);
  const [selectedModel, setSelectedModel] = useState<string | null>(null);
  const { t } = useTranslation();

  const title = t('publishPage.publishModal.title', { modelName });
  const ctaNegative = t('publishPage.publishModal.ctaNegative');
  const ctaPositive = t('publishPage.publishModal.ctaPositive');
  const message = t('publishPage.publishModal.message');

  const isSelectedEnvSet = exists(selectedEnv);
  const isSelectedModelSet = exists(selectedModel);
  const environmentsOptions = getPublishEnvOptions();

  const trainingModelOptions = useMemo(() => Object.values(trainingModels), [trainingModels]);

  const handleStart = async () => {
    if (!isSelectedEnvSet || !selectedModel) return;
    setSubmitting(true);

    await onStart(selectedModel, selectedEnv);
    setSubmitting(false);
  };

  return (
    <ModalBase size='lg' title={title} onClose={onClose} opened>
      <Text className='whitespace-pre-line' c='gray.7' my='lg' data-testid={testIds.message}>
        {message}
      </Text>
      <Box py='sm'>
        <EnvironmentSelect onChange={setSelectedEnv} environmentOptions={environmentsOptions} disabled />
      </Box>
      <Box py='sm'>
        <ModelSelect onChange={setSelectedModel} options={trainingModelOptions} />
      </Box>
      <Group justify='end' pt='lg'>
        <Button variant='subtle' onClick={onClose} data-testid={testIds.ctaNegative}>
          {ctaNegative}
        </Button>
        <Button
          variant='submit'
          loading={submitting}
          disabled={!isSelectedEnvSet || !isSelectedModelSet}
          onClick={handleStart}
          data-testid={testIds.ctaPositive}
        >
          {ctaPositive}
        </Button>
      </Group>
    </ModalBase>
  );
});
