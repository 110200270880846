import { Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';
import { IconDots, IconTrash, IconTrashOff } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { User } from '@jargonic/customer-types';
import { DeleteUserFromGroupModal, testIds as deleteModalTestIds } from './DeleteUserFromGroupModal';
import { UsersTableState } from '../../UsersTable.types';

export const testIds = {
  getButtonTestId: (id: string) => `users-table-row-actions-button-${id}`,
  icon: 'users-table-row-actions-icon',
  options: {
    delete: {
      button: 'users-table-row-actions-delete',
      modal: deleteModalTestIds,
    },
  },
};

export const UsersTableRowActions: NonNullable<MRT_TableOptions<User>['renderRowActions']> = ({ row, table }) => {
  const { t } = useTranslation();
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure();
  const { group, onChangeUsers } = table.getState() as UsersTableState;

  const onDelete = async () => {
    const filteredUsersIds = group.users.filter((user) => user.userId !== row.id).map((user) => user.userId);
    const response = await onChangeUsers(filteredUsersIds);
    if (response)
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.groupDetails.panels.users.table.deleteUser.success.title'),
        message: null,
        icon: <IconTrash />,
      });
    else
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.groupDetails.panels.users.table.deleteUser.error.title'),
        message: t('common.tryAgain'),
        icon: <IconTrashOff />,
      });
  };

  return (
    <>
      <Menu>
        <Menu.Target>
          <ActionButton label={t('common.actions')} data-testid={testIds.getButtonTestId(row.id)}>
            <IconDots data-testid={testIds.icon} />
          </ActionButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openDeleteModal} data-testid={testIds.options.delete.button}>
            {t('common.delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <DeleteUserFromGroupModal opened={deleteModalOpened} onClose={closeDeleteModal} onDelete={onDelete} />
    </>
  );
};
