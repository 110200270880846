import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { CreatePolicyPayload, Policy, UpdatePolicyPayload } from '../../userMgmt.types';

const policiesUrl = (customerId: string) => `${config.jargonicApiUrl}/${customerId}/policies`;
const policyUrl = (customerId: string, policyId: string) => `${policiesUrl(customerId)}/${policyId}`;

export const policyApi = {
  getPolicies: async (customerId: string): Promise<Policy[] | undefined> => {
    const { data } = await hermes.get<Policy[]>(policiesUrl(customerId));
    return data;
  },

  createPolicy: async (customerId: string, payload: CreatePolicyPayload): Promise<Policy | undefined> => {
    const { data } = await hermes.post<Policy>(policiesUrl(customerId), payload);
    return data;
  },

  updatePolicy: async (
    customerId: string,
    policyId: string,
    payload: UpdatePolicyPayload,
  ): Promise<Policy | undefined> => {
    const { data } = await hermes.put<Policy>(policyUrl(customerId, policyId), payload);
    return data;
  },

  deletePolicy: async (customerId: string, policyId: string): Promise<boolean> => {
    const { data } = await hermes.delete(policyUrl(customerId, policyId));
    return data;
  },
};
