import { useTranslation } from 'react-i18next';
import { TextInput } from '@mantine/core';
import { InfoLabel } from 'components';
import { PLACEHOLDER_LENGTH } from 'consts';

interface EventDefPlaceholderInputProps {
  value: string;
  rootClassName?: string;
  onChange: (value: string) => void;
}

export const testIds = {
  input: 'event-def-settings-placeholder-input',
  label: 'event-def-settings-placeholder-label',
};

export const EventDefPlaceholderInput = ({ value, rootClassName, onChange }: EventDefPlaceholderInputProps) => {
  const { t } = useTranslation();

  const hasLengthWarning = value.length > PLACEHOLDER_LENGTH;

  return (
    <TextInput
      classNames={{ root: rootClassName, input: 'mb-2' }}
      description={!hasLengthWarning && t('wizard.steps.reportedEvents.generalSettings.eventPlaceholder.lengthWarning')}
      error={hasLengthWarning && t('wizard.steps.reportedEvents.generalSettings.eventPlaceholder.lengthWarning')}
      inputWrapperOrder={['label', 'input', 'error', 'description']}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.generalSettings.eventPlaceholder.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.generalSettings.eventPlaceholder.title')}
        </InfoLabel>
      }
      placeholder={t('wizard.steps.reportedEvents.generalSettings.eventPlaceholder.placeholder')}
      value={value}
      onChange={(e) => onChange(e.currentTarget.value)}
      data-testid={testIds.input}
      data-warning={hasLengthWarning}
    />
  );
};
