import { MRT_RowSelectionState } from 'mantine-react-table';
import { ReportedEventDef, ReportedEventsData } from 'stores/wizard';

export function getSortedChildrenEventDefs(eventDefs: ReportedEventsData, parentId: string): ReportedEventDef[] {
  return Object.values(eventDefs)
    .filter((eventDef) => eventDef.parentId === parentId)
    .sort((a, b) => a.order - b.order);
}

export function getRowSelectionObject(
  eventDefs: ReportedEventDef[],
  selectedEventDefId?: string,
): MRT_RowSelectionState {
  if (selectedEventDefId) return { [selectedEventDefId]: true };
  const firstEventDef = eventDefs.at(0);
  return firstEventDef ? { [firstEventDef.id]: true } : {};
}
