import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { filesApi } from './files.api';
import { FileWithUrl } from './files.types';
import { fileFromApi } from './files.adapters';

interface FileState {
  files: FileWithUrl[];
  loading: boolean;
}

interface FileActions {
  fetchData: (customerId: string, flowId: string) => Promise<boolean>;
  uploadFiles: (customerId: string, flowId: string, files: File[]) => Promise<FileWithUrl[] | undefined>;
  deleteFile: (customerId: string, flowId: string, fileId: string) => Promise<boolean>;
  reset: () => void;
}

const initialState: FileState = {
  files: [],
  loading: false,
};

export const fileStore = create(
  immer<FileState & FileActions>((set) => ({
    ...initialState,
    fetchData: async (customerId, flowId) => {
      set((state) => {
        state.loading = true;
      });
      const files = await filesApi.getFiles(customerId, flowId);
      const filesWithUrls = files?.map((file) => fileFromApi(file, customerId, flowId));
      set((state) => {
        state.files = filesWithUrls ?? [];
        state.loading = false;
      });
      return !!filesWithUrls;
    },
    uploadFiles: async (customerId, flowId, files) => {
      const uploadedFiles = await filesApi.uploadFile(customerId, flowId, files);
      const filesWithUrls = uploadedFiles.map((file) => fileFromApi(file, customerId, flowId));
      set((state) => {
        state.files.push(...filesWithUrls);
        state.loading = false;
      });
      return filesWithUrls;
    },
    deleteFile: async (customerId, flowId, fileId) => {
      const response = await filesApi.deleteFile(customerId, flowId, fileId);
      if (response)
        set((state) => {
          state.files = state.files.filter((file) => file.id !== fileId);
        });
      return response;
    },
    reset: () => set(initialState),
  })),
);

export const useFileStore = createStoreHook({ store: fileStore, useShallow });
