import { Box, Button } from '@mantine/core';
import { createFormContext } from '@mantine/form';
import { CreateGroupPayload, Group, useGroupArray } from 'stores/userMgmt';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { IconLicense, IconLicenseOff } from '@tabler/icons-react';
import { NiceResponse } from 'consts';
import { useNavigate, useParams } from 'react-router-dom';
import { UserId } from '@flow/flow-backend-types';
import { GroupRouteParams, ROUTES } from 'routes/routes.config';
import { GroupPanel } from 'pages/userMgmt/GroupPage/GroupPage.panels';
import { AddGroupPoliciesModal, testIds as addGroupPoliciesTestIds } from './AddGroupPoliciesModal';
import { AddGroupUsersModal, testIds as addGroupUsersTestIds } from './AddGroupUsersModal';
import { AddGroupNameModal, testIds as addGroupNameTestIds } from './AddGroupNameModal';

enum OpenedModal {
  GROUP_NAME = 'GROUP_NAME',
  GROUP_USERS = 'GROUP_USERS',
  GROUP_POLICIES = 'GROUP_POLICIES',
  NONE = 'NONE',
}

interface CreateGroupFormState {
  name: string;
  userIds: string[];
  policyIds: string[];
}

interface CreateGroupProps {
  onCreateGroup: (payload: CreateGroupPayload) => Promise<Group | undefined>;
}

export const testIds = {
  button: 'create-group-action-button',
  nameStepModal: addGroupNameTestIds,
  usersStepModal: addGroupUsersTestIds,
  policiesStepModal: addGroupPoliciesTestIds,
};

export const [FormProvider, useCreateGroupForm, useForm] = createFormContext<CreateGroupFormState>();

const checkIfGroupNameExist = (name: string, groups: Group[]) =>
  Object.values(groups).some((g) => g.name.toLowerCase() === name.toLowerCase());

export const GroupTableCreateAction = ({ onCreateGroup }: CreateGroupProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams() as GroupRouteParams;
  const navigate = useNavigate();
  const groups = useGroupArray();
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      name: '',
      userIds: [],
      policyIds: [],
    },
    validateInputOnChange: true,
    validate: {
      name: (value) => checkIfGroupNameExist(value, groups) && t('common.noDuplicates'),
    },
  });
  const [openModal, setOpenModal] = useState(OpenedModal.NONE);

  const closeAndReset = () => {
    setOpenModal(OpenedModal.NONE);
    form.reset();
  };

  const onCreationSuccess = (name: string) =>
    notifications.show({
      title: t('userMgmt.groups.table.actions.create.createSuccess.title', { name }),
      message: null,
      icon: <IconLicense />,
    });

  const onCreationFailure = (name: string) =>
    notifications.show({
      color: 'red',
      title: t('userMgmt.groups.table.actions.create.createError.title', { name }),
      message: t('userMgmt.groups.table.actions.create.createError.message'),
      icon: <IconLicenseOff />,
    });

  const navigateToGroupDetails = (userIds: UserId[], policyIds: string[], groupId: string) => {
    switch (true) {
      case Boolean(policyIds.length):
        navigate(`${ROUTES.USER_MGMT(customerId).GROUP(groupId)}?panel=${GroupPanel.POLICIES}`);
        break;
      case Boolean(userIds.length):
        navigate(`${ROUTES.USER_MGMT(customerId).GROUP(groupId)}?panel=${GroupPanel.USERS}`);
        break;
      default:
    }
  };

  const createGroup = async ({ name, userIds, policyIds }: CreateGroupFormState): Promise<NiceResponse> => {
    setLoading(true);
    const response = await onCreateGroup({ name, users: userIds, policies: policyIds });
    setLoading(false);

    if (response) {
      onCreationSuccess(response.name);
      navigateToGroupDetails(userIds, policyIds, response.id);
      closeAndReset();
      return NiceResponse.OK;
    }

    onCreationFailure(name);
    return NiceResponse.ERROR;
  };

  return (
    <>
      <Button ml='auto' onClick={() => setOpenModal(OpenedModal.GROUP_NAME)} data-testid={testIds.button}>
        {t('userMgmt.groups.table.actions.create.button')}
      </Button>
      <FormProvider form={form}>
        <Box component='form' onSubmit={form.onSubmit(createGroup)}>
          {OpenedModal.GROUP_NAME === openModal && (
            <AddGroupNameModal opened onClose={closeAndReset} onNext={() => setOpenModal(OpenedModal.GROUP_USERS)} />
          )}
          {OpenedModal.GROUP_USERS === openModal && (
            <AddGroupUsersModal
              opened
              loading={loading}
              onClose={closeAndReset}
              onBack={() => setOpenModal(OpenedModal.GROUP_NAME)}
              onNext={() => setOpenModal(OpenedModal.GROUP_POLICIES)}
              onSubmit={form.onSubmit(createGroup)}
            />
          )}
          {OpenedModal.GROUP_POLICIES === openModal && (
            <AddGroupPoliciesModal
              opened
              loading={loading}
              onClose={closeAndReset}
              onBack={() => setOpenModal(OpenedModal.GROUP_USERS)}
              onSubmit={form.onSubmit(createGroup)}
            />
          )}
        </Box>
      </FormProvider>
    </>
  );
};
