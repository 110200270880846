import { ComboboxItem } from '@mantine/core';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useLabelStore } from 'stores/wizard';

export function useLabelSelection() {
  const { labels } = useLabelStore(['labels', 'loading']);
  const labelOptions = useMemo<ComboboxItem[]>(
    () =>
      Object.values(labels)
        .sort((a, b) => a.order - b.order)
        .map((label) => ({ value: label.id, label: label.name })),
    [labels],
  );
  const firstLabelId = labelOptions.at(0)?.value ?? null;
  const [params, setParams] = useSearchParams();
  const selectedLabelId = params.get('labelId') ?? firstLabelId;
  const setSelectedLabelId = (labelId: string) => {
    if (labelId !== selectedLabelId) setParams({ labelId });
  };

  return {
    labelOptions,
    selectedLabelId,
    setSelectedLabelId,
  };
}
