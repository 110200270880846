import { useEffect, useState } from 'react';

export function useImageDimensions(src: string) {
  const [dimensions, setDimensions] = useState<[number, number]>([0, 0]);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setDimensions([img.width, img.height]);
    };
    img.src = src;
  }, [src]);

  return dimensions;
}
