import { Checkbox, Combobox, Flex, Highlight } from '@mantine/core';
import { Container } from 'stores/wizard';

interface ParentFilterOptionProps {
  container: Container;
  search: string;
  selected: boolean;
}

export const testIds = {
  getOptionTestId: (id: string) => `event-def-parent-filter-option-${id}`,
  checkbox: 'event-def-parent-filter-checkbox',
};

export const ParentFilterOption = ({ container, search, selected }: ParentFilterOptionProps) => (
  <Combobox.Option
    key={container.id}
    value={container.id}
    active={selected}
    variant='white'
    data-testid={testIds.getOptionTestId(container.id)}
  >
    <Flex gap='sm' align='center'>
      <Checkbox size='sm' checked={selected} readOnly data-testid={testIds.checkbox} />
      <Highlight size='sm' highlight={search} truncate>
        {container.name}
      </Highlight>
    </Flex>
  </Combobox.Option>
);
