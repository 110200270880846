import cn from 'classnames';
import { NavLink } from '@mantine/core';
import { IconLayoutSidebarLeftExpand } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import classes from '../FlowLayout.module.css';

interface CollapseButtonProps {
  sidebarExpanded: boolean;
  onClick: () => void;
}

export const testIds = {
  button: 'flow-sidebar-collapse-button',
  icon: 'flow-sidebar-collapse-icon',
};

export const CollapseButton = ({ sidebarExpanded, onClick }: CollapseButtonProps) => {
  const { t } = useTranslation();

  return (
    <NavLink
      className={classes.navLink}
      leftSection={
        <IconLayoutSidebarLeftExpand
          className={cn('transition-transform', { '-rotate-180': sidebarExpanded })}
          height={24}
          stroke={1}
          data-testid={testIds.icon}
        />
      }
      label={t('navbar.collapse')}
      onClick={onClick}
      data-testid={testIds.button}
    />
  );
};
