import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { Annotation, AnnotationId, Coordinates } from './annotations.types';
import { cropImage, getNewAnnotation } from './annotations.utils';
import { fileStore } from '../files';
import { annotationsApi } from './annotations.api';
import { containerStore } from '../containers';

interface AnnotationState {
  annotations: Record<AnnotationId, Annotation>;
}

type ProcessArgs = { customerId: string; annotationId: string; parentId: string | null; flowId: string };
interface AnnotationActions {
  annotate: (fileId: string, coords: Coordinates) => Annotation;
  delete: (annotationId: string) => void;
  relabel: (annotationId: string, labelId: string) => void;
  reposition: (annotationId: string, coords: Partial<Coordinates>) => void;
  process: (args: ProcessArgs) => Promise<boolean>;
  reset: () => void;
}

const initialState: AnnotationState = {
  annotations: {},
};

export const annotationStore = create(
  immer<AnnotationState & AnnotationActions>((set, get) => ({
    ...initialState,
    annotate: (fileId, coords) => {
      const annotation = getNewAnnotation(fileId, coords);
      set((state) => {
        state.annotations[annotation.id] = annotation;
      });
      return annotation;
    },
    delete: (annotationId) =>
      set((state) => {
        delete state.annotations[annotationId];
      }),
    relabel: (annotationId, labelId) =>
      set((state) => {
        const annotation = state.annotations[annotationId];
        if (annotation) annotation.labelId = labelId;
      }),
    reposition: (annotationId, coords) =>
      set((state) => {
        const annotation = state.annotations[annotationId];
        if (annotation) Object.assign(annotation, coords);
      }),
    process: async ({ customerId, annotationId, parentId, flowId }: ProcessArgs) => {
      const annotation = get().annotations[annotationId];
      const file = fileStore.getState().files.find((f) => f.id === annotation?.fileId);
      if (!file || !annotation) return false;
      if (!annotation.labelId) return false;
      let returnValue = false;
      set((state) => {
        state.annotations[annotationId].processing = true;
      });
      try {
        const blob = await cropImage(file.url, annotation);
        const texts = await annotationsApi.process(customerId, blob);
        if (!texts) returnValue = false;
        else {
          containerStore.getState().createContainers({
            names: texts,
            flowId,
            labelId: annotation.labelId,
            parentId: parentId ?? undefined,
          });
          returnValue = true;
        }
      } catch {
        returnValue = false;
      }
      set((state) => {
        state.annotations[annotationId].processing = false;
      });
      return returnValue;
    },
    reset: () => set(initialState),
  })),
);

export const useAnnotationStore = createStoreHook({ store: annotationStore, useShallow });
