import { ModelType } from '@ai-platform/common-types';

export function isModelType(maybeModelType: string): maybeModelType is ModelType {
  return Object.values(ModelType).includes(maybeModelType as ModelType);
}

export function getModelType(maybeModelType: string): ModelType | undefined {
  if (!isModelType(maybeModelType)) {
    return undefined;
  }

  return maybeModelType;
}
