import { Select, Stack, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { EditableFlowValues, useFlowSettingStore } from 'stores/wizard';
import { InfoLabel } from 'components';
import { dateFormatOptions } from './GeneralSettings.const';

export const testIds = {
  name: 'general-settings-name',
  dateFormat: { input: 'general-settings-date-format-input', label: 'general-settings-date-format-label' },
};

export const FlowProperties = () => {
  const { t } = useTranslation();
  const values = useFlowSettingStore((state) => state.data.flow);
  const update = useFlowSettingStore((state) => state.updateGeneralSettings);

  return (
    <Stack miw={400}>
      <TextInput
        maw={400}
        label={t('wizard.steps.settings.generalSettings.flowName.title')}
        value={values.name}
        required
        onChange={(e) => update({ name: e.target.value })}
        data-testid={testIds.name}
      />
      <Select
        value={values.dateFormat}
        data={dateFormatOptions}
        onChange={(value) => update({ dateFormat: value as EditableFlowValues['dateFormat'] })}
        label={
          <InfoLabel
            infoText={t('wizard.steps.settings.generalSettings.dateFormat.info')}
            data-testid={testIds.dateFormat.label}
          >
            {t('wizard.steps.settings.generalSettings.dateFormat.title')}
          </InfoLabel>
        }
        data-testid={testIds.dateFormat.input}
      />
    </Stack>
  );
};
