import { useMemo } from 'react';
import { useContainerStore, useEventDefStore } from 'stores/wizard';

export function useValidationContainerPool(eventDefId: string) {
  const { data: eventDefs } = useEventDefStore(['data']);
  const eventDef = eventDefs[eventDefId];
  const { data: containers } = useContainerStore(['data']);

  return useMemo(() => {
    if (!eventDef) return [];
    return eventDef.containerIds.map((containerId) => containers[containerId]).filter(Boolean);
  }, [eventDef, containers]);
}
