import { DragEvent } from 'react';

export { getRootContainers } from 'stores/wizard/containers/containers.utils';

export enum DndPosition {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
}
export const DND_AREA_THRESHOLD = 20;
export const DND_ATTR_NAME = 'data-dnd-position';
export function dragOutOfRowHandler(e: DragEvent<HTMLTableRowElement>) {
  const element = e.currentTarget;
  if (!element) return;
  element.removeAttribute(DND_ATTR_NAME);
}

export function getDndPositionFromEvent(e: DragEvent<HTMLElement>, targetDropElement?: HTMLElement) {
  const element = targetDropElement ?? e.currentTarget;
  if (!element) return undefined;
  const eventY = e.clientY;
  const { bottom, y } = element.getBoundingClientRect();
  const isInTop = eventY < y + DND_AREA_THRESHOLD;
  const isInBottom = eventY > bottom - DND_AREA_THRESHOLD;

  if (isInTop) return DndPosition.TOP;
  if (isInBottom) return DndPosition.BOTTOM;
  return DndPosition.MIDDLE;
}

export function handleDragOverRow(e: DragEvent<HTMLTableRowElement>) {
  const element = e.currentTarget;
  if (!element) return;
  const dndPosition = getDndPositionFromEvent(e);
  if (!dndPosition) return;
  element.setAttribute(DND_ATTR_NAME, dndPosition);
}
