import { useEffect, useMemo, useState } from 'react';
import { getContainerIdsByLabel, getRootEventDefs, useContainerStore, useEventDefStore } from 'stores/wizard';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { notifications } from '@mantine/notifications';
import { IconReplaceOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { ExpandedState } from '@tanstack/react-table';
import { useEventDefSelection } from '../../hooks';
import { useEventDefTableColumns } from './hooks';
import { getRowSelectionObject, getSortedChildrenEventDefs } from './EventDefTable.utils';
import { tableStaticDefs, testIds } from './definitions';
import { EventDefEmptyState, EventDefTableActions } from './components';
import classes from './EventDefTable.module.css';

interface EventDefTableProps {
  labelId: string | null;
}

export const EventDefTable = ({ labelId }: EventDefTableProps) => {
  const { t } = useTranslation();
  const {
    selectedEventDefId,
    selectedEventDef,
    onChangeSelectedEventDefId: onSelectEventDef,
  } = useEventDefSelection(labelId);
  const { data: containers } = useContainerStore(['data']);
  const { data, createEventDef, repositionEventDef } = useEventDefStore([
    'data',
    'createEventDef',
    'repositionEventDef',
  ]);
  const rootEventDefs = useMemo(
    () => getRootEventDefs(data).filter((eventDef) => eventDef.labelId === labelId),
    [data, labelId],
  );
  const rowSelection = useMemo(
    () => getRowSelectionObject(rootEventDefs, selectedEventDefId),
    [rootEventDefs, selectedEventDefId],
  );
  const [expanded, setExpanded] = useState<ExpandedState>(true);

  useEffect(() => {
    const eventDefParentId = selectedEventDef?.parentId ?? '';

    if (eventDefParentId) {
      if (typeof expanded === 'boolean') return;
      setExpanded((prev) => ({ ...(prev as Record<string, boolean>), [eventDefParentId]: true }));
    }
  }, [selectedEventDefId]);

  const onCreate = () => {
    if (labelId) {
      const containerIds = getContainerIdsByLabel(containers, labelId);
      const newEventDef = createEventDef(labelId, undefined, containerIds);
      onSelectEventDef(newEventDef.id);
    }
  };

  const columns = useEventDefTableColumns();
  const table = useMantineReactTable({
    columns,
    data: rootEventDefs,
    ...tableStaticDefs,
    onExpandedChange: setExpanded,
    getSubRows: (eventDef) => getSortedChildrenEventDefs(data, eventDef.id),
    renderTopToolbar: () => <EventDefTableActions onCreate={onCreate} />,
    renderEmptyRowsFallback: () => <EventDefEmptyState onCreate={onCreate} />,
    mantineTableBodyRowProps: ({ row }) => ({
      onClick: () => onSelectEventDef(row.id),
      'data-testid': testIds.getRowTestId(row.id),
      className: classes.row,
      h: 44,
    }),
    mantineRowDragHandleProps: ({ table: tbl }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = tbl.getState();
        if (!(hoveredRow?.id && draggingRow?.id)) return;
        const moveSuccessful = repositionEventDef(draggingRow.id, hoveredRow.id);
        if (!moveSuccessful)
          notifications.show({
            icon: <IconReplaceOff />,
            color: 'red',
            title: t('wizard.steps.reportedEvents.eventTable.repositionError.title'),
            message: t('wizard.steps.reportedEvents.eventTable.repositionError.message'),
          });
      },
    }),
    initialState: {
      columnVisibility: { 'mrt-row-select': false },
    },
    state: {
      rowSelection,
      expanded,
    },
  });

  return <MantineReactTable table={table} />;
};
