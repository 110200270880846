import { MRT_SortingState, MRT_TableOptions } from 'mantine-react-table';
import i18n from 'services/i18n';
import { AiPublishedModel } from 'stores/aiPlatform/aiPublishedModels';

export const publishPageTableDefs = {
  getRowId: (original) => original.publishExecutionId,
  enableSorting: true,
  enablePagination: false,
  enableBottomToolbar: false,
  enableStickyHeader: true,
  enableColumnActions: false,
  enableTopToolbar: false,
  enableRowActions: true,
  positionActionsColumn: 'last',
  displayColumnDefOptions: {
    'mrt-row-actions': {
      header: i18n.t('publishPage.table.headers.modelData'),
      mantineTableBodyCellProps: { onClick: (e) => e.stopPropagation() },
    },
  },
  layoutMode: 'grid',
  mantineTableBodyCellProps: { py: 'xs' },
} satisfies Partial<MRT_TableOptions<AiPublishedModel>>;

export const initialSorting: MRT_SortingState = [{ id: 'publishedDate', desc: true }];
