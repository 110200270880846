import { FileDefinitionType, GetFilesResponse } from '@jargonic/files-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { filesToApi } from './files.adapters';

const filesUrl = (customerId: string, flowId: string) => `${config.jargonicApiUrl}/${customerId}/files/${flowId}`;

export const filesApi = {
  getFiles: async (customerId: string, flowId: string): Promise<FileDefinitionType[] | undefined> => {
    const url = filesUrl(customerId, flowId);
    const { data } = await hermes.get<GetFilesResponse>(url);
    return data?.items;
  },

  uploadFile: async (customerId: string, flowId: string, files: File[]): Promise<FileDefinitionType[]> => {
    const payload = await filesToApi(files);
    const url = filesUrl(customerId, flowId);
    const { data } = await hermes.post<FileDefinitionType[]>(url, payload, {
      fallback: [],
    });
    return data;
  },

  deleteFile: async (customerId: string, flowId: string, fileId: string) => {
    const url = `${filesUrl(customerId, flowId)}/${fileId}`;
    const { data } = await hermes.delete(url);
    return data;
  },
};
