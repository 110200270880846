import { ComponentProps } from 'react';
import { IconFileBarcode, IconSettings, IconTimelineEventText } from '@tabler/icons-react';
import i18n from 'services/i18n';
import { ROUTES } from 'routes/routes.config';
import { WizardStep } from 'layouts';
import { ApplicationCard } from '../ApplicationCard/ApplicationCard';

type PassableApplicationCardProps = Omit<ComponentProps<typeof ApplicationCard>, 'onClick'>;
type AdditionalProps = { navigateTo?: (customerId: string, flowId: string) => string; key: string };
type ApplicationCardProps = PassableApplicationCardProps & AdditionalProps;

export const applicationCardsProps: ApplicationCardProps[] = [
  {
    key: WizardStep.SETTINGS,
    title: i18n.t('flowPage.applications.flowSettings'),
    icon: <IconSettings />,
    color: 'green',
    navigateTo: (customerId, flowId) => ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.SETTINGS),
  },
  {
    key: WizardStep.LABELING,
    title: i18n.t('flowPage.applications.labeling'),
    icon: <IconFileBarcode />,
    color: 'violet',
    navigateTo: (customerId, flowId) => ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.LABELING),
  },
  {
    key: WizardStep.EVENTS,
    title: i18n.t('flowPage.applications.events'),
    icon: <IconTimelineEventText />,
    color: 'blue',
    navigateTo: (customerId, flowId) => ROUTES.WIZARD_STEP(customerId, flowId, WizardStep.EVENTS),
  },
];
