import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group as MantineGroup, Text } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';
import { Group } from 'stores/userMgmt';

interface DeleteGroupModalProps {
  opened: boolean;
  group: Group;
  onDelete: () => Promise<boolean>;
  onClose: () => void;
}

export const testIds = {
  title: modalBaseTestIds.title,
  deleteButton: 'group-table-delete-modal-delete',
  cancelButton: 'group-table-delete-modal-cancel',
  message: 'group-table-delete-modal-message',
};

export const DeleteGroupModal = ({ opened, group, onDelete, onClose }: DeleteGroupModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const response = await onDelete();
    setLoading(false);
    if (response) onClose();
  };

  return (
    <ModalBase
      color='red'
      opened={opened}
      title={t('userMgmt.groups.deleteGroup.title', { name: group.name })}
      onClose={onClose}
    >
      <Text data-testid={testIds.message}>
        {t('userMgmt.groups.deleteGroup.message', { name: '' })}
        <Text span fw={700}>
          {group.name}.
        </Text>
      </Text>
      <MantineGroup justify='end' mt='lg'>
        <Button variant='subtle' onClick={onClose} disabled={loading} data-testid={testIds.cancelButton}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} loading={loading} data-testid={testIds.deleteButton}>
          {t('common.delete')}
        </Button>
      </MantineGroup>
    </ModalBase>
  );
};
