import { Button, CloseButton, Flex, Stack, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Container } from 'stores/wizard';
import { AllCheckbox } from 'components';
import { ParentFilter, testIds as parentFilterTestIds } from '../ParentFilter/ParentFilter';

interface ContainerMappingControlsProps {
  allSelected?: boolean;
  containers: Container[];
  enableParentFilter: boolean;
  search: string;
  someSelected?: boolean;
  onApply: () => void;
  onChangeSelectedParents: (selectedParentIds: string[]) => void;
  onSearch: (search: string) => void;
  onToggleAll: () => void;
}

export const testIds = {
  search: {
    input: 'container-mapping-modal-search-input',
    clear: 'container-mapping-modal-search-clear',
    icon: 'container-mapping-modal-search-icon',
  },
  selectAll: 'container-mapping-modal-select-all',
  parentFilter: parentFilterTestIds,
  done: 'container-mapping-modal-done',
};

export const ContainerMappingControls = ({
  allSelected = false,
  containers,
  enableParentFilter,
  search,
  someSelected = false,
  onApply,
  onChangeSelectedParents,
  onSearch,
  onToggleAll,
}: ContainerMappingControlsProps) => {
  const { t } = useTranslation();

  const resetSearch = () => onSearch('');

  return (
    <Stack gap='sm' w='100%'>
      <Flex gap='sm'>
        <TextInput
          className='grow'
          placeholder={t('wizard.steps.reportedEvents.generalSettings.mapping.modal.searchPlaceholder')}
          leftSection={<IconSearch data-testid={testIds.search.icon} />}
          rightSection={<CloseButton onClick={resetSearch} data-testid={testIds.search.clear} />}
          value={search}
          onChange={(event) => onSearch(event.currentTarget.value)}
          data-testid={testIds.search.input}
        />
        <AllCheckbox all={allSelected} some={someSelected} onChange={onToggleAll} data-testid={testIds.selectAll} />
        <Button onClick={onApply} data-testid={testIds.done}>
          {t('common.apply')}
        </Button>
      </Flex>
      {enableParentFilter && <ParentFilter containers={containers} onChange={onChangeSelectedParents} />}
    </Stack>
  );
};
