import { Flex } from '@mantine/core';
import { InfoTip } from 'components';
import { PropsWithChildren } from 'react';

interface InfoLabelProps extends PropsWithChildren {
  infoText: string;
}

export const testIds = { label: 'info-label' };

export const InfoLabel = ({ infoText, children, ...props }: InfoLabelProps) => (
  <Flex display='inline-flex' className='select-none' gap='tiny' data-testid={testIds.label} {...props}>
    {children}
    <InfoTip>{infoText}</InfoTip>
  </Flex>
);
