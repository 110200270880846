import { Box, Divider, NavLink, Text } from '@mantine/core';
import { Link, To } from 'react-router-dom';
import { ModelType } from '@ai-platform/common-types';
import { useTranslation } from 'react-i18next';
import classes from './AiPlatformCommonSidebar.module.css';

interface AiPlatformCommonSidebarProps {
  title: string;
  activeModelType: ModelType;
  pathname: string;
}

export const testIds = {
  title: 'ai-platform-common-sidebar-title',
  links: {
    NLP: 'ai-platform-common-sidebar-link-nlp',
    ASR: 'ai-platform-common-sidebar-link-asr',
  },
};

export const AiPlatformCommonSidebar = ({ title, activeModelType, pathname }: AiPlatformCommonSidebarProps) => {
  const { t } = useTranslation();

  const getPathByType = (type: ModelType): To => ({
    pathname,
    search: `?modelType=${type}`,
  });

  return (
    <Box component='nav' className={classes.sidebar}>
      <Text fw={600} py={12} px={16} data-testid={testIds.title}>
        {title}
      </Text>
      <Divider my={4} />
      <Box px={4}>
        <NavLink
          label={t('common.modelType.NLP')}
          component={Link}
          to={getPathByType(ModelType.NLP)}
          className={classes.navLink}
          active={activeModelType === ModelType.NLP}
          data-testid={testIds.links.NLP}
        />
        <NavLink
          label={t('common.modelType.ASR')}
          component={Link}
          to={getPathByType(ModelType.ASR)}
          className={classes.navLink}
          active={activeModelType === ModelType.ASR}
          data-testid={testIds.links.ASR}
        />
      </Box>
    </Box>
  );
};
