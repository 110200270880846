import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { AiModel } from 'stores/aiPlatform/aiModels';
import { CreatedAtCell, CreatedByCell, StatusCell, createdByCellTestIds, statusCellTestIds } from './components';

export const testIds = {
  createdBy: createdByCellTestIds,
  status: statusCellTestIds,
};

export const columns: MRT_ColumnDef<AiModel>[] = [
  {
    id: 'createdBy',
    accessorFn: ({ createdBy }) => `${createdBy.givenName} ${createdBy.familyName}`,
    header: i18n.t('trainingPage.table.headers.createdBy'),
    Cell: CreatedByCell,
  },
  {
    accessorKey: 'createdAt',
    header: i18n.t('trainingPage.table.headers.createdAt'),
    Cell: CreatedAtCell,
  },
  {
    accessorKey: 'status',
    header: i18n.t('trainingPage.table.headers.status'),
    Cell: StatusCell,
  },
];
