import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Text } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';

interface RemoveEntityModalProps {
  opened: boolean;
  policyName: string;
  entityName: string;
  onClose: () => void;
  onRemove: () => Promise<void>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  closeButton: modalBaseTestIds.close,
  message: 'remove-permission-message',
  cancel: 'remove-permission-cancel-button',
  modal: 'remove-permission-modal',
  removeButton: 'remove-permission-confirm-button',
};

export const RemoveEntityModal = ({ opened, onClose, onRemove, entityName, policyName }: RemoveEntityModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    await onRemove();
    setLoading(false);
    onClose();
  };

  return (
    <ModalBase
      closable={!loading}
      title={t('userMgmt.policies.policyDetails.panels.entities.table.actions.remove.title')}
      opened={opened}
      variant='warning'
      onClose={onClose}
      data-testid={testIds.modal}
    >
      <Text mb='lg' data-testid={testIds.message}>
        {t('userMgmt.policies.policyDetails.panels.entities.table.actions.remove.message', {
          entity: entityName,
          policy: policyName,
        })}
      </Text>
      <Group justify='end'>
        <Button disabled={loading} variant='subtle' onClick={onClose} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button loading={loading} onClick={onConfirm} data-testid={testIds.removeButton}>
          {t('common.remove')}
        </Button>
      </Group>
    </ModalBase>
  );
};
