import { HermesStatusCode, createStoreHook } from '@aiola/frontend';
import { hermes } from 'services/hermes';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { User } from './auth.types';
import { authApi } from './auth.api';

interface AuthState {
  authenticated: boolean;
  me: User | null;
}

interface AuthActions {
  fetchCurrentUser: () => Promise<User | null>;
  reset: () => void;
}

const initialState: AuthState = {
  authenticated: true,
  me: null,
};

export const authStore = create(
  immer<AuthState & AuthActions>((set) => ({
    ...initialState,
    fetchCurrentUser: async () => {
      const user = await authApi.getCurrentUser();
      set({ me: user });
      return user;
    },
    reset: () => {
      set({ ...initialState });
    },
  })),
);

hermes.registerHandler('error', (error) => {
  if (error.status === HermesStatusCode.Unauthorized) authStore.setState({ authenticated: false });
});

export const useAuthStore = createStoreHook({ store: authStore, useShallow });
