import { Flex } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { Group } from 'stores/userMgmt';
import { GroupTableSearch, testIds as searchTestIds } from './GroupTableSearch';
import { GroupTableState } from '../../GroupTable.types';
import { GroupTableCreateAction, testIds as createGroupTestIds } from './GroupTableCreateAction/GroupTableCreateAction';

type GroupTableActionsProps = { table: MRT_TableInstance<Group> };

export const testIds = {
  search: searchTestIds,
  create: createGroupTestIds,
};

export const GroupTableActions = ({ table }: GroupTableActionsProps) => {
  const { globalFilter, onSearch, onCreateGroup } = table.getState() as GroupTableState;

  return (
    <Flex p='md' align='center'>
      <GroupTableSearch value={globalFilter} onChange={onSearch} />
      <GroupTableCreateAction onCreateGroup={onCreateGroup} />
    </Flex>
  );
};
