/*
  There are 3 ways to configure the application with env vars (in order of priority):
  1. using window vars - production
  2. using vite env vars from .env file - development with proxy server against the cloud
  3. default value - development with docker-compose
*/

const authPath = window.AIOLA_CLIENT_AUTH_PATH ?? import.meta.env.VITE_AUTH_PATH;
const apiHost = window.AIOLA_CLIENT_API_HOST ?? import.meta.env.VITE_API_HOST;
const apiPath = window.AIOLA_CLIENT_JARGONIC_PATH ?? import.meta.env.VITE_API_PATH;
const aipApiPath = window.AIOLA_CLIENT_AIPLATFORM_PATH ?? import.meta.env.VITE_AIP_API_PATH;

export const config = {
  authApiUrl: `${apiHost}${authPath}`,
  jargonicApiUrl: `${apiHost}${apiPath}`,
  pingUrl: `${apiHost}/api/ping`,
  aipApiHost: `${apiHost}${aipApiPath}`,
} as const;
