import { Box, Flex, Text, Title } from '@mantine/core';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import { User } from 'stores/auth';

interface UserDetailsProps {
  user: User;
}

export const testIds = {
  wrapper: 'user-details',
  getAvatarTestId: (userId: string) => `user-details-avatar-${userId}`,
  name: 'user-details-name',
  email: 'user-details-email',
};

export const UserDetails = ({ user, ...props }: UserDetailsProps) => (
  <Flex gap='sm' data-testid={testIds.wrapper} {...props}>
    <UserAvatar user={user} data-testid={testIds.getAvatarTestId(user.userId)} />
    <Box>
      <Title order={5} fw={700} lh={1} data-testid={testIds.name}>
        {user.givenName} {user.familyName}
      </Title>
      <Text c='gray.5' size='xs' lh={2} data-testid={testIds.email}>
        {user.email}
      </Text>
    </Box>
  </Flex>
);
