import { useContainerStore, useEventDefStore, useFlowSettingStore } from 'stores/wizard';
import { noop } from '@mantine/core';
import { ValidationOopsie } from 'stores/wizard/wizard.types';
import { useCallback } from 'react';
import { CustomerId } from '@jargonic/customer-types';
import { FlowId } from 'stores/flows';
import { WizardStep } from '../WizardLayout.steps';

type ReturnValue = {
  dirty: boolean;
  saveDraft: (customerId: string, flowId: string) => Promise<boolean>;
  revertChanges: () => void;
  validate: () => ValidationOopsie[];
};

export function useStepDraft(step: WizardStep): ReturnValue {
  const flowSettingsDirt = useFlowSettingStore(['saveDraft', 'validate', 'revertChanges', 'dirty']);
  const containersDirt = useContainerStore(['saveDraft', 'validate', 'revertChanges', 'dirty']);
  const eventDefsDirt = useEventDefStore(['saveDraft', 'validate', 'revertChanges', 'dirty']);

  const saveDraft = useCallback(
    async (customerId: CustomerId, flowId: FlowId) =>
      Promise.all([
        flowSettingsDirt.saveDraft(customerId, flowId),
        containersDirt.saveDraft(customerId, flowId),
        eventDefsDirt.saveDraft(customerId, flowId),
      ]).then((results) => results.every(Boolean)),
    [],
  );

  const revertChanges = useCallback(() => {
    flowSettingsDirt.revertChanges();
    containersDirt.revertChanges();
    eventDefsDirt.revertChanges();
  }, []);

  switch (step) {
    case WizardStep.SETTINGS:
      return {
        ...flowSettingsDirt,
        saveDraft,
        revertChanges,
      };
    case WizardStep.LABELING:
      return {
        ...containersDirt,
        saveDraft,
        revertChanges,
      };
    case WizardStep.EVENTS:
      return {
        ...eventDefsDirt,
        saveDraft,
        revertChanges,
      };
    default:
      return {
        dirty: false,
        saveDraft: () => Promise.resolve(true),
        revertChanges: noop,
        validate: () => [],
      };
  }
}
