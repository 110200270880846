import { Button, Group, Stack, Text, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes/routes.config';
import { ErrorLayoutGame, testIds as gameTestIds } from './ErrorLayoutGame';

export const testIds = {
  logo: 'error-layout-logo',
  reloadButton: 'error-layout-reload-button',
  homeButton: 'error-layout-home-button',
  game: gameTestIds,
};

export const ErrorLayout = () => {
  const { t } = useTranslation();

  const onReloadPage = () => window.location.reload();

  return (
    <Stack align='center' justify='center' gap='md' h='100%' w='100%'>
      <Title order={1} fw={900}>
        {t('appErrorFallback.title')}
      </Title>
      <Title order={3} className='text-center'>
        {t('appErrorFallback.subtitle')}
      </Title>
      <Text className='text-center'>{t('appErrorFallback.message1')}</Text>
      <Text className='text-center'>{t('appErrorFallback.message2')}</Text>
      <ErrorLayoutGame />
      <Group>
        <Button onClick={onReloadPage} data-testid={testIds.reloadButton}>
          {t('appErrorFallback.reloadButton')}
        </Button>
        <Button component={Link} to={ROUTES.ROOT} data-testid={testIds.homeButton}>
          {t('appErrorFallback.homeButton')}
        </Button>
      </Group>
    </Stack>
  );
};
