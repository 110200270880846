import { AiModelStatusBadge } from 'components';
import { MRT_ColumnDef } from 'mantine-react-table';
import { AiPublishedModel } from 'stores/aiPlatform/aiPublishedModels';

export const testIds = {
  badge: 'published-model-table-status-badge',
};

export const StatusCell: NonNullable<MRT_ColumnDef<AiPublishedModel>['Cell']> = ({ row }) => (
  <AiModelStatusBadge status={row.original.status} data-testid={testIds.badge} />
);
