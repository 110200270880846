import { ReactNode, forwardRef } from 'react';
import { ActionIcon, ActionIconProps, ElementProps, FloatingPosition, Tooltip } from '@mantine/core';

interface ActionButtonProps extends ActionIconProps, ElementProps<'button', keyof ActionIconProps> {
  label: string | ReactNode;
  tooltipPosition?: FloatingPosition;
  tooltipDelay?: number;
  tooltipMultiline?: boolean;
  tooltipWidth?: number;
}

export const testIds = {
  button: 'action-button',
  tooltip: 'action-button-tooltip',
};

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonProps>(
  ({ label, children, tooltipPosition = 'bottom', tooltipDelay, tooltipMultiline, tooltipWidth, ...props }, ref) => (
    <Tooltip
      label={label}
      position={tooltipPosition}
      openDelay={tooltipDelay}
      multiline={tooltipMultiline}
      w={tooltipWidth}
      data-testid={testIds.tooltip}
    >
      <ActionIcon ref={ref} variant='subtle' color='gray' radius='xl' size='lg' data-testid={testIds.button} {...props}>
        {children}
      </ActionIcon>
    </Tooltip>
  ),
);
