import { Button, Flex } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';
import { IconLicense, IconLicenseOff } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { EditPermissionModal, editPermissionTestIds } from 'pages/userMgmt/components';
import { Permission } from 'stores/userMgmt';
import { PermissionTableState } from '../PermissionTable.types';

type PermissionTableActionsProps = { table: MRT_TableInstance<Permission> };

export const testIds = {
  button: 'create-permission-button',
  entitiesModal: editPermissionTestIds,
};

export const PermissionTableActions = ({ table }: PermissionTableActionsProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();

  const onSuccess = () =>
    notifications.show({
      color: 'green',
      title: t('userMgmt.policies.policyDetails.panels.permissions.table.actions.create.success.title'),
      message: t('userMgmt.policies.policyDetails.panels.permissions.table.actions.create.success.message'),
      icon: <IconLicense />,
    });

  const onError = () =>
    notifications.show({
      color: 'red',
      title: t('userMgmt.policies.policyDetails.panels.permissions.table.actions.create.error.title'),
      message: t('common.tryAgain'),
      icon: <IconLicenseOff />,
    });

  const onSubmit = async (payload: Permission) => {
    const { onCreatePermission } = table.getState() as PermissionTableState;
    const success = await onCreatePermission(payload);
    if (success) onSuccess();
    else onError();
    return success;
  };

  return (
    <>
      <Flex justify='end' p='md'>
        <Button onClick={open} data-testid={testIds.button}>
          {t('userMgmt.policies.policyDetails.panels.permissions.table.actions.create.button')}
        </Button>
      </Flex>
      <EditPermissionModal opened={opened} onClose={close} onSubmit={onSubmit} />
    </>
  );
};
