import { Stack, Tabs } from '@mantine/core';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { GroupRouteParams, ROUTES } from 'routes/routes.config';
import { useUserMgmtStore } from 'stores/userMgmt';
import { GroupPanel, panels } from './GroupPage.panels';
import { GroupDetails } from './components/GroupDetails/GroupDetails';
import { groupDetailsTestIds, policiesPanelTestIds, usersPanelTestIds } from './components';

export const testIds = {
  getTabTestId: (panel: GroupPanel) => `group-page-tab-${panel}`,
  panels: {
    [GroupPanel.DETAILS]: groupDetailsTestIds,
    [GroupPanel.USERS]: { wrapper: usersPanelTestIds.table },
    [GroupPanel.POLICIES]: { wrapper: policiesPanelTestIds.table },
  },
};

export const GroupPage = () => {
  const [params, setParams] = useSearchParams();
  const { customerId, groupId } = useParams() as GroupRouteParams;
  const { groups } = useUserMgmtStore(['groups', 'upsertGroup']);

  const group = groups[groupId];
  const activePanel = (params.get('panel') as GroupPanel) || GroupPanel.DETAILS;

  const onChangePanel = (panel: string | null) => setParams({ panel: panel as GroupPanel });

  return group ? (
    <Stack>
      <GroupDetails group={group} />
      <Tabs value={activePanel} className='grow flex flex-col' onChange={onChangePanel}>
        <Tabs.List>
          {panels.map((panel) => (
            <Tabs.Tab key={panel.key} value={panel.key} data-testid={testIds.getTabTestId(panel.key)}>
              {panel.title}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {panels.map((panel) => (
          <Tabs.Panel
            key={panel.key}
            value={panel.key}
            className={panel.key !== GroupPanel.DETAILS ? 'grow mt-4 flex' : ''}
          >
            {panel.panel}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Stack>
  ) : (
    <Navigate to={ROUTES.USER_MGMT(customerId).GROUPS} replace />
  );
};
