import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import { flowStore } from 'stores/flows';
import { FlowMetadataFieldId, FlowMetadataField } from '@jargonic/flow-metadata-types';
import { parseCSV } from 'utils';
import { WizardStoreActions, WizardStoreState } from '../wizard.types';
import {
  EditableFlowValues,
  FieldValuesCSVParsedPayload,
  FieldValuesCSVResponse,
  FieldValuesValueCSVColumnName,
  FlowSettingsData,
  MetadataType,
} from './flowSettings.types';
import { FLOW_SETTINGS_SESSION_STORAGE_KEY, initialFlowState, initialMetadataState } from './flowSettings.const';
import { flowSettingsApi } from './flowSettings.api';
import {
  dataFromApi,
  fieldsKey,
  getDefaultField,
  getDefaultMetadata,
  getFieldValuesFromCSVPayload,
} from './flowSettings.utils';
import { createWizardStoreSlice } from '../wizard.slice';
import { validateFlowSettings } from './flowSettings.validation';
import { metadataToApi } from './flowSettings.adapters';

interface FlowSettingsState extends WizardStoreState<FlowSettingsData> {}

interface FlowSettingsActions extends WizardStoreActions {
  updateGeneralSettings: (values: Partial<EditableFlowValues>) => void;
  updateMetadataField: (type: MetadataType, fieldId: FlowMetadataFieldId, values: Partial<FlowMetadataField>) => void;
  createFieldValuesFromFile: (
    type: MetadataType,
    fieldId: FlowMetadataFieldId,
    file: File,
  ) => Promise<FieldValuesCSVResponse>;
  reorderMetadataField: (type: MetadataType, fieldId: FlowMetadataFieldId, index: number) => void;
  addMetadataField: (type: MetadataType) => FlowMetadataField;
  deleteMetadataFields: (type: MetadataType, fieldIds: FlowMetadataFieldId[]) => void;
  updateUniqueIdentifierFieldId: (fieldId?: FlowMetadataFieldId) => void;
  updateExposedField: (fieldId?: FlowMetadataFieldId) => void;
  updateMetadataTitle: (title: string) => void;
}

const initialData: FlowSettingsData = {
  flow: initialFlowState,
  metadata: initialMetadataState,
};

export const flowSettingStore = create(
  immer<FlowSettingsState & FlowSettingsActions>((set, get, ...args) => ({
    ...createWizardStoreSlice<FlowSettingsData>({
      initialData,
      sessionStorageKey: FLOW_SETTINGS_SESSION_STORAGE_KEY,
      fetchData: async (customerId, flowId) => {
        const flow = flowStore.getState().flows[flowId];
        const metadata = await flowSettingsApi.getMetadata(customerId, flowId);
        metadata?.preInspectionFields?.sort((a, b) => a.order - b.order);
        metadata?.postInspectionFields?.sort((a, b) => a.order - b.order);
        return metadata && dataFromApi(flow, metadata ?? getDefaultMetadata(flowId));
      },
      saveData: async (customerId, flowId, data) => {
        const { flow, metadata } = data;
        const updatedFlow = await flowStore
          .getState()
          .upsertFlow({ action: 'update', customerId, flowId, payload: flow });
        const updateMetadata = await flowSettingsApi.updateMetadata(customerId, flowId, metadataToApi(metadata));
        return !!updatedFlow && !!updateMetadata;
      },
      validateData: validateFlowSettings,
      sliceArgs: [set, get, ...args],
    }),
    updateGeneralSettings: (values) => {
      set((state) => {
        state.data.flow = { ...state.data.flow, ...values };
        state.dirty = true;
      });
    },
    updateMetadataField: (type, fieldId, values) => {
      set((state) => {
        const fields = state.data.metadata[fieldsKey(type)];
        const field = fields.find((f) => f.id === fieldId);
        if (field) Object.assign(field, values);
        state.dirty = true;
      });
    },
    createFieldValuesFromFile: async (type, fieldId, file) => {
      try {
        const parsed = await parseCSV<FieldValuesCSVParsedPayload>(file);
        if (!parsed.meta.fields?.includes(FieldValuesValueCSVColumnName.VALUE))
          return FieldValuesCSVResponse.MISSING_COLUMN;

        const newValues = getFieldValuesFromCSVPayload(parsed.data);
        const field = get().data.metadata[fieldsKey(type)].find((f) => f.id === fieldId);
        if (field) {
          const existingOptionSet = new Set(field.options?.map((o) => o.toLowerCase()));
          const uniqueNewValues = newValues.filter((newValue) => !existingOptionSet.has(newValue.toLowerCase()));
          get().updateMetadataField(type, fieldId, { options: [...(field.options ?? []), ...uniqueNewValues] });
          const totalValueCount = parsed.data.length;
          const filteredValueCount = uniqueNewValues.length;
          if (filteredValueCount < totalValueCount) return FieldValuesCSVResponse.DUPLICATE_IMPORTED_NAME;
        }
        return FieldValuesCSVResponse.OK;
      } catch {
        return FieldValuesCSVResponse.ERROR;
      }
    },
    deleteMetadataFields: (type, fieldIds) => {
      set((state) => {
        const fields = state.data.metadata[fieldsKey(type)];
        state.data.metadata[fieldsKey(type)] = fields.filter((f) => !fieldIds.includes(f.id));
        state.dirty = true;
      });
    },
    reorderMetadataField: (type, fieldId, newOrder) => {
      set((state) => {
        const fields = state.data.metadata[fieldsKey(type)];
        const fieldIndex = fields.findIndex((field) => field.id === fieldId);
        if (fieldIndex !== -1) {
          const field = fields[fieldIndex];
          fields.splice(fieldIndex, 1);
          fields.splice(newOrder, 0, field);
          fields.forEach((f, index) => {
            f.order = index;
          });
          state.dirty = true;
        }
      });
    },
    addMetadataField: (type) => {
      const fields = get().data.metadata[fieldsKey(type)];
      const newField = getDefaultField(fields.length);
      set((state) => {
        state.data.metadata[fieldsKey(type)].push(newField);
        state.dirty = true;
      });
      return newField;
    },
    updateUniqueIdentifierFieldId: (fieldId) => {
      set((state) => {
        const { preInspectionFields, postInspectionFields, uniqueIdentifierFieldId } = state.data.metadata;
        const allFields = [...preInspectionFields, ...postInspectionFields];

        // Reset
        const currentField = allFields.find((f) => f.id === uniqueIdentifierFieldId);
        if (currentField) {
          currentField.required = false;
        }

        if (uniqueIdentifierFieldId === fieldId) {
          state.data.metadata.uniqueIdentifierFieldId = '';
        } else {
          const newField = allFields.find((f) => f.id === fieldId);
          if (newField) {
            newField.required = true;
          }
          state.data.metadata.uniqueIdentifierFieldId = fieldId ?? '';
        }

        state.dirty = true;
      });
    },
    updateExposedField: (fieldId) => {
      set((state) => {
        const fields = [...state.data.metadata.preInspectionFields, ...state.data.metadata.postInspectionFields];

        fields.forEach((field) => {
          if (field.id === fieldId) {
            field.exposed = !field.exposed;
          } else if (field.exposed) {
            field.exposed = false;
          }
        });

        state.dirty = true;
      });
    },
    updateMetadataTitle: (title) => {
      set((state) => {
        state.data.metadata.title = title;
        state.dirty = true;
      });
    },
  })),
);

export const useFlowSettingStore = createStoreHook({ store: flowSettingStore, useShallow });
