import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TrainingPipelineRouteParams } from 'routes/routes.config';
import { TrainingStepStatus, useTrainingSteps } from 'stores/aiPlatform/aiModels';
import { noop } from '@mantine/core';
import { metricsTableTestIds, AiModelMetricsTable } from 'pages/aiPlatform/components';
import { StepCommonContent, testIds as commonContentTestIds } from '../StepCommonContent/StepCommonContent';
import { TrainingContentHeader, testIds as headerTestIds } from './TrainingContentHeader';
import classes from '../../TrainingPipelinePage.module.css';

export const testIds = {
  header: headerTestIds,
  metrics: metricsTableTestIds,
  commonContent: commonContentTestIds,
};

export const TrainingContent = () => {
  const { t } = useTranslation();
  const { modelId } = useParams() as TrainingPipelineRouteParams;
  const { train: trainStep } = useTrainingSteps(modelId) ?? {};

  switch (trainStep?.status) {
    case TrainingStepStatus.COMPLETED:
      return (
        <AiModelMetricsTable
          key={trainStep.status}
          pages={trainStep.results}
          classNames={{ paper: classes.greenBorder, table: classes.metricsTable }}
          header={<TrainingContentHeader />}
        />
      );
    case TrainingStepStatus.PAUSED:
      if (trainStep.results.length) {
        return (
          <AiModelMetricsTable
            key={trainStep.status}
            pages={trainStep.results}
            classNames={{ paper: classes.yellowBorder, table: classes.metricsTable }}
            header={<TrainingContentHeader onAbort={noop} onResume={noop} />}
          />
        );
      }
      return (
        <StepCommonContent
          key={trainStep.status}
          title={t('trainingPipelinePage.steps.pausedTitle')}
          message={t('trainingPipelinePage.steps.contentMessage')}
          className={classes.yellowBorder}
          onAbort={noop}
          onResume={noop}
        />
      );

    case TrainingStepStatus.IN_PROGRESS:
      if (trainStep.results.length) {
        return (
          <AiModelMetricsTable
            key={trainStep.status}
            pages={trainStep.results}
            classNames={{ paper: classes.greenBorder, table: classes.metricsTable }}
            header={<TrainingContentHeader onAbort={noop} onPause={noop} />}
          />
        );
      }
      return (
        <StepCommonContent
          key={trainStep.status}
          title={t('trainingPipelinePage.steps.train.inProgressTitle')}
          message={t('trainingPipelinePage.steps.contentMessage')}
          className={classes.greenBorder}
          onAbort={noop}
          onPause={noop}
        />
      );

    case TrainingStepStatus.FAILED:
      return (
        <StepCommonContent
          key={trainStep.status}
          title={t('trainingPipelinePage.steps.failedTitle')}
          message={t('trainingPipelinePage.steps.failedMessage')}
          className={classes.redBorder}
          onAbort={noop}
          onReset={noop}
        />
      );
    default:
      return null;
  }
};
