import cn from 'classnames';
import { Card, Indicator, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CSS } from '@dnd-kit/utilities';
import { useSortable } from '@dnd-kit/sortable';
import { ReportedEventDefValue } from 'stores/wizard';
import classes from './ValueList.module.css';
import { ValueSettingsModal } from './ValueSettingsModal';

interface ValueItemProps {
  eventValue: ReportedEventDefValue;
  onChange: (partialValue: Partial<ReportedEventDefValue>) => boolean;
  onDelete: () => void;
}

export const testIds = {
  getItemTestId: (valueId: string) => `draggable-value-item-${valueId}`,
  indicator: 'draggable-value-item-indicator',
};

export const DraggableValueItem = ({ eventValue, onChange, onDelete }: ValueItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: eventValue.id });
  const [opened, { close, open }] = useDisclosure();

  const isValid = eventValue.valid === true;
  const isInvalid = eventValue.valid === false;
  const hasNoValidation = eventValue.valid === undefined;

  return (
    <>
      <Card
        ref={setNodeRef}
        className={classes.item}
        style={{ transform: CSS.Translate.toString(transform), transition }}
        onClick={open}
        {...attributes}
        {...listeners}
        data-dragging={isDragging}
        data-testid={testIds.getItemTestId(eventValue.id)}
      >
        <Indicator
          color={cn({
            blue: isValid,
            red: isInvalid,
            gray: hasNoValidation,
          })}
          position='middle-start'
          data-testid={testIds.indicator}
        >
          <Text ml='sm' size='sm'>
            {eventValue.name}
          </Text>
        </Indicator>
      </Card>
      <ValueSettingsModal
        eventValue={eventValue}
        opened={opened}
        onClose={close}
        onDelete={onDelete}
        onSubmit={onChange}
      />
    </>
  );
};
