import { ModelType } from '@ai-platform/common-types';
import { createStoreHook } from '@aiola/frontend';
import { AiDataSourceId } from 'stores/aiPlatform/aiDataSources/aiDataSources.types';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { modelTypeToStartTrainModelType } from './aiModels.adapters';
import { endSubscriptionStatusList } from './aiModels.const';
import { AiModel, AiModelId, AiModelStatus, AiModelSubsciptionOptions } from './aiModels.types';
import { aiModelsApi } from './api/aiModels.api';

interface AiModelsState {
  loading: boolean;
  aiModels: Record<AiModelId, AiModel>;
  subscriptions: Record<AiModelId, NodeJS.Timeout>;
}

interface AiModelsActions {
  fetchAiModels: (customerId: string, flowId: string, flowVersion: string) => Promise<AiModel[] | undefined>;
  subscribeToAiModel: (modelId: AiModelId, options?: AiModelSubsciptionOptions) => () => void;
  reset: () => void;
  startTrainingExec: (dataSourceId: string, modelType: ModelType) => Promise<string | undefined>;
  internal: {
    unsubscribeFromAiModel: (modelId: AiModelId) => void;
  };
}

const initialState: AiModelsState = {
  loading: true,
  aiModels: {},
  subscriptions: {},
};

export const aiModelStore = create(
  immer<AiModelsState & AiModelsActions>((set, get) => ({
    ...initialState,
    fetchAiModels: async (customerId: string, flowId: string, flowVersion: string) => {
      set({ loading: true });
      const aiModelList = await aiModelsApi.getAiModels({
        tenantId: customerId,
        flowId,
        flowVersion: Number(flowVersion),
      });
      const aiModels =
        aiModelList?.reduce<Record<AiModelId, AiModel>>((acc, aiModel) => {
          acc[aiModel.id] = aiModel;
          return acc;
        }, {}) ?? {};
      set({ aiModels, loading: false });
      return aiModelList;
    },
    subscribeToAiModel: (modelId: AiModelId, { onComplete, unsubscribeOnFinish }: AiModelSubsciptionOptions = {}) => {
      const interval = aiModelsApi.subscribeToAiModel(modelId, (aiModel) => {
        const prevStatus = get().aiModels[aiModel.id]?.status;
        const { status } = aiModel;
        // When status is completed/failed, we don't expect changes, so unsubscribe
        if (unsubscribeOnFinish && endSubscriptionStatusList.includes(status)) {
          get().internal.unsubscribeFromAiModel(modelId);
        }
        if (onComplete && status === AiModelStatus.COMPLETED && prevStatus !== status) {
          onComplete();
        }
        set((state) => {
          state.aiModels[aiModel.id] = aiModel;
        });
      });
      set((state) => {
        state.subscriptions[modelId] = interval;
      });
      return () => get().internal.unsubscribeFromAiModel(modelId);
    },
    reset: () => {
      Object.values(get().subscriptions).forEach(clearInterval);
      set(initialState);
    },
    startTrainingExec: async (dataSourceId: AiDataSourceId, modelType: ModelType) => {
      const response = await aiModelsApi.startModelTraining({
        executionId: dataSourceId,
        trainingModel: modelTypeToStartTrainModelType[modelType],
        asrConfig: {},
        nlpConfig: {},
      });
      if (!response?.executionId) {
        return undefined;
      }
      const model = await aiModelsApi.getAiModel(response.executionId);
      if (!model) {
        return undefined;
      }
      set((state) => {
        state.aiModels[model.id] = model;
      });
      return response.executionId;
    },
    internal: {
      unsubscribeFromAiModel: (modelId: AiModelId) => {
        clearInterval(get().subscriptions[modelId]);
        set((state) => {
          delete state.subscriptions[modelId];
        });
      },
    },
  })),
);

export const useAiModelStore = createStoreHook<AiModelsState & AiModelsActions>({
  store: aiModelStore,
  useShallow,
});
