import { Box, Progress, Text } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

interface DataGenerationProgressbarProps {
  percent: number;
}

export const testIds = {
  dataGenPercentage: 'data-gen-percentage',
  dataGenProgress: 'data-gen-progress',
};

export const DataGenerationProgressbar = ({ percent }: DataGenerationProgressbarProps) => {
  const percentStepRef = useRef(percent);
  const { t } = useTranslation();

  useEffect(() => {
    if (percentStepRef.current !== percent) {
      percentStepRef.current = percent;
    }
  }, [percent]);

  return (
    <Box>
      <Progress
        animated={percent !== 100}
        value={percent}
        transitionDuration={500}
        data-testid={testIds.dataGenProgress}
      />
      <Text size='sm' c='dark.3' data-testid={testIds.dataGenPercentage} py='10'>
        {t('common.percentage', { percent })}
      </Text>
    </Box>
  );
};
