import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { createStoreHook } from '@aiola/frontend';
import {
  UserMgmtState,
  policySlice,
  usersSlice,
  groupSlice,
  usersInitialState,
  policiesInitialState,
  groupsInitialState,
} from './slices';

export const userMgmtStore = create(
  immer<UserMgmtState>((...args) => ({
    ...usersSlice(...args),
    ...policySlice(...args),
    ...groupSlice(...args),
    reset: () => {
      const [set] = args;
      set({
        ...usersInitialState,
        ...policiesInitialState,
        ...groupsInitialState,
      });
    },
  })),
);

export const useUserMgmtStore = createStoreHook({ store: userMgmtStore, useShallow });
