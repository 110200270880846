import { MRT_ColumnDef } from 'mantine-react-table';
import { User } from 'stores/auth';
import { UserDetails } from 'components';
import { Group } from '@jargonic/customer-types';
import { isUser } from './EntitiesPanel.utils';
import { GroupCell } from './components/GroupCell';

export const testIds = {
  details: {
    getUserDetailsTestId: (userId: string) => `user-details-${userId}`,
  },
};

export const columns: MRT_ColumnDef<User | Group>[] = [
  {
    id: 'details',
    accessorFn: (row) => (isUser(row) ? `${row.givenName} ${row.familyName} ${row.email}` : row.name),
    header: '',
    Cell: ({ row }) =>
      isUser(row.original) ? (
        <UserDetails user={row.original} data-testid={testIds.details.getUserDetailsTestId(row.original.userId)} />
      ) : (
        <GroupCell group={row.original} />
      ),
  },
];
