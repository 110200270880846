import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, TextInput } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';
import { Policy } from 'stores/userMgmt';

interface RenamePolicyModalProps {
  opened: boolean;
  policies: Policy[];
  onRename: (name: string) => Promise<boolean>;
  onClose: () => void;
}

export const testIds = {
  title: modalBaseTestIds.title,
  renameButton: 'policy-table-rename-modal-rename',
  cancelButton: 'policy-table-rename-modal-cancel',
  input: 'policy-table-rename-modal-input',
};

export const RenamePolicyModal = ({ policies, opened, onRename, onClose }: RenamePolicyModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const error = policies.some((policy) => policy.name === name);

  const onSubmit = async () => {
    setLoading(true);
    const response = await onRename(name);
    setLoading(false);
    if (response) onClose();
  };

  return (
    <ModalBase color='green' opened={opened} title={t('userMgmt.policies.renamePolicy.title')} onClose={onClose}>
      <TextInput
        placeholder={t('userMgmt.policies.renamePolicy.placeholder')}
        error={error && t('userMgmt.policies.renamePolicy.inputError')}
        onChange={(e) => setName(e.target.value)}
        data-testid={testIds.input}
      />
      <Group justify='end' mt='lg'>
        <Button variant='subtle' disabled={loading} onClick={onClose} data-testid={testIds.cancelButton}>
          {t('common.cancel')}
        </Button>
        <Button loading={loading} disabled={error} data-autofocus onClick={onSubmit} data-testid={testIds.renameButton}>
          {t('common.rename')}
        </Button>
      </Group>
    </ModalBase>
  );
};
