import { MantineColor } from '@mantine/core';

export const labelColors: MantineColor[] = [
  'blue',
  'orange',
  'lime',
  'indigo',
  'cyan',
  'yellow',
  'pink',
  'green',
  'red',
  'teal',
];
