import { CustomerId } from 'stores/customers';
import { userMgmtStore } from 'stores/userMgmt/userMgmt.store';
import { GroupSliceActions, GroupSliceState, UpsertGroupArgs, UserMgmtSlice } from '../userMgmt.slices.types';
import { groupApi } from './groups.api';

export const groupsInitialState: GroupSliceState = {
  loadingGroups: false,
  groups: {},
};

async function upsertGroup(customerId: CustomerId, { action, groupId, payload }: UpsertGroupArgs) {
  switch (action) {
    case 'create': {
      return groupApi.createGroup(customerId, payload);
    }
    case 'update':
      return groupApi.updateGroup(customerId, groupId, payload);
    default:
      return undefined;
  }
}

export const groupSlice: UserMgmtSlice<GroupSliceState, GroupSliceActions> = (set) => ({
  ...groupsInitialState,
  fetchGroups: async (customerId) => {
    set({ loadingGroups: true });
    const groups = await groupApi.getGroups(customerId);
    if (groups) {
      const groupsMap = groups.reduce((acc, group, index) => {
        const groupId = group.id || `group_${index}`;
        return { ...acc, [groupId]: group };
      }, {});
      set({ groups: groupsMap });
    }
    set({ loadingGroups: false });
    return !!groups;
  },
  upsertGroup: async (customerId, payload) => {
    const group = await upsertGroup(customerId, payload);
    if (group) {
      userMgmtStore.getState().fetchPolicies(customerId);
      userMgmtStore.getState().fetchUsers(customerId);
      set(({ groups }) => {
        groups[group.id] = group;
      });
    }
    return group;
  },
  deleteGroup: async (customerId, groupId) => {
    const success = await groupApi.deleteGroup(customerId, groupId);

    if (success) {
      userMgmtStore.getState().fetchPolicies(customerId);
      userMgmtStore.getState().fetchUsers(customerId);
      set(({ groups }) => {
        delete groups[groupId];
      });
    }
    return success;
  },
});
