import { Stack, Title, Text } from '@mantine/core';

interface PageTitleProps {
  title: string;
  subtitle: string;
}

export const testIds = {
  title: 'page-title-title',
  subtitle: 'page-title-subtitle',
};

export const PageTitle = ({ subtitle, title }: PageTitleProps) => (
  <Stack gap='zero'>
    <Title order={2} fw={600} data-testid={testIds.title}>
      {title}
    </Title>
    <Text data-testid={testIds.subtitle}>{subtitle}</Text>
  </Stack>
);
