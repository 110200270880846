import { CreateUserPayload } from 'stores/userMgmt';

export function createUserToApi(usersToCreate: CreateUserPayload[]): CreateUserPayload[] {
  return usersToCreate.map(({ email, givenName, familyName, username }) => ({
    email: email.toLowerCase(),
    givenName,
    familyName,
    username,
  }));
}
