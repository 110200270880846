import { ElementProps, Select, SelectProps } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';

interface PermissionServiceInputProps extends SelectProps, ElementProps<'input', keyof SelectProps> {
  services: string[];
}

export const testIds = {
  select: 'permission-service-input-select',
  label: 'permission-service-input-label',
};

export const PermissionServiceInput = ({ services, ...props }: PermissionServiceInputProps) => {
  const { t } = useTranslation();

  return (
    <Select
      label={
        <InfoLabel infoText={t('userMgmt.policies.editPermission.serviceInput.tooltip')} data-testid={testIds.label}>
          {t('userMgmt.policies.editPermission.serviceInput.label')}
        </InfoLabel>
      }
      allowDeselect={false}
      data={services}
      data-testid={testIds.select}
      required
      {...props}
    />
  );
};
