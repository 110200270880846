import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Combobox, Flex, Group, Image, Text, ThemeIcon, UnstyledButton } from '@mantine/core';
import { IconCaretDownFilled } from '@tabler/icons-react';
import { Customer } from '@jargonic/customer-types';

interface CustomerSelectProps {
  customer?: Customer;
  onClick: () => void;
}

export const testIds = {
  target: 'customer-select-target',
  selectedLogo: 'customer-select-selected-logo',
  chevron: 'customer-select-chevron',
};

export const CustomerSelectTarget = forwardRef<HTMLButtonElement, CustomerSelectProps>(({ customer, onClick }, ref) => {
  const { t } = useTranslation();

  return (
    <Combobox.Target>
      <UnstyledButton ref={ref} color='gray' variant='transparent' onClick={onClick} data-testid={testIds.target}>
        <Flex align='center' gap='xl' justify='space-between'>
          {customer ? (
            <Group>
              <Image
                src={customer?.logo}
                alt={customer?.name}
                fallbackSrc='/CustomerFallback.svg'
                h={26}
                w='auto'
                fit='contain'
                data-testid={testIds.selectedLogo}
              />
              <Text>{customer.name}</Text>
            </Group>
          ) : (
            t('header.noCustomer')
          )}
          <ThemeIcon color='gray' size='xs' variant='transparent'>
            <IconCaretDownFilled data-testid={testIds.chevron} />
          </ThemeIcon>
        </Flex>
      </UnstyledButton>
    </Combobox.Target>
  );
});
