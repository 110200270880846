import i18n from 'services/i18n';

function isMorning(hour: number) {
  return hour >= 5 && hour < 12;
}

function isAfternoon(hour: number) {
  return hour >= 12 && hour < 17;
}

function isEvening(hour: number) {
  return hour >= 17 && hour < 21;
}

export function getGreeting(hour: number, name: string) {
  if (isMorning(hour)) return i18n.t('header.userMenu.greetings.morning', { name });
  if (isAfternoon(hour)) return i18n.t('header.userMenu.greetings.afternoon', { name });
  if (isEvening(hour)) return i18n.t('header.userMenu.greetings.evening', { name });
  return i18n.t('header.userMenu.greetings.night', { name });
}
