import { MRT_ColumnDef } from 'mantine-react-table';
import { Group } from 'stores/userMgmt';
import i18n from 'services/i18n';

export const columns: MRT_ColumnDef<Group>[] = [
  {
    accessorKey: 'name',
    header: i18n.t('common.name'),
  },
  {
    accessorFn: (row) => row.users.length,
    header: i18n.t('userMgmt.groups.table.columns.users'),
    id: 'usersCount',
  },
  {
    accessorFn: (row) => row.policies.length,
    header: i18n.t('userMgmt.groups.table.columns.policies'),
    id: 'policiesCount',
  },
];
