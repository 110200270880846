import { supportedLanguages } from '@ai-platform/common-types';
import { CommonValueType } from '@jargonic/event-definition-types';
import { CreateFlowRequest } from '@jargonic/flows-types';
import { CreateFlowPayload } from './flows.types';

export const LIVE_STATUS = 'live';
const englishLocale = supportedLanguages.find((l) => l.code === 'en-GB')!;

export const createFlowPayloadSupplement: Omit<CreateFlowRequest, keyof CreateFlowPayload> = {
  dateFormat: 'DD/MM/YYYY',
  maxInspectors: 1,
  singleInstance: false,
  supportedCommonEvents: [CommonValueType.APPLICABILITY, CommonValueType.IMAGE],
  mainLocale: englishLocale.code,
  locales: [
    {
      code: englishLocale.code,
      englishName: englishLocale.language,
      localName: englishLocale.language,
    },
  ],
};
