import { Box, ElementProps, TagsInput, TagsInputProps } from '@mantine/core';
import { forwardRef, useCallback, useState } from 'react';
import { InfoLabel } from 'components/InfoLabel/InfoLabel';
import { useTranslation } from 'react-i18next';
import classes from './SynonymsInput.module.css';

interface SynonymsInputProps extends TagsInputProps, ElementProps<'input', keyof TagsInputProps> {
  infoText?: string;
  withLabel?: boolean;
}

export const testIds = {
  input: 'synonyms-input',
  label: 'synonyms-input-label',
  wrapper: 'synonyms-input-wrapper',
};

export const SynonymsInput = forwardRef<HTMLInputElement, SynonymsInputProps>(
  (
    {
      infoText,
      label,
      description,
      withLabel = true,
      error,
      classNames,
      allowDuplicates,
      ...props
    }: SynonymsInputProps,
    ref,
  ) => {
    const { t } = useTranslation();
    const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
    const errorClassName = !allowDuplicates && isDuplicate ? classes.error : undefined;

    const resetIsDuplicate = useCallback(() => !allowDuplicates && isDuplicate && setIsDuplicate(false), [isDuplicate]);

    return (
      <TagsInput
        wrapperProps={{ 'data-testid': testIds.wrapper }}
        ref={ref}
        inputWrapperOrder={['label', 'input', 'description', 'error']}
        label={
          // eslint-disable-next-line no-nested-ternary
          withLabel ? (
            infoText ? (
              <InfoLabel infoText={infoText} data-testid={testIds.label}>
                {label ?? t('common.synonyms')}
              </InfoLabel>
            ) : (
              <Box component='span' data-testid={testIds.label}>
                {label ?? t('common.synonyms')}
              </Box>
            )
          ) : null
        }
        allowDuplicates={allowDuplicates}
        classNames={{ description: errorClassName, ...classNames }}
        description={description ?? t('common.noDuplicates')}
        error={error || (!allowDuplicates && isDuplicate)}
        onDuplicate={() => setIsDuplicate(true)}
        onSearchChange={resetIsDuplicate}
        data-testid={testIds.input}
        {...props}
      />
    );
  },
);
