import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mantine/core';
import { useCustomerStore } from 'stores/customers';
import { useAuthStore } from 'stores/auth';
import { AppHeader } from './AppHeader';
import { GlobalModal } from './GlobalModal/GlobalModal';
import classes from './AppLayout.module.css';

export const AppLayout = () => {
  const { fetchCurrentUser } = useAuthStore(['fetchCurrentUser']);
  const { fetchCustomers } = useCustomerStore(['fetchCustomers']);

  useEffect(() => {
    fetchCurrentUser();
    fetchCustomers();
  }, []);

  return (
    <Box className={classes.wrapper}>
      <AppHeader />
      <Outlet />
      <GlobalModal />
    </Box>
  );
};
