import { ThemeIcon } from '@mantine/core';
import { IconCheck, IconDeviceFloppy, IconX } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface SaveButtonProps {
  disabled: boolean;
  loading: boolean;
  onClick: () => Promise<boolean>;
}

export const testIds = {
  button: 'wizard-actions-save-button',
  icon: 'wizard-actions-save-button-icon',
  markers: {
    success: 'wizard-actions-save-button-success-marker',
    error: 'wizard-actions-save-button-error-marker',
  },
};

type RecentResult = 'success' | 'error';
const resultIcons = {
  success: <IconCheck />,
  error: <IconX />,
};

const resultColors = {
  success: 'green',
  error: 'red',
};

export const SaveButton = ({ disabled, loading, onClick }: SaveButtonProps) => {
  const { t } = useTranslation();
  const [recentResult, setRecentResult] = useState<RecentResult | null>(null);

  const onClickSave = async () => {
    const result = await onClick();
    setRecentResult(result ? 'success' : 'error');
  };

  const onAnimationEnd = () => {
    setRecentResult(null);
  };

  return (
    <ActionButton
      label={t('common.save')}
      color='gray.5'
      disabled={disabled}
      loading={loading}
      pos='relative'
      onClick={onClickSave}
      data-testid={testIds.button}
    >
      <IconDeviceFloppy data-testid={testIds.icon} />
      {recentResult && (
        <ThemeIcon
          className='animate-fade-out'
          color={resultColors[recentResult]}
          pos='absolute'
          radius='xl'
          onAnimationEnd={onAnimationEnd}
          data-testid={testIds.markers[recentResult]}
        >
          {resultIcons[recentResult]}
        </ThemeIcon>
      )}
    </ActionButton>
  );
};
