import { Group, useMantineTheme } from '@mantine/core';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import { useModal } from 'stores/globalModal';
import { Folder } from 'stores/folders';
import { Flow } from 'stores/flows';
import { createFlowGlobalModalProps, deleteFolderGlobalModalProps } from '../../../modals';

interface FolderRowActionsProps {
  folder: Folder;
  onCreateFlow: (flow: Flow) => void;
}

export const testIds = {
  group: 'folder-row-actions-group',
  delete: {
    button: 'folder-row-delete-button',
    icon: 'folder-row-delete-icon',
  },
  newFlow: {
    button: 'folder-row-new-flow-button',
    icon: 'folder-row-new-flow-icon',
  },
};

export const FolderRowActions = ({ folder, onCreateFlow }: FolderRowActionsProps) => {
  const { t } = useTranslation();
  const { open } = useModal();
  const { primaryColor } = useMantineTheme();

  const onClickDelete = () => open(deleteFolderGlobalModalProps(folder.id));
  const onClickNewFlow = () => open(createFlowGlobalModalProps({ folderId: folder.id, onCreate: onCreateFlow }));

  return (
    <Group gap='zero' justify='end' onClick={(e) => e.stopPropagation()} data-testid={testIds.group}>
      <ActionButton
        color={primaryColor}
        label={t('flowsPage.newFlowButton')}
        onClick={onClickNewFlow}
        data-testid={testIds.newFlow.button}
      >
        <IconPlus data-testid={testIds.newFlow.icon} />
      </ActionButton>
      <ActionButton
        color='gray.7'
        label={t('flowsPage.table.folder.delete')}
        onClick={onClickDelete}
        data-testid={testIds.delete.button}
      >
        <IconTrash data-testid={testIds.delete.icon} />
      </ActionButton>
    </Group>
  );
};
