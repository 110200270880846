import { Button, Flex, Stack } from '@mantine/core';
import { IconRocket, IconWand } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { PublishRouteParams, ROUTES } from 'routes/routes.config';
import { useFlow } from 'stores/flows';

import { ModelType } from '@ai-platform/common-types';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { isModelType, useAiModelsByType } from 'stores/aiPlatform';
import { useSubscribedPublishedModels } from 'stores/aiPlatform/aiPublishedModels';
import { useAiPublishedModelStore } from 'stores/aiPlatform/aiPublishedModels/aiPublishedModels.store';
import { PageTitle } from '../components/PageTitle/PageTitle';
import { PublishPageTable } from './components/PublishPageTable/PublishPageTable';
import { PublishModal } from './components/PublishPageTable/components/PublishModal/PublishModal';

export const testIds = {
  wrapper: 'publish-page',
  publishButton: 'publish-page-new-publish-button',
};

export const PublishPage = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as PublishRouteParams;
  const flow = useFlow(flowId);
  const [params] = useSearchParams();
  const modelType = (params.get('modelType') ?? '') as ModelType;
  const isValidModelType = isModelType(modelType);
  const trainingModels = useAiModelsByType(modelType);

  const [isPublishModalOpen, { open: openPublishModal, close: closePublishModal }] = useDisclosure(false);
  const { startPublishExec } = useAiPublishedModelStore(['startPublishExec']);

  const onStartPublishing = async (aiDataSourceId: string, environment: string) => {
    const modelId = await startPublishExec(aiDataSourceId, environment);
    if (!modelId) {
      notifications.show({
        title: t('publishPage.startPublishError.title'),
        message: t('publishPage.startPublishError.message'),
        color: 'red',
      });
    }
  };

  const { fetchPublishedModels, subscribeToAiModel } = useAiPublishedModelStore([
    'fetchPublishedModels',
    'loading',
    'subscribeToAiModel',
  ]);

  useEffect(() => {
    if (flow && modelType && customerId) {
      const tenantId = customerId.replace(/^aiola$/, 'internal-dev');
      const dto = {
        tenantId,
        activeVersion: flow.activeVersion!,
        modelType,
        flowId: flow.id,
        mainLocale: flow.mainLocale,
      };
      fetchPublishedModels(dto);
    }
  }, [flow, modelType, customerId]);

  const modelsToSubscribe = useSubscribedPublishedModels(modelType);

  useEffect(() => {
    for (let i = 0; i < modelsToSubscribe.length; i++) {
      subscribeToAiModel(modelsToSubscribe[i].publishExecutionId, {
        unsubscribeOnFinish: true,
        onComplete: () => {
          notifications.show({
            color: 'green',
            icon: <IconRocket />,
            title: t('common.completed'),
            message: t('common.publishedSuccess', { id: modelsToSubscribe[i].publishExecutionId }),
            withCloseButton: true,
          });
        },
      });
    }
  }, [modelsToSubscribe]);

  const pageTitle = t(`publishPage.pageTitle.${modelType}`);

  if (!isValidModelType) {
    return (
      <Navigate
        to={{
          pathname: ROUTES.AI_PLATFORM(customerId, flowId).PUBLISH,
          search: `?modelType=${ModelType.NLP}`,
        }}
        replace
      />
    );
  }

  return (
    <Stack p='xl' gap='md' className='h-full' pos='relative' pb={50} data-testid={testIds.wrapper}>
      <Flex align='center' justify='space-between'>
        <PageTitle title={pageTitle} subtitle={flow.name} />
        <Button leftSection={<IconWand />} onClick={openPublishModal} data-testid={testIds.publishButton}>
          {t('publishPage.publishButton')}
        </Button>
      </Flex>
      <PublishPageTable />
      {isPublishModalOpen && (
        <PublishModal
          modelName={t(`common.modelType.${modelType}`)}
          onClose={closePublishModal}
          onStart={onStartPublishing}
          opened
          trainingModels={trainingModels}
        />
      )}
    </Stack>
  );
};
