export const CLASSIC_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const DEFAULT_COLOR = '#6F6F6F';
/** We allow email inputs with uppercase. Developers should convert to lowercase before submitting to API. */
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
export const PLACEHOLDER_LENGTH = 20;
export const USERNAME_DELIMITER = '.';
export const MAX_NAME_LENGTH = 21;

/**
 * Used in cases where a nice generic response is needed, but a simple boolean would not suffice.
 */
export enum NiceResponse {
  OK,
  ERROR,
  DUPLICATE,
}

export type PartialErrorResponse = { message?: string; statusText: string };
