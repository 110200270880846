import { Outlet } from 'react-router-dom';
import { Box } from '@mantine/core';
import { FlowSidebar, testIds as sidebarTestIds } from './FlowSidebar';
import classes from './FlowLayout.module.css';

export const testIds = {
  sidebar: sidebarTestIds,
};

export const FlowLayout = () => (
  <Box className={classes.wrapper}>
    <FlowSidebar />
    <Outlet />
  </Box>
);
