import { Box } from '@mantine/core';
import { FeaturesProperties, testIds as featuresTestIds } from './FeaturesProperties';
import { ExpirationProperties, testIds as expirationTestIds } from './ExpirationProperties/ExpirationProperties';

export const testIds = {
  wrapper: 'flow-settings-features-wrapper',
  features: featuresTestIds,
  expiration: expirationTestIds,
};

export const FlowFeatures = () => (
  <Box className='grid grid-cols-2 gap-6' data-testid={testIds.wrapper}>
    <FeaturesProperties />
    <ExpirationProperties />
  </Box>
);
