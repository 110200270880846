import { OnSelectEnd } from 'react-selecto';
import { Coordinates } from 'stores/wizard';

export const zoomLimits = [0.5, 1.5];
export const zoomMedian = (zoomLimits[0] + zoomLimits[1]) / 2;

export function getNewAnnotationCoordsFromSelecto(event: OnSelectEnd, zoom: number): Coordinates {
  const { rect, inputEvent } = event;
  const { offsetX, offsetY, clientY, clientX } = inputEvent;
  const { width, height, top: rectClientTop, left: rectClientLeft } = rect;
  const w = width / zoom;
  const h = height / zoom;
  const top = clientY > rectClientTop ? offsetY - h : offsetY;
  const left = clientX > rectClientLeft ? offsetX - w : offsetX;
  return { y: top, x: left, width: w, height: h };
}
