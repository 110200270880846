import { Flex, TextInput } from '@mantine/core';
import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventValueCSVResponse } from 'stores/wizard';
import { EventValuesCSVUpload, testIds as uploadCsvTestIds } from './EventValuesCSVUpload';

interface NewValueInputProps {
  existingNameSet: Set<string>;
  valueCount: number;
  rootClassName?: string;
  onSubmit: (valueName: string) => void;
  onUploadCSV: (file: File) => Promise<EventValueCSVResponse>;
}

export const testIds = {
  input: 'new-value-input',
  uploadCsv: uploadCsvTestIds,
};

export const NewValueInput = ({
  existingNameSet,
  valueCount,
  rootClassName,
  onSubmit,
  onUploadCSV,
}: NewValueInputProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState('');

  const nameExists = existingNameSet.has(value);
  const nameError = nameExists && t('wizard.steps.reportedEvents.typeSettings.types.select.duplicateValueError');

  const hasInsufficientValues = valueCount < 2;
  const insufficientValuesError =
    hasInsufficientValues &&
    t('wizard.steps.reportedEvents.typeSettings.types.select.newValue.insufficientValuesError');

  const error = nameError || insufficientValuesError;

  const onChange: ChangeEventHandler<HTMLInputElement> = (event) => setValue(event.currentTarget.value);
  const onKeyPress: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter' && !nameExists) {
      onSubmit(event.currentTarget.value);
      setValue('');
    }
  };

  return (
    <Flex classNames={{ root: rootClassName }} gap='sm'>
      <TextInput
        label={t('wizard.steps.reportedEvents.typeSettings.types.select.newValue.title')}
        placeholder={t('wizard.steps.reportedEvents.typeSettings.types.select.newValue.placeholder')}
        error={error}
        classNames={{ root: 'grow' }}
        required
        value={value}
        onChange={onChange}
        onKeyDown={onKeyPress}
        data-testid={testIds.input}
      />
      <EventValuesCSVUpload onUpload={onUploadCSV} />
    </Flex>
  );
};
