import { useEffect, useMemo, useState } from 'react';
import { Modal, ScrollArea } from '@mantine/core';
import { useListState } from '@mantine/hooks';
import { EmptyState } from 'components';
import { useTranslation } from 'react-i18next';
import { Container, ContainerId, LabelId } from 'stores/wizard';
import { ContainerMappingGrid, testIds as gridTestIds } from './ContainerMappingGrid';
import { ContainerMappingControls, testIds as controlsTestIds } from './Controls/ContainerMappingControls';
import { MappingContainer } from './ContainerMappingModal.types';
import classes from './ContainerMappingModal.module.css';

interface ContainerMappingModalProps {
  containers: Container[];
  /** If provided, `containers` will be filtered by label, but all `containers` will be used for parent filtering. */
  labelId?: LabelId;
  /** @default false */
  enableParentFilter?: boolean;
  opened: boolean;
  selectedIds: ContainerId[];
  onChange: (selectedIds: ContainerId[]) => void;
  onClose: () => void;
}

export const testIds = {
  ...gridTestIds,
  overlay: 'container-mapping-modal-overlay',
  modal: 'container-mapping-modal',
  controls: controlsTestIds,
};

export const ContainerMappingModal = ({
  containers,
  labelId,
  selectedIds,
  opened,
  enableParentFilter = false,
  onChange,
  onClose,
}: ContainerMappingModalProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [selectedParentIds, setSelectedParentIds] = useState<ContainerId[]>([]);
  const labelContainers = useMemo(
    () => (labelId ? containers.filter((container) => container.typeId === labelId) : containers),
    [containers, labelId],
  );
  const [mappingContainers, { apply, applyWhere, setState }] = useListState<MappingContainer>([]);

  const filteredContainers = useMemo(() => {
    const parentIdSet = new Set(selectedParentIds);
    const shouldFilterByParent = selectedParentIds.length > 0;
    return mappingContainers.filter((container) => {
      const parentIsSelected = shouldFilterByParent ? parentIdSet.has(container.parentId!) : true;
      const nameMatches = container.name.toLowerCase().includes(search.toLowerCase());
      return parentIsSelected && nameMatches;
    });
  }, [mappingContainers, selectedParentIds, search]);

  const onApply = () => {
    onChange(mappingContainers.filter((container) => container.selected).map((container) => container.id));
    onClose();
  };

  const onSelect = (containerId: string) =>
    applyWhere(
      (c) => c.id === containerId,
      (c) => ({ ...c, selected: !c.selected }),
    );

  const areAllSelected = mappingContainers.every((container) => container.selected);
  const areSomeSelected = mappingContainers.some((container) => container.selected);

  const deselectAll = () => apply((c) => ({ ...c, selected: false }));
  const selectAll = () => apply((c) => ({ ...c, selected: true }));

  const onToggleAll = () => {
    if (areAllSelected) deselectAll();
    else selectAll();
  };
  const initMapping = () =>
    setState(labelContainers.map((container) => ({ ...container, selected: selectedIds.includes(container.id) })));

  const resetSearch = () => setSearch('');
  const resetModal = () => resetSearch();

  useEffect(() => {
    if (opened) initMapping();
  }, [opened]);

  return (
    <Modal.Root opened={opened} scrollAreaComponent={ScrollArea.Autosize} size='auto' onClose={onClose}>
      <Modal.Overlay data-testid={testIds.overlay} />
      <Modal.Content w={918} data-testid={testIds.modal} transitionProps={{ onExited: resetModal }}>
        <Modal.Header className={classes.header}>
          <ContainerMappingControls
            allSelected={areAllSelected}
            containers={containers}
            enableParentFilter={enableParentFilter}
            search={search}
            someSelected={areSomeSelected}
            onApply={onApply}
            onChangeSelectedParents={setSelectedParentIds}
            onSearch={setSearch}
            onToggleAll={onToggleAll}
          />
        </Modal.Header>
        <Modal.Body>
          {labelContainers.length ? (
            <ContainerMappingGrid containers={filteredContainers} onSelect={onSelect} />
          ) : (
            <EmptyState
              mt='md'
              title={t('wizard.steps.reportedEvents.generalSettings.mapping.modal.emptyState.title')}
              message={t('wizard.steps.reportedEvents.generalSettings.mapping.modal.emptyState.message')}
            />
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
