import { sanitizeStr } from 'utils';
import { USERNAME_DELIMITER } from 'consts';
import { UsersParsedCSVPayload } from './slices';
import { CreateUserPayload } from './userMgmt.types';

export function getUsersFromCSVPayload(payload: UsersParsedCSVPayload[]): Omit<CreateUserPayload, 'username'>[] {
  return payload
    .filter(({ email, first_name, last_name }) => email && first_name && last_name)
    .map(({ email, first_name, last_name }) => ({
      email,
      givenName: first_name,
      familyName: last_name,
    }));
}

export function assertCSVColumns(names: Record<string, string>, columns: string[] = []): boolean {
  return Object.values(names).every((col) => columns.includes(col));
}

export function generateUserName(givenName: string, familyName: string) {
  const sanitizedGivenName = sanitizeStr(givenName).trim();
  const sanitizedFamilyName = sanitizeStr(familyName).trim();
  if (!sanitizedGivenName || !sanitizedFamilyName) return '';
  const delimer = sanitizedGivenName && sanitizedFamilyName ? USERNAME_DELIMITER : '';
  return `${sanitizedGivenName}${delimer}${sanitizedFamilyName}`.toLowerCase();
}

export function uniquifyUserName(baseUsername: string, allUsernames?: Set<string>) {
  let username = baseUsername;
  let index = 1;
  while (allUsernames?.has(username)) {
    username = `${baseUsername}${index}`;
    index++;
  }
  return username;
}
