import { MRT_TableOptions } from 'mantine-react-table';
import { Flow } from 'stores/flows';
import { Folder } from 'stores/folders';
import classes from './FlowTable.module.css';

export const flowTableDefs = {
  enableBottomToolbar: false,
  enableColumnActions: false,
  enableExpandAll: false,
  enableExpanding: true,
  enablePagination: false,
  enableRowActions: true,
  enableRowOrdering: true,
  enableSorting: false,
  enableStickyHeader: true,
  layoutMode: 'grid',
  filterFromLeafRows: true,
  enableGlobalFilter: true,
  positionActionsColumn: 'last',
  displayColumnDefOptions: {
    'mrt-row-drag': {
      header: '',
      size: 0,
      minSize: 25,
      mantineTableBodyCellProps: { pr: 0, pl: 'teeny' },
      mantineTableHeadCellProps: { pr: 0, pl: 'teeny' },
    },
    'mrt-row-actions': { header: '', grow: false, size: 100 },
  },
  mantinePaperProps: { className: classes.paper },
  mantineTableContainerProps: { className: classes.container },
  initialState: { columnVisibility: { 'mrt-row-expand': false } },
  mantineTableBodyCellProps: { p: 'sm' },
} satisfies Partial<MRT_TableOptions<Folder | Flow>>;
