import { CopyButton as CopyButtonComponent, rem } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { ActionButton } from 'components/ActionButton/ActionButton';
import { useTranslation } from 'react-i18next';

export const CopyButton = ({ value, copyText, ...props }: { value: string; copyText?: string }) => {
  const { t } = useTranslation();

  return (
    <CopyButtonComponent value={value} timeout={2000}>
      {({ copied, copy }) => (
        <ActionButton
          label={copied ? t('common.copied') : (copyText ?? t('common.copy'))}
          tooltipPosition='right'
          color={copied ? 'teal' : 'gray'}
          variant='subtle'
          onClick={copy}
          data-testid='copy-button-icon'
          {...props}
        >
          {copied ? <IconCheck style={{ width: rem(16) }} /> : <IconCopy style={{ width: rem(16) }} />}
        </ActionButton>
      )}
    </CopyButtonComponent>
  );
};
