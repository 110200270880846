import { Button, Group, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle } from '@tabler/icons-react';
import { ModalBase } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiDataSourceSamplesType, useAiDataSourcesStore } from 'stores/aiPlatform';

interface DeclineGenerationModalProps {
  opened: boolean;
  samplesType: AiDataSourceSamplesType;
  generationId: string;
  close: () => void;
}

export const testIds = {
  modal: 'decline-generation-modal',
  message: 'decline-generation-modal-message',
  cancel: 'decline-generation-modal-cancel',
  decline: 'decline-generation-modal-submit',
};

export const DeclineGenerationModal = ({ opened, close, samplesType, generationId }: DeclineGenerationModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { declineGeneration } = useAiDataSourcesStore(['declineGeneration']);
  const { t } = useTranslation();
  const samplesTypeLabel = t(`dataGenerationPage.declineModal.samplesType.${samplesType}`);

  const onSubmit = async () => {
    setSubmitting(true);
    const success = await declineGeneration(generationId);
    setSubmitting(false);
    if (!success) {
      notifications.show({
        color: 'red',
        title: t('dataGenerationPage.declineModal.errorMessage'),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    } else {
      close();
    }
  };

  return (
    <ModalBase
      opened={opened}
      title={t('dataGenerationPage.declineModal.title')}
      variant='warning'
      onClose={close}
      data-testid={testIds.modal}
    >
      <Text data-testid={testIds.message}>
        {t('dataGenerationPage.declineModal.message', { samplesType: samplesTypeLabel })}
      </Text>
      <Group justify='end'>
        <Button variant='subtle' color='gray.7' onClick={close} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='submit' loading={submitting} onClick={onSubmit} data-testid={testIds.decline}>
          {t('dataGenerationPage.declineModal.declineButton')}
        </Button>
      </Group>
    </ModalBase>
  );
};
