import { MRT_TableOptions, MRT_TableState } from 'mantine-react-table';
import { LabelMetrics } from 'stores/aiPlatform/aiModels';

export const metricsTableDefs = {
  getRowId: (original) => original.label,
  enableSorting: true,
  enableStickyHeader: true,
  enableColumnActions: false,
  enableRowSelection: false,
  enableRowPinning: true,
  rowPinningDisplayMode: 'select-bottom',
  manualPagination: true,
  paginationDisplayMode: 'pages',
  layoutMode: 'grid',
  mantineTableBodyCellProps: { py: 'xs' },
} satisfies Partial<MRT_TableOptions<LabelMetrics>>;

export const rowPinning: MRT_TableState<LabelMetrics>['rowPinning'] = {
  bottom: ['micro', 'macro'],
};

export const initialPagination = {
  pageIndex: 0,
  pageSize: 100,
} satisfies MRT_TableState<LabelMetrics>['pagination'];
