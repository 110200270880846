import { FlowMetadataField } from '@jargonic/flow-metadata-types';
import { useCallback } from 'react';
import { MetadataType, useFlowSettingStore } from 'stores/wizard';

export function useMetadataActions(type: MetadataType) {
  const {
    updateMetadataField,
    addMetadataField,
    reorderMetadataField,
    updateUniqueIdentifierFieldId,
    updateExposedField,
    deleteMetadataFields,
    createFieldValuesFromFile,
  } = useFlowSettingStore([
    'updateMetadataField',
    'updateUniqueIdentifierFieldId',
    'updateExposedField',
    'addMetadataField',
    'reorderMetadataField',
    'deleteMetadataFields',
    'createFieldValuesFromFile',
  ]);

  const onNewField = useCallback(() => addMetadataField(type), [type]);
  const onDeleteFields = useCallback((fieldIds: string[]) => deleteMetadataFields(type, fieldIds), [type]);
  const onReorder = useCallback((fieldId: string, i: number) => reorderMetadataField(type, fieldId, i), [type]);
  const onUpdateField = useCallback(
    (fieldId: string, values: Partial<FlowMetadataField>) => updateMetadataField(type, fieldId, values),
    [type],
  );
  const onUploadCSV = useCallback(
    (fieldId: string, file: File) => createFieldValuesFromFile(type, fieldId, file),
    [type],
  );

  const onUpdateExposedField = useCallback((fieldId: string) => updateExposedField(fieldId), []);

  return {
    onNewField,
    onDeleteFields,
    onUpdateUniqueId: updateUniqueIdentifierFieldId,
    onUpdateExposedField,
    onReorder,
    onUpdateField,
    onUploadCSV,
  };
}
