import { Flex } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { Policy } from 'stores/userMgmt';
import { PolicyTableSearch, testIds as searchTestIds } from './PolicyTableSearch';
import { PolicyTableCreateAction, testIds as createTestIds } from './PolicyTableCreateAction';
import { PolicyTableState } from '../../PolicyTable.types';

type PolicyTableActionsProps = { table: MRT_TableInstance<Policy> };

export const testIds = {
  search: searchTestIds,
  create: createTestIds,
};

export const PolicyTableActions = ({ table }: PolicyTableActionsProps) => {
  const { globalFilter, onSearch, onCreatePolicy, onCreatePermission } = table.getState() as PolicyTableState;

  return (
    <Flex justify='space-between' p='md' align='center'>
      <PolicyTableSearch value={globalFilter} onChange={onSearch} />
      <PolicyTableCreateAction
        policies={table.options.data}
        onCreatePolicy={onCreatePolicy}
        onCreatePermission={onCreatePermission}
      />
    </Flex>
  );
};
