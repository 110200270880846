import { Center, Menu, SegmentedControl, Text } from '@mantine/core';
import { IconMoon, IconSun } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface DarkModeOptionProps {
  colorScheme: 'light' | 'dark';
  onClick: () => void;
}

export const testIds = {
  option: 'dark-mode-option',
  toggle: 'dark-mode-toggle',
  lightOption: 'dark-mode-light-option',
  darkOption: 'dark-mode-dark-option',
};

export const DarkModeOption = ({ colorScheme, onClick }: DarkModeOptionProps) => {
  const { t } = useTranslation();

  return (
    <Menu.Item p='tiny' onClick={onClick} data-testid={testIds.option}>
      <SegmentedControl
        classNames={{ root: 'pointer-events-none' }}
        value={colorScheme}
        size='tn'
        w='100%'
        data={[
          {
            value: 'light',
            label: (
              <Center className='gap-2' py='tiny' data-testid={testIds.lightOption}>
                <IconSun height={16} width={16} />
                <Text span size='sm'>
                  {t('header.userMenu.lightMode')}
                </Text>
              </Center>
            ),
          },
          {
            value: 'dark',
            label: (
              <Center className='gap-2' py='tiny' data-testid={testIds.darkOption}>
                <IconMoon height={16} width={16} />
                <Text span size='sm'>
                  {t('header.userMenu.darkMode')}
                </Text>
              </Center>
            ),
          },
        ]}
        data-testid={testIds.toggle}
      />
    </Menu.Item>
  );
};
