import { ElementProps, TextInput, TextInputProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type CreateContainerNameInputProps = TextInputProps & ElementProps<'input', keyof TextInputProps>;

export const testIds = {
  input: 'create-container-menu-name-input',
};

export const CreateContainerNameInput = (props: CreateContainerNameInputProps) => {
  const { t } = useTranslation();

  return (
    <TextInput
      label={t('wizard.steps.labeling.containerTable.addItemMenu.nameInput.title')}
      placeholder={t('wizard.steps.labeling.containerTable.addItemMenu.nameInput.placeholder')}
      required
      size='xs'
      data-testid={testIds.input}
      {...props}
    />
  );
};
