import { ModelType } from '@ai-platform/common-types';
import { useMemo } from 'react';
import { useAiModelStore } from './aiModels.store';
import { AiModel, AiModelId, AiModelStatus, TrainingSteps } from './aiModels.types';

export function useAiModel(modelId: AiModelId): AiModel | undefined {
  return useAiModelStore((state) => state.aiModels[modelId]);
}

export function useAiModelsByType<T extends ModelType>(type: T): AiModel<T>[] {
  const { aiModels } = useAiModelStore(['aiModels']);
  return useMemo(
    () => Object.values(aiModels).filter((model): model is AiModel<T> => model.type === type),
    [aiModels, type],
  );
}

export function useLastTrainedModel(type: ModelType): AiModel | undefined {
  const aiModels = useAiModelsByType(type);
  return useMemo(
    () =>
      aiModels.filter((model) => model.status === AiModelStatus.COMPLETED).sort((a, b) => b.createdAt - a.createdAt)[0],
    [aiModels],
  );
}

export function useTrainingSteps(modelId: AiModelId): TrainingSteps | undefined {
  return useAiModelStore((state) => state.aiModels[modelId]?.trainingSteps);
}
