import { ModelType } from '@ai-platform/common-types';
import { useMemo } from 'react';
import { useAiDataSourcesStore } from './aiDataSources.store';
import {
  AiDataSourceId,
  AiDataSourceSamplesType,
  AiDataSourceUIModel,
  AiDataTextSample,
  AiDataVoiceSample,
} from './aiDataSources.types';

const MAX_INITIAL_SAMPLE_COUNT_SHOWN = 100;
export function useAiDataSource(id: AiDataSourceId): AiDataSourceUIModel | undefined {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return aiDataSources[id];
}

export function useAiDataSourcesAvailability(): boolean {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return Object.keys(aiDataSources).length > 0;
}

export function useLatestAiDataSourceByType(type: ModelType): AiDataSourceUIModel {
  const { aiDataSources } = useAiDataSourcesStore(['aiDataSources']);
  return useMemo(
    () =>
      Object.values(aiDataSources)
        .filter(({ samplesType }) =>
          [
            AiDataSourceSamplesType.BOTH,
            type === ModelType.NLP ? AiDataSourceSamplesType.TEXT : AiDataSourceSamplesType.VOICE,
          ].includes(samplesType),
        )
        .sort((a, b) => (b.createdAt ?? 0) - (a.createdAt ?? 0))[0],
    [aiDataSources, type],
  );
}

export function useLastAiDataGenSamples(
  id: AiDataSourceId | undefined,
  modelType: ModelType,
): AiDataTextSample[] | AiDataVoiceSample[] {
  const { aiSamples } = useAiDataSourcesStore(['aiSamples']);
  if (!id) {
    return [];
  }
  return aiSamples?.[id]?.[modelType]?.slice(0, MAX_INITIAL_SAMPLE_COUNT_SHOWN) ?? [];
}
