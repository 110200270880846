import { useContainerStore, useEventDefStore, useFileStore, useFlowSettingStore, useLabelStore } from 'stores/wizard';
import { WizardStep } from '../WizardLayout.steps';

export function useStepLoader(step: WizardStep) {
  const { loading: loadingSettings, fetched: fetchedFlowSettings } = useFlowSettingStore(['loading', 'fetched']);
  const { loading: loadingContainers, fetched: fetchedContainers } = useContainerStore(['loading', 'fetched']);
  const { loading: loadingEventDefs, fetched: fetchedEventDefs } = useEventDefStore(['loading', 'fetched']);
  const { loading: loadingLabels } = useLabelStore(['loading']);
  const { loading: loadingFiled } = useFileStore(['loading']);

  switch (step) {
    case WizardStep.SETTINGS:
      return loadingSettings || !fetchedFlowSettings;
    case WizardStep.LABELING:
      return loadingLabels || loadingFiled || loadingContainers || !fetchedContainers;
    case WizardStep.EVENTS:
      return loadingLabels || loadingContainers || loadingEventDefs || !fetchedEventDefs;
    default:
      return true;
  }
}
