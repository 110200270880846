import { Checkbox, CheckboxGroupProps, Divider, ElementProps, Flex, Input } from '@mantine/core';
import { InfoLabel } from 'components';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface PermissionActionInputProps
  extends Omit<CheckboxGroupProps, 'children'>,
    ElementProps<'input', keyof CheckboxGroupProps> {
  actions: string[];
}

export const testIds = {
  getActionTestId: (action: string) => `permission-action-input-${action}`,
  all: 'permission-action-input-all',
  label: 'permission-action-input-label',
};

export const PermissionActionInput = ({ actions, value = [], onChange, ...props }: PermissionActionInputProps) => {
  const { t } = useTranslation();

  const allActionsSelected = actions.length === value.length;
  const someActionsSelected = value.length > 0;
  const allHalfChecked = !allActionsSelected && someActionsSelected;
  const allChecked = actions.length === value.length;
  const onChangeAll: ChangeEventHandler<HTMLInputElement> = () => {
    if (allChecked) onChange?.([]);
    else onChange?.(actions);
  };

  return (
    <Input.Wrapper
      label={
        <InfoLabel infoText={t('userMgmt.policies.editPermission.actionInput.tooltip')} data-testid={testIds.label}>
          {t('userMgmt.policies.editPermission.actionInput.label')}
        </InfoLabel>
      }
      {...props}
    >
      <Flex gap='md' mt='xs'>
        <Checkbox
          label={t('common.all')}
          checked={allChecked}
          indeterminate={allHalfChecked}
          onChange={onChangeAll}
          data-testid={testIds.all}
        />
        <Divider orientation='vertical' />
        <Checkbox.Group value={value} onChange={onChange} {...props}>
          {actions.map((action) => (
            <Checkbox key={action} value={action} label={action} data-testid={testIds.getActionTestId(action)} />
          ))}
        </Checkbox.Group>
      </Flex>
    </Input.Wrapper>
  );
};
