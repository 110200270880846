import { Box, Center, Text } from '@mantine/core';
import { Dropzone, FileRejection, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import classes from './FileDropzone.module.css';

interface FileDropzoneProps {
  loading?: boolean;
  onDrop: (files: File[]) => void;
  onReject: (files: FileRejection[]) => void;
}

export const testIds = {
  dropzone: 'file-dropzone',
  title: 'file-dropzone-title',
  message: 'file-dropzone-message',
};

const mimeType = [...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE];

export const FileDropzone = ({ loading = false, onDrop, onReject }: FileDropzoneProps) => {
  const { t } = useTranslation();

  return (
    <Dropzone
      accept={mimeType}
      className={classes.dropzone}
      loading={loading}
      name='files'
      onDrop={onDrop}
      onReject={onReject}
      data-testid={testIds.dropzone}
    >
      <Center h='100%' className='pointer-events-none'>
        <Dropzone.Accept>
          <IconUpload size={52} stroke={1.5} />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX size={52} stroke={1.5} />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto size={52} stroke={1.5} />
        </Dropzone.Idle>
        <Box m='sm'>
          <Text size='xl' data-testid={testIds.title}>
            {t('wizard.steps.labeling.fileManagement.dropzone.title')}
          </Text>
          <Text size='sm' c='dimmed' data-testid={testIds.message}>
            {t('wizard.steps.labeling.fileManagement.dropzone.message')}
          </Text>
        </Box>
      </Center>
    </Dropzone>
  );
};
