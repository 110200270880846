import { InfoLabel } from 'components/InfoLabel/InfoLabel';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { FlowMetadataField } from 'stores/wizard';

export const testIds = {
  infoLabel: 'metadata-table-expose-tooltip',
};

export const ExposedHeader: MRT_ColumnDef<FlowMetadataField>['Header'] = () => {
  const { t } = useTranslation();

  return (
    <InfoLabel
      data-testid={testIds.infoLabel}
      infoText={t('wizard.steps.settings.metadata.table.exposedField.columnTooltip')}
    >
      {t('wizard.steps.settings.metadata.table.exposedField.title')}
    </InfoLabel>
  );
};
