import { FocusEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SynonymsInput } from 'components';
import { timeStringToMinutes } from 'utils';

const TIME_FORMAT_WITHOUT_COLON = /^\d{4}$/;
const TIME_FORMAT_WITH_COLON = /^([01]\d|2[0-3]):([0-5]\d)$/;

export const convertTODtoMinutes = (values: string[]) =>
  values.map(timeStringToMinutes).filter((time) => time !== undefined);

interface TimeMultipleInputProps {
  value: string[];
  onChange: (newValue: string[]) => void;
  onBlur: (valuesInMinutes: number[]) => void;
  disabled?: boolean;
  placeholder?: string;
  description?: string;
}

export const TimeMultipleInput = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  placeholder = '',
  ...props
}: TimeMultipleInputProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [duplicateError, setDuplicateError] = useState(false);

  const handleOnChangeTOD = (newTODvalues: string[]) => {
    const isTriggeredByRemove = newTODvalues.length < value.length;

    if (isTriggeredByRemove) {
      onChange(newTODvalues);
      return;
    }

    const latestValueAdded = newTODvalues.at(-1) ?? '';
    let formattedValue = latestValueAdded;

    if (TIME_FORMAT_WITHOUT_COLON.test(latestValueAdded)) {
      formattedValue = `${latestValueAdded.slice(0, 2)}:${latestValueAdded.slice(2)}`;
    }

    const isValidTime = TIME_FORMAT_WITH_COLON.test(formattedValue);
    const isDuplicate = value.includes(formattedValue);

    if (isValidTime && !isDuplicate) {
      onChange([...newTODvalues.slice(0, -1), formattedValue]);
      setSearchValue('');
    } else if (isDuplicate) {
      setSearchValue(latestValueAdded);
      setDuplicateError(true);
    }
  };

  const handleOnBlurTOD: FocusEventHandler<HTMLInputElement> = () => {
    const minutesValues = convertTODtoMinutes(value);
    onBlur(minutesValues);
  };

  const handleOnRemove = (valueToRemove: string) => {
    setSearchValue('');
    onChange(value.filter((v) => v !== valueToRemove));
  };

  const handleOnSearchChange = (search: string) => {
    setSearchValue(search);
    setDuplicateError(false);
  };

  return (
    <SynonymsInput
      w='90%'
      variant='filled'
      allowDuplicates
      disabled={disabled}
      withLabel={false}
      description={t('wizard.steps.settings.features.expiration.manyValues')}
      placeholder={placeholder ?? t('common.enterValue')}
      value={value}
      error={duplicateError ? t('common.noDuplicates') : undefined}
      onChange={handleOnChangeTOD}
      onBlur={handleOnBlurTOD}
      onRemove={handleOnRemove}
      searchValue={searchValue}
      onSearchChange={handleOnSearchChange}
      {...props}
    />
  );
};
