import { notifications } from '@mantine/notifications';
import { IconRocket, IconRocketOff } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FlowRouteParams } from 'routes/routes.config';
import { useFlowStore } from 'stores/flows';
import ConfettiExplosion from 'react-confetti-explosion';

export const testIds = {
  button: 'deploy-action-button',
  icon: 'deploy-action-icon',
};

export const TOAST_ID = 'deploy-action-toast';

export const DeployAction = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as FlowRouteParams;
  const { flows, upsertFlow } = useFlowStore(['flows', 'upsertFlow']);
  const flow = flows[flowId];
  const [loading, setLoading] = useState(false);

  const showProcessNotification = () => {
    notifications.hide(TOAST_ID);
    notifications.show({
      id: TOAST_ID,
      loading: true,
      title: t('flowPage.deploy.processNotification.title', { name: flow.name }),
      message: t('flowPage.deploy.processNotification.message'),
      withCloseButton: false,
    });
  };

  const updateSuccessNotification = () => {
    notifications.update({
      id: TOAST_ID,
      color: 'green',
      loading: false,
      icon: (
        <>
          <IconRocket />
          <ConfettiExplosion
            particleCount={500}
            zIndex={1000}
            colors={['var(--mantine-color-green-filled)', 'var(--mantine-color-gray-7)', 'var(--mantine-color-gray-3)']}
          />
        </>
      ),
      title: t('flowPage.deploy.successNotification.title'),
      message: t('flowPage.deploy.successNotification.message', { name: flow.name }),
      withCloseButton: true,
    });
  };

  const updateErrorNotification = () => {
    notifications.update({
      id: TOAST_ID,
      color: 'red',
      loading: false,
      icon: <IconRocketOff />,
      title: t('flowPage.deploy.errorNotification.title'),
      message: t('flowPage.deploy.errorNotification.message'),
      withCloseButton: true,
    });
  };

  const onClick = async () => {
    setLoading(true);
    showProcessNotification();
    const response = await upsertFlow({
      action: 'publish',
      customerId,
      flowId,
    });
    if (response) updateSuccessNotification();
    else updateErrorNotification();
    setLoading(false);
  };

  return (
    <ActionButton label={t('common.deploy')} loading={loading} onClick={onClick} data-testid={testIds.button}>
      <IconRocket data-testid={testIds.icon} />
    </ActionButton>
  );
};
