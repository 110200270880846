import i18n from 'services/i18n';
import { Flow, FlowStatus } from 'stores/flows';
import { Folder, FolderId } from 'stores/folders';

export function isFolder(flowOrFolder: Flow | Folder): flowOrFolder is Folder {
  return 'flowIds' in flowOrFolder;
}

export function isFlow(flowOrFolder: Flow | Folder): flowOrFolder is Flow {
  return 'folderId' in flowOrFolder;
}

export function isFreeFlow(flow: Flow): boolean {
  return !flow.folderId && flow.status !== FlowStatus.ARCHIVED;
}

export const ARCHIVE_ID = 'flows-archive-fake-folder';
export function isArchive(flowOrFolder: Flow | Folder): boolean {
  return flowOrFolder.id === ARCHIVE_ID;
}

export function getFakeArchiveFolder(flows: Flow[]): Folder {
  return {
    id: ARCHIVE_ID,
    order: flows.length,
    createdAt: Date.now(),
    flowIds: flows.filter((flow) => flow.status === FlowStatus.ARCHIVED).map((flow) => flow.id),
    name: i18n.t('flowsPage.table.archive.title'),
  };
}

export async function moveFlowOrFolder(
  movingThing: Flow | Folder,
  targetThing: Flow | Folder,
  onMoveAsFlow: (folderId: FolderId | null, index: number) => Promise<boolean>,
  onMoveAsFolder: (index: number) => Promise<boolean>,
): Promise<boolean> {
  // dragging into archive is not allowed
  const isTargetArchive = isArchive(targetThing) || (isFlow(targetThing) && targetThing.status === FlowStatus.ARCHIVED);
  if (isTargetArchive) return false;

  const isTargetFolder = isFolder(targetThing);
  if (isFolder(movingThing)) return isTargetFolder ? onMoveAsFolder(targetThing.order) : false;

  const isMovingToCurrentFolder = movingThing.folderId === targetThing.id;
  if (isMovingToCurrentFolder) return false;

  const targetId = isTargetFolder ? targetThing.id : targetThing.folderId;
  const targetIndex = isTargetFolder ? targetThing.flowIds.length : targetThing.order;
  return onMoveAsFlow(targetId, targetIndex);
}
