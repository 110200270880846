import { ModelType } from '@ai-platform/common-types';
import { useEffect } from 'react';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { AIPlatformRouteParams } from 'routes/routes.config';
import { useAiDataSourcesStore } from 'stores/aiPlatform';
import { useAiModelStore } from 'stores/aiPlatform/aiModels';
import { useFlow } from 'stores/flows';

export const AiPlatformLayout = () => {
  const { fetchAiModels, reset: resetAiModels } = useAiModelStore(['fetchAiModels', 'reset']);
  const { fetchLatestAiDataSources, reset: resetAiDataSources } = useAiDataSourcesStore([
    'fetchLatestAiDataSources',
    'reset',
  ]);
  const { customerId, flowId } = useParams() as AIPlatformRouteParams;
  const flow = useFlow(flowId);
  const [params] = useSearchParams();
  const modelTypeParam = params.get('modelType') ?? '';

  useEffect(() => {
    if (flow) {
      const { activeVersion } = flow;
      if (activeVersion) {
        // TODO: Temporary fix for this tenant until properly fixed in BE
        const tenantId = customerId.replace(/^aiola$/, 'internal-dev');
        fetchAiModels(tenantId, flowId, activeVersion);
        const dataSourceType = modelTypeParam ? (modelTypeParam.toLocaleUpperCase() as ModelType) : undefined;
        const input = {
          flowId,
          flowVersion: Number(activeVersion),
          tenantId,
        };
        if (dataSourceType) {
          fetchLatestAiDataSources(input, dataSourceType);
        } else {
          fetchLatestAiDataSources(input);
        }
      }
    }
    return () => {
      resetAiModels();
      resetAiDataSources();
    };
  }, [flow, modelTypeParam]);

  return <Outlet />;
};
