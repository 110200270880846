import { DatePickerInput, DateValue } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DateRangeInputProps {
  max: number | null;
  min: number | null;
  onChange: (min: number | null, max: number | null) => void;
}

export const testIds = {
  input: 'date-range-input',
};

export const DateRangeInput = ({ max, min, onChange }: DateRangeInputProps) => {
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);

  const onChangeValues = ([newMin, newMax]: [DateValue, DateValue]) => {
    const newMinValue = newMin ? newMin.getTime() : null;
    const newMaxValue = newMax ? newMax.getTime() : null;
    onChange(newMinValue, newMaxValue);
    setDirty(true);
  };

  const isMinNull = min === null;
  const isMaxNull = max === null;
  const maxDate = isMaxNull ? null : new Date(max);
  const minDate = isMinNull ? null : new Date(min);

  return (
    <DatePickerInput
      w='70%'
      error={dirty && (isMinNull || isMaxNull)}
      leftSection={<IconCalendar />}
      placeholder={t('wizard.steps.reportedEvents.typeSettings.types.number.validation.date.rangePlaceholder')}
      type='range'
      value={[minDate, maxDate]}
      onChange={onChangeValues}
      data-testid={testIds.input}
    />
  );
};
