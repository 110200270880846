import { ElementProps, TextInput, TextInputProps } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';

type ValueNameInputProps = TextInputProps & ElementProps<'input', keyof TextInputProps>;

export const testIds = {
  input: 'value-settings-modal-name-input',
  label: 'value-settings-modal-name-input-label',
};

export const ValueNameInput = (props: ValueNameInputProps) => {
  const { t } = useTranslation();

  return (
    <TextInput
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.name.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.name.title')}
        </InfoLabel>
      }
      placeholder={t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.name.placeholder')}
      required
      data-testid={testIds.input}
      {...props}
    />
  );
};
