import { ModelType } from '@ai-platform/common-types';
import { Combobox, Input, InputBase, useCombobox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiModel } from 'stores/aiPlatform';
import { ModelSelectOption, testIds as optionTestIds } from './ModelSelectOption';

interface ModelSelectProps {
  onChange: (value: string | null) => void;
  forceOpenOptions?: boolean;
  options: AiModel<ModelType>[];
}

export const testIds = {
  input: 'model-select-input',
  dropdown: 'model-select-dropdown',
  option: optionTestIds,
};

export const ModelSelect = memo(({ onChange, options }: ModelSelectProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onOpenedChange: (shouldOpen) => (shouldOpen ? open() : close()),
    opened,
  });
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<AiModel<ModelType> | null>(null);

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={(id) => {
        setSelectedOption(options.find((item) => item.id === id) ?? null);
        onChange(id);

        combobox.closeDropdown();
      }}
    >
      <Combobox.Target>
        <InputBase
          label={t('publishPage.publishModal.modelSelect.title')}
          description={t('publishPage.publishModal.modelSelect.description')}
          component='button'
          type='button'
          pointer
          rightSection={<Combobox.Chevron />}
          rightSectionPointerEvents='none'
          multiline
          onClick={() => combobox.toggleDropdown()}
          data-testid={testIds.input}
        >
          {selectedOption ? (
            <ModelSelectOption {...selectedOption} placeholder />
          ) : (
            <Input.Placeholder>{t('publishPage.publishModal.modelSelect.placeholder')}</Input.Placeholder>
          )}
        </InputBase>
      </Combobox.Target>

      <Combobox.Dropdown>
        <Combobox.Options data-testid={testIds.dropdown}>
          {options.map((item) => (
            <Combobox.Option value={item.id} key={item.id}>
              <ModelSelectOption {...item} checked={item.id === selectedOption?.id} />
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
});
