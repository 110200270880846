import { Flex, Group, Skeleton, Stack } from '@mantine/core';

const rows = Array.from({ length: 10 }, (_, i) => i.toString());
const circles = Array.from({ length: 3 }, (_, i) => i.toString());

export const SkeletonPage = () => (
  <Stack justify='space-between' p='10%' h='100%' w='100%'>
    <Flex justify='space-between' align='start'>
      <Skeleton animate={false} height={60} width={300} />
      <Group>
        {circles.map((circle) => (
          <Skeleton key={circle} animate={false} circle height={60} />
        ))}
      </Group>
    </Flex>
    {rows.map((row) => (
      <Skeleton key={row} animate={false} height={30} width='100%' />
    ))}
  </Stack>
);
