import { Button, Group, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle } from '@tabler/icons-react';
import { ModalBase } from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiDataSourceSamplesType, useAiDataSourcesStore } from 'stores/aiPlatform';

interface AbortGenerationModalProps {
  opened: boolean;
  samplesType: AiDataSourceSamplesType;
  generationId: string;
  close: () => void;
}

export const testIds = {
  modal: 'abort-generation-modal',
  message: 'abort-generation-modal-message',
  cancel: 'abort-generation-modal-cancel',
  abort: 'abort-generation-modal-submit',
};

export const AbortGenerationModal = ({ opened, close, samplesType, generationId }: AbortGenerationModalProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { abortGeneration } = useAiDataSourcesStore(['abortGeneration']);
  const { t } = useTranslation();
  const samplesTypeLabel = t(`dataGenerationPage.abortModal.samplesType.${samplesType}`);

  const onSubmit = async () => {
    setSubmitting(true);
    const success = await abortGeneration(generationId);
    setSubmitting(false);
    if (!success) {
      notifications.show({
        color: 'red',
        title: t('dataGenerationPage.abortModal.errorMessage'),
        icon: <IconAlertTriangle />,
        message: t('common.tryAgain'),
      });
    } else {
      close();
    }
  };

  return (
    <ModalBase
      opened={opened}
      title={t('dataGenerationPage.abortModal.title')}
      variant='warning'
      onClose={close}
      data-testid={testIds.modal}
    >
      <Text data-testid={testIds.message}>
        {t('dataGenerationPage.abortModal.message', { samplesType: samplesTypeLabel })}
      </Text>
      <Group justify='end'>
        <Button variant='subtle' color='gray.7' onClick={close} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='submit' loading={submitting} onClick={onSubmit} data-testid={testIds.abort}>
          {t('dataGenerationPage.abortModal.abortButton')}
        </Button>
      </Group>
    </ModalBase>
  );
};
