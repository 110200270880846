import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Text } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';
import { Policy } from 'stores/userMgmt';

interface DeletePolicyModalProps {
  opened: boolean;
  policy: Policy;
  onDelete: () => Promise<boolean>;
  onClose: () => void;
}

export const testIds = {
  title: modalBaseTestIds.title,
  deleteButton: 'policy-table-delete-modal-delete',
  cancelButton: 'policy-table-delete-modal-cancel',
  message: 'policy-table-delete-modal-message',
};

export const DeletePolicyModal = ({ opened, policy, onDelete, onClose }: DeletePolicyModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const response = await onDelete();
    setLoading(false);
    if (response) onClose();
  };

  return (
    <ModalBase
      color='red'
      opened={opened}
      title={t('userMgmt.policies.deletePolicy.title', { name: policy.name })}
      onClose={onClose}
    >
      <Text data-testid={testIds.message}>{t('userMgmt.policies.deletePolicy.message', { name: policy.name })}</Text>
      <Group justify='end' mt='lg'>
        <Button variant='subtle' onClick={onClose} disabled={loading} data-testid={testIds.cancelButton}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} loading={loading} data-autofocus data-testid={testIds.deleteButton}>
          {t('common.delete')}
        </Button>
      </Group>
    </ModalBase>
  );
};
