import { Menu } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface LogoutOptionProps {
  onClick: () => void;
}

export const testIds = {
  option: 'logout-option',
};

export const LogoutOption = ({ onClick }: LogoutOptionProps) => {
  const { t } = useTranslation();

  return (
    <Menu.Item leftSection={<IconLogout height={14} width={14} />} onClick={onClick} data-testid={testIds.option}>
      {t('header.userMenu.logout')}
    </Menu.Item>
  );
};
