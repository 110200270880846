import { testIds as timeSingleInputTestIds } from 'components/TimeSingleInput/TimeSingleInput';
import { testIds as dateRangeInputTestIds } from './DateRangeInput';
import { testIds as dateSingleInputTestIds } from './DateSingleInput';
import { testIds as numberRangeInputTestIds } from './NumberRangeInput';
import { testIds as numberSingleInputTestIds } from './NumberSingleInput';
import { testIds as timeRangeInputTestIds } from './TimeRangeInput';
import { ValidationMapping, testIds as mappingTestIds } from './ValidationMapping';

const testIds = {
  inputs: {
    time: {
      singleInput: timeSingleInputTestIds,
      rangeInput: timeRangeInputTestIds,
    },
    date: {
      rangeInput: dateRangeInputTestIds,
      singleInput: dateSingleInputTestIds,
    },
    number: {
      rangeInput: numberRangeInputTestIds,
      singleInput: numberSingleInputTestIds,
    },
  },
  mapping: mappingTestIds,
};

export { ValidationMapping, testIds };
export * from './ValidationItemInput';
