import { Flex, ThemeIcon, Tooltip, CopyButton as CopyButtonComponent, rem } from '@mantine/core';
import { IconCheck, IconInfoCircle } from '@tabler/icons-react';
import { PropsWithChildren } from 'react';

interface InfoTipProps extends PropsWithChildren {
  value?: string;
  isCopyEnabled?: boolean;
}

export const testIds = {
  tooltip: 'info-tip-tooltip',
  icon: 'info-tip-icon',
  hoverable: 'info-tip-hoverable',
};

export const InfoTip = ({ value, isCopyEnabled = false, children, ...props }: InfoTipProps) => (
  <Tooltip label={children} maw={200} multiline data-testid={testIds.tooltip}>
    <ThemeIcon data-testid={testIds.hoverable} {...props} color='dark.2' size='sm' variant='transparent'>
      <CopyButtonComponent value={value || (children as string)} timeout={2000}>
        {({ copied, copy }) => (
          <Flex onClick={isCopyEnabled ? copy : () => {}}>
            {copied ? (
              <IconCheck style={{ width: rem(16) }} />
            ) : (
              <IconInfoCircle size={16} data-testid={testIds.icon} style={isCopyEnabled ? { cursor: 'pointer' } : {}} />
            )}
          </Flex>
        )}
      </CopyButtonComponent>
    </ThemeIcon>
  </Tooltip>
);
