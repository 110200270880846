import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { I18nextProvider } from 'react-i18next';
import { theme } from 'assets';
import { AppRoutes } from 'routes';
import i18n from 'services/i18n';

import '@mantine/core/styles.layer.css';
import '@mantine/notifications/styles.layer.css';
import '@mantine/dates/styles.layer.css';
import '@mantine/dropzone/styles.layer.css';
import 'mantine-react-table/styles.css';

export const App = () => (
  <>
    <ColorSchemeScript defaultColorScheme='auto' />
    <MantineProvider theme={theme} defaultColorScheme='auto'>
      <I18nextProvider i18n={i18n}>
        <AppRoutes />
        <Notifications position='top-center' autoClose={10000} />
      </I18nextProvider>
    </MantineProvider>
  </>
);
