import { ComponentProps, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center, Flex, Loader, Modal, ScrollArea } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { EmptyState, modalBaseTestIds } from 'components';
import { CustomerId, Customer, CreateCustomerPayload } from 'stores/customers';
import { CustomerModalLayoutToggle } from './CustomerModalLayoutToggle';
import { CustomerCard, testIds as cardTestIds } from './CustomerCard';
import { CustomerEditModal, testIds as editModalTestIds } from '../CustomerEditModal/CustomerEditModal';
import { GridLayout } from './CustomerModal.types';
import classes from './CustomerModal.module.css';

interface CustomerModalProps {
  closeable?: boolean;
  customers: Customer[];
  loading?: boolean;
  opened: boolean;
  onClose: () => void;
  onCreate: (payload: CreateCustomerPayload) => Promise<boolean>;
  onSelect: (customerId: CustomerId) => void;
}

export const testIds = {
  title: 'customer-modal-title',
  grid: 'customer-modal-grid',
  closeButton: 'customer-modal-close-button',
  layoutToggle: 'customer-modal-layout-toggle',
  emptyState: 'customer-modal-empty-state',
  loader: 'customer-modal-loader',
  editModal: editModalTestIds,
  card: cardTestIds,
  create: {
    button: 'customer-modal-create-button',
  },
};

export const CustomerModal = ({
  closeable = true,
  customers,
  loading = false,
  opened,
  onClose,
  onCreate,
  onSelect,
}: CustomerModalProps) => {
  const { t } = useTranslation();
  const [layout, setLayout] = useState<GridLayout>('grid');
  const [editOpened, { open: openEdit, close: closeEdit }] = useDisclosure();
  const onToggleLayout = () => setLayout((current) => (current === 'grid' ? 'list' : 'grid'));

  const onSubmit: ComponentProps<typeof CustomerEditModal>['onSubmit'] = async (payload) =>
    // TODO add edit case here
    onCreate(payload as CreateCustomerPayload);

  return (
    <Modal.Root
      closeOnClickOutside={closeable}
      closeOnEscape={closeable}
      centered
      opened={opened}
      size={739}
      trapFocus={false}
      onClose={onClose}
    >
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title data-testid={testIds.title}>{t('customersPage.title')}</Modal.Title>
          {closeable && <Modal.CloseButton data-testid={modalBaseTestIds.close} />}
        </Modal.Header>
        <Modal.Body p='zero'>
          <Flex justify='end' px='md' gap='md'>
            <Button leftSection={<IconPlus />} size='sm' onClick={openEdit} data-testid={testIds.create.button}>
              {t('common.client')}
            </Button>
            <CustomerModalLayoutToggle layout={layout} onChange={onToggleLayout} />
          </Flex>
          {loading && (
            <Center my='xl'>
              <Loader type='bars' data-testid={testIds.loader} />
            </Center>
          )}
          <ScrollArea classNames={{ viewport: 'p-4 max-h-[500px]' }}>
            <Box className={layout === 'grid' ? classes.grid : classes.list} data-testid={testIds.grid}>
              {customers.map((customer) => (
                <CustomerCard key={customer.id} customer={customer} onClick={() => onSelect(customer.id)} />
              ))}
            </Box>
          </ScrollArea>
          {!customers.length && !loading && (
            <EmptyState
              title={t('customersPage.emptyState.title')}
              message={t('customersPage.emptyState.message')}
              data-testid={testIds.emptyState}
            />
          )}
        </Modal.Body>
        <CustomerEditModal opened={editOpened} onClose={closeEdit} onSubmit={onSubmit} />
      </Modal.Content>
    </Modal.Root>
  );
};
