export enum ENVIRONMENTS {
  PROD = 'PROD',
  INTERNAL_DEV = 'internal-dev',
  INTERNAL_QA = 'internal-qa',
  INTERNAL_AUTO = 'internal-auto',
}

enum ENVIRONMENT_NAME {
  PROD = 'prod',
  DEV = 'dev',
  QA = 'qa',
  AUTO = 'auto',
}

export const getCurrentEnv = () => {
  const { origin } = window.location;
  const envName = origin.split('.')[1];
  switch (envName) {
    case ENVIRONMENT_NAME.DEV:
      return ENVIRONMENTS.INTERNAL_DEV;
    case ENVIRONMENT_NAME.QA:
      return ENVIRONMENTS.INTERNAL_QA;
    case ENVIRONMENT_NAME.AUTO:
      return ENVIRONMENTS.INTERNAL_AUTO;
    default:
      return ENVIRONMENTS.INTERNAL_DEV;
  }
};

// @TODO: Hard coded option ID `currentEnvOptionId` because environment select is locked to use current environment.
// To be changed when environment list API is implemented
export const currentEnvOptionId = '1';
export const getPublishEnvOptions = () => [{ value: currentEnvOptionId, label: getCurrentEnv() as string }];
