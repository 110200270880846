import { NavLink } from '@mantine/core';
import { Link } from 'react-router-dom';
import { ComponentType } from 'react';
import classes from '../FlowLayout.module.css';

interface ChildNavLinkProps {
  Icon?: ComponentType<any>;
  label: string;
  to: string;
  active?: boolean;
  disabled?: boolean;
}

export const testIds = {
  link: 'flow-sidebar-child-nav-link',
};

export const ChildNavLink = ({ Icon, label, to, active, disabled, ...props }: ChildNavLinkProps) => (
  <NavLink
    active={active}
    className={classes.navLink}
    component={Link}
    disabled={disabled}
    leftSection={Icon && <Icon height={24} stroke={1} />}
    label={label}
    to={to}
    {...props}
  />
);
