import { Box, Flex, Space, Stack, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

interface EnvironmentSelectOptionProps {
  checked?: boolean;
  id: string;
  label: string;
}

export const testIds = {
  icon: 'environment-select-option-icon',
  user: 'environment-select-option-user',
  date: 'environment-select-option-date',
  getOptionTestSettingsTip: (id: string) => `environment-select-option-tip--${id}`,
  getOptionTestId: (id: string) => `environment-select-option--${id}`,
};

export const EnvironmentSelectOption = ({ id, checked, label }: EnvironmentSelectOptionProps) => (
  <Flex data-testid={testIds.getOptionTestId(id)}>
    <Box w={18} mx='tn'>
      {checked && <IconCheck size={18} data-testid={testIds.icon} />}
    </Box>
    <Stack gap={0}>
      <Text size='sm' data-testid={testIds.user}>{`${label}`}</Text>
    </Stack>
    <Space className='grow' />
  </Flex>
);
