import { useState } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Group, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { ModalBase, modalBaseTestIds } from 'components';
import { useFlowStore } from 'stores/flows';
import { Permission } from 'stores/userMgmt';
import {
  PermissionActionInput,
  PermissionResourceInput,
  PermissionServiceInput,
  actionsTestIds,
  resourcesTestIds,
  servicesTestIds,
} from './components';

interface EditPermissionModalProps {
  permission?: Permission;
  opened: boolean;
  onClose: () => void;
  onSubmit: (permission: Permission) => Promise<boolean>;
}

interface PermissionFormState {
  service: string;
  actions: string[];
  resources: string[];
}

// TODO replace when actual real resources become available
export const actions = ['Read'];
export const services = ['Flows'];

export const testIds = {
  title: modalBaseTestIds.title,
  close: modalBaseTestIds.close,
  serviceInput: servicesTestIds,
  actionsInput: actionsTestIds,
  resourcesInput: resourcesTestIds,
  submit: 'edit-permission-modal-submit',
};

export const EditPermissionModal = ({ permission, opened, onClose, onSubmit }: EditPermissionModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const flows = useFlowStore((state) => Object.values(state.flows));
  const form = useForm<PermissionFormState>({
    initialValues: {
      service: permission?.resource ?? services[0],
      actions: permission?.actions ?? actions,
      resources: (permission?.pattern ?? [])[0] === '*' ? flows.map((flow) => flow.id) : (permission?.pattern ?? []),
    },
  });

  const title = permission
    ? t('userMgmt.policies.editPermission.edit.title')
    : t('userMgmt.policies.editPermission.create.title');

  const getPayload = (values: PermissionFormState): Permission => ({
    id: permission?.id ?? nanoid(),
    resource: values.service,
    actions: values.actions,
    pattern: values.resources.length === flows.length ? ['*'] : values.resources,
  });

  const onSubmitForm = async (values: PermissionFormState) => {
    if (!permission || form.isDirty()) {
      setLoading(true);
      const payload = getPayload(values);
      await onSubmit(payload);
      setLoading(false);
      form.reset();
    }
    onClose();
  };

  return (
    <ModalBase opened={opened} closable={!loading} title={title} onClose={onClose}>
      <Box component='form' onSubmit={form.onSubmit(onSubmitForm)}>
        <Stack>
          <PermissionServiceInput services={services} {...form.getInputProps('service')} />
          <Divider />
          <PermissionActionInput actions={actions} {...form.getInputProps('actions')} />
          <Divider />
          <PermissionResourceInput resources={flows} {...form.getInputProps('resources')} />
          <Group justify='end'>
            <Button loading={loading} type='submit' data-testid={testIds.submit}>
              {t('common.apply')}
            </Button>
          </Group>
        </Stack>
      </Box>
    </ModalBase>
  );
};
