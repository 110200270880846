export const getTrainingExecutionQuery = `
  query GetTrainingExecution($executionId: ID!) {
    getTrainingExecution(executionId: $executionId) {
      executionId
      startTimestamp
      endTimestamp
      state
      triggeredBy
      currentSteps {
        state
        stepName
      }
      previousSteps {
        state
        stepName
      }
      trainedModels {
        modelType
      }
    }
  }
`;

export const listTrainingExecutionsQuery = `
  query ListTrainingExecutions($listTrainingExecutionsInput: ListTrainingExecutionsInput!) {
    listTrainingExecutions(listTrainingExecutionsInput: $listTrainingExecutionsInput) {
      paginationToken
      trainingExecutions {
        executionId
        startTimestamp
        endTimestamp
        state
        triggeredBy
        currentSteps {
          state
          stepName
        }
        previousSteps {
          state
          stepName
        }
        trainedModels {
          modelType
        }
      }
    }
  }
`;
