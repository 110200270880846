import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components';
import { Group } from 'stores/userMgmt';

export const testIds = { wrapper: 'group-table-empty-state' };

export const GroupTableEmptyState: NonNullable<MRT_TableOptions<Group>['renderEmptyRowsFallback']> = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('userMgmt.groups.table.emptyState.title')}
      message={t('userMgmt.groups.table.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
