import { GetTrainingExecutionQuery, ModelType, State, Step, StepName } from '@ai-platform/common-types';
import { keyBy } from 'utils';
import { StartTrainModel } from '../aiPlatformBackendTypes';
import { AiModel, AiModelStatus, TrainingSteps, TrainingStepStatus } from './aiModels.types';

export const modelTypeToStartTrainModelType: Record<ModelType, StartTrainModel> = {
  [ModelType.ASR]: StartTrainModel.asr,
  [ModelType.NLP]: StartTrainModel.nlp,
};

const stateToModelStatus: Record<State, AiModelStatus> = {
  [State.DONE]: AiModelStatus.COMPLETED,
  [State.STARTED]: AiModelStatus.IN_TRAINING,
  [State.FAILED]: AiModelStatus.FAILED,
  [State.ABORTED]: AiModelStatus.ABORTED,
  [State.DECLINED]: AiModelStatus.DECLINED,
};

const stateToTrainingStatus: Record<State, TrainingStepStatus> = {
  [State.DONE]: TrainingStepStatus.COMPLETED,
  [State.STARTED]: TrainingStepStatus.IN_PROGRESS,
  [State.FAILED]: TrainingStepStatus.FAILED,
  [State.ABORTED]: TrainingStepStatus.FAILED,
  [State.DECLINED]: TrainingStepStatus.FAILED,
};

const trainingStepToStepName: Record<keyof Omit<TrainingSteps, 'warmup'>, Record<ModelType, StepName>> = {
  dataset: {
    [ModelType.ASR]: StepName.PREPROCESS_SPEECH_SAMPLES,
    [ModelType.NLP]: StepName.PREPROCESS_TEXT_SAMPLES,
  },
  train: {
    [ModelType.ASR]: StepName.TRAIN_ASR_MODEL,
    [ModelType.NLP]: StepName.TRAIN_NLP_MODEL,
  },
  test: {
    [ModelType.ASR]: StepName.EVALUATE_ASR_MODEL,
    [ModelType.NLP]: StepName.EVALUATE_NLP_MODEL,
  },
};

const modelTypeMap: Record<ModelType, ModelType> = {
  [ModelType.ASR]: ModelType.ASR,
  [ModelType.NLP]: ModelType.NLP,
};

const getTrainingStepStatus = (
  stepsMap: Record<string, Step>,
  trainingStep: keyof Omit<TrainingSteps, 'warmup'>,
  type: ModelType,
) => {
  const stepName = trainingStepToStepName[trainingStep][type];
  const state = stepsMap[stepName]?.state;
  return stateToTrainingStatus[state] ?? TrainingStepStatus.PENDING;
};

export const aiModelFromTrainingExecution = (
  execution: GetTrainingExecutionQuery['getTrainingExecution'],
): AiModel | undefined => {
  if (!execution) return undefined;

  const stepsMap = keyBy([...(execution.currentSteps ?? []), ...(execution.previousSteps ?? [])], 'stepName');
  const type: ModelType = modelTypeMap[execution.trainedModels?.[0]?.modelType ?? ModelType.ASR];

  return {
    id: execution.executionId,
    createdAt: execution.startTimestamp ?? NaN,
    // TODO: construct user identity
    createdBy: {
      userId: execution.triggeredBy,
      givenName: execution.triggeredBy,
      familyName: '',
    },
    status: stateToModelStatus[execution.state],
    type,
    trainingSteps: {
      warmup: {
        status: Object.keys(stepsMap).length ? TrainingStepStatus.COMPLETED : stateToTrainingStatus[execution.state],
      },
      dataset: {
        status: getTrainingStepStatus(stepsMap, 'dataset', type),
        // TODO: Add the following in response
        trainingUri: '',
        testingUri: '',
        evaluationUri: '',
      },
      train: {
        status: getTrainingStepStatus(stepsMap, 'train', type),
        // TODO: Add the following in response
        results: [],
      },
      test: {
        status: getTrainingStepStatus(stepsMap, 'test', type),
        // TODO: Add the following in response
        results: {
          labels: [],
        },
        failedSamplesCount: 0,
      },
    },
  };
};
