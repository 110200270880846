import { IconFingerprint } from '@tabler/icons-react';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { ActionButton } from 'components';
import { FlowMetadataField } from 'stores/wizard';
import { MetadataTableState } from '../../MetadataTable.types';

export const testIds = {
  button: 'metadata-table-unique-id-button',
  icon: 'metadata-table-unique-id-button-icon',
};

export const UniqueIdentifierCell: MRT_ColumnDef<FlowMetadataField>['Cell'] = ({ row, table }) => {
  const { t } = useTranslation();
  const { uniqueIdFieldId, onUpdateUniqueId } = table.getState() as MetadataTableState;
  const isUniqueIdField = row.id === uniqueIdFieldId;

  return (
    <ActionButton
      label={t('wizard.steps.settings.metadata.table.uniqueIdentifierField.buttonTooltip')}
      color={isUniqueIdField ? 'blue' : 'gray.3'}
      role='checkbox'
      onClick={() => onUpdateUniqueId(row.id)}
      aria-checked={isUniqueIdField}
      data-testid={testIds.button}
    >
      <IconFingerprint data-testid={testIds.icon} />
    </ActionButton>
  );
};
