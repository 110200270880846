import { StateCreator } from 'zustand';
import { flowStore } from 'stores/flows';
import { FlowStatus } from '@jargonic/flows-types';
import { authStore } from 'stores/auth';
import { ValidationOopsie, WizardStoreActions, WizardStoreState } from './wizard.types';
import { getUpdatedEditors } from './wizard.utils';

function getInitialState<Data>(initialData: Data): WizardStoreState<Data> {
  return {
    data: initialData,
    fetched: false,
    dirty: false,
    loading: false,
  };
}

type CombinedWizardState<Data> = WizardStoreState<Data> & WizardStoreActions;
type WizardStoreSliceCreator<Data, StoreState extends CombinedWizardState<Data>> = StateCreator<
  StoreState,
  [['zustand/immer', never]],
  [],
  CombinedWizardState<Data>
>;

interface WizardSliceArgs<Data, ParsedData = any> {
  initialData: Data;
  sessionStorageKey: string;
  fetchData: (customerId: string, flowId: string) => Promise<Data | undefined>;
  saveData: (customerId: string, flowId: string, data: Data) => Promise<boolean>;
  validateData: (data: Data) => ValidationOopsie[];
  sliceArgs: Parameters<WizardStoreSliceCreator<Data, CombinedWizardState<Data>>>;
  formatData?: {
    fromSessionStorage: (data: ParsedData) => Data;
    toSessionStorage: (data: Data) => ParsedData;
  };
}

export function createWizardStoreSlice<Data>({
  initialData,
  sessionStorageKey,
  fetchData,
  saveData,
  validateData,
  sliceArgs,
  formatData,
}: WizardSliceArgs<Data>) {
  const initialState = getInitialState(initialData);
  const sliceCreator: WizardStoreSliceCreator<Data, CombinedWizardState<Data>> = (set, get) => ({
    ...initialState,
    fetchData: async (customerId, flowId) => {
      set({ loading: true });
      const data = await fetchData(customerId, flowId);
      if (data) set({ data, fetched: true });
      set({ loading: false });
      return Boolean(data);
    },
    saveDraft: async (customerId, flowId) => {
      const { data, dirty } = get();
      if (!dirty) return true;
      const response = await saveData(customerId, flowId, data);
      if (response) {
        const { flows, updateFlows } = flowStore.getState();
        const flow = flows[flowId];
        const { me } = authStore.getState();
        const oldEditors = flow?.editors ?? [];
        const newEditors = me ? getUpdatedEditors(oldEditors, me) : oldEditors;
        updateFlows([
          {
            id: flowId,
            status: FlowStatus.DRAFT,
            updatedAt: Date.now(),
            editors: newEditors,
          },
        ]);
        set({ dirty: false });
      }
      return response;
    },
    startDraft: () => {
      const data = formatData ? formatData.toSessionStorage(get().data) : get().data;
      sessionStorage.setItem(sessionStorageKey, JSON.stringify(data));
    },
    endDraft: () => {
      sessionStorage.removeItem(sessionStorageKey);
    },
    validate: () => {
      const { data } = get();
      return validateData(data);
    },
    revertChanges: () => {
      const parsedData = JSON.parse(sessionStorage.getItem(sessionStorageKey)!);
      const data = formatData ? formatData.fromSessionStorage(parsedData) : parsedData;
      if (data) set({ data, dirty: false });
    },
    reset: () => set(initialState),
  });
  return sliceCreator(...sliceArgs);
}
