import { useCallback, useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useParams, useSearchParams } from 'react-router-dom';
import { GroupRouteParams } from 'routes/routes.config';
import { UpdateGroupPayload, usePolicyArray, useUserMgmtStore } from 'stores/userMgmt';
import cn from 'classnames';
import {
  PoliciesTableActions,
  PoliciesTableEmptyState,
  PoliciesTableRowActions,
  actionsTestIds,
  emptyStateTestIds,
} from './components';
import classes from './PoliciesTable.module.css';
import { PoliciesTableState } from './PoliciesTable.types';
import { policiesTableDefs } from './PoliciesTable.defs';
import { columns } from './PoliciesTable.columns';

export const testIds = {
  getRowTestId: (id: string) => `policies-table-row-${id}`,
  table: 'policies-table',
  emptyState: emptyStateTestIds,
  actions: actionsTestIds,
};

export const PoliciesTable = () => {
  const { customerId, groupId } = useParams() as GroupRouteParams;
  const [params, setParams] = useSearchParams();
  const { policies, groups, upsertGroup } = useUserMgmtStore(['policies', 'groups', 'upsertGroup']);
  const search = params.get('search') || '';
  const group = groups[groupId];
  const policiesArray = usePolicyArray();
  const data = useMemo(() => group.policies.map((policy) => policies[policy.id]), [group, policies]);

  const onSearch = (value: string) => {
    setParams((prev) => {
      prev.set('search', value);
      return prev;
    });
  };

  const onChangePolicies: PoliciesTableState['onChangePolicies'] = useCallback(
    async (policiesIds: string[]) => {
      const payload: UpdateGroupPayload = {
        ...group,
        users: group.users.map((user) => user.userId),
        policies: policiesIds,
      };
      const response = await upsertGroup(customerId, { action: 'update', groupId, payload });
      return Boolean(response);
    },
    [customerId, groups],
  );

  const table = useMantineReactTable({
    data,
    columns,
    ...policiesTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: cn(classes.row, 'cursor-pointer'),
      'data-testid': testIds.getRowTestId(row.id),
    }),
    renderEmptyRowsFallback: (props) => <PoliciesTableEmptyState {...props} />,
    renderTopToolbar: (props) => <PoliciesTableActions {...props} />,
    renderRowActions: (props) => <PoliciesTableRowActions {...props} />,
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    state: {
      globalFilter: search,
      policies: policiesArray,
      group,
      onChangePolicies,
      onSearch,
    } as PoliciesTableState,
  });

  return <MantineReactTable table={table} data-testid={testIds.table} />;
};
