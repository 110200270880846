import { useMemo } from 'react';
import { useEventDefStore, useLabelStore } from 'stores/wizard';

/** Check if every label has a main event */
export function useMainEventRequirement() {
  const { labels } = useLabelStore(['labels']);
  const { data: eventDefs } = useEventDefStore(['data']);
  return useMemo(() => {
    const labelIds = Object.keys(labels);
    if (!labelIds.length) return true;
    const events = Object.values(eventDefs);
    const labelIdsWithAnyEvents = labelIds.filter((labelId) => events.some((eventDef) => eventDef.labelId === labelId));
    const mainEvents = Object.values(eventDefs).filter((eventDef) => eventDef.isMainEvent);
    if (mainEvents.length !== labelIdsWithAnyEvents.length) return false;
    return labelIdsWithAnyEvents.every((labelId) => mainEvents.some((eventDef) => eventDef.labelId === labelId));
  }, [eventDefs, labels]);
}
