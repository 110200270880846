import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FlowRouteParams, ROUTES } from 'routes/routes.config';
import { Box, Button, Flex, Group, Title } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useFlow, useFlowStore } from 'stores/flows';
import {
  ApplicationMenu,
  DeployAction,
  DownloadAction,
  FlowDetails,
  appMenuTestIds,
  deployTestIds,
  downloadTestIds,
  flowDetailsTestIds,
} from './components';

export const testIds = {
  title: 'flow-page-title',
  backButton: {
    button: 'flow-page-back-button',
    icon: 'flow-page-back-button-icon',
  },
  deploy: deployTestIds,
  download: downloadTestIds,
  details: flowDetailsTestIds,
  appMenu: appMenuTestIds,
};

export const FlowMenuPage = () => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as FlowRouteParams;
  const { loading } = useFlowStore(['loading']);
  const flow = useFlow(flowId);

  const shouldDisplay = flow && !loading;

  return (
    <Box p='xl'>
      <Group justify='space-between'>
        <Button
          component={Link}
          to={ROUTES.FLOWS(customerId)}
          variant='subtle'
          leftSection={<IconArrowLeft data-testid={testIds.backButton.icon} />}
          data-testid={testIds.backButton.button}
        >
          {t('flowPage.backButton')}
        </Button>
        <Group>
          <DownloadAction />
          <DeployAction />
        </Group>
      </Group>
      {!shouldDisplay ? null : (
        <Flex pl='md' direction='column' gap='lg' align='start'>
          <Title order={2} fw={600} data-testid={testIds.title}>
            {t('flowPage.title')}
          </Title>
          <FlowDetails />
          <ApplicationMenu />
        </Flex>
      )}
    </Box>
  );
};
