import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { Group, CreateGroupPayload, UpdateGroupPayload } from 'stores/userMgmt/userMgmt.types';

const groupsUrl = (customerId: string) => `${config.jargonicApiUrl}/${customerId}/groups`;
const groupUrl = (customerId: string, groupId: string) => `${groupsUrl(customerId)}/${groupId}`;

export const groupApi = {
  getGroups: async (customerId: string): Promise<Group[] | undefined> => {
    const { data } = await hermes.get<Group[]>(groupsUrl(customerId));
    return data;
  },

  createGroup: async (customerId: string, payload: CreateGroupPayload): Promise<Group | undefined> => {
    const { data } = await hermes.post<Group>(groupsUrl(customerId), payload);
    return data;
  },

  updateGroup: async (customerId: string, groupId: string, payload: UpdateGroupPayload): Promise<Group | undefined> => {
    const { data } = await hermes.put<Group>(groupUrl(customerId, groupId), payload);
    return data;
  },

  deleteGroup: async (customerId: string, groupId: string): Promise<boolean> => {
    const { data } = await hermes.delete(groupUrl(customerId, groupId));
    return data;
  },
};
