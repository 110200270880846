import { MRT_ColumnDef } from 'mantine-react-table';
import { FlowMetadataField } from 'stores/wizard';
import { Switch } from '@mantine/core';
import { MetadataTableState } from '../../MetadataTable.types';

export const testIds = {
  input: 'metadata-table-exposed-switch-input',
};

export const ExposedCell: MRT_ColumnDef<FlowMetadataField>['Cell'] = ({ row, table }) => {
  const { onUpdateExposedField } = table.getState() as MetadataTableState;

  return (
    <Switch checked={row.original.exposed} onClick={() => onUpdateExposedField(row.id)} data-testid={testIds.input} />
  );
};
