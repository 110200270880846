import { User } from '@jargonic/customer-types';
import { GetDataGenerationQuery } from '@ai-platform/common-types';
import { TextDataSourceSample, VoiceDataSourceSample } from '@ai-platform/data-generation-types';
import { DataGenUISettings } from '../aiPlatformBackendTypes';

export { State as DataSourceState } from '@ai-platform/common-types';

export type AiDataSourceId = string;

export enum AiDataSourceSamplesType {
  VOICE = 'VOICE',
  TEXT = 'TEXT',
  BOTH = 'BOTH',
}

export enum AiDataSourceStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  ABORTED = 'ABORTED',
  DECLINED = 'DECLINED',
}

export enum AiDataSourceStep {
  GET_FLOW_SETTINGS = 'GET_FLOW_SETTINGS',
  GENERATE_AND_STORE_TEXT_SAMPLES = 'GENERATE_AND_STORE_TEXT_SAMPLES',
  GENERATE_AND_STORE_SPEECH_SAMPLES = 'GENERATE_AND_STORE_SPEECH_SAMPLES',
  PROVISIONING_DATA_FILTERING_NOTEBOOK = 'PROVISIONING_DATA_FILTERING_NOTEBOOK',
  DATA_GENERATION_DONE = 'DATA_GENERATION_DONE',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export interface AiDataSourceUIModel {
  id: AiDataSourceId;
  createdBy: User;
  createdAt?: number;
  endedAt?: number;
  status: AiDataSourceStatus;
  step: AiDataSourceStep;
  samplesType: AiDataSourceSamplesType;
  isSamplesGeneration: boolean;
  settings: DataGenUISettings;
  samplesCount: number;
  onlyTextSamples: boolean;
  onlyVoiceSamples: boolean;
}

export interface AiDataTextSample extends TextDataSourceSample {
  id: string;
}
export interface AiDataVoiceSample extends VoiceDataSourceSample {
  id: string;
}

export type ApiAiDataSource = GetDataGenerationQuery['getDataGeneration'];
export interface AiDataSourceSubscriptionOptions {
  unsubscribeOnFinish?: boolean;
  onComplete?: () => void;
  onFail?: () => void;
}
