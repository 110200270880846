import { Badge, Box, Flex, useComputedColorScheme, useMantineColorScheme } from '@mantine/core';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserAvatar } from 'components';
import { CustomerRouteParams, ROUTES } from 'routes/routes.config';
import { useCustomerStore } from 'stores/customers';
import { useCurrentUser } from 'stores/auth';
import { ComponentProps, useEffect, useMemo } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { config } from 'services/config';
import { CustomerSelect, testIds as customerSelectTestIds } from './CustomerSelect/CustomerSelect';
import { CustomerModal, testIds as customerModalTestIds } from './CustomerModal/CustomerModal';
import { LocationTabs, testIds as tabsTestIds } from './LocationTabs/LocationTabs';
import { UserMenu, testIds as userMenuTestIds } from './UserMenu/UserMenu';
import classes from './AppLayout.module.css';

export const testIds = {
  header: 'app-header',
  logo: 'app-header-logo',
  customer: 'app-header-customer',
  avatar: 'app-header-avatar',
  betaBanner: 'app-header-beta-banner',
  customerSelect: customerSelectTestIds,
  customerModal: customerModalTestIds,
  locationTabs: tabsTestIds,
  userMenu: userMenuTestIds,
};

export const AppHeader = () => {
  const { pathname } = useLocation();
  const { customerId } = useParams() as CustomerRouteParams;
  const navigate = useNavigate();
  const colorScheme = useComputedColorScheme('light');
  const { toggleColorScheme } = useMantineColorScheme();
  const { customers, loading, createCustomer } = useCustomerStore(['customers', 'loading', 'createCustomer']);
  const customerArray = useMemo(() => Object.values(customers), [customers]);
  const currentUser = useCurrentUser();
  const [customerModalOpen, { open, close }] = useDisclosure();
  const mustSelectCustomer = pathname === ROUTES.CUSTOMERS;

  const onSelectCustomer = (id: string) => {
    navigate(ROUTES.FLOWS(id));
    close();
  };

  const onSelectTab = (location: string) => navigate(location);

  const onLogout = () => {
    const url = `${config.jargonicApiUrl}/users/logout`;
    window.location.replace(url);
  };

  const onCreate: ComponentProps<typeof CustomerModal>['onCreate'] = async (payload) => {
    const response = await createCustomer(payload);
    return Boolean(response);
  };

  useEffect(() => {
    if (mustSelectCustomer) open();
  }, [mustSelectCustomer]);

  return (
    <Box component='header' className={classes.header} data-testid={testIds.header}>
      <Flex align='center' justify='space-between' gap='md' w='100%'>
        <CustomerSelect
          customers={customerArray}
          selectedCustomerId={customerId}
          onSelectCustomer={onSelectCustomer}
          onViewAll={open}
        />
        <Badge color='teal' mr='auto' variant='light' data-testid={testIds.betaBanner}>
          BETA
        </Badge>
        {currentUser && (
          <UserMenu
            user={currentUser}
            colorScheme={colorScheme}
            onChangeColorScheme={toggleColorScheme}
            onLogout={onLogout}
          >
            <UserAvatar className='cursor-pointer' user={currentUser} ml='teeny' data-testid={testIds.avatar} />
          </UserMenu>
        )}
        <CustomerModal
          closeable={!mustSelectCustomer}
          customers={customerArray}
          loading={loading}
          opened={customerModalOpen}
          onClose={close}
          onCreate={onCreate}
          onSelect={onSelectCustomer}
        />
      </Flex>
      <LocationTabs
        className='absolute left-1/2 top-0 -translate-x-1/2 h-full'
        location={pathname}
        customerId={customerId}
        onChange={onSelectTab}
      />
    </Box>
  );
};
