import { ModelType } from '@ai-platform/common-types';
import cn from 'classnames';
import {
  MRT_Row,
  MRT_RowVirtualizer,
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { FC, UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { PublishRouteParams } from 'routes/routes.config';
import {
  AiPublishedModel,
  useAiModelsByType,
  useLastPublishedModel,
  usePaginationToken,
} from 'stores/aiPlatform/aiPublishedModels';
import { useAiPublishedModelStore } from 'stores/aiPlatform/aiPublishedModels/aiPublishedModels.store';
import { useFlow } from 'stores/flows';
import { columns, testIds as columnsTestIds } from './PublishPageTable.columns';
import { initialSorting, publishPageTableDefs } from './PublishPageTable.defs';
import {
  PublishPageTableEmptyState,
  PublishPageTableRowActions,
  emptyStateTestIds,
  rowActionsTestIds,
} from './components';

import classes from './PublishPageTable.module.css';

export const testIds = {
  table: 'published-model-table',
  getRowTestId: (id: string) => `published-model-table-row-${id}`,
  rowRegex: /published-model-table-row-[^-]+$/,
  emptyState: emptyStateTestIds,
  rowActions: rowActionsTestIds,
  columns: columnsTestIds,
  pagination: 'ai-published-pagination',
};

type PublishPageTableProps = {
  classNames?: {
    table?: string;
  };
};

export const PublishPageTable: FC<PublishPageTableProps> = ({ classNames }) => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const modelType = params.get('modelType') as ModelType;
  const models = useAiModelsByType(modelType);
  const { customerId, flowId } = useParams() as PublishRouteParams;
  const flow = useFlow(flowId);
  const { fetchPublishedModels, loading } = useAiPublishedModelStore(['fetchPublishedModels', 'loading']);
  const [sorting, setSorting] = useState<MRT_SortingState>(initialSorting);
  const paginationToken = usePaginationToken();

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
        if (scrollHeight - scrollTop - clientHeight < 400 && !loading && paginationToken) {
          const tenantId = customerId.replace(/^aiola$/, 'internal-dev');

          const dto = {
            tenantId,
            activeVersion: flow.activeVersion!,
            modelType,
            flowId: flow.id,
            mainLocale: flow.mainLocale,
          };
          fetchPublishedModels(dto, paginationToken);
        }
      }
    },
    [flow, paginationToken, loading, models],
  );

  const rowVirtualizerInstanceRef = useRef<MRT_RowVirtualizer<HTMLDivElement, HTMLTableRowElement>>(null);

  useEffect(() => {
    if (rowVirtualizerInstanceRef.current) {
      try {
        rowVirtualizerInstanceRef.current.scrollToIndex(0);
      } catch (e) {
        console.error(e);
      }
    }
  }, [sorting]);

  const lastPublishedModel = useLastPublishedModel(modelType);

  const isLastPublishedModel = (row: MRT_Row<AiPublishedModel>) =>
    row.original.publishExecutionId === lastPublishedModel?.publishExecutionId;
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const table = useMantineReactTable({
    data: models || [],
    columns,
    ...publishPageTableDefs,
    enableBottomToolbar: true,
    mantineTableBodyRowProps: ({ row }) => ({
      className: cn('cursor-pointer', { [classes.lastPublishedRow]: isLastPublishedModel(row) }),
      'data-testid': testIds.getRowTestId(row.id),
      ...(isLastPublishedModel(row) ? { 'data-last-published-text': t('publishPage.table.published') } : {}),
    }),
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    renderEmptyRowsFallback: (props) => <PublishPageTableEmptyState {...props} />,
    renderRowActions: (props) => <PublishPageTableRowActions {...props} />,
    onSortingChange: setSorting,
    mantinePaperProps: { className: 'h-auto' },
    mantineTableContainerProps: {
      className: classNames?.table,
      ref: tableContainerRef, // get access to the table container element
      onScroll: (
        event: UIEvent<HTMLDivElement>, // add an event listener to the table container element
      ) => fetchMoreOnBottomReached(event.target as HTMLDivElement),
    },
    state: { isLoading: loading, showLoadingOverlay: loading, sorting },
    rowVirtualizerInstanceRef,
    renderBottomToolbar: false,
    mantineTableHeadCellProps: {
      className: classes.sortingLabel,
    },
  });

  return <MantineReactTable table={table} />;
};
