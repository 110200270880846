import { Button, Group, Text } from '@mantine/core';
import { ModalBase } from 'components';
import { useTranslation } from 'react-i18next';

interface TrainingDraftNoticeModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const testIds = {
  message: 'training-draft-notice-modal-message',
  cancel: 'training-draft-notice-modal-cancel',
  submit: 'training-draft-notice-modal-submit',
};

export const TrainingDraftNoticeModal = ({ open, onClose, onSubmit }: TrainingDraftNoticeModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalBase opened={open} title={t('trainingPage.draftNoticeModal.title')} size='lg' onClose={onClose}>
      <Text className='whitespace-pre-line' c='gray.6' my='lg' data-testid={testIds.message}>
        {t('trainingPage.draftNoticeModal.message')}
      </Text>
      <Group justify='end'>
        <Button variant='subtle' onClick={onClose} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button variant='submit' onClick={onSubmit} data-testid={testIds.submit}>
          {t('trainingPage.draftNoticeModal.submit')}
        </Button>
      </Group>
    </ModalBase>
  );
};
