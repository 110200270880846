import { useCallback } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { FlowMetadataField } from '@jargonic/flow-metadata-types';
import { FieldValuesCSVResponse } from 'stores/wizard';
import { MetadataTableActions, MetadataTableEmptyState, actionsTestIds, emptyStateTestIds } from './components';
import { MetadataTableState } from './MetadataTable.types';
import { metadataTableDefs } from './MetadataTable.defs';
import { columnTestIds, useMetadataTableColumns } from './hooks';

interface MetadataTableProps {
  fields: FlowMetadataField[];
  uniqueIdFieldId?: string;
  onDeleteFields: (fieldIds: string[]) => void;
  onNewField: () => void;
  onReorder: (fieldId: string, newOrder: number) => void;
  onUpdateField: (fieldId: string, field: Partial<FlowMetadataField>) => void;
  onUpdateUniqueId: (fieldId?: string) => void;
  onUpdateExposedField: (fieldId: string) => void;
  onUploadCSV: (fieldId: string, file: File) => Promise<FieldValuesCSVResponse>;
}

export const testIds = {
  getRowTestId: (id: string) => `metadata-table-row-${id}`,
  columns: columnTestIds,
  actions: actionsTestIds,
  emptyState: emptyStateTestIds,
};

export const MetadataTable = ({
  fields,
  uniqueIdFieldId,
  onDeleteFields,
  onNewField,
  onReorder,
  onUpdateField,
  onUpdateUniqueId,
  onUpdateExposedField,
  onUploadCSV,
}: MetadataTableProps) => {
  const onChange = useCallback((id: string, values: Partial<FlowMetadataField> = {}) => onUpdateField(id, values), []);

  const columns = useMetadataTableColumns();

  const table = useMantineReactTable({
    columns,
    data: fields,
    ...metadataTableDefs,
    // @ts-ignore
    mantineTableBodyRowProps: ({ row }) => ({
      'data-testid': testIds.getRowTestId(row.id),
    }),
    mantineRowDragHandleProps: ({ table: tbl }) => ({
      className: 'rotate-90',
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = tbl.getState();
        if (draggingRow && hoveredRow) {
          const targetOrder = fields.find((f) => f.id === hoveredRow.id)?.order;
          onReorder(draggingRow.id, targetOrder!);
        }
      },
    }),
    renderEmptyRowsFallback: (props) => <MetadataTableEmptyState {...props} />,
    renderTopToolbar: MetadataTableActions,
    state: {
      onChange,
      onDeleteFields,
      onNewField,
      onUpdateUniqueId,
      uniqueIdFieldId,
      onUpdateExposedField,
      onUploadCSV,
    } as MetadataTableState,
  });

  return <MantineReactTable table={table} />;
};
