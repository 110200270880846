import { Button, Group } from '@mantine/core';
import { IconArrowRight, IconCheck, IconRotateClockwise } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { useTranslation } from 'react-i18next';
import { ValidationOopsie } from 'stores/wizard/wizard.types';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { SaveButton, ValidationErrorListPopover, errorListTestIds, saveButtonTestIds } from './components';

interface WizardActionsProps {
  dirty: boolean;
  isFinalStep: boolean;
  loading: boolean;
  saving: boolean;
  onNext: () => void;
  onRevert: () => void;
  onSave: () => Promise<ValidationOopsie[]>;
}

export const testIds = {
  wrapper: 'wizard-actions-wrapper',
  revert: {
    button: 'wizard-actions-revert-button',
    icon: 'wizard-actions-revert-button-icon',
  },
  save: saveButtonTestIds,
  next: {
    button: 'wizard-actions-next-button',
    icon: 'wizard-actions-next-button-icon',
    doneIcon: 'wizard-actions-next-button-done-icon',
  },
  errorList: errorListTestIds,
};

export const WizardActions = ({
  dirty,
  isFinalStep,
  loading,
  saving,
  onNext,
  onRevert,
  onSave,
}: WizardActionsProps) => {
  const { t } = useTranslation();
  const [, setParams] = useSearchParams();
  const [errors, setErrors] = useState<ValidationOopsie[]>([]);

  const onClickRevert = () => {
    onRevert();
    setErrors([]);
  };

  const onClickSave = async () => {
    const validationErrors = await onSave();
    setErrors(validationErrors);
    return !validationErrors.length;
  };

  const onDismissErrorList = () => setErrors([]);
  const onClickErrorListItem = (error: ValidationOopsie) => {
    if (!error.queryParams) return;
    setParams(error.queryParams);
  };

  return (
    <Group wrap='nowrap' data-testid={testIds.wrapper}>
      <ActionButton
        color='gray.5'
        label={t('wizard.header.revertChanges')}
        onClick={onClickRevert}
        disabled={loading || saving || !dirty}
        data-testid={testIds.revert.button}
      >
        <IconRotateClockwise data-testid={testIds.revert.icon} />
      </ActionButton>
      <SaveButton disabled={loading || !dirty} loading={saving} onClick={onClickSave} />
      <ValidationErrorListPopover items={errors} onClickItem={onClickErrorListItem} onDismiss={onDismissErrorList}>
        <Button
          rightSection={
            isFinalStep ? (
              <IconCheck data-testid={testIds.next.doneIcon} />
            ) : (
              <IconArrowRight data-testid={testIds.next.icon} />
            )
          }
          onClick={onNext}
          disabled={loading || saving}
          data-testid={testIds.next.button}
        >
          {isFinalStep ? t('common.done') : t('common.next')}
        </Button>
      </ValidationErrorListPopover>
    </Group>
  );
};
