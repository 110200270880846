import { Drawer, Stack } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Label, useLabelStore } from 'stores/wizard';
import { useParams } from 'react-router-dom';
import { WizardRouteParams } from 'routes/routes.config';
import { notifications } from '@mantine/notifications';
import { IconAlertTriangle, IconCopyOff } from '@tabler/icons-react';
import { useMemo } from 'react';
import classes from './LabelManagementDrawer.module.css';
import { labelColors } from './LabelManagementDrawer.const';
import {
  EditableDynamicLabel,
  EditableStaticLabel,
  LabelListEmptyState,
  NewLabelButtons,
  dynamicLabelTestIds,
  emptyStateTestIds,
  newLabelTestIds,
  staticLabelTestIds,
} from './components';

interface LabelManagementDrawerProps {
  opened: boolean;
  onClose: () => void;
}

export const testIds = {
  drawer: 'label-management-drawer',
  dynamic: dynamicLabelTestIds,
  static: staticLabelTestIds,
  newLabel: newLabelTestIds,
  emptyState: emptyStateTestIds,
};

export const LabelManagementDrawer = ({ opened, onClose }: LabelManagementDrawerProps) => {
  const { t } = useTranslation();
  const { customerId, flowId } = useParams() as WizardRouteParams;
  const { createLabel, updateLabel, deleteLabel, labels } = useLabelStore([
    'createLabel',
    'updateLabel',
    'deleteLabel',
    'labels',
  ]);
  const labelArray = useMemo(() => Object.values(labels).sort((a, b) => a.order - b.order), [labels]);
  const hasDynamicLabels = labelArray.some((label) => label.isDynamic);
  const hasStaticLabels = labelArray.some((label) => !label.isDynamic);
  const allowDynamic = hasDynamicLabels || !labelArray.length;
  const allowStatic = hasStaticLabels || !labelArray.length;

  const onDuplicateLabelError = (name: string) =>
    notifications.show({
      icon: <IconCopyOff />,
      title: t('wizard.steps.labeling.labelManagement.duplicateError.title'),
      message: t('wizard.steps.labeling.labelManagement.duplicateError.message', { name }),
      color: 'red',
    });

  const onCreationError = () =>
    notifications.show({
      icon: <IconAlertTriangle />,
      title: t('wizard.steps.labeling.labelManagement.creationError.title'),
      message: t('wizard.steps.labeling.labelManagement.creationError.message'),
      color: 'red',
    });

  const onUpdateError = () =>
    notifications.show({
      icon: <IconAlertTriangle />,
      title: t('wizard.steps.labeling.labelManagement.updateError.title'),
      message: t('wizard.steps.labeling.labelManagement.updateError.message'),
      color: 'red',
    });

  const onDeletionError = () =>
    notifications.show({
      icon: <IconAlertTriangle />,
      title: t('wizard.steps.labeling.labelManagement.deleteError.title'),
      message: t('wizard.steps.labeling.labelManagement.deleteError.message'),
      color: 'red',
    });

  const onCreate = async (dynamic: boolean) => {
    const order = labelArray.length;
    const response = await createLabel(customerId, flowId, {
      order,
      isDynamic: dynamic,
      color: labelColors[order % labelColors.length],
    });
    if (!response) onCreationError();
  };

  const isDuplicatedName = (name: string) => labelArray.some((label) => label.name === name);

  const handleDelete = (id: string) => async () => {
    const response = await deleteLabel(customerId, flowId, id);
    if (!response) onDeletionError();
  };

  const handleEdit = (id: string) => async (label: Partial<Label>) => {
    const nameDidChange = label.name !== labels[id].name;
    if (label.name && nameDidChange && isDuplicatedName(label.name)) {
      onDuplicateLabelError(label.name);
      return false;
    }
    const response = await updateLabel(customerId, flowId, id, label);
    if (!response) onUpdateError();
    return Boolean(response);
  };

  return (
    <Drawer
      title={t('wizard.steps.labeling.labelManagement.title')}
      className={classes.drawer}
      opened={opened}
      size='md'
      withinPortal={false}
      zIndex={100}
      onClose={onClose}
      data-testid={testIds.drawer}
    >
      <Stack gap='lg'>
        <NewLabelButtons allowDynamic={allowDynamic} allowStatic={allowStatic} onCreate={onCreate} />
        {labelArray.map((label) => {
          const LabelComponent = label.isDynamic ? EditableDynamicLabel : EditableStaticLabel;
          return (
            <LabelComponent
              key={label.id}
              label={label}
              onDelete={handleDelete(label.id)}
              onEdit={handleEdit(label.id)}
            />
          );
        })}
        {labelArray.length === 0 && <LabelListEmptyState />}
      </Stack>
    </Drawer>
  );
};
