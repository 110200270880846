import InfiniteViewer, { SetOptions } from 'react-infinite-viewer';
import { Box, Image, Stack } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { useAnnotationStore, useFileAnnotations, useFileStore } from 'stores/wizard';
import Selecto, { OnSelectEnd } from 'react-selecto';
import { useImageDimensions } from './hooks/useImageDimensions';
import { AnnotatorToolbar } from './components/AnnotatorToolbar/AnnotatorToolbar';
import { AnnotationBox } from './components';
import classes from './Annotator.module.css';
import { getNewAnnotationCoordsFromSelecto, zoomLimits } from './Annotator.utils';

export const testIds = {
  image: 'annotator-image',
  viewport: 'annotator-viewport',
};

const initialCenter: SetOptions = { x: 0, y: 0, zoom: 1 };
const INITIAL_ZOOM = 1;

export const Annotator = () => {
  const { files } = useFileStore(['files']);
  const viewerRef = useRef<InfiniteViewer>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const [dragging, setDragging] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [zoom, setZoom] = useState(INITIAL_ZOOM);
  const [fileIndex, setFileIndex] = useState(0);
  const image = files[fileIndex];
  const { annotate, reposition } = useAnnotationStore(['annotate', 'reposition']);
  const [width, height] = useImageDimensions(image?.url);
  const annotations = useFileAnnotations(image?.id);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState<string | undefined>();

  const onPaginate = (index: number) => setFileIndex(index - 1);
  const onRecenter = () => viewerRef.current?.setTo(initialCenter);
  const onDragToggle = (isDragging: boolean) => setDragging(isDragging);
  const onToggleDraw = () => setDrawing((d) => !d);
  const onSelectAnnotation = (id: string) => setSelectedAnnotationId((selected) => (selected === id ? undefined : id));
  const onFinishDrawing = (event: OnSelectEnd) => {
    const newAnnotationCoords = getNewAnnotationCoordsFromSelecto(event, zoom);
    const newAnnotation = annotate(image?.id, newAnnotationCoords);
    onToggleDraw();
    onSelectAnnotation(newAnnotation.id);
  };

  const onChangeZoom = (newZoom: number) => {
    if (newZoom < zoomLimits[0]) setZoom(zoomLimits[0]);
    else if (newZoom > zoomLimits[1]) setZoom(zoomLimits[1]);
    else setZoom(newZoom);
  };

  useEffect(() => {
    onRecenter();
  }, [width, height]);

  return (
    <Stack className='border border-solid' gap='zero' w='100%' h='100%'>
      <AnnotatorToolbar
        drawState={drawing}
        imageCount={files.length}
        zoom={zoom}
        onDraw={onToggleDraw}
        onPaginate={onPaginate}
        onRecenter={onRecenter}
        onZoom={onChangeZoom}
      />
      <InfiniteViewer
        ref={viewerRef}
        className='w-full grow'
        displayVerticalScroll={false}
        displayHorizontalScroll={false}
        margin={0}
        threshold={0}
        useMouseDrag={!dragging && !drawing}
        zoomRange={zoomLimits}
        zoom={zoom}
        onPinch={(e) => onChangeZoom(e.zoom)}
      >
        <Box ref={boxRef} className='relative' h={height} w={width} data-testid={testIds.viewport}>
          <Image src={files[fileIndex]?.url} data-testid={testIds.image} />
          {annotations.map((annotation) => (
            <AnnotationBox
              key={annotation.id}
              annotation={annotation}
              zoom={zoom}
              isSelected={selectedAnnotationId === annotation.id}
              onToggleSelected={() => onSelectAnnotation(annotation.id)}
              onDragToggle={onDragToggle}
              onUpdateAnnotation={(coords) => reposition(annotation.id, coords)}
            />
          ))}
        </Box>
        {drawing && <Selecto boundContainer className={classes.selecto} preventDefault onSelectEnd={onFinishDrawing} />}
      </InfiniteViewer>
    </Stack>
  );
};
