import { Combobox, ComboboxItem, Select, useCombobox } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { memo } from 'react';
import { ENVIRONMENTS } from '../PublishModal.utils';
import { EnvironmentSelectOption, testIds as optionTestIds } from './EnvironmentSelectOption';

interface EnvironmentSelectProps {
  onChange: (value: ENVIRONMENTS) => void;
  disabled?: boolean;
  environmentOptions: ComboboxItem[];
}

export const testIds = {
  input: 'environment-select-input',
  dropdown: 'environment-select-dropdown',
  option: optionTestIds,
};

export const EnvironmentSelect = memo(({ onChange, disabled, environmentOptions }: EnvironmentSelectProps) => {
  const [opened, { open, close }] = useDisclosure(false);
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onOpenedChange: (shouldOpen) => (shouldOpen ? open() : close()),
    opened,
  });
  const { t } = useTranslation();

  return (
    <Select
      data={environmentOptions}
      onChange={(label) => onChange((label ?? '') as ENVIRONMENTS)}
      label={t('publishPage.publishModal.environmentSelect.title')}
      description={t('publishPage.publishModal.environmentSelect.description')}
      pointer
      defaultValue={environmentOptions[0].value}
      rightSection={<Combobox.Chevron />}
      rightSectionPointerEvents='none'
      data-testid={testIds.input}
      disabled={disabled}
      renderOption={(item) => (
        <EnvironmentSelectOption id={item.option.value} label={item.option.label} checked={item.checked} />
      )}
    />
  );
});
