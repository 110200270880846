import { useState } from 'react';
import { SynonymsInput } from 'components';
import { useTranslation } from 'react-i18next';
import { Flex } from '@mantine/core';
import { testIds } from '../TextTypeLayout';
import classes from '../TextTypeLayout.module.css';

interface CommandWordProps {
  startCommandWords: string[];
  endCommandWords: string[];
  onChangeStartCommandWords: (values?: string[]) => void;
  onChangeEndCommandWords: (values?: string[]) => void;
}

export const CommandWords = ({
  startCommandWords,
  endCommandWords,
  onChangeStartCommandWords,
  onChangeEndCommandWords,
}: CommandWordProps) => {
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const translationKey = 'wizard.steps.reportedEvents.typeSettings.commandWordsInput.';

  const validateForm = (currentFieldValues: string[], otherFieldValues: string[]) => {
    if (!dirty) {
      return null;
    }

    const someInputIsEmpty = currentFieldValues.length === 0 && otherFieldValues?.length !== 0;
    if (someInputIsEmpty) {
      return t(`${translationKey}requiredError`);
    }

    const fieldsValuesSet = new Set(otherFieldValues?.map((v) => v.toLowerCase()));
    const duplicates = currentFieldValues.some((command: string) => fieldsValuesSet.has(command.toLowerCase()));

    if (duplicates) {
      return t(`${translationKey}duplicationError`);
    }

    return null;
  };

  return (
    <>
      <Flex className={classes.commandWordsStart}>
        <SynonymsInput
          value={startCommandWords}
          error={validateForm(startCommandWords, endCommandWords)}
          label={t(`${translationKey}start`)}
          infoText={t(`${translationKey}tooltip`)}
          placeholder={t(`${translationKey}placeholder`)}
          className='grow'
          variant='filled'
          onChange={(values) => {
            setDirty(true);
            onChangeStartCommandWords(values.length ? values : undefined);
          }}
          data-testid={testIds.startCommandWordsInput}
        />
      </Flex>
      <Flex className={classes.commandWordsEnd}>
        <SynonymsInput
          value={endCommandWords}
          error={validateForm(endCommandWords, startCommandWords)}
          label={t(`${translationKey}end`)}
          infoText={t(`${translationKey}tooltip`)}
          placeholder={t(`${translationKey}placeholder`)}
          className='grow'
          variant='filled'
          onChange={(values) => {
            setDirty(true);
            onChangeEndCommandWords(values.length ? values : undefined);
          }}
          data-testid={testIds.endCommandWordsInput}
        />
      </Flex>
    </>
  );
};
