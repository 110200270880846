import { Policy } from '@jargonic/customer-types';
import { Checkbox, Flex, Highlight } from '@mantine/core';

interface GroupPoliciesMappingPolicyRowProps {
  policy: Policy;
  selected: boolean;
  search: string;
  onChange: (checked: boolean) => void;
}

export const testIds = {
  getRowTestId: (policyId: string) => `group-mapping-policy-row-${policyId}`,
  getAvatarTestId: (policyId: string) => `group-mapping-policy-row-avatar-${policyId}`,
  checkbox: 'group-mapping-policy-row-checkbox',
  name: 'group-mapping-policy-row-name',
};

export const GroupPoliciesMappingPolicyRow = ({
  policy,
  selected,
  search,
  onChange,
}: GroupPoliciesMappingPolicyRowProps) => (
  <Flex align='center' gap='md' data-testid={testIds.getRowTestId(policy.id)}>
    <Highlight className='grow' highlight={search} data-testid={testIds.name}>
      {`${policy.name}`}
    </Highlight>
    <Checkbox
      size='md'
      checked={selected}
      onChange={(event) => onChange(event.currentTarget.checked)}
      data-testid={testIds.checkbox}
    />
  </Flex>
);
