import { MRT_TableOptions } from 'mantine-react-table';
import { FlowMetadataField } from 'stores/wizard';

export const metadataTableDefs = {
  enableRowSelection: true,
  enablePagination: false,
  enableColumnFilters: false,
  enableColumnActions: false,
  enableBottomToolbar: false,
  enableRowOrdering: true,
  editDisplayMode: 'cell',
  enableEditing: true,
  positionToolbarAlertBanner: 'none',
  getRowId: (field) => field.id,
  displayColumnDefOptions: {
    'mrt-row-drag': {
      header: '',
    },
  },
} satisfies Partial<MRT_TableOptions<FlowMetadataField>>;
