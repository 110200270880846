import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { CreateUserPayload } from 'stores/userMgmt';
import { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { IconUser, IconUserOff } from '@tabler/icons-react';
import { NiceResponse } from 'consts';
import { UserTableManualCreateModal, testIds as modalTestIds } from './UserTableManualCreateModal';

interface UserTableManualCreateActionProps {
  onCreate: (payload: CreateUserPayload) => Promise<NiceResponse>;
}

export const testIds = {
  button: 'user-table-manual-create-button',
  modal: modalTestIds,
};

export const UserTableManualCreateAction = ({ onCreate }: UserTableManualCreateActionProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure();

  const onSuccess = (name: string) =>
    notifications.show({
      color: 'green',
      icon: <IconUser />,
      title: t('userMgmt.users.table.actions.createUsers.successMessage', { name }),
      message: null,
    });

  const onError = () =>
    notifications.show({
      color: 'red',
      icon: <IconUserOff />,
      title: t('userMgmt.users.table.actions.createUsers.errorTitle'),
      message: t('userMgmt.users.table.actions.createUsers.errorMessage'),
    });

  const onDuplicate = (email: string) =>
    notifications.show({
      color: 'red',
      icon: <IconUserOff />,
      title: t('userMgmt.users.table.actions.createUsers.errorTitle'),
      message: t('userMgmt.users.table.actions.createUsers.duplicateErrorMessage', { email }),
    });

  const onResponse = (response: NiceResponse, data: CreateUserPayload) => {
    switch (response) {
      case NiceResponse.OK: {
        const name = `${data.givenName} ${data.familyName}`;
        return onSuccess(name);
      }
      case NiceResponse.DUPLICATE:
        return onDuplicate(data.email);
      default:
        return onError();
    }
  };

  const onSubmit = async (data: CreateUserPayload) => {
    setLoading(true);
    const response = await onCreate(data);
    onResponse(response, data);
    setLoading(false);
  };

  return (
    <>
      <Button loading={loading} onClick={open} data-testid={testIds.button}>
        {t('userMgmt.users.table.actions.createUsers.button')}
      </Button>
      <UserTableManualCreateModal opened={opened} onClose={close} onSubmit={onSubmit} />
    </>
  );
};
