import { Box, Flex, Paper, Stack, Text, noop } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { AiDataset, TrainingStepStatus, useTrainingSteps } from 'stores/aiPlatform/aiModels';
import { Link, useParams } from 'react-router-dom';
import { TrainingPipelineRouteParams } from 'routes/routes.config';
import { StepCommonContent, testIds as commonContentTestIds } from '../StepCommonContent/StepCommonContent';
import classes from '../../TrainingPipelinePage.module.css';

export const testIds = {
  completedContent: 'dataset-content-completed',
  downloadIcon: 'dataset-content-download-icon',
  commonContent: commonContentTestIds,
  getDatasetTestId: (dataset: AiDataset) => `dataset-content-${dataset}`,
};

const datasetToUriProp = {
  [AiDataset.TRAINING]: 'trainingUri',
  [AiDataset.EVALUATION]: 'evaluationUri',
  [AiDataset.TESTING]: 'testingUri',
} as const;

export const DatasetContent = () => {
  const { t } = useTranslation();
  const { modelId } = useParams() as TrainingPipelineRouteParams;
  const { dataset: datasetCreationStep } = useTrainingSteps(modelId) ?? {};

  const datasets = Object.values(AiDataset);

  switch (datasetCreationStep?.status) {
    case TrainingStepStatus.IN_PROGRESS:
      return (
        <StepCommonContent
          key={TrainingStepStatus.IN_PROGRESS}
          title={t('trainingPipelinePage.steps.dataset.inProgressTitle')}
          message={t('trainingPipelinePage.steps.contentMessage')}
          className={classes.greenBorder}
          onAbort={noop}
          onPause={noop}
        />
      );
    case TrainingStepStatus.PAUSED:
      return (
        <StepCommonContent
          key={TrainingStepStatus.PAUSED}
          title={t('trainingPipelinePage.steps.pausedTitle')}
          message={t('trainingPipelinePage.steps.contentMessage')}
          className={classes.greenBorder}
          onAbort={noop}
          onResume={noop}
        />
      );

    case TrainingStepStatus.FAILED:
      return (
        <StepCommonContent
          key={TrainingStepStatus.FAILED}
          title={t('trainingPipelinePage.steps.failedTitle')}
          message={t('trainingPipelinePage.steps.failedMessage')}
          className={classes.redBorder}
          onAbort={noop}
          onReset={noop}
        />
      );
    case TrainingStepStatus.COMPLETED:
      return (
        <Paper p='lg' withBorder className={classes.greenBorder} data-testid={testIds.completedContent}>
          <Stack>
            {datasets.map((dataset) => (
              <Flex
                key={dataset}
                component={Link}
                to={datasetCreationStep[datasetToUriProp[dataset]]}
                gap='md'
                align='center'
                className='no-underline'
                data-testid={testIds.getDatasetTestId(dataset)}
              >
                <Box className={classes.downloadIcon}>
                  <IconDownload size={20} data-testid={testIds.downloadIcon} />
                </Box>
                <Text c='black'>{t(`trainingPipelinePage.dataset.${dataset}`)}</Text>
              </Flex>
            ))}
          </Stack>
        </Paper>
      );
    default:
      return null;
  }
};
