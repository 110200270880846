import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { LabelId, ReportedEventsData, useEventDefStore } from 'stores/wizard';

const getFirstEventDef = (data: ReportedEventsData, labelId: LabelId) =>
  Object.values(data)
    .filter((eventDef) => eventDef.labelId === labelId)
    .filter((eventDef) => !eventDef.parentId)
    .sort((a, b) => a.order - b.order)
    .at(0);

export function useEventDefSelection(labelId: LabelId | null) {
  const { data } = useEventDefStore(['data']);
  const firstEventDef = useMemo(() => (labelId ? getFirstEventDef(data, labelId) : undefined), [labelId, data]);
  const [params, setParams] = useSearchParams();
  const selectedEventDefId = params.get('eventDefId') ?? firstEventDef?.id;
  const selectedEventDef = data[selectedEventDefId!];

  const setSelectedEventDefId = (eventDefId: string) => {
    if (labelId) setParams({ eventDefId, labelId });
  };

  useEffect(() => {
    // reset selected if it's deleted
    if (!selectedEventDef && labelId) {
      setParams((currentParams) => {
        currentParams.delete('eventDefId');
        return currentParams;
      });
    }
  }, [selectedEventDef]);

  return {
    selectedEventDefId,
    selectedEventDef,
    onChangeSelectedEventDefId: setSelectedEventDefId,
  };
}
