import { Customer } from '@jargonic/customer-types';
import { Combobox, Flex, Image, Stack, Text } from '@mantine/core';

interface CustomerOptionProps {
  customer: Customer;
}

export const testIds = {
  getOptionTestId: (id: string) => `customer-select-option-${id}`,
  logo: 'customer-select-selected-logo',
};

export const CustomerOption = ({ customer }: CustomerOptionProps) => (
  <Combobox.Option value={customer.id} data-testid={testIds.getOptionTestId(customer.id)}>
    <Flex align='center' gap='sm'>
      <Image
        src={customer.logo}
        alt={customer.name}
        fallbackSrc='/CustomerFallback.svg'
        h={40}
        w={40}
        miw={40}
        fit='contain'
        data-testid={testIds.logo}
      />
      <Stack gap='zero'>
        <Text className='whitespace-nowrap' size='sm' fw={700}>
          {customer.name}
        </Text>
        <Text size='xs' c='gray.6'>
          {customer.industry}
        </Text>
      </Stack>
    </Flex>
  </Combobox.Option>
);
