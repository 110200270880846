import { Button, Menu, Radio, Text, Select, Stack } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconChevronRight, IconRepeatOff, IconTrash } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { PropsWithChildren, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { WizardRouteParams } from 'routes/routes.config';
import { AnnotationId, LabelId, useAnnotationStore, useContainerStore, useLabelStore } from 'stores/wizard';

interface AnnotationMenuProps extends PropsWithChildren {
  id: AnnotationId;
  opened: boolean;
  labelId?: LabelId;
  onToggleOpen: () => void;
}

export const testIds = {
  menu: 'annotation-menu',
  labelListTitle: 'annotation-menu-title',
  processTitle: 'annotation-menu-process-title',
  getRadioTestId: (labelId: string) => `annotation-menu-radio-${labelId}`,
  radioRegex: /annotation-menu-radio-.*/,
  noStaticGroupsLabel: 'annotation-menu-no-static-groups-label',
  delete: {
    button: 'annotation-menu-delete-button',
    icon: 'annotation-menu-delete-icon',
  },
  parentSelect: 'annotation-menu-parent-select',
  process: {
    button: 'annotation-menu-process-button',
    icon: 'annotation-menu-process-icon',
  },
};

export const AnnotationMenu = ({ id, opened, children, labelId, onToggleOpen }: AnnotationMenuProps) => {
  const { t } = useTranslation();
  const { flowId, customerId } = useParams() as WizardRouteParams;
  const { labels: labelsObj } = useLabelStore(['labels']);
  const { data } = useContainerStore(['data']);
  const [selectedParentId, setSelectedParentId] = useState<string | null>(null);
  const { delete: deleteAnnotation, process, relabel } = useAnnotationStore(['delete', 'process', 'relabel']);
  const labels = useMemo(() => Object.values(labelsObj).sort((a, b) => a.order - b.order), [labelsObj]);
  const containerOptions = useMemo(() => Object.values(data).map((c) => ({ value: c.id, label: c.name })), [data]);

  const onDelete = () => deleteAnnotation(id);
  const onRelabel = (value: string) => relabel(id, value);
  const onProcess = async () => {
    onToggleOpen();
    const response = await process({
      annotationId: id,
      parentId: selectedParentId,
      flowId,
      customerId,
    });
    if (!response) {
      notifications.show({
        title: t('wizard.steps.labeling.annotator.contextMenu.processError.title'),
        message: t('wizard.steps.labeling.annotator.contextMenu.processError.message'),
        color: 'red',
        icon: <IconRepeatOff />,
        autoClose: 5000,
      });
    }
  };

  const staticLabels = labels.filter((label) => !label.isDynamic);

  return (
    <Menu id={id} position='right' opened={opened} onClose={onToggleOpen} onOpen={onToggleOpen}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown data-testid={testIds.menu}>
        <Menu.Label className='flex items-center justify-between' data-testid={testIds.labelListTitle}>
          {t('wizard.steps.labeling.annotator.contextMenu.title')}
          <ActionButton label={t('common.delete')} size='sm' onClick={onDelete} data-testid={testIds.delete.button}>
            <IconTrash data-testid={testIds.delete.icon} />
          </ActionButton>
        </Menu.Label>

        {staticLabels.length > 0 ? (
          <Radio.Group value={labelId} mb='sm' onChange={onRelabel}>
            {labels.map((label) => (
              <Radio
                key={label.id}
                label={label.name}
                value={label.id}
                c={label.attributes.color}
                color={label.attributes.color}
                mt='sm'
                size='xs'
                data-testid={testIds.getRadioTestId(label.id)}
              />
            ))}
          </Radio.Group>
        ) : (
          <Text fz='xs' c='red' style={{ whiteSpace: 'pre-line' }} data-testid={testIds.noStaticGroupsLabel}>
            {t('wizard.steps.labeling.containerTable.addItemMenu.labelList.noStaticGroups')}
          </Text>
        )}

        <Menu.Divider />
        <Menu.Label data-testid={testIds.processTitle}>
          {t('wizard.steps.labeling.annotator.contextMenu.processTitle')}
        </Menu.Label>
        <Stack gap='sm'>
          <Select
            clearable
            comboboxProps={{
              withinPortal: false,
            }}
            data={containerOptions}
            label={t('wizard.steps.labeling.annotator.contextMenu.parentSelect.title')}
            placeholder={t('wizard.steps.labeling.annotator.contextMenu.parentSelect.placeholder')}
            searchable
            size='xs'
            value={selectedParentId}
            onChange={setSelectedParentId}
            data-testid={testIds.parentSelect}
          />
          <Button
            disabled={!labelId}
            size='xs'
            rightSection={<IconChevronRight data-testid={testIds.process.icon} />}
            onClick={onProcess}
            data-testid={testIds.process.button}
          >
            {t('wizard.steps.labeling.annotator.contextMenu.processButton')}
          </Button>
        </Stack>
      </Menu.Dropdown>
    </Menu>
  );
};
