import { CloseButton, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface GroupMappingSearchProps {
  value: string;
  onChange: (value: string) => void;
}

export const testIds = {
  icon: 'group-mapping-search-icon',
  input: 'group-mapping-search-input',
  clear: 'group-mapping-search-clear',
};

export const GroupUsersMappingSearch = ({ value, onChange }: GroupMappingSearchProps) => {
  const { t } = useTranslation();

  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => onChange(event.currentTarget.value);
  const onClear = () => onChange('');

  return (
    <TextInput
      flex={1}
      placeholder={t('common.searchPlaceholder')}
      rightSection={value && <CloseButton onClick={onClear} data-testid={testIds.clear} />}
      leftSection={<IconSearch size={18} data-testid={testIds.icon} />}
      value={value}
      onChange={onSearch}
      data-testid={testIds.input}
    />
  );
};
