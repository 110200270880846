import { Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { UserTable, testIds as tableTestIds } from './UserTable/UserTable';

export const testIds = {
  title: 'users-page-title',
  table: tableTestIds,
};

export const UsersPage = () => {
  const { t } = useTranslation();

  return (
    <Stack h='100%'>
      <Title order={5} h={40} className='flex items-center' fw={400} data-testid={testIds.title}>
        {t('userMgmt.navbar.users')}
      </Title>
      <UserTable />
    </Stack>
  );
};
