import { Combobox, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface CustomerSelectFooterProps {
  onViewAll: () => void;
}

export const testIds = {
  viewAll: 'customer-select-view-all',
};

export const CustomerSelectFooter = ({ onViewAll }: CustomerSelectFooterProps) => {
  const { t } = useTranslation();

  return (
    <Combobox.Footer className='cursor-pointer' onClick={onViewAll} data-testid={testIds.viewAll}>
      <Text className='text-[var(--mantine-primary-color-filled)] select-none' size='xs'>
        {t('header.select.viewAll')}
      </Text>
    </Combobox.Footer>
  );
};
