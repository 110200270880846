import { Flex, Stack, Text, Title } from '@mantine/core';
import { FlowStatusBadge } from 'components/FlowStatusBadge/FlowStatusBadge';
import { Flow } from 'stores/flows';

interface FlowPageTitleProps {
  title: string;
  flow: Flow;
}

export const testIds = {
  title: 'flow-title-title',
  flowName: 'flow-title-flow-name',
};

export const FlowPageTitle = ({ flow, title }: FlowPageTitleProps) => (
  <Stack gap='zero'>
    <Title order={2} fw={600} data-testid={testIds.title}>
      {title}
    </Title>
    <Flex gap='sm'>
      <FlowStatusBadge flow={flow} />
      <Text data-testid={testIds.flowName}>{flow.name}</Text>
    </Flex>
  </Stack>
);
