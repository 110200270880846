import { AiModelStatusBadge } from 'components';
import { MRT_ColumnDef } from 'mantine-react-table';
import { AiModel } from 'stores/aiPlatform/aiModels';

export const testIds = {
  badge: 'ai-model-table-status-badge',
};

export const StatusCell: NonNullable<MRT_ColumnDef<AiModel>['Cell']> = ({ row }) => (
  <AiModelStatusBadge status={row.original.status} data-testid={testIds.badge} />
);
