import { Flex, InputWrapper, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import dayjs from 'dayjs';
import { FlowRouteParams } from 'routes/routes.config';
import { useFlow } from 'stores/flows';
import { FlowStatusBadge, UserGroupAvatars } from 'components';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import classes from './FlowDetails.module.css';

export const testIds = {
  wrapper: 'flow-details-wrapper',
  flowName: 'flow-details-flow-name',
  editor: 'flow-details-editor',
  status: 'flow-details-status',
  lastEdited: 'flow-details-last-edited',
};

export const FlowDetails = () => {
  const { t } = useTranslation();
  const { flowId } = useParams() as FlowRouteParams;
  const flow = useFlow(flowId);

  return (
    <InputWrapper label={t('flowPage.detailsTitle')} w='100%' data-testid={testIds.wrapper}>
      <Flex className={classes.wrapper}>
        <InputWrapper
          label={t('flowsPage.table.headers.flowName')}
          className={cn(classes.item, 'flex-1')}
          data-testid={testIds.flowName}
        >
          <Text>{flow.name}</Text>
        </InputWrapper>
        <InputWrapper label={t('flowsPage.table.headers.editor')} className={classes.item} data-testid={testIds.editor}>
          <UserGroupAvatars users={flow.editors} />
        </InputWrapper>
        <InputWrapper label={t('flowsPage.table.headers.status')} className={classes.item} data-testid={testIds.status}>
          <FlowStatusBadge flow={flow} display='flex' />
        </InputWrapper>
        <InputWrapper
          label={t('flowsPage.table.headers.lastEdited')}
          className={classes.item}
          data-testid={testIds.lastEdited}
        >
          <Text>{dayjs(flow.updatedAt).format(CLASSIC_DATE_TIME_FORMAT)}</Text>
        </InputWrapper>
      </Flex>
    </InputWrapper>
  );
};
