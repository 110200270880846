import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import { FlowMetadataField } from 'stores/wizard';
import { MetadataTableState } from '../MetadataTable.types';
import {
  FieldTypeCell,
  fieldTypeCellTestIds,
  MandatoryCell,
  UniqueIdentifierCell,
  UniqueIdentifierHeader,
  mandatoryCellTestIds,
  uniqueIdCellTestIds,
  uniqueIdHeaderTestIds,
  FieldValuesCell,
  fieldValuesCellTestIds,
  ExposedHeader,
  ExposedCell,
  ExposedCellTestIds,
  ExposedHeaderTestIds,
  PlaceholderCell,
  placeholderCellTestIds,
  placeholderEditTestIds,
  PlaceholderEdit,
} from '../components';

export const testIds = {
  fieldType: fieldTypeCellTestIds,
  mandatory: mandatoryCellTestIds,
  fieldValues: fieldValuesCellTestIds,
  uniqueId: {
    cell: uniqueIdCellTestIds,
    header: uniqueIdHeaderTestIds,
  },
  exposed: {
    cell: ExposedCellTestIds,
    header: ExposedHeaderTestIds,
  },
  title: {
    input: 'metadata-table-title-input',
  },
  placeholder: {
    cell: placeholderCellTestIds,
    edit: placeholderEditTestIds,
  },
};

export function useMetadataTableColumns() {
  const { t } = useTranslation();

  return useMemo<MRT_ColumnDef<FlowMetadataField>[]>(
    () => [
      {
        accessorKey: 'id',
        header: '',
        size: 0,
        enableEditing: false,
        enableSorting: false,
        Header: UniqueIdentifierHeader,
        Cell: UniqueIdentifierCell,
      },
      {
        accessorKey: 'title',
        header: t('wizard.steps.settings.metadata.table.headers.title'),
        mantineEditTextInputProps: ({ row, table }) => {
          const { onChange } = table.getState() as MetadataTableState;
          return {
            required: true,
            onBlur: (e) => onChange(row.id, { title: e.currentTarget.value }),
            'data-testid': testIds.title.input,
          };
        },
      },
      {
        accessorKey: 'placeholder',
        header: t('wizard.steps.settings.metadata.table.headers.placeholder'),
        size: 200,
        Cell: PlaceholderCell,
        Edit: PlaceholderEdit,
      },
      {
        accessorKey: 'type',
        header: t('wizard.steps.settings.metadata.table.headers.type'),
        enableEditing: false,
        Cell: FieldTypeCell,
      },
      {
        accessorKey: 'options',
        header: '',
        enableSorting: false,
        enableEditing: false,
        Cell: FieldValuesCell,
      },
      {
        accessorKey: 'expose',
        header: '',
        size: 0,
        enableEditing: false,
        Header: ExposedHeader,
        Cell: ExposedCell,
      },
      {
        accessorKey: 'required',
        header: t('wizard.steps.settings.metadata.table.headers.mandatory'),
        size: 0,
        enableEditing: false,
        Cell: MandatoryCell,
      },
    ],
    [],
  );
}
