import { Checkbox, CheckboxProps, ElementProps, noop } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import classes from './AllCheckbox.module.css';

interface AllCheckboxProps
  extends Omit<CheckboxProps, 'onChange' | 'checked' | 'indeterminate'>,
    ElementProps<'input', keyof CheckboxProps> {
  all?: boolean;
  some?: boolean;
  onChange: (checked: boolean) => void;
}

export const testIds = {
  wrapper: 'all-checkbox-wrapper',
  input: 'all-checkbox-input',
};

export const AllCheckbox = ({ all = false, some = false, onChange, ...rest }: AllCheckboxProps) => {
  const { t } = useTranslation();

  const onToggle = () => onChange(!all);

  return (
    <Checkbox
      className={classes.input}
      checked={all}
      indeterminate={some && !all}
      label={t('common.all')}
      w='fit-content'
      wrapperProps={{ onClick: onToggle, 'data-testid': testIds.wrapper }}
      onChange={noop}
      data-testid={testIds.input}
      {...rest}
    />
  );
};
