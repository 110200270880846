import { Avatar, AvatarProps, ElementProps, Tooltip } from '@mantine/core';
import { forwardRef, useMemo } from 'react';
import { MiniUser } from 'stores/auth';
import { useTranslation } from 'react-i18next';
import { computerUserColor, getInitials } from '@aiola/frontend';

interface UserAvatarProps extends AvatarProps, ElementProps<'div', keyof AvatarProps> {
  user?: MiniUser;
}

export const testIds = {
  avatar: 'user-avatar',
  fallback: 'user-avatar-fallback',
  tooltip: 'user-avatar-tooltip',
};

export const UserAvatar = forwardRef<HTMLDivElement, UserAvatarProps>(({ user, ...props }, ref) => {
  const { t } = useTranslation();
  const color = useMemo(() => user && computerUserColor(user), [user]);
  const fullName = useMemo(() => (user ? `${user.givenName} ${user.familyName}` : t('common.unknown')), [user]);

  return (
    <Tooltip label={fullName} openDelay={1000} data-testid={testIds.tooltip}>
      {user ? (
        <Avatar ref={ref} color={color} data-testid={testIds.avatar} {...props}>
          {getInitials(user)}
        </Avatar>
      ) : (
        <Avatar ref={ref} data-testid={testIds.fallback} {...props} />
      )}
    </Tooltip>
  );
});
