import { useSetState } from '@mantine/hooks';
import { Folder, FolderId } from 'stores/folders';
import { ARCHIVE_ID } from '../FlowTable.utils';

export function useFolderExpandedState(folders: Record<FolderId, Folder>) {
  const [expanded, setExpanded] = useSetState<Record<FolderId, boolean>>(
    Object.values(folders).reduce((acc, folder) => ({ ...acc, [folder.id]: true }), {}),
  );

  const toggleFolder = (folderId: FolderId) => setExpanded({ [folderId]: !expanded[folderId] });
  const toggleAllFolders = (state: boolean) =>
    setExpanded({
      ...Object.values(folders).reduce((acc, folder) => ({ ...acc, [folder.id]: state }), {}),
      [ARCHIVE_ID]: state,
    });

  return {
    expanded,
    toggleFolder,
    toggleAllFolders,
  };
}
