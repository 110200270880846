import { Loader, Flex, Stack, Select, Divider } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { WizardStep, useStepLoader } from 'layouts';
import { useEventDefStore } from 'stores/wizard';
import { FlowPageTitle } from 'components';
import { WizardRouteParams } from 'routes/routes.config';
import { useParams } from 'react-router-dom';
import { useFlow } from 'stores/flows';
import { EventDefSettings, EventDefTable } from './components';
import { useEventDefSelection, useLabelSelection, useMainEventRequirement } from './hooks';

export const testIds = {
  loader: 'reported-events-page-loader',
  labelSelect: 'reported-events-page-label-select',
};

export const ReportedEventsPage = () => {
  const { t } = useTranslation();
  const { flowId } = useParams() as WizardRouteParams;
  const flow = useFlow(flowId);
  const loading = useStepLoader(WizardStep.EVENTS);
  const { startDraft, endDraft } = useEventDefStore(['startDraft', 'endDraft']);
  const { labelOptions, selectedLabelId, setSelectedLabelId } = useLabelSelection();
  const { selectedEventDefId } = useEventDefSelection(selectedLabelId);
  const hasSufficientMainEvents = useMainEventRequirement();

  const onChangeSelectedLabelId = (labelId: string | null) => {
    if (labelId) setSelectedLabelId(labelId);
  };

  useEffect(() => {
    if (!loading) startDraft();
    return endDraft;
  }, [loading]);

  return loading || !flow ? (
    <Loader type='bars' m='auto' data-testid={testIds.loader} />
  ) : (
    <Stack className='grow' px='xl' gap='xl'>
      <FlowPageTitle flow={flow} title={t('flowPage.applications.events')} />
      <Flex className='grow' pb='xl' gap='lg'>
        <Stack className='shrink-0' w={300}>
          <Select
            allowDeselect={false}
            data={labelOptions}
            label={t('wizard.steps.reportedEvents.labelSelect.title')}
            placeholder={t('wizard.steps.reportedEvents.labelSelect.placeholder')}
            error={!hasSufficientMainEvents && t('wizard.steps.reportedEvents.labelSelect.insufficientMainEventsError')}
            value={selectedLabelId}
            onChange={onChangeSelectedLabelId}
            data-testid={testIds.labelSelect}
          />
          <EventDefTable labelId={selectedLabelId} />
        </Stack>
        <Divider orientation='vertical' />
        <EventDefSettings eventDefId={selectedEventDefId} />
      </Flex>
    </Stack>
  );
};
