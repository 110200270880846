import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Text } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';

interface DeleteUserFromGroupModalProps {
  opened: boolean;
  onClose: () => void;
  onDelete: () => Promise<void>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  closeButton: modalBaseTestIds.close,
  message: 'delete-user-from-group-message',
  cancel: 'delete-user-from-group-cancel-button',
  modal: 'delete-user-from-group-modal',
  confirmButton: 'delete-user-from-group-confirm-button',
};

export const DeleteUserFromGroupModal = ({ opened, onClose, onDelete }: DeleteUserFromGroupModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
    onClose();
  };

  return (
    <ModalBase
      closable={!loading}
      title={t('userMgmt.groups.groupDetails.panels.users.table.deleteUser.title')}
      opened={opened}
      variant='warning'
      onClose={onClose}
      data-testid={testIds.modal}
    >
      <Text mb='lg' data-testid={testIds.message}>
        {t('userMgmt.groups.groupDetails.panels.users.table.deleteUser.message')}
      </Text>
      <Group justify='end'>
        <Button disabled={loading} variant='subtle' onClick={onClose} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button loading={loading} onClick={onConfirm} data-testid={testIds.confirmButton}>
          {t('common.delete')}
        </Button>
      </Group>
    </ModalBase>
  );
};
