import { Stack } from '@mantine/core';
import { useParams } from 'react-router-dom';
import { NiceResponse } from 'consts';
import { useUserMgmtStore } from 'stores/userMgmt';
import { GroupRouteParams } from 'routes/routes.config';
import { Group } from '@jargonic/customer-types';
import { DeleteGroupAction, deleteGroupTestIds, GroupPersonalData, personalDataTestIds } from './components';

export const testIds = {
  wrapper: 'group-details-panel',
  personalData: personalDataTestIds,
  deleteGroup: deleteGroupTestIds,
};

export const GroupDetailsPanel = () => {
  const { customerId, groupId } = useParams() as GroupRouteParams;
  const { users, groups, upsertGroup, deleteGroup } = useUserMgmtStore([
    'users',
    'groups',
    'upsertGroup',
    'deleteGroup',
  ]);
  const group = groups[groupId];

  const checkIfGroupNameExist = (name: string) =>
    Object.values(groups).some((g) => g.name === name && g.id !== groupId);

  const onChange = async (key: keyof Group, value: string) => {
    if (!value) {
      return NiceResponse.ERROR;
    }

    if (group[key] === value) {
      return NiceResponse.OK;
    }

    if (key === 'name' && checkIfGroupNameExist(value)) {
      return NiceResponse.DUPLICATE;
    }

    const { name } = group;
    const response = await upsertGroup(customerId, {
      action: 'update',
      groupId,
      payload: {
        name,
        [key]: value,
        users: group.users.map((user) => user.userId),
        policies: group.policies.map((policy) => policy.id),
      },
    });
    return response ? NiceResponse.OK : NiceResponse.ERROR;
  };

  const onDelete = async () => deleteGroup(customerId, groupId);

  // TODO: Should be updated after BE be ready with the correct user id
  // There are 2 different data sources
  // user token from alb and user entity from cognito, should be decide how align them together
  // const userId = users[group.metadata.createdBy.userId];
  const userId = '';

  return (
    <Stack mt='lg' data-testid={testIds.wrapper}>
      <GroupPersonalData group={group} user={users[userId]} onChange={onChange} />
      <DeleteGroupAction group={group} onDelete={onDelete} />
    </Stack>
  );
};
