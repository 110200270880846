import { EventDefElement, EventDefType, useEventDefStore } from 'stores/wizard';
import {
  TextEventBehavior,
  DefaultValueInput,
  defaultValueInputTestIds,
  extractEventsCheckboxTestIds,
  textEventBehaviorTestIds,
} from './components';
import classes from './TextTypeLayout.module.css';
import { ExtractEventsCheckbox } from './components/ExtractEventsCheckbox';
import { CommandWords } from './components/CommandWords';

interface TextTypeLayoutProps {
  eventDefId: string;
}

export const testIds = {
  defaultInput: defaultValueInputTestIds,
  extractedEvents: extractEventsCheckboxTestIds,
  startCommandWordsInput: 'start-command-words-input',
  endCommandWordsInput: 'end-command-words-input',
  textEventBehavior: textEventBehaviorTestIds,
};

export const TextTypeLayout = ({ eventDefId }: TextTypeLayoutProps) => {
  const { data, updateEventDef } = useEventDefStore(['data', 'updateEventDef']);
  const eventDef = data[eventDefId];

  if (!eventDef) return null;

  const onChangeDefaultValue = (value?: string) => updateEventDef(eventDefId, { default: value });
  const onChangeExtractEvents = (checked: boolean) => updateEventDef(eventDefId, { extractEvents: checked });
  const onChangeStartCommandWords = (values?: string[]) => updateEventDef(eventDefId, { startCommandWords: values });
  const onChangeEndCommandWords = (values?: string[]) => updateEventDef(eventDefId, { endCommandWords: values });
  const onChangeTextEventBehavior = (values: { elementType: EventDefElement; valueType: EventDefType }) => {
    updateEventDef(eventDefId, values);
  };

  return (
    <>
      <DefaultValueInput
        required
        rootClassName={classes.defaultInput}
        value={eventDef.default ?? ''}
        onChange={onChangeDefaultValue}
      />
      <ExtractEventsCheckbox
        checked={eventDef.extractEvents}
        rootClassName={classes.extractEvents}
        onChange={onChangeExtractEvents}
      />
      <TextEventBehavior
        value={eventDef.elementType}
        rootClassName={classes.appendTextSelect}
        onChange={onChangeTextEventBehavior}
      />
      <CommandWords
        startCommandWords={eventDef.startCommandWords ?? []}
        endCommandWords={eventDef.endCommandWords ?? []}
        onChangeStartCommandWords={onChangeStartCommandWords}
        onChangeEndCommandWords={onChangeEndCommandWords}
      />
    </>
  );
};
