import { MRT_TableOptions } from 'mantine-react-table';
import { User } from 'stores/auth';
import classes from './UserTable.module.css';

export const userTableDefs = {
  getRowId: (original) => original.userId,
  enableSorting: true,
  enableFilterMatchHighlighting: true,
  enablePagination: false,
  enableBottomToolbar: false,
  enableStickyHeader: true,
  enableColumnActions: false,
  layoutMode: 'grid',
  mantinePaperProps: { className: classes.paper },
  mantineTableContainerProps: { className: classes.container },
} satisfies Partial<MRT_TableOptions<User>>;
