import { PropsWithChildren, useEffect } from 'react';
import cn from 'classnames';
import {
  Button,
  Collapse,
  Divider,
  Flex,
  Popover,
  ScrollArea,
  Stack,
  Text,
  ThemeIcon,
  UnstyledButton,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { ValidationOopsie } from 'stores/wizard/wizard.types';
import { ValidationOopsieItem, testIds as itemTestIds } from './ValidationErrorItem';

interface ValidationErrorListPopoverProps extends PropsWithChildren {
  items: ValidationOopsie[];
  onClickItem: (item: ValidationOopsie) => void;
  onDismiss: () => void;
}

export const testIds = {
  card: 'validation-error-list-card',
  title: 'validation-error-list-title',
  dismissButton: 'validation-error-list-dismiss-button',
  collapseChevron: 'validation-error-list-collapse-chevron',
  collapseButton: 'validation-error-list-collapse-button',
  list: 'validation-error-list',
  item: itemTestIds,
};

export const ValidationErrorListPopover = ({
  children,
  items,
  onClickItem,
  onDismiss,
}: ValidationErrorListPopoverProps) => {
  const { t } = useTranslation();
  const [expanded, { open: expand, toggle: toggleExpand }] = useDisclosure(true);

  const handleClickItem = (item: ValidationOopsie) => {
    if (item.queryParams) return () => onClickItem(item);
    return undefined;
  };

  useEffect(() => {
    expand();
  }, [items]);

  return (
    <Popover closeOnClickOutside={false} opened={Boolean(items.length)} position='bottom-end'>
      <Popover.Target>{children}</Popover.Target>
      <Popover.Dropdown p='zero' w={350} data-testid={testIds.card}>
        <Flex align='center' justify='space-between' p='xs'>
          <UnstyledButton onClick={toggleExpand} data-testid={testIds.collapseButton}>
            <Flex align='center' gap='sm'>
              <ThemeIcon color='gray' variant='transparent' data-testid={testIds.collapseChevron}>
                <IconChevronDown className={cn({ 'rotate-180': !expanded })} />
              </ThemeIcon>
              <Stack gap='zero' data-testid={testIds.title}>
                <Text fw={700} size='sm'>
                  {t('wizard.validationList.title')}
                </Text>
                <Text size='xs'>{t('wizard.validationList.message')}</Text>
              </Stack>
            </Flex>
          </UnstyledButton>
          <Button variant='outline' color='gray' size='xs' onClick={onDismiss} data-testid={testIds.dismissButton}>
            {t('common.dismiss')}
          </Button>
        </Flex>
        <Collapse in={expanded}>
          <Divider />
          <ScrollArea pb='sm'>
            <Stack gap='zero' px='sm' pt='sm' mah={120} data-testid={testIds.list}>
              {items.map((item) => (
                <ValidationOopsieItem key={item.text} text={item.text} onClick={handleClickItem(item)} />
              ))}
            </Stack>
          </ScrollArea>
        </Collapse>
      </Popover.Dropdown>
    </Popover>
  );
};
