import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Group, Text } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';
import { User } from 'stores/auth';

interface DeleteUserModalProps {
  opened: boolean;
  user: User;
  onClose: () => void;
  onDelete: () => Promise<void>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  closeButton: modalBaseTestIds.close,
  message: 'delete-user-message',
  modal: 'delete-user-modal',
  confirmButton: 'delete-user-confirm-button',
};

export const DeleteUserModal = ({ opened, user, onClose, onDelete }: DeleteUserModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const name = `${user?.givenName} ${user?.familyName}`;

  const onConfirm = async () => {
    setLoading(true);
    await onDelete();
    setLoading(false);
    onClose();
  };

  return (
    <ModalBase
      closable={!loading}
      title={t('userMgmt.users.userDetails.panels.details.deleteUser.modal.title', { name })}
      opened={opened}
      variant='warning'
      onClose={onClose}
      data-testid={testIds.modal}
    >
      <Text mb='lg' data-testid={testIds.message}>
        {t('userMgmt.users.userDetails.panels.details.deleteUser.modal.message', { name })}
      </Text>
      <Group justify='end'>
        <Button onClick={onConfirm} loading={loading} data-testid={testIds.confirmButton}>
          {t('common.delete')}
        </Button>
      </Group>
    </ModalBase>
  );
};
