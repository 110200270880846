// FIXME: Most types here will eventually be replaced by the types from @ai-platform/common-types

import { DataGenerationSettingsForRequest } from '@ai-platform/data-generation-types';

export interface GraphQLResponse<T> {
  data: T;
  errors?: any[];
}

export enum DataGenerationStatus {
  success = 'success',
  failure = 'failure',
}

export type DataGenExecutionId = string;

export enum StartTrainModel {
  both = 'both',
  nlp = 'nlp',
  asr = 'asr',
}

export enum TrainStatus {
  success = 'success',
  failure = 'failure',
}

export type TrainExecutionId = string;

export interface DataGenUISettings extends DataGenerationSettingsForRequest {
  initialSamplesNumber?: number; // TODO support this attribute in appsync and remove this interface extension
}

export interface StartTrainRequest {
  executionId: DataGenExecutionId;
  trainingModel: StartTrainModel;
  asrConfig: Record<string, string>;
  nlpConfig: Record<string, string>;
}

export interface StartTrainResponse {
  status: TrainStatus;
  executionId: TrainExecutionId;
  prefectMonitorUrl: string;
}

export interface GetSamplesRequest {
  executionId: DataGenExecutionId;
}

export interface GetSamplesResponse {
  textSample: string;
  voiceSample: string;
}

export interface AbortDataGenerationRequest {
  executionId: DataGenExecutionId;
}

export interface DeclineDataGenerationRequest {
  executionId: DataGenExecutionId;
}
