import { TextInput } from '@mantine/core';
import { InfoLabel } from 'components';
import { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

interface DefaultValueInputProps {
  required?: boolean;
  rootClassName?: string;
  value?: string;
  disabled?: boolean;
  onChange: (value?: string) => void;
}

export const testIds = {
  input: 'default-value-input',
  label: 'default-value-input-label',
};

export const DefaultValueInput = ({ required, rootClassName, value, disabled, onChange }: DefaultValueInputProps) => {
  const { t } = useTranslation();

  const onChangeValue: ChangeEventHandler<HTMLInputElement> = (event) => {
    const newValue = event.currentTarget.value;
    if (!newValue) onChange(undefined);
    else onChange(newValue);
  };

  return (
    <TextInput
      classNames={{ root: rootClassName }}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.title')}
        </InfoLabel>
      }
      placeholder={t('wizard.steps.reportedEvents.typeSettings.defaultValueInput.placeholder')}
      required={required}
      value={value}
      disabled={disabled}
      onChange={onChangeValue}
      data-testid={testIds.input}
    />
  );
};
