import { ChangeEventHandler, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  CloseButton,
  Divider,
  Flex,
  Group,
  Highlight,
  ScrollArea,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';
import { StepsCounter } from 'components/StepsCounter/StepsCounter';
import { IconSearch } from '@tabler/icons-react';
import { usePoliciesArray } from 'stores/userMgmt';
import { useCreateGroupForm } from './GroupTableCreateAction';
import { CREATE_GROUP_POLICIES_STEP, CREATE_GROUP_STEPS } from './GroupTableCreateAction.const';

interface AddGroupPoliciesModalProps {
  opened: boolean;
  loading: boolean;
  onClose: () => void;
  onBack: () => void;
  onSubmit: () => void;
}

export const testIds = {
  title: modalBaseTestIds.title,
  stepName: 'add-group-policies-modal',
  search: 'add-group-policies-modal-search',
  clearSearch: 'add-group-policies-modal-clear-search',
  getPolicy: (id: string) => `add-group-policies-modal-policy-${id}`,
  all: 'add-group-policies-modal-all-checkbox',
  stepper: 'add-group-policies-modal-stepper',
  backButton: 'add-group-policies-modal-back-button',
  createButton: 'add-group-policies-modal-create-button',
};

export const AddGroupPoliciesModal = ({ opened, loading, onClose, onBack, onSubmit }: AddGroupPoliciesModalProps) => {
  const { t } = useTranslation();
  const policies = usePoliciesArray();
  const { getValues, setValues } = useCreateGroupForm();
  const [search, setSearch] = useState('');
  const [selectedSet, setSelectedSet] = useState<Set<string>>(new Set(getValues().policyIds));

  useEffect(() => setValues({ policyIds: Array.from(selectedSet) }), [selectedSet, setValues]);

  const onSearch: ChangeEventHandler<HTMLInputElement> = (event) => setSearch(event.currentTarget.value);
  const onClear = () => setSearch('');

  const filteredPolicies = useMemo(
    () => policies.filter((policy) => policy.name.toLowerCase().includes(search.toLowerCase())),
    [policies, search],
  );

  const allSelected = filteredPolicies.length === selectedSet.size;
  const someSelected = selectedSet.size > 0;

  const onChangeAll = () =>
    setSelectedSet(allSelected ? new Set() : new Set(filteredPolicies.map((policy) => policy.id)));

  const handleChangeSelected = (ids: string[]) => setSelectedSet(new Set(ids));

  return (
    <ModalBase
      opened={opened}
      title={t('userMgmt.groups.table.actions.create.title')}
      headerProps={{ mb: 'none', pb: '0' }}
      bodyProps={{ p: 'zero' }}
      onClose={onClose}
    >
      <Text size='sm' fw={400} c='dark.2' data-testid={testIds.stepName} mb='md' px='lg'>
        {t('userMgmt.groups.table.actions.create.addPoliciesModal.subtitle')}
      </Text>
      <Box pt='tiny'>
        <Flex px='lg' gap='lg'>
          <TextInput
            flex={1}
            placeholder={t('userMgmt.groups.table.actions.create.addPoliciesModal.searchInput.placeholder')}
            rightSection={search && <CloseButton onClick={onClear} data-testid={testIds.clearSearch} />}
            leftSection={<IconSearch size={18} />}
            value={search}
            onChange={onSearch}
            data-testid={testIds.search}
          />
        </Flex>
        <ScrollArea h={288}>
          <Stack p='md' gap='md'>
            <Checkbox
              label={t('common.all')}
              checked={allSelected}
              indeterminate={!allSelected && someSelected}
              onChange={onChangeAll}
              data-testid={testIds.all}
            />
            <Checkbox.Group value={Array.from(selectedSet)} onChange={handleChangeSelected}>
              <Stack gap='md' pl='xl'>
                {filteredPolicies.map(({ id, name }) => (
                  <Checkbox
                    key={id}
                    value={id}
                    label={
                      <Highlight highlight={search} size='sm'>
                        {name}
                      </Highlight>
                    }
                    data-testid={testIds.getPolicy(id)}
                  />
                ))}
              </Stack>
            </Checkbox.Group>
          </Stack>
        </ScrollArea>
      </Box>
      <Divider />
      <Group p='lg' justify='end'>
        <StepsCounter
          currentStep={CREATE_GROUP_POLICIES_STEP}
          numberOfSteps={CREATE_GROUP_STEPS}
          mr='auto'
          data-testid={testIds.stepper}
        />
        <Button variant='subtle' disabled={loading} onClick={onBack} data-testid={testIds.backButton}>
          {t('common.back')}
        </Button>
        <Button loading={loading} onClick={onSubmit} data-testid={testIds.createButton}>
          {t('common.create')}
        </Button>
      </Group>
    </ModalBase>
  );
};
