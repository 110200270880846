import { supportedLanguages } from '@ai-platform/common-types';
import { ComboboxItem, ComboboxLikeRenderOptionInput, Select, SelectProps, Text } from '@mantine/core';
import React, { useState } from 'react';

type Language = (typeof supportedLanguages)[number];

interface LanguageSelectProps extends Omit<SelectProps, 'onChange' | 'renderOption'> {
  initialValue?: Language['code'];
  languages?: Language[];
  getOptionlabel?: (language: Language) => string;
  renderOption?: (item: ComboboxLikeRenderOptionInput<ComboboxItem>, testId: string) => React.ReactNode;
  onChange?: (value: string) => void;
}

export const testIds = {
  input: 'language-select-input',
  getOptionId: (key: string) => `language-select-option-${key}`,
};

export const LanguageSelect = ({
  initialValue,
  languages = supportedLanguages,
  getOptionlabel: getOptionLabel = ({ code, language }: Language) => `${language} | ${code}`,
  renderOption = ({ option }, testId) => <Text data-testid={testId}>{option.label}</Text>,
  onChange,
  ...inputProps
}: LanguageSelectProps) => {
  const [value, setValue] = useState(initialValue ?? '');

  const hangleSelectChange = (newValue: string | null) => {
    if (newValue) {
      onChange?.(newValue);
      setValue(newValue);
    }
  };

  return (
    <Select
      {...inputProps}
      value={value}
      data={languages.map((l) => ({ value: l.code, label: getOptionLabel(l) }))}
      renderOption={(item) => renderOption(item, testIds.getOptionId(item.option.value))}
      searchable
      allowDeselect={false}
      onChange={hangleSelectChange}
      data-testid={testIds.input}
    />
  );
};
