import { useTranslation } from 'react-i18next';
import { Flex, Image, Stack, Text } from '@mantine/core';

interface SidebarLogoProps {
  sidebarExpanded: boolean;
}

export const testIds = {
  wrapper: 'flow-sidebar-title-wrapper',
  icon: 'flow-sidebar-title-icon',
  texts: 'flow-sidebar-title-texts',
};

export const SidebarLogo = ({ sidebarExpanded }: SidebarLogoProps) => {
  const { t } = useTranslation();

  return (
    <Flex gap='xs' px='teeny' py='tiny' wrap='nowrap' data-testid={testIds.wrapper}>
      <Image src='/LogoWhite.svg' className='shrink-0' height={36} width={36} data-testid={testIds.icon} />
      {sidebarExpanded && (
        <Stack className='shrink-0' gap='zero' data-testid={testIds.texts}>
          <Text c='white' fw={600} lh={1.2} size='md'>
            {t('appName')}
          </Text>
          <Text c='white' size='xs'>
            {t('navbar.subtitle')}
          </Text>
        </Stack>
      )}
    </Flex>
  );
};
