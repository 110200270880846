import { Flow } from 'stores/flows';
import { Folder } from 'stores/folders';
import { Flex, ThemeIcon } from '@mantine/core';
import { MRT_ColumnDef } from 'mantine-react-table';
import { IconArchiveFilled, IconFolder, IconFolderOpen } from '@tabler/icons-react';
import { isArchive, isFolder } from '../../FlowTable.utils';

export const testIds = {
  flow: {
    name: 'flow-table-name-cell',
  },
  folder: {
    name: 'flow-table-folder-name-cell',
    closedIcon: 'flow-table-folder-closed-icon',
    openIcon: 'flow-table-folder-open-icon',
  },
  archive: {
    name: 'flow-table-archive-name-cell',
    icon: 'flow-table-archive-icon',
  },
};

export const NameCell: MRT_ColumnDef<Flow | Folder>['Cell'] = ({ row }) => {
  if (isArchive(row.original)) {
    return (
      <Flex align='center' gap='sm' h={34} data-testid={testIds.archive.name}>
        <ThemeIcon color='violet' variant='transparent'>
          <IconArchiveFilled data-testid={testIds.archive.icon} />
        </ThemeIcon>
        {row.original.name}
      </Flex>
    );
  }
  if (isFolder(row.original)) {
    return (
      <Flex align='center' gap='sm' h={34} data-testid={testIds.folder.name}>
        <ThemeIcon color='gray' variant='transparent'>
          {row.getIsExpanded() ? (
            <IconFolderOpen data-testid={testIds.folder.openIcon} />
          ) : (
            <IconFolder data-testid={testIds.folder.closedIcon} />
          )}
        </ThemeIcon>
        {row.original.name} ({row.original.flowIds.length})
      </Flex>
    );
  }
  const shouldHaveIndent = row.depth > 0;
  return (
    <Flex align='center' h={34} ml={shouldHaveIndent ? 52 : 0} data-testid={testIds.flow.name}>
      {row.original.name}
    </Flex>
  );
};
