import { Flex, Group } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { User } from 'stores/auth';
import { CreateUserPayload } from 'stores/userMgmt';
import { NiceResponse } from 'consts';
import { UserTableState } from '../../UserTable.types';
import { UserTableSearch, testIds as searchTestIds } from './UserTableSearch';
import { UserTableUploadFileAction, testIds as uploadTestIds } from './UserTableUploadFileAction';
import { UserTableManualCreateAction, testIds as manualTestIds } from './UserTableManualCreateAction';

type UserTableActionsProps = { table: MRT_TableInstance<User> };

export const testIds = {
  search: searchTestIds,
  upload: uploadTestIds,
  manual: manualTestIds,
};

export const UserTableActions = ({ table }: UserTableActionsProps) => {
  const { globalFilter, onSearch, onUploadFile, onCreateUser } = table.getState() as UserTableState;

  const onCreate = async (payload: CreateUserPayload) => {
    const response = await onCreateUser(payload);
    return response ? NiceResponse.OK : NiceResponse.ERROR;
  };

  return (
    <Flex justify='space-between' p='md' align='center'>
      <UserTableSearch value={globalFilter} onChange={onSearch} />
      <Group gap='xs' align='center'>
        <UserTableUploadFileAction onUpload={onUploadFile} />
        <UserTableManualCreateAction onCreate={onCreate} />
      </Group>
    </Flex>
  );
};
