import { Flex, Stack, Text } from '@mantine/core';
import { MRT_TableInstance } from 'mantine-react-table';
import { User } from 'stores/auth';
import { useTranslation } from 'react-i18next';
import { testIds as searchTestIds, UsersTableSearch } from './UsersTableSearch';
import { testIds as manageActionTestIds, UsersTableManageAction } from './UsersTableManageAction';
import { UsersTableState } from '../../UsersTable.types';

type UsersTableActionsProps = { table: MRT_TableInstance<User> };

export const testIds = {
  search: searchTestIds,
  manage: manageActionTestIds,
  count: 'users-table-count',
};

export const UsersTableActions = ({ table }: UsersTableActionsProps) => {
  const { t } = useTranslation();
  const { globalFilter, onSearch, users, group, onChangeUsers } = table.getState() as UsersTableState;
  const { data } = table.options;

  return (
    <Stack gap='xs' p='md'>
      <Flex justify='space-between' align='center'>
        <UsersTableSearch value={globalFilter} onChange={onSearch} />
        <UsersTableManageAction users={users} group={group} onChangeUsers={onChangeUsers} />
      </Flex>
      <Text size='xs' c='gray.5' data-testid={testIds.count}>
        {t('userMgmt.groups.groupDetails.panels.users.table.userCount', { count: data.length })}
      </Text>
    </Stack>
  );
};
