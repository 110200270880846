import { FlowMetadataField, FlowMetadataFieldType } from 'stores/wizard';
import { MRT_ColumnDef } from 'mantine-react-table';
import { Select } from '@mantine/core';
import { MetadataTableState } from '../../MetadataTable.types';
import { fieldTypeIcons, options } from './FieldTypeCell.utils';

export const testIds = {
  select: 'field-type-select',
  getIconTestId: (type: FlowMetadataFieldType) => `meta-field-type-options-${type}`,
};

export const FieldTypeCell: MRT_ColumnDef<FlowMetadataField>['Cell'] = ({ row, table }) => {
  const { type } = row.original;
  const Icon = fieldTypeIcons[type];

  const onChangeValue = (value: string | null) => {
    const { onChange } = table.getState() as MetadataTableState;
    if (value) onChange(row.id, { type: value as FlowMetadataFieldType });
  };

  return (
    <Select
      data={options}
      leftSection={<Icon data-testid={testIds.getIconTestId(type)} />}
      value={type}
      variant='unstyled'
      onChange={onChangeValue}
      data-testid={testIds.select}
    />
  );
};
