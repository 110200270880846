import { useState } from 'react';
import { TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import { PLACEHOLDER_LENGTH } from 'consts';
import { FlowMetadataField } from 'stores/wizard';
import { getHotkeyHandler } from '@mantine/hooks';
import { MetadataTableState } from '../../MetadataTable.types';

export const testIds = {
  input: 'metadata-table-placeholder-input',
};

export const PlaceholderEdit: NonNullable<MRT_ColumnDef<FlowMetadataField>['Edit']> = ({ row, table }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>(row.original.placeholder ?? '');
  const hasLengthWarning = value.length > PLACEHOLDER_LENGTH;
  const { onChange } = table.getState() as MetadataTableState;

  const onBlur = () => {
    onChange(row.id, { placeholder: value || undefined });
    table.setEditingCell(null);
  };

  return (
    <TextInput
      classNames={{ input: 'mb-2' }}
      description={!hasLengthWarning && t('common.characterLimitRecommendation', { limit: 20 })}
      error={hasLengthWarning && t('common.characterLimitRecommendation', { limit: 20 })}
      inputWrapperOrder={['label', 'input', 'error', 'description']}
      autoFocus
      value={value}
      onBlur={onBlur}
      onChange={(e) => setValue(e.currentTarget.value)}
      onKeyDown={getHotkeyHandler([['Enter', onBlur]])}
      data-testid={testIds.input}
      data-warning={hasLengthWarning}
    />
  );
};
