import { Box, Flex, Image, Paper, PaperProps, Text } from '@mantine/core';
import { PropsWithChildren } from 'react';

interface EmptyStateProps extends PropsWithChildren, PaperProps {
  title: string;
  message?: string;
}

export const testIds = {
  container: 'empty-state-container',
  logo: 'empty-state-logo',
  title: 'empty-state-title',
  message: 'empty-state-message',
  children: 'empty-state-children',
};

export const EmptyState = ({ title, message, children, ...props }: EmptyStateProps) => (
  <Paper py='xl' data-testid={testIds.container} {...props}>
    <Flex direction='column' align='center' justify='center' gap='tn'>
      <Image
        src='/LoopGray.svg'
        w={36}
        h={36}
        className='animate-[spin_3s_infinite_ease-in-out] grayscale-[70%]'
        data-testid={testIds.logo}
      />
      <Text fw={600} c='dark.2' data-testid={testIds.title}>
        {title}
      </Text>
      {message && (
        <Text fw={400} className='text-center' c='dark.2' data-testid={testIds.message}>
          {message}
        </Text>
      )}
      {children && (
        <Box data-testid={testIds.children} mt='lg'>
          {children}
        </Box>
      )}
    </Flex>
  </Paper>
);
