import { useCallback, useMemo } from 'react';
import { MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CustomerRouteParams, ROUTES } from 'routes/routes.config';
import {
  CreateGroupPayload,
  UpdateGroupPayload,
  usePolicyArray,
  useUserArray,
  useUserMgmtStore,
  useUserMinifier,
} from 'stores/userMgmt';
import { columns } from './GroupTable.columns';
import { groupTableDefs } from './GroupTable.defs';
import { GroupTableState } from './GroupTable.types';
import {
  actionsTestIds,
  emptyStateTestIds,
  GroupTableActions,
  GroupTableRowActions,
  rowActionsTestIds,
} from './components';
import { GroupTableEmptyState } from './components/GroupTableEmptyState';

export const testIds = {
  getRowTestId: (id: string) => `group-table-row-${id}`,
  table: 'group-table',
  emptyState: emptyStateTestIds,
  actions: actionsTestIds,
  rowActions: rowActionsTestIds,
};

export const GroupTable = () => {
  const { customerId } = useParams() as CustomerRouteParams;
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const { groups, upsertGroup, deleteGroup } = useUserMgmtStore(['groups', 'upsertGroup', 'deleteGroup']);
  const search = params.get('search') || '';
  const userArray = useUserArray();
  const policyArray = usePolicyArray();
  const data = useMemo(() => Object.values(groups), [groups]);
  const getMiniUsers = useUserMinifier();

  const onSearch = useCallback((value: string) => {
    setParams({ search: value });
  }, []);

  const onCreateGroup: GroupTableState['onCreateGroup'] = useCallback(
    async (payload: CreateGroupPayload) => upsertGroup(customerId, { action: 'create', payload }),
    [customerId],
  );

  const onDeleteGroup: GroupTableState['onDeleteGroup'] = useCallback(
    async (groupId) => deleteGroup(customerId, groupId),
    [customerId],
  );

  const onMapUsersToGroup: GroupTableState['onMapUsersToGroup'] = useCallback(
    async (groupId, userIds) => {
      const miniUsers = getMiniUsers(userIds);
      const group = groups[groupId];

      const payload: UpdateGroupPayload = {
        ...group,
        users: miniUsers.map((user) => user.userId),
        policies: group.policies.map((policy) => policy.id),
      };

      const response = await upsertGroup(customerId, { action: 'update', groupId, payload });
      return Boolean(response);
    },
    [customerId, groups],
  );

  const onMapPoliciesToGroup: GroupTableState['onMapPoliciesToGroup'] = useCallback(
    async (groupId, policiesIds) => {
      const group = groups[groupId];

      const payload: UpdateGroupPayload = {
        ...group,
        users: group.users.map((user) => user.userId),
        policies: policiesIds,
      };

      const response = await upsertGroup(customerId, { action: 'update', groupId, payload });
      return Boolean(response);
    },
    [customerId, groups],
  );

  const table = useMantineReactTable({
    data,
    columns,
    ...groupTableDefs,
    mantineTableBodyRowProps: ({ row }) => ({
      className: 'cursor-pointer',
      onClick: () => navigate(ROUTES.USER_MGMT(customerId).GROUP(row.id)),
      'data-testid': testIds.getRowTestId(row.id),
    }),
    renderEmptyRowsFallback: (props) => <GroupTableEmptyState {...props} />,
    renderTopToolbar: (props) => <GroupTableActions {...props} />,
    renderRowActions: (props) => <GroupTableRowActions {...props} />,
    mantineTableProps: () => ({ className: '', 'data-testid': testIds.table }),
    state: {
      globalFilter: search,
      users: userArray,
      policies: policyArray,
      onSearch,
      onCreateGroup,
      onDeleteGroup,
      onMapUsersToGroup,
      onMapPoliciesToGroup,
    } as GroupTableState,
  });

  return <MantineReactTable table={table} data-testid={testIds.table} />;
};
