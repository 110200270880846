import { Box, Button, Group, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { OpenGlobalModalProps, useModal } from 'stores/globalModal';
import i18n from 'services/i18n';

interface DeleteChildlessEventDefModalProps {
  onDelete: () => void;
}

export const testIds = {
  container: 'event-def-delete-modal-container',
  delete: 'event-def-delete-modal-delete-button',
  cancel: 'event-def-delete-modal-cancel-button',
  message: 'event-def-delete-modal-message',
};

export const DeleteChildlessEventDefModal = ({ onDelete }: DeleteChildlessEventDefModalProps) => {
  const { t } = useTranslation();
  const { close } = useModal();

  const onSubmit = () => {
    onDelete();
    close();
  };

  return (
    <Box data-testid={testIds.container}>
      <Text c='gray.6' data-testid={testIds.message}>
        {t('wizard.steps.reportedEvents.modals.delete.messageChildless')}
      </Text>
      <Group justify='end' mt='lg'>
        <Button variant='subtle' onClick={close} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} data-testid={testIds.delete}>
          {t('common.delete')}
        </Button>
      </Group>
    </Box>
  );
};

export const deleteChildlessEventDefGlobalModalProps = (
  props: DeleteChildlessEventDefModalProps,
): OpenGlobalModalProps => ({
  title: i18n.t('wizard.steps.reportedEvents.modals.delete.title'),
  children: <DeleteChildlessEventDefModal {...props} />,
  variant: 'warning',
});
