import {
  Flow as ApiFlow,
  CreateFlowRequest,
  GetFlowsResponse,
  PatchFlowRequest,
  UpdateFlowRequest,
} from '@jargonic/flows-types';
import qs from 'qs';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { ImportJargonResponse, PublishJargonResponse } from '@jargonic/jargon-types';
import { PartialErrorResponse } from 'consts';
import { Flow, FlowId } from './flows.types';
import { flowFromApi } from './flows.adapters';

const customerUrl = (customerId: string) => `${config.jargonicApiUrl}/${customerId}`;
const flowUrl = (customerId: string, flowId: string) => `${customerUrl(customerId)}/flows/${flowId}`;

async function getFlowsRecursively(customerId: string, flows: ApiFlow[], nextToken?: string) {
  const query = {
    nextToken,
    version: 'jargonic',
  };
  const url = `${customerUrl(customerId)}/flows?${qs.stringify(query)}`;
  const { data } = await hermes.get<GetFlowsResponse>(url, { fallback: { items: [] } });

  flows.push(...data.items);

  if (data.nextToken) await getFlowsRecursively(customerId, flows, data.nextToken);
  return flows;
}

export const flowsApi = {
  getFlowById: async (customerId: string, flowId: string): Promise<Flow | undefined> => {
    const query = { version: 'jargonic' };
    const url = `${flowUrl(customerId, flowId)}?${qs.stringify(query)}`;
    const { data } = await hermes.get<ApiFlow>(url);
    return flowFromApi(data);
  },

  getFlows: async (customerId: string): Promise<Flow[]> => {
    const flows = await getFlowsRecursively(customerId, []);
    return flows.map(flowFromApi) as Flow[];
  },

  createFlow: async (customerId: string, request: CreateFlowRequest): Promise<Flow | undefined> => {
    const url = `${customerUrl(customerId)}/flows`;
    const { data } = await hermes.post<ApiFlow>(url, request);
    return flowFromApi(data);
  },

  archiveFlow: async (customerId: string, flowId: string): Promise<Flow | undefined> => {
    const url = `${flowUrl(customerId, flowId)}/archive`;
    const { data } = await hermes.post<Flow>(url, {});
    return flowFromApi(data);
  },

  unarchiveFlow: async (customerId: string, flowId: string): Promise<Flow | undefined> => {
    const url = `${flowUrl(customerId, flowId)}/unarchive`;
    const { data } = await hermes.post<Flow>(url, {});
    return flowFromApi(data);
  },

  updateFlow: async (
    customerId: string,
    flowId: string,
    request: Partial<UpdateFlowRequest>,
  ): Promise<Flow | undefined> => {
    const url = `${flowUrl(customerId, flowId)}`;
    const { data } = await hermes.patch<Flow>(url, request);
    return flowFromApi(data);
  },

  importFlow: async (customerId: string, file: File): Promise<{ flowId?: FlowId } & PartialErrorResponse> => {
    const url = `${customerUrl(customerId)}/jargon/import`;
    const formData = new FormData();
    formData.append('file', file);
    const response = await hermes.post<ImportJargonResponse>(url, formData, {
      config: { headers: { 'Content-Type': 'multipart/form-data' } },
    });

    if (response?.data?.flowId) {
      return {
        flowId: response.data.flowId,
        statusText: response.statusText,
      };
    }

    if (response.error) {
      const errorData = response.error?.response?.data as PartialErrorResponse;
      const errorMessage = errorData?.message;
      return {
        message: errorMessage,
        statusText: response.statusText,
      };
    }

    return {
      statusText: 'unknown',
    };
  },

  bulkUpdateFlows: async (customerId: string, request: PatchFlowRequest[]): Promise<Flow[]> => {
    const url = `${customerUrl(customerId)}/flows/bulk`;
    const { data } = await hermes.patch<ApiFlow[]>(url, request);
    return data?.map(flowFromApi) as Flow[];
  },

  cloneFlow: async (customerId: string, flowId: string): Promise<Flow[] | undefined> => {
    const url = `${customerUrl(customerId)}/jargon/${flowId}/clone`;
    const { data } = await hermes.post<Flow[]>(url, {});
    return data?.map(flowFromApi) as Flow[];
  },

  publishFlow: async (customerId: string, flowId: string): Promise<PublishJargonResponse | undefined> => {
    const url = `${customerUrl(customerId)}/jargon/${flowId}/publish`;
    const { data } = await hermes.post<PublishJargonResponse>(url, {});
    return data;
  },

  deleteFlow: async (customerId: string, flowId: string): Promise<boolean> => {
    const url = `${flowUrl(customerId, flowId)}`;
    const { data } = await hermes.delete(url);
    return data;
  },
};
