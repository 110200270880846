import { Text } from '@mantine/core';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { Permission } from 'stores/userMgmt';

export const testIds = {
  text: 'actions-cell',
};

export const ActionsCell: NonNullable<MRT_ColumnDef<Permission>['Cell']> = ({ row }) => {
  const text = useMemo(() => row.original.actions.join(', '), [row.original.actions]);

  return (
    <Text size='sm' data-testid={testIds.text}>
      {text}
    </Text>
  );
};
