import { ElementProps, TagsInputProps } from '@mantine/core';
import { SynonymsInput } from 'components';
import { useTranslation } from 'react-i18next';

type ValueSynonymsInputProps = TagsInputProps & ElementProps<'input', keyof TagsInputProps>;

export const testIds = {
  input: 'value-settings-modal-synonyms-input',
  label: 'value-settings-modal-synonyms-input-label',
};

export const ValueSynonymsInput = (props: ValueSynonymsInputProps) => {
  const { t } = useTranslation();

  return (
    <SynonymsInput
      infoText={t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.synonyms.tooltip')}
      placeholder={t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.synonyms.placeholder')}
      variant='filled'
      data-testid={testIds.input}
      {...props}
    />
  );
};
