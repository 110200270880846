import { ElementProps, Select, SelectProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';

type CreateContainerParentSelectProps = SelectProps & ElementProps<'input', keyof SelectProps>;

export const testIds = {
  input: 'create-container-menu-parent-select',
};

export const CreateContainerParentSelect = (props: CreateContainerParentSelectProps) => {
  const { t } = useTranslation();

  return (
    <Select
      clearable
      comboboxProps={{ withinPortal: false }}
      label={t('wizard.steps.labeling.containerTable.addItemMenu.parentSelect.title')}
      placeholder={t('wizard.steps.labeling.containerTable.addItemMenu.parentSelect.placeholder')}
      searchable
      size='xs'
      data-testid={testIds.input}
      {...props}
    />
  );
};
