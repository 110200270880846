import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useModal } from 'stores/globalModal';
import { Flow } from 'stores/flows';
import { EmptyState } from 'components';
import { createFlowGlobalModalProps } from '../../../modals';

interface FlowTableEmptyStateProps {
  onCreateFlow: (flow: Flow) => void;
  searchValue?: string;
}

export const testIds = {
  wrapper: 'flow-table-empty-state',
  button: 'flow-table-empty-state-button',
};

export const FlowTableEmptyState = ({ searchValue, onCreateFlow }: FlowTableEmptyStateProps) => {
  const { t } = useTranslation();
  const { open } = useModal();
  const text = searchValue
    ? { title: t('flowsPage.table.searchEmptyState.title'), message: t('flowsPage.table.searchEmptyState.message') }
    : { title: t('flowsPage.table.defaultEmptyState.title'), message: t('flowsPage.table.defaultEmptyState.message') };

  const onClickCreateFlow = () => open(createFlowGlobalModalProps({ onCreate: onCreateFlow }));

  return (
    <EmptyState title={text.title} message={text.message} py='xl' data-testid={testIds.wrapper}>
      {!searchValue && (
        <Button onClick={onClickCreateFlow} data-testid={testIds.button}>
          {t('flowsPage.table.defaultEmptyState.button')}
        </Button>
      )}
    </EmptyState>
  );
};
