import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconTrash, IconTrashOff } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { User } from 'stores/auth';
import { Button } from '@mantine/core';
import { DeleteUserModal, testIds as modalTestIds } from './DeleteUserModal';

interface DeleteUserActionProps {
  user: User;
  onDelete: () => Promise<boolean>;
}

export const testIds = {
  modal: modalTestIds,
  button: 'delete-user-action-button',
  icon: 'delete-user-action-icon',
};

export const DeleteUserAction = ({ user, onDelete }: DeleteUserActionProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure();

  const onConfirm = async () => {
    const name = `${user.givenName} ${user.familyName}`;
    const result = await onDelete();
    notifications.show({
      color: result ? 'blue' : 'red',
      title: result
        ? t('userMgmt.users.userDetails.panels.details.deleteUser.modal.successTitle', { name })
        : t('userMgmt.users.userDetails.panels.details.deleteUser.modal.errorTitle', { name }),
      message: !result && t('common.tryAgain'),
      icon: result ? <IconTrash /> : <IconTrashOff />,
    });
  };

  return (
    <>
      <Button color='red' variant='outline' w='fit-content' onClick={open} data-testid={testIds.button}>
        {t('userMgmt.users.userDetails.panels.details.deleteUser.button')}
      </Button>
      <DeleteUserModal opened={opened} user={user} onClose={close} onDelete={onConfirm} />
    </>
  );
};
