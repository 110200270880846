import qs from 'qs';
import { FlowStatus, PatchFlowRequest } from '@jargonic/flows-types';
import { Folder, FolderId } from 'stores/folders';
import { orderPredicate } from 'utils';
import { config } from 'services/config';
import { Flow, FlowId } from './flows.types';
import { LIVE_STATUS } from './flows.const';

export function isFlowLive(flow: Flow) {
  return !!flow.activeVersion;
}

export function getFlowStatus(flow: Flow) {
  if (isFlowLive(flow) && flow.status === FlowStatus.PUBLISHED) return LIVE_STATUS;
  return flow.status;
}

/** Reposition flow in array, in place */
function repositionFlow(flows: Flow[], from: number, to: number) {
  const [removed] = flows.splice(from, 1);
  flows.splice(to, 0, removed);
}

/** Place a new flow into an array */
function positionFlow(flows: Flow[], flow: Flow, index: number) {
  flows.splice(index, 0, flow);
}

/** Remove flow from array, in place */
function removeFlow(flows: Flow[], index: number) {
  flows.splice(index, 1);
}

/** Find all flows in the same folder */
function findFlows(flows: Record<FlowId, Flow>, folderId: FolderId | null) {
  return Object.values(flows)
    .filter((flow) => flow.folderId === folderId)
    .sort(orderPredicate);
}

/** Place or remove a flow to/from its own parent. Then, update the siblings and return those that were changed. */
function moveFlowInsideParent(flows: Record<FlowId, Flow>, flowId: FlowId, newOrder: number | null) {
  const flow = flows[flowId];
  const siblingFlows = findFlows(flows, flow.folderId);
  const currentFlowIndex = siblingFlows.findIndex((f) => f.id === flowId);
  const shouldRemoveFlow = newOrder === null;
  if (shouldRemoveFlow) removeFlow(siblingFlows, currentFlowIndex);
  else repositionFlow(siblingFlows, currentFlowIndex, newOrder);

  const reorderedFlowsStartIndex = newOrder === null ? currentFlowIndex : Math.min(currentFlowIndex, newOrder);
  const reorderedFlowsEndIndex = newOrder === null ? siblingFlows.length - 1 : Math.max(currentFlowIndex, newOrder);
  return siblingFlows
    .map((sibling, index) => ({ ...sibling, order: index }))
    .slice(reorderedFlowsStartIndex, reorderedFlowsEndIndex + 1);
}

/** Place a flow into a new parent. Then, update the new parent's children and return those that were changed. */
function moveFlowToAnotherParent(
  flows: Record<FlowId, Flow>,
  flowId: FlowId,
  folderId: FolderId | null,
  newOrder: number,
) {
  const flow = flows[flowId];
  const newSiblingFlows = findFlows(flows, folderId);
  positionFlow(newSiblingFlows, flow, newOrder);
  return newSiblingFlows.map((f, i) => ({ ...f, order: i, folderId })).slice(newOrder);
}

function getBulkPatchPayload(flows: Flow[]): PatchFlowRequest[] {
  return flows.map((flow) => ({
    id: flow.id,
    folderId: flow.folderId,
    order: flow.order,
  }));
}

export function getFlowReorderPayload(
  flows: Record<FlowId, Flow>,
  flowId: FlowId,
  folderId: FolderId | null,
  newOrder: number | null,
): PatchFlowRequest[] {
  const flow = flows[flowId];

  const flowDidMove = newOrder !== flow.order || folderId !== flow.folderId;
  if (!flowDidMove) return [];

  const flowWasRemoved = newOrder === null;
  if (flowWasRemoved) return getBulkPatchPayload(moveFlowInsideParent(flows, flowId, null));

  const parentDidChange = folderId !== flow.folderId;
  if (!parentDidChange) return getBulkPatchPayload(moveFlowInsideParent(flows, flowId, newOrder));

  const reorderedCurrentSiblings = moveFlowInsideParent(flows, flowId, null);
  const reorderedNewSiblings = moveFlowToAnotherParent(flows, flowId, folderId, newOrder);
  return getBulkPatchPayload([...reorderedCurrentSiblings, ...reorderedNewSiblings]);
}

export function getFolderFlowLists(
  flows: Record<FlowId, Flow>,
  folderIds: FolderId[],
): Pick<Folder, 'id' | 'flowIds'>[] {
  return folderIds.map((folderId) => ({
    id: folderId,
    flowIds: findFlows(flows, folderId).map((flow) => flow.id),
  }));
}

export function generateFlowDownloadUrl(customerId: string, flowId: string, version?: string) {
  const queryParams = { version };
  return `${config.jargonicApiUrl}/${customerId}/jargon/${flowId}/export?${qs.stringify(queryParams)}`;
}
