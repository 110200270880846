import { Box, Button, Group, Radio, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { OpenGlobalModalProps, useModal } from 'stores/globalModal';
import i18n from 'services/i18n';
import { useState } from 'react';

interface DeleteEventDefModalProps {
  onDelete: (withChildren: boolean) => void;
}

export const testIds = {
  container: 'event-def-delete-modal-container',
  delete: 'event-def-delete-modal-delete-button',
  cancel: 'event-def-delete-modal-cancel-button',
  message: 'event-def-delete-modal-message',
  options: {
    group: 'event-def-settings-delete-modal-options',
    move: 'event-def-settings-delete-modal-option-move',
    delete: 'event-def-settings-delete-modal-option-delete',
  },
};

type Action = 'move' | 'delete';

export const DeleteEventDefModal = ({ onDelete }: DeleteEventDefModalProps) => {
  const { t } = useTranslation();
  const { close } = useModal();
  const [action, setAction] = useState<Action>('move');

  const onChange = (value: string) => setAction(value as Action);
  const onSubmit = () => {
    onDelete(action === 'delete');
    close();
  };

  return (
    <Box data-testid={testIds.container}>
      <Text c='gray.6' data-testid={testIds.message}>
        {t('wizard.steps.reportedEvents.modals.delete.messageWithChildren')}
      </Text>
      <Radio.Group value={action} onChange={onChange} data-testid={testIds.options.group}>
        <Radio
          value='move'
          py='tn'
          label={t('wizard.steps.reportedEvents.modals.delete.optionMoveChildren')}
          data-testid={testIds.options.move}
        />
        <Radio
          value='delete'
          py='tn'
          label={t('wizard.steps.reportedEvents.modals.delete.optionDeleteChildren')}
          data-testid={testIds.options.delete}
        />
      </Radio.Group>
      <Group justify='end' mt='lg'>
        <Button variant='subtle' onClick={close} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} data-testid={testIds.delete}>
          {t('common.delete')}
        </Button>
      </Group>
    </Box>
  );
};

export const deleteEventDefGlobalModalProps = (props: DeleteEventDefModalProps): OpenGlobalModalProps => ({
  title: i18n.t('wizard.steps.reportedEvents.modals.delete.title'),
  children: <DeleteEventDefModal {...props} />,
  variant: 'warning',
});
