import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components';
import { Permission } from 'stores/userMgmt';

export const testIds = { wrapper: 'entity-table-empty-state' };

export const PermissionTableEmptyState: NonNullable<MRT_TableOptions<Permission>['renderEmptyRowsFallback']> = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('userMgmt.policies.policyDetails.panels.permissions.table.emptyState.title')}
      message={t('userMgmt.policies.policyDetails.panels.permissions.table.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
