import { PropsWithChildren } from 'react';
import { Menu, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { User } from 'stores/auth';
import { getGreeting } from './UserMenu.utils';
import { DarkModeOption, testIds as darkModeTestIds } from './DarkModeOption';
import { LogoutOption, testIds as logoutTestIds } from './LogoutOption';

interface UserMenuProps extends PropsWithChildren {
  colorScheme: 'light' | 'dark';
  user: User;
  onChangeColorScheme: () => void;
  onLogout: () => void;
}

export const testIds = {
  wrapper: 'user-menu-wrapper',
  label: 'user-menu-greeting',
  title: 'user-menu-title',
  email: 'user-menu-email',
  options: {
    darkMode: darkModeTestIds,
    logout: logoutTestIds,
  },
};

export const UserMenu = ({ children, colorScheme, user, onChangeColorScheme, onLogout }: UserMenuProps) => {
  const labelText = getGreeting(dayjs().hour(), user.givenName);

  return (
    <Menu position='bottom-end' closeOnItemClick={false}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown data-testid={testIds.wrapper}>
        <Menu.Label data-testid={testIds.label}>
          <Text c='black' size='xs' fw={600} data-testid={testIds.title}>
            {labelText}
          </Text>
          <Text size='12px' fw={400} data-testid={testIds.email}>
            {user.email}
          </Text>
        </Menu.Label>
        <DarkModeOption colorScheme={colorScheme} onClick={onChangeColorScheme} />
        <Menu.Divider />
        <LogoutOption onClick={onLogout} />
      </Menu.Dropdown>
    </Menu>
  );
};
