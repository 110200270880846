import { ModelType, State } from '@ai-platform/common-types';
import { useMemo } from 'react';
import { AiModelId, AiPublishedModel } from '../aiModels';
import { useAiPublishedModelStore } from './aiPublishedModels.store';

export function useAiModel(modelId: AiModelId): AiPublishedModel | undefined {
  return useAiPublishedModelStore((state) => state.aiPublishedModels[modelId]);
}

export function usePaginationToken(): string {
  return useAiPublishedModelStore(({ paginationToken }) => paginationToken);
}

export function useAiModelsByType<T extends ModelType>(type: T): AiPublishedModel<T>[] {
  const { aiPublishedModels } = useAiPublishedModelStore(['aiPublishedModels']);

  return useMemo(
    () =>
      Object.values(aiPublishedModels)
        .filter((model): model is AiPublishedModel<T> => model.type === type)
        .sort((a, b) => b.publishedDate - a.publishedDate),
    [aiPublishedModels, type],
  );
}

export function useSubscribedAiModels(): string[] {
  const { subscriptions } = useAiPublishedModelStore(['subscriptions']);
  return useMemo(() => Object.keys(subscriptions), [subscriptions]);
}

export function useLastPublishedModel(type: ModelType): AiPublishedModel | undefined {
  const aiPublishedModels = useAiModelsByType(type);

  return useMemo(
    () =>
      aiPublishedModels
        .filter((model) => model.status === State.DONE && model.publishedDate)
        .sort((a, b) => b.publishedDate - a.publishedDate)[0],
    [aiPublishedModels],
  );
}

export function useSubscribedPublishedModels(type: ModelType): AiPublishedModel[] | [] {
  const aiPublishedModels = useAiModelsByType(type);
  const subscriptions = useSubscribedAiModels();
  return useMemo(() => {
    const modelsToSubscribe = [];
    for (let i = 0; i < aiPublishedModels.length; i++) {
      if (
        !subscriptions.includes(aiPublishedModels[i].publishExecutionId) &&
        aiPublishedModels[i].status !== State.DONE
      ) {
        modelsToSubscribe.push(aiPublishedModels[i]);
      }
    }
    return modelsToSubscribe;
  }, [aiPublishedModels, subscriptions]);
}
