import { IconCalendar, IconClock, IconList, IconNumber, IconTextSize } from '@tabler/icons-react';
import i18n from 'services/i18n';
import { EventDefType } from 'stores/wizard';

export const testIds = {
  select: 'event-def-type-select',
  options: {
    [EventDefType.FREE_TEXT]: {
      option: 'event-def-type-select-option-text',
      icon: 'event-def-type-select-option-text-icon',
    },
    [EventDefType.APPEND_TEXT_EVENT]: {
      option: 'event-def-type-select-option-text',
      icon: 'event-def-type-select-option-text-icon',
    },
    [EventDefType.NUMBER]: {
      option: 'event-def-type-select-option-number',
      icon: 'event-def-type-select-option-number-icon',
    },
    [EventDefType.DATE]: {
      option: 'event-def-type-select-option-date',
      icon: 'event-def-type-select-option-date-icon',
    },
    [EventDefType.TIME_OF_DAY]: {
      option: 'event-def-type-select-option-time',
      icon: 'event-def-type-select-option-time-icon',
    },
    [EventDefType.LIST_OF_VALUES_SINGLE_SELECTION]: {
      option: 'event-def-type-select-option-select',
      icon: 'event-def-type-select-option-select-icon',
    },
    [EventDefType.LIST_OF_VALUES_MULTI_SELECTION]: {
      option: 'event-def-type-select-option-multi-select',
      icon: 'event-def-type-select-option-multi-select-icon',
    },
  } satisfies Record<EventDefType, { option: string; icon: string }>,
};

export const options = [
  {
    value: EventDefType.FREE_TEXT,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.typeSelect.options.text'),
    icon: <IconTextSize data-testid={testIds.options[EventDefType.FREE_TEXT].icon} />,
  },
  {
    value: EventDefType.APPEND_TEXT_EVENT,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.typeSelect.options.text'),
    icon: <IconTextSize data-testid={testIds.options[EventDefType.APPEND_TEXT_EVENT].icon} />,
  },
  {
    value: EventDefType.NUMBER,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.typeSelect.options.number'),
    icon: <IconNumber data-testid={testIds.options[EventDefType.NUMBER].icon} />,
  },
  {
    value: EventDefType.DATE,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.typeSelect.options.date'),
    icon: <IconCalendar data-testid={testIds.options[EventDefType.DATE].icon} />,
  },
  {
    value: EventDefType.TIME_OF_DAY,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.typeSelect.options.timeOfDay'),
    icon: <IconClock data-testid={testIds.options[EventDefType.TIME_OF_DAY].icon} />,
  },
  {
    value: EventDefType.LIST_OF_VALUES_SINGLE_SELECTION,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.typeSelect.options.select'),
    icon: <IconList data-testid={testIds.options[EventDefType.LIST_OF_VALUES_SINGLE_SELECTION].icon} />,
  },
  {
    value: EventDefType.LIST_OF_VALUES_MULTI_SELECTION,
    label: i18n.t('wizard.steps.reportedEvents.typeSettings.typeSelect.options.multiSelect'),
    icon: <IconList data-testid={testIds.options[EventDefType.LIST_OF_VALUES_MULTI_SELECTION].icon} />,
  },
];
