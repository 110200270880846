import Moveable from 'react-moveable';
import { Box } from '@mantine/core';
import { Annotation } from 'stores/wizard';
import { useRef, useState } from 'react';
import { useLabelColor } from '../../hooks';
import classes from './AnnotationBox.module.css';
import { AnnotationMenu } from '../AnnotationMenu/AnnotationMenu';
import { Processable, ProcessableProps } from './Processable';
import { getBoxId } from './AnnotationBox.utils';

interface AnnotationBoxProps {
  annotation: Annotation;
  isSelected: boolean;
  zoom: number;
  onDragToggle: (dragging: boolean) => void;
  onToggleSelected: () => void;
  onUpdateAnnotation: (coords: Partial<Pick<Annotation, 'x' | 'y' | 'width' | 'height'>>) => void;
}

export const testIds = {
  annotationRegex: /annotation-box-.*/,
  getAnnotationTestId: (id: string) => `annotation-box-${id}`,
};

const renderDirections = ['nw', 'ne', 'sw', 'se'];

export const AnnotationBox = ({
  annotation,
  isSelected,
  zoom,
  onDragToggle,
  onToggleSelected,
  onUpdateAnnotation,
}: AnnotationBoxProps) => {
  const moveableRef = useRef<Moveable>(null);
  const ref = useRef<HTMLDivElement>(null);
  const { id, x, y, width, height, processing } = annotation;
  const [[initialX, initialY, initialW, initialH]] = useState([x, y, width, height]);
  const color = useLabelColor(annotation.labelId) ?? 'gray';
  const annotationColor = `var(--mantine-color-${color}-filled)`;
  const inverseZoom = 1 / zoom;
  const targetId = `#${getBoxId(id)}`; // is set by Mantine menu

  const updateAnnotation = () => {
    const rect = moveableRef.current?.getRect();
    onUpdateAnnotation({ x: rect?.left, y: rect?.top, width: rect?.width, height: rect?.height });
  };

  const onDragEnd = () => {
    onDragToggle(false);
    updateAnnotation();
  };

  const processableProps: ProcessableProps = {
    processable: true,
    processing,
    inverseZoom,
  };

  return (
    <Box className={classes.wrapper} style={{ '--annotation-color': annotationColor }}>
      <AnnotationMenu id={id} labelId={annotation.labelId} opened={isSelected} onToggleOpen={onToggleSelected}>
        <Box
          ref={ref}
          pos='absolute'
          className='cursor-grab'
          left={initialX}
          top={initialY}
          w={initialW}
          h={initialH}
          bg={`${color}.1`}
          opacity={0.5}
          data-testid={testIds.getAnnotationTestId(id)}
        />
      </AnnotationMenu>
      <Moveable
        ref={moveableRef}
        ables={[Processable]}
        target={targetId}
        draggable
        throttleDrag={1}
        edgeDraggable={false}
        scalable
        origin={false}
        throttleScale={0}
        zoom={inverseZoom}
        renderDirections={renderDirections}
        props={processableProps}
        onDragStart={() => onDragToggle(true)}
        onDragEnd={onDragEnd}
        onDrag={(e) => {
          e.target.style.transform = e.transform;
        }}
        onScale={(e) => {
          e.target.style.transform = e.drag.transform;
        }}
        onScaleEnd={updateAnnotation}
      />
    </Box>
  );
};
