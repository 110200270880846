// TODO: Remove when AI Platform feature is publicly available
import { useHotkeys, useLocalStorage } from '@mantine/hooks';

const key = 'isAIPEnabled';

export function useAiPlatformFeatureVisibility() {
  const [isAIPEnabled] = useLocalStorage({ key, defaultValue: false });
  return isAIPEnabled;
}

export function useAiPlatformSecretAccess() {
  const [, setIsAIPEnabled] = useLocalStorage({ key, defaultValue: false });
  useHotkeys([['shift+A', () => setIsAIPEnabled(true)]]);
}
