import { Breadcrumbs, Text } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import { GroupRouteParams, PolicyRouteParams, ROUTES, UserRouteParams } from 'routes/routes.config';
import { useUserMgmtStore } from 'stores/userMgmt';

export const testIds = {
  wrapper: 'user-mgmt-breadcrumbs',
  location: 'user-mgmt-breadcrumbs-location',
  identity: 'user-mgmt-breadcrumbs-identity',
};

export const UserMgmtBreadcrumbs = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { customerId, userId, groupId, policyId } = useParams<UserRouteParams & GroupRouteParams & PolicyRouteParams>();
  const { users, policies, groups } = useUserMgmtStore(['users', 'policies', 'groups']);

  const { location, to, identity } = useMemo(() => {
    if (pathname.includes(ROUTES.USER_MGMT(customerId).USERS))
      return {
        location: t('userMgmt.navbar.users'),
        to: ROUTES.USER_MGMT(customerId).USERS,
        identity: userId && `${users[userId]?.givenName} ${users[userId]?.familyName}`,
      };
    if (pathname.includes(ROUTES.USER_MGMT(customerId).GROUPS))
      return {
        location: t('userMgmt.navbar.groups'),
        to: ROUTES.USER_MGMT(customerId).GROUPS,
        identity: groupId && `${groups[groupId]?.name}`,
      };
    if (pathname.includes(ROUTES.USER_MGMT(customerId).POLICIES))
      return {
        location: t('userMgmt.navbar.policies'),
        to: ROUTES.USER_MGMT(customerId).POLICIES,
        identity: policyId && policies[policyId]?.name,
      };
    return {
      location: t('userMgmt.title'),
      to: '#',
    };
  }, [pathname]);

  return (
    <Breadcrumbs data-testid={testIds.wrapper}>
      <Text component={Link} to={to} data-testid={testIds.location}>
        {location}
      </Text>
      {identity && <Text data-testid={testIds.identity}>{identity}</Text>}
    </Breadcrumbs>
  );
};
