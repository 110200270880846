import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { Permission } from 'stores/userMgmt';
import { ActionsCell, ResourcesCell, actionsCellTestIds, resourceCellTestIds } from './components';

export const testIds = {
  resources: resourceCellTestIds,
  actions: actionsCellTestIds,
};

export const columns: MRT_ColumnDef<Permission>[] = [
  {
    accessorKey: 'resource',
    header: i18n.t('userMgmt.policies.editPermission.serviceInput.label'),
    grow: false,
  },
  {
    accessorKey: 'actions',
    header: i18n.t('userMgmt.policies.editPermission.actionInput.label'),
    grow: false,
    Cell: ActionsCell,
  },
  {
    accessorKey: 'pattern',
    header: i18n.t('userMgmt.policies.editPermission.resourceInput.label'),
    Cell: ResourcesCell,
  },
];
