import { Divider, Flex } from '@mantine/core';
import { FlowProperties } from './FlowProperties';
import { ExecutionProperties } from './ExecutionProperties';

export const testIds = {
  wrapper: 'flow-settings-general-settings-wrapper',
};

export const GeneralSettings = () => (
  <Flex gap='xl' data-testid={testIds.wrapper}>
    <FlowProperties />
    <Divider orientation='vertical' />
    <ExecutionProperties />
  </Flex>
);
