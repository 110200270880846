import { Badge } from '@mantine/core';
import { AiModelEnvironment } from 'stores/aiPlatform';
import { envColors, envLabels } from './AiModelEnvBadge.const';

interface AiModelEnvBadgeProps {
  env: AiModelEnvironment;
}

export const testIds = {
  badge: 'ai-model-env-badge',
};

export const AiModelEnvBadge = ({ env, ...props }: AiModelEnvBadgeProps) => (
  <Badge variant='light' radius='sm' color={envColors[env]} data-testid={testIds.badge} {...props}>
    {envLabels[env]}
  </Badge>
);
