import { Avatar, Flex, Stack, Tabs, Title } from '@mantine/core';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { PolicyRouteParams, ROUTES } from 'routes/routes.config';
import { useUserMgmtStore } from 'stores/userMgmt';
import { IconLicense } from '@tabler/icons-react';
import { PolicyPanel, panels } from './PolicyPage.panels';
import { entityPanelTestIds, permissionsPanelTestIds } from './components';

export const testIds = {
  avatar: 'user-details-avatar',
  name: 'user-details-name',
  getTabTestId: (panel: PolicyPanel) => `user-page-tab-${panel}`,
  panels: {
    [PolicyPanel.DETAILS]: 'user-details-panel',
    [PolicyPanel.ENTITIES]: entityPanelTestIds.table,
    [PolicyPanel.PERMISSIONS]: permissionsPanelTestIds.table,
  },
};

export const PolicyPage = () => {
  const [params, setParams] = useSearchParams();
  const { customerId, policyId } = useParams() as PolicyRouteParams;
  const { policies } = useUserMgmtStore(['policies', 'upsertPolicy']);
  const policy = policies[policyId];
  const activePanel = (params.get('panel') as PolicyPanel) || PolicyPanel.DETAILS;

  const onChangePanel = (panel: string | null) =>
    setParams((prev) => {
      if (panel) prev.set('panel', panel);
      return prev;
    });

  return policy ? (
    <Stack h='100%'>
      <Flex align='center' gap='sm'>
        <Avatar variant='light' color='green.9'>
          <IconLicense />
        </Avatar>
        <Title order={5} fw={700} data-testid={testIds.name}>
          {policy.name}
        </Title>
      </Flex>
      <Tabs value={activePanel} className='grow flex flex-col' onChange={onChangePanel}>
        <Tabs.List>
          {panels.map((panel) => (
            <Tabs.Tab
              key={panel.key}
              value={panel.key}
              disabled={panel.disabled}
              data-testid={testIds.getTabTestId(panel.key)}
            >
              {panel.title}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {panels.map((panel) => (
          <Tabs.Panel key={panel.key} value={panel.key} className='grow mt-4 flex'>
            {panel.panel}
          </Tabs.Panel>
        ))}
      </Tabs>
    </Stack>
  ) : (
    <Navigate to={ROUTES.USER_MGMT(customerId).POLICIES} replace />
  );
};
