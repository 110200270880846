import i18n from 'services/i18n';
import { GeneralSettings } from './components/GeneralSettings/GeneralSettings';
import { PreFlowSettings } from './components/PreFlowSettings/PreFlowSettings';
import { FlowFeatures } from './components/FlowFeatures/FlowFeatures';

export const panelKeys = {
  generalSettings: 'general-settings',
  preFlow: 'pre-flow',
  features: 'flow-features',
};

export const panels = [
  {
    key: panelKeys.generalSettings,
    title: i18n.t('wizard.steps.settings.generalSettings.title'),
    content: <GeneralSettings />,
  },
  {
    key: panelKeys.preFlow,
    title: i18n.t('wizard.steps.settings.preFlow.title'),
    content: <PreFlowSettings />,
  },
  {
    key: panelKeys.features,
    title: i18n.t('wizard.steps.settings.features.title'),
    content: <FlowFeatures />,
  },
];
