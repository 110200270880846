import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Flex, Group as MantineGroup, ScrollArea, Stack } from '@mantine/core';
import { Group } from 'stores/userMgmt';
import { User, UserId } from 'stores/auth';
import { AllCheckbox, ModalBase, modalBaseTestIds } from 'components';
import { GroupUsersMappingUserRow, testIds as rowTestIds } from './GroupUsersMappingUserRow';
import { GroupUsersMappingSearch, testIds as searchTestIds } from './GroupUsersMappingSearch';

interface GroupUsersMappingModalProps {
  opened: boolean;
  group: Group;
  users: User[];
  onClose: () => void;
  onSubmit: (userIds: UserId[]) => Promise<boolean>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  search: searchTestIds,
  row: rowTestIds,
  apply: 'group-mapping-apply',
  all: 'group-mapping-all',
};

export const GroupUsersMappingModal = ({ opened, group, users, onClose, onSubmit }: GroupUsersMappingModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedSet, setSelectedSet] = useState<Set<UserId>>(new Set(group.users.map((user) => user.userId)));

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          user.givenName.toLowerCase().includes(search.toLowerCase()) ||
          user.familyName.toLowerCase().includes(search.toLowerCase()),
      ),
    [users, search],
  );

  const allSelected = filteredUsers.length === selectedSet.size;
  const someSelected = selectedSet.size > 0;
  const onToggleAll = (allChecked: boolean) =>
    setSelectedSet(allChecked ? new Set(filteredUsers.map((user) => user.userId)) : new Set());

  const onSearch = (value: string) => setSearch(value);
  const handleChangeSelected = (userId: UserId) => (checked: boolean) =>
    setSelectedSet((set) => {
      const newSet = new Set(set);
      if (checked) newSet.add(userId);
      else newSet.delete(userId);
      return newSet;
    });

  const onApply = async () => {
    setLoading(true);
    const success = await onSubmit(Array.from(selectedSet));
    setLoading(false);
    if (success) onClose();
  };

  useEffect(() => {
    if (opened) setSelectedSet(new Set(group.users.map((user) => user.userId)));
  }, [group, opened]);

  return (
    <ModalBase
      opened={opened}
      title={t('userMgmt.groups.manageUsers.title', { name: group.name })}
      bodyProps={{ p: 'zero' }}
      onClose={onClose}
    >
      <Box pt='tiny'>
        <Flex px='lg' gap='lg'>
          <GroupUsersMappingSearch value={search} onChange={onSearch} />
          <AllCheckbox all={allSelected} some={someSelected} onChange={onToggleAll} data-testid={testIds.all} />
        </Flex>
        <ScrollArea h={288}>
          <Stack p='lg'>
            {filteredUsers.map((user) => (
              <GroupUsersMappingUserRow
                key={user.userId}
                user={user}
                selected={selectedSet.has(user.userId)}
                search={search}
                onChange={handleChangeSelected(user.userId)}
              />
            ))}
          </Stack>
        </ScrollArea>
      </Box>
      <Divider />
      <MantineGroup p='lg' justify='end'>
        <Button loading={loading} onClick={onApply} data-testid={testIds.apply}>
          {t('common.apply')}
        </Button>
      </MantineGroup>
    </ModalBase>
  );
};
