import { HTMLAttributes, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FileButton } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconUpload, IconX } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { FieldValuesCSVResponse } from 'stores/wizard';

interface FieldValuesCSVUploadProps {
  onUpload: (file: File) => Promise<FieldValuesCSVResponse>;
}

export const testIds = {
  button: 'metadata-table-csv-upload-button',
  icon: 'metadata-table-csv-upload-icon',
  input: 'metadata-table-csv-upload-input',
};

export const FieldValuesCSVUpload = ({ onUpload }: FieldValuesCSVUploadProps) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState(false);
  const fileResetRef = useRef<() => void>(null);

  const onSuccess = (response: FieldValuesCSVResponse) => {
    notifications.show({
      color: response === FieldValuesCSVResponse.OK ? 'green' : 'blue',
      icon: <IconCheck />,
      title: t('wizard.steps.settings.metadata.table.fieldValues.csvUpload.success.title'),
      message:
        response === FieldValuesCSVResponse.DUPLICATE_IMPORTED_NAME &&
        t('wizard.steps.settings.metadata.table.fieldValues.csvUpload.success.duplicateValueMessage'),
    });
  };

  const onFailure = (response: FieldValuesCSVResponse) => {
    notifications.show({
      color: 'red',
      title: t('wizard.steps.settings.metadata.table.fieldValues.csvUpload.parseError.title'),
      icon: <IconX />,
      message:
        response === FieldValuesCSVResponse.MISSING_COLUMN
          ? t('wizard.steps.settings.metadata.table.fieldValues.csvUpload.parseError.missingValueMessage')
          : t('wizard.steps.settings.metadata.table.fieldValues.csvUpload.parseError.genericMessage'),
    });
  };

  const onUploadCSV = async (file: File | null) => {
    if (!file) return;
    setUploading(true);
    const response = await onUpload(file);
    const isSuccess = [FieldValuesCSVResponse.OK, FieldValuesCSVResponse.DUPLICATE_IMPORTED_NAME].includes(response);
    if (isSuccess) onSuccess(response);
    else onFailure(response);
    fileResetRef.current?.();
    setUploading(false);
  };

  return (
    <FileButton
      accept='text/csv'
      inputProps={{ 'data-testid': testIds.input } as HTMLAttributes<HTMLInputElement>}
      resetRef={fileResetRef}
      onChange={onUploadCSV}
    >
      {(props) => (
        <ActionButton
          color='green'
          label={
            <Box className='whitespace-pre-wrap'>
              {t('wizard.steps.settings.metadata.table.fieldValues.csvUpload.tooltip')}
            </Box>
          }
          mt={24}
          tooltipDelay={1000}
          tooltipMultiline
          loading={uploading}
          data-testid={testIds.button}
          {...props}
        >
          <IconUpload data-testid={testIds.icon} />
        </ActionButton>
      )}
    </FileButton>
  );
};
