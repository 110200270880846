import { Box, Button, Flex, Image, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes/routes.config';
import { config } from 'services/config';
import classes from './LoginPage.module.css';

export const testIds = {
  wrapper: 'login-page-wrapper',
  logo: 'login-page-logo',
  title: 'login-page-title',
  button: {
    button: 'login-page-button',
    icon: 'login-page-button-icon',
  },
};

export const LoginPage = () => {
  const { t } = useTranslation();

  const onClickLogin = () => {
    const searchParams = new URLSearchParams();
    searchParams.append('redirect_uri', `${window.location.origin}${ROUTES.CUSTOMERS}`);
    const url = `${config.authApiUrl}?${searchParams.toString()}`;
    window.location.href = url;
  };

  return (
    <Flex w='100%' h='100%' direction='column' justify='center' align='center' data-testid={testIds.wrapper}>
      <Box className={classes.box} data-testid={testIds.logo}>
        <Image src='/Logo.png' className={classes.logo} />
        <Image src='/Loop.png' className={classes.loop} />
      </Box>
      <Text className={classes.otherElement} size='xl' data-testid={testIds.title}>
        {t('loginPage.title')}
      </Text>
      <Button
        className={classes.otherElement}
        mt='xl'
        rightSection={<IconChevronRight data-testid={testIds.button.icon} />}
        onClick={onClickLogin}
        data-testid={testIds.button.button}
      >
        {t('loginPage.button')}
      </Button>
    </Flex>
  );
};
