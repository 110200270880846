import { CreateUserRequest, UpdateUserRequest } from '@jargonic/customer-types';
import { HermesConfig } from '@aiola/frontend';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { User } from 'stores/auth';

const customerUrl = (customerId: string) => `${config.jargonicApiUrl}/${customerId}`;

export const userMgmtApi = {
  getUsers: async (
    customerId: string,
    filters?: { givenName?: string; familyName?: string },
  ): Promise<User[] | undefined> => {
    const url = `${customerUrl(customerId)}/users`;
    const options: HermesConfig<User[]> = {};

    if (filters && Object.keys(filters).length > 0) {
      options.config = { params: filters };
    }

    const { data } = await hermes.get<User[]>(url, options);
    return data;
  },

  createUsers: async (customerId: string, payload: CreateUserRequest[]): Promise<User[] | undefined> => {
    const url = `${customerUrl(customerId)}/users/bulkCreate`;
    const { data } = await hermes.post<User[]>(url, payload);
    return data;
  },

  updateUser: async (customerId: string, userId: string, payload: UpdateUserRequest): Promise<User | undefined> => {
    const url = `${customerUrl(customerId)}/users/${userId}`;
    const { data } = await hermes.patch<User>(url, payload);
    return data;
  },

  deleteUser: async (customerId: string, userId: string): Promise<boolean> => {
    const url = `${customerUrl(customerId)}/users/${userId}`;
    const { data } = await hermes.delete(url);
    return data;
  },
};
