import { MRT_ColumnDef } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { FlowMetadataField } from 'stores/wizard';
import { Box, Switch, Tooltip } from '@mantine/core';
import { MetadataTableState } from '../../MetadataTable.types';

export const testIds = {
  wrapper: 'metadata-table-mandatory-switch-wrapper',
  tooltip: 'metadata-table-must-be-mandatory-tooltip',
  input: 'metadata-table-mandatory-switch-input',
};

export const MandatoryCell: MRT_ColumnDef<FlowMetadataField>['Cell'] = ({ row, table }) => {
  const { t } = useTranslation();
  const { uniqueIdFieldId, onChange } = table.getState() as MetadataTableState;

  return (
    <Tooltip
      disabled={row.original.id !== uniqueIdFieldId}
      label={t('wizard.steps.settings.metadata.table.uniqueIdentifierField.mustBeMandatoryTooltip')}
      data-testid={testIds.tooltip}
    >
      <Box data-testid={testIds.wrapper}>
        <Switch
          checked={row.original.required}
          disabled={row.original.id === uniqueIdFieldId}
          onChange={(e) => onChange(row.id, { required: e.currentTarget.checked })}
          data-testid={testIds.input}
        />
      </Box>
    </Tooltip>
  );
};
