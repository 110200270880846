import { AiModelEnvBadge } from 'components/AiModelEnvBadge/AiModelEnvBadge';
import { MRT_ColumnDef } from 'mantine-react-table';
import { AiPublishedModel } from 'stores/aiPlatform/aiPublishedModels';

export const testIds = {
  badge: 'published-model-table-env-badge',
};

export const EnvCell: NonNullable<MRT_ColumnDef<AiPublishedModel>['Cell']> = ({ row }) => (
  <AiModelEnvBadge env={row.original.environment} data-testid={testIds.badge} />
);
