import { Breadcrumbs, Loader, ScrollArea, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate, useParams } from 'react-router-dom';
import { ROUTES, TrainingPipelineRouteParams } from 'routes/routes.config';
import { useAiModel, useAiModelStore } from 'stores/aiPlatform/aiModels';
import { useFlow } from 'stores/flows';
import { PageTitle } from '../components';
import {
  PipelineStepper,
  TrainingCompletedModal,
  completedModalTestIds,
  datasetContentTestIds,
  testingContentTestIds,
  trainingContentTestIds,
} from './components';
import { getPipelineSteps } from './TrainingPipelinePage.steps';

export const testIds = {
  wrapper: 'training-pipeline-page',
  breadcrumbs: {
    parent: 'training-pipeline-page-breadcrumbs-parent',
    current: 'training-pipeline-page-breadcrumbs-current',
  },
  dataset: datasetContentTestIds,
  train: trainingContentTestIds,
  test: testingContentTestIds,
  modal: completedModalTestIds,
};

export const TrainingPipelinePage = () => {
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const { t } = useTranslation();
  const { customerId, flowId, modelId } = useParams() as TrainingPipelineRouteParams;
  const flow = useFlow(flowId);
  const aiModel = useAiModel(modelId);
  const { subscribeToAiModel, loading } = useAiModelStore(['subscribeToAiModel', 'loading']);

  useEffect(() => {
    const unsubscribe = subscribeToAiModel(modelId, {
      unsubscribeOnFinish: true,
      onComplete: () => setShowCompletedModal(true),
    });
    return unsubscribe;
  }, [modelId]);

  if (!aiModel && loading) {
    return <Loader type='bars' my='xl' mx='auto' />;
  }

  if (!flow || !aiModel) {
    return <Navigate to={ROUTES.AI_PLATFORM(customerId, flowId).TRAINING} replace />;
  }

  const { trainingSteps, type } = aiModel;
  const trainingPageTitle = t(`trainingPage.pageTitle.${type}`);
  const pipelinePageTitle = t(`trainingPipelinePage.pageTitle.${type}`);

  return (
    <Stack pt='xl' px='xl' gap='md' className='h-full' data-testid={testIds.wrapper}>
      <Stack>
        <Breadcrumbs>
          <Text
            component={Link}
            to={{ pathname: ROUTES.AI_PLATFORM(customerId, flowId).TRAINING, search: `?modelType=${type}` }}
            data-testid={testIds.breadcrumbs.parent}
          >
            {trainingPageTitle}
          </Text>
          <Text data-testid={testIds.breadcrumbs.current}>{pipelinePageTitle}</Text>
        </Breadcrumbs>
        <PageTitle title={pipelinePageTitle} subtitle={flow.name} />
      </Stack>
      <ScrollArea pr='sm'>
        <PipelineStepper
          steps={getPipelineSteps(
            trainingSteps.warmup.status,
            trainingSteps.dataset.status,
            trainingSteps.train.status,
            trainingSteps.test.status,
          )}
        />
      </ScrollArea>
      <TrainingCompletedModal
        aiModel={aiModel}
        opened={showCompletedModal}
        onClose={() => setShowCompletedModal(false)}
      />
    </Stack>
  );
};
