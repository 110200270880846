import { CloseButton, TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface PermissionResourceSearchProps {
  value?: string;
  onChange: (value: string) => void;
}

export const testIds = {
  input: 'permission-resource-search-input',
  clear: 'permission-resource-search-clear',
  icon: 'permission-resource-search-icon',
};

export const PermissionResourceSearch = ({ value = '', onChange }: PermissionResourceSearchProps) => {
  const { t } = useTranslation();

  const onClear = () => onChange('');

  return (
    <TextInput
      placeholder={t('common.searchPlaceholder')}
      value={value}
      variant='unstyled'
      rightSection={value && <CloseButton onClick={onClear} data-testid={testIds.clear} />}
      leftSection={<IconSearch size={18} data-testid={testIds.icon} />}
      onChange={(event) => onChange(event.currentTarget.value)}
      data-testid={testIds.input}
    />
  );
};
