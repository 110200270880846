import { Card, Image, Text, Title } from '@mantine/core';
import { Customer } from '@jargonic/customer-types';
import classes from './CustomerModal.module.css';

interface CustomerCardProps {
  customer: Customer;
  onClick?: () => void;
}

export const testIds = {
  getCardTestId: (id: string) => `customer-card-${id}`,
  logo: 'customer-card-logo',
  name: 'customer-card-name',
  industry: 'customer-card-industry',
};

export const CustomerCard = ({ customer, onClick }: CustomerCardProps) => (
  <Card
    tabIndex={0}
    className={classes.card}
    color='green'
    radius={8}
    onClick={onClick}
    data-testid={testIds.getCardTestId(customer.id)}
  >
    <Image
      src={customer.logo}
      alt={customer.name}
      fallbackSrc='/CustomerFallback.svg'
      w='auto'
      fit='contain'
      data-testid={testIds.logo}
    />
    <Title className='truncate' order={5} w='100%' data-testid={testIds.name}>
      {customer.name}
    </Title>
    <Text fw={400} size='sm' c='gray.5' data-testid={testIds.industry}>
      {customer.industry}
    </Text>
  </Card>
);
