import { Flex, Text } from '@mantine/core';
import { CopyButton } from 'components/CopyButton/CopyButton';
import { MRT_ColumnDef } from 'mantine-react-table';
import i18n from 'services/i18n';
import { AiModelDataTooltip, PublishedModelTrainingData } from 'stores/aiPlatform/aiPublishedModels';
import { testIds } from './AiModelDataTable';
import { DataTooltipHeader } from './cells/DataTooltipHeader';

export const getColumns: ({
  trainingInfo,
  dataGenerationInfo,
}: {
  trainingInfo: AiModelDataTooltip;
  dataGenerationInfo: AiModelDataTooltip;
}) => MRT_ColumnDef<PublishedModelTrainingData>[] = ({ trainingInfo, dataGenerationInfo }) => [
  {
    accessorKey: 'language',
    header: i18n.t('publishPage.modelDataTable.headers.language'),
    grow: 0,
  },
  {
    accessorKey: 'trainingExecutionId',
    Header: ({ header }) => (
      <DataTooltipHeader
        {...{
          id: header.id,
          headerTitle: i18n.t('publishPage.modelDataTable.headers.trainingExecutionId'),
          data: trainingInfo,
        }}
      />
    ),
    header: '',
    accessorFn: ({ trainingInfo: { executionId } }) => `${executionId}`,
    grow: 1,
    Cell: ({ cell }) => (
      <Flex gap={8} align='center'>
        <Text data-testid={testIds.getCopyCellId('trainingExecutionId')} style={{ whiteSpace: 'pre' }}>
          {cell.getValue<number>()}
        </Text>
        <CopyButton
          data-testid={testIds.getCopyButtonId('trainingExecutionId')}
          value={cell.getValue<number>().toString()}
        />
      </Flex>
    ),
  },
  {
    accessorKey: 'dataGenerationId',
    accessorFn: ({ dataGenerationInfo: { executionId } }) => `${executionId}`,
    grow: 1,
    Header: ({ header }) => (
      <DataTooltipHeader
        {...{
          id: header.id,
          headerTitle: i18n.t('publishPage.modelDataTable.headers.dataGenerationId'),
          data: dataGenerationInfo,
        }}
      />
    ),
    header: '',
    Cell: ({ cell }) => (
      <Flex gap={8} align='center'>
        <Text data-testid={testIds.getCopyCellId('dataGenerationId')} style={{ whiteSpace: 'pre' }}>
          {cell.getValue<number>()}
        </Text>
        <CopyButton
          data-testid={testIds.getCopyButtonId('dataGenerationId')}
          value={cell.getValue<number>().toString()}
        />
      </Flex>
    ),
  },
  {
    accessorKey: 'flowVersion',
    header: i18n.t('publishPage.modelDataTable.headers.flowVersion'),
    Cell: ({ cell }) => (
      <Flex gap={8} align='center'>
        <Text data-testid={testIds.getCopyCellId('flowVersion')} style={{ whiteSpace: 'pre' }}>
          {cell.getValue<number>()}
        </Text>
        <CopyButton data-testid={testIds.getCopyButtonId('flowVersion')} value={cell.getValue<number>().toString()} />
      </Flex>
    ),
    grow: 1,
  },
  {
    accessorKey: 'flowId',
    header: i18n.t('publishPage.modelDataTable.headers.flowId'),
    Cell: ({ cell }) => (
      <Flex gap={8} align='center'>
        <Text data-testid={testIds.getCopyCellId('flowId')} style={{ whiteSpace: 'pre' }}>
          {cell.getValue<number>()}
        </Text>
        <CopyButton data-testid={testIds.getCopyButtonId('flowId')} value={cell.getValue<number>().toString()} />
      </Flex>
    ),
    grow: 1,
  },
  {
    accessorKey: 'tenant',
    header: i18n.t('publishPage.modelDataTable.headers.tenant'),
    grow: 0,
  },
];
