import i18n from 'services/i18n';
import { TrainingStepStatus } from 'stores/aiPlatform/aiModels';
import { DatasetContent, TrainingContent, TestingContent, type PipelineStep } from './components';
import { WarmupContent } from './components/WarmupContent/WarmupContent';

export function getPipelineSteps(
  warmupStatus: TrainingStepStatus,
  datasetStatus: TrainingStepStatus,
  trainStatus: TrainingStepStatus,
  testStatus: TrainingStepStatus,
): PipelineStep[] {
  return [
    {
      title: i18n.t('trainingPipelinePage.steps.warmup.stepName'),
      id: 'warmup',
      content: <WarmupContent />,
      status: warmupStatus,
    },
    {
      title: i18n.t('trainingPipelinePage.steps.dataset.stepName'),
      id: 'dataset',
      content: <DatasetContent />,
      status: datasetStatus,
    },
    {
      title: i18n.t('trainingPipelinePage.steps.train.stepName'),
      id: 'train',
      content: <TrainingContent />,
      status: trainStatus,
    },
    {
      title: i18n.t('trainingPipelinePage.steps.test.stepName'),
      id: 'test',
      content: <TestingContent />,
      status: testStatus,
    },
  ];
}
