import { Button, Input } from '@mantine/core';
import { InfoLabel } from 'components';
import { useTranslation } from 'react-i18next';

interface ValueValidityToggleProps {
  value?: boolean;
  onChange: (value?: boolean) => void;
}

export const testIds = {
  label: 'value-settings-modal-validity-toggle-label',
  validButton: 'value-settings-modal-valid-button',
  invalidButton: 'value-settings-modal-invalid-button',
};

export const ValueValidityToggle = ({ value, onChange }: ValueValidityToggleProps) => {
  const { t } = useTranslation();

  const isValid = value === true;
  const isInvalid = value === false;
  const onClickValid = () => onChange(isValid ? undefined : true);
  const onClickInvalid = () => onChange(isInvalid ? undefined : false);

  return (
    <Input.Wrapper
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.validityToggle.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.types.select.valueSettings.validityToggle.title')}
        </InfoLabel>
      }
    >
      <Button.Group>
        <Button
          color='blue'
          variant={isValid ? 'filled' : 'outline'}
          onClick={onClickValid}
          aria-checked={isValid}
          data-checked={isValid}
          data-testid={testIds.validButton}
        >
          {t('common.valid')}
        </Button>
        <Button
          color='red'
          variant={isInvalid ? 'filled' : 'outline'}
          onClick={onClickInvalid}
          aria-checked={isInvalid}
          data-checked={isInvalid}
          data-testid={testIds.invalidButton}
        >
          {t('common.invalid')}
        </Button>
      </Button.Group>
    </Input.Wrapper>
  );
};
