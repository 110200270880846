import { FlowStatusBadge } from 'components';
import { testIds as badgeTestIds } from 'components/FlowStatusBadge/FlowStatusBadge';
import { MRT_ColumnDef } from 'mantine-react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Flow } from 'stores/flows';
import { Folder } from 'stores/folders';
import { isFolder } from '../FlowTable.utils';
import {
  EditorCell,
  NameCell,
  TimestampCell,
  editorCellTestIds,
  nameCellTestIds,
  timestampCellTestIds,
} from '../components';

export const testIds = {
  name: nameCellTestIds,
  badge: badgeTestIds,
  editor: editorCellTestIds,
  timestamp: timestampCellTestIds,
};

export function useFlowTableColumns() {
  const { t } = useTranslation();
  return useMemo<MRT_ColumnDef<Flow | Folder>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('flowsPage.table.headers.flowName'),
        Cell: NameCell,
      },
      {
        accessorKey: 'editor',
        header: t('flowsPage.table.headers.editor'),
        Cell: EditorCell,
        grow: false,
      },
      {
        accessorKey: 'status',
        header: t('flowsPage.table.headers.status'),
        Cell: ({ row }) => (isFolder(row.original) ? null : <FlowStatusBadge size='md' flow={row.original} />),
        grow: false,
      },
      {
        accessorKey: 'lastEdited',
        header: t('flowsPage.table.headers.lastEdited'),
        Cell: TimestampCell,
        grow: false,
      },
    ],
    [],
  );
}
