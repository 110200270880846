import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconLicense, IconLicenseOff } from '@tabler/icons-react';
import { Permission, Policy } from 'stores/userMgmt';
import { EditPermissionModal, editPermissionTestIds } from 'pages/userMgmt/components';
import { NiceResponse } from 'consts';
import { CreatePolicyModal, testIds as createTestIds } from './CreatePolicyModal';

interface PolicyTableCreateActionProps {
  policies: Policy[];
  onCreatePolicy: (name: string) => Promise<Policy | undefined>;
  onCreatePermission: (policyId: string, permission: Permission) => Promise<boolean>;
}

export const testIds = {
  button: 'policy-table-create-action-button',
  createModal: createTestIds,
  permissionModal: editPermissionTestIds,
};

export const PolicyTableCreateAction = ({
  policies,
  onCreatePolicy,
  onCreatePermission,
}: PolicyTableCreateActionProps) => {
  const { t } = useTranslation();
  const policyRef = useRef<Policy | undefined>(undefined);
  const [createModalOpened, { open: openCreateModal, close: closeCreateModal }] = useDisclosure();
  const [updateModalOpened, { open: openUpdateModal, close: closeUpdateModal }] = useDisclosure(false, {
    onClose: () => {
      policyRef.current = undefined;
    },
  });

  const onCreationSuccess = (name: string) =>
    notifications.show({
      title: t('userMgmt.policies.table.actions.create.createSuccess.title', { name }),
      message: null,
      icon: <IconLicense />,
    });

  const onCreationFailure = (name: string) =>
    notifications.show({
      color: 'red',
      title: t('userMgmt.policies.table.actions.create.createError.title', { name }),
      message: t('userMgmt.policies.table.actions.create.createError.message'),
      icon: <IconLicenseOff />,
    });

  const onPermissionCreationSuccess = (name: string) =>
    notifications.show({
      title: t('userMgmt.policies.table.actions.create.createPermissionSuccess.title'),
      message: t('userMgmt.policies.table.actions.create.createPermissionSuccess.message', { name }),
      icon: <IconLicense />,
    });

  const onPermissionCreationFailure = (name: string) =>
    // policy creation successful, but permission creation failed
    notifications.show({
      color: 'blue',
      title: t('userMgmt.policies.table.actions.create.createPermissionError.title'),
      message: t('userMgmt.policies.table.actions.create.createPermissionError.message', { name }),
      icon: <IconLicense />,
    });

  const onCreate = async (name: string) => {
    const nameSet = new Set(policies.map((policy) => policy.name.toLowerCase()));
    const isDuplicateName = nameSet.has(name.toLowerCase());
    if (isDuplicateName) return NiceResponse.DUPLICATE;
    const response = await onCreatePolicy(name);
    if (response) {
      policyRef.current = response;
      openUpdateModal();
      onCreationSuccess(response.name);
    } else onCreationFailure(name);
    return response ? NiceResponse.OK : NiceResponse.ERROR;
  };

  const onSubmitPermission = async (payload: Permission) => {
    if (!policyRef.current) return false;
    const { id, name } = policyRef.current;
    const response = await onCreatePermission(id, payload);
    if (response) onPermissionCreationSuccess(name);
    else onPermissionCreationFailure(name);
    return response;
  };

  return (
    <>
      <Button onClick={openCreateModal} data-testid={testIds.button}>
        {t('userMgmt.policies.table.actions.create.button')}
      </Button>
      <CreatePolicyModal opened={createModalOpened} onClose={closeCreateModal} onSubmit={onCreate} />
      <EditPermissionModal opened={updateModalOpened} onClose={closeUpdateModal} onSubmit={onSubmitPermission} />
    </>
  );
};
