import { Box, Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerRouteParams, ROUTES } from 'routes/routes.config';
import { Flow } from 'stores/flows';
import { FlowTable } from './components/FlowTableV2/FlowTable';

export const testIds = {
  page: 'flows-page',
  title: 'flows-page-title',
  newFlowButton: 'flows-page-new-flow-button',
  newFolderButton: 'flows-page-new-folder-button',
};

export const FlowsPage = () => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const navigate = useNavigate();

  const onSelectFlow = (flow: Flow) => navigate(ROUTES.FLOW(customerId, flow.id));

  return (
    <Stack h='100%' w='100%' p='xl' gap={44} data-testid={testIds.page}>
      <Title order={3} data-testid={testIds.title}>
        {t('flowsPage.title')}
      </Title>
      <Box className='grow' pos='relative'>
        <FlowTable onSelectFlow={onSelectFlow} />
      </Box>
    </Stack>
  );
};
