import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Flex, Group as MantineGroup, ScrollArea, Stack } from '@mantine/core';
import { Group, Policy } from 'stores/userMgmt';
import { User, UserId } from 'stores/auth';
import { AllCheckbox, ModalBase, modalBaseTestIds, UserAvatar, GroupAvatar } from 'components';
import { isUser } from 'pages/userMgmt/PolicyPage/components/EntitiesPanel/EntitiesPanel.utils';
import { PolicyMappingRow, testIds as rowTestIds } from './PolicyMappingRow';
import { PolicyMappingSearch, testIds as searchTestIds } from './PolicyMappingSearch';

interface PolicyMappingModalProps {
  opened: boolean;
  policy: Policy;
  users: User[];
  groups: Group[];
  onClose: () => void;
  onSubmit: (userIds: UserId[], groupIds: string[]) => Promise<boolean>;
}

export const testIds = {
  title: modalBaseTestIds.title,
  search: searchTestIds,
  row: rowTestIds,
  apply: 'policy-mapping-apply',
  all: 'policy-mapping-all',
};

const sortAlphabetically = (arr: (User | Group)[]) =>
  arr.sort((a: User | Group, b: User | Group) => {
    const nameA = isUser(a) ? a.givenName.toLowerCase() : a.name.toLowerCase();
    const nameB = isUser(b) ? b.givenName.toLowerCase() : b.name.toLowerCase();

    if (nameA < nameB)
      // sort ascending
      return -1;
    if (nameA > nameB) return 1;
    return 0; // default (no sorting)
  });

export const PolicyMappingModal = ({ opened, policy, users, groups, onClose, onSubmit }: PolicyMappingModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [{ selectedUsersSet, selectedGroupsSet }, setSelectedSet] = useState<{
    selectedUsersSet: Set<string>;
    selectedGroupsSet: Set<string>;
  }>({
    selectedUsersSet: new Set(policy.users.map((user) => user.userId)),
    selectedGroupsSet: new Set(policy.groups.map((group) => group.id)),
  });
  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          user.givenName.toLowerCase().includes(search.toLowerCase()) ||
          user.familyName.toLowerCase().includes(search.toLowerCase()),
      ),
    [users, search],
  );
  const filteredGroups = useMemo(
    () => groups.filter((group) => group.name.toLowerCase().includes(search.toLowerCase())),
    [groups, search],
  );

  const sorted = sortAlphabetically([...filteredGroups, ...filteredUsers]);
  const selectedSize = selectedUsersSet.size + selectedGroupsSet.size;
  const allSelected = sorted.length === selectedSize;
  const someSelected = selectedSize > 0;
  const onToggleAll = (allChecked: boolean) => {
    setSelectedSet(
      allChecked
        ? {
            selectedUsersSet: new Set(filteredUsers.map((user) => user.userId)),
            selectedGroupsSet: new Set(filteredGroups.map((group) => group.id)),
          }
        : {
            selectedUsersSet: new Set(),
            selectedGroupsSet: new Set(),
          },
    );
  };

  const onSearch = (value: string) => setSearch(value);

  const handleChangeSelectedUsers = (id: string) => (checked: boolean) =>
    setSelectedSet((set) => {
      const newSet = new Set(set.selectedUsersSet);
      if (checked) newSet.add(id);
      else newSet.delete(id);
      return {
        selectedUsersSet: newSet,
        selectedGroupsSet: set.selectedGroupsSet,
      };
    });

  const handleChangeSelectedGroups = (id: string) => (checked: boolean) =>
    setSelectedSet((set) => {
      const newSet = new Set(set.selectedGroupsSet);
      if (checked) newSet.add(id);
      else newSet.delete(id);
      return {
        selectedUsersSet: set.selectedUsersSet,
        selectedGroupsSet: newSet,
      };
    });

  const onApply = async () => {
    setLoading(true);
    const success = await onSubmit(Array.from(selectedUsersSet), Array.from(selectedGroupsSet));
    setLoading(false);
    if (success) onClose();
  };

  useEffect(() => {
    if (opened) {
      setSelectedSet({
        selectedUsersSet: new Set(policy.users.map((user) => user.userId)),
        selectedGroupsSet: new Set(policy.groups.map((group) => group.id)),
      });
    }
  }, [policy, opened]);

  return (
    <ModalBase
      opened={opened}
      title={t('userMgmt.policies.manageEntities.title', { name: policy.name })}
      bodyProps={{ p: 'zero' }}
      onClose={onClose}
    >
      <Box pt='tiny'>
        <Flex px='lg' gap='lg'>
          <PolicyMappingSearch value={search} onChange={onSearch} />
          <AllCheckbox all={allSelected} some={someSelected} onChange={onToggleAll} data-testid={testIds.all} />
        </Flex>
        <ScrollArea h={288}>
          <Stack p='lg'>
            {sorted.map((item) =>
              isUser(item) ? (
                <PolicyMappingRow
                  key={item.userId}
                  item={item}
                  label={`${item.givenName} ${item.familyName}`}
                  avatar={<UserAvatar user={item} data-testid={rowTestIds.getAvatarTestId(item.userId)} />}
                  selected={selectedUsersSet.has(item.userId)}
                  search={search}
                  onChange={handleChangeSelectedUsers(item.userId)}
                />
              ) : (
                <PolicyMappingRow
                  key={item.id}
                  item={item}
                  label={item.name}
                  avatar={<GroupAvatar group={item} />}
                  selected={selectedGroupsSet.has(item.id)}
                  search={search}
                  onChange={handleChangeSelectedGroups(item.id)}
                />
              ),
            )}
          </Stack>
        </ScrollArea>
      </Box>
      <Divider />
      <MantineGroup p='lg' justify='end'>
        <Button loading={loading} onClick={onApply} data-testid={testIds.apply}>
          {t('common.apply')}
        </Button>
      </MantineGroup>
    </ModalBase>
  );
};
