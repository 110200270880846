import { useTranslation } from 'react-i18next';
import { Button, Group } from '@mantine/core';
import { MRT_TableOptions } from 'mantine-react-table';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { FlowMetadataField } from 'stores/wizard';
import { MetadataTableState } from '../MetadataTable.types';

export const testIds = {
  newField: {
    button: 'metadata-table-new-field-button',
    icon: 'metadata-table-new-field-button-icon',
  },
  deleteSelected: {
    button: 'metadata-table-delete-selected-button',
    icon: 'metadata-table-delete-selected-button-icon',
  },
};

export const MetadataTableActions: MRT_TableOptions<FlowMetadataField>['renderTopToolbar'] = ({ table }) => {
  const { t } = useTranslation();

  const { onNewField, onDeleteFields } = table.getState() as MetadataTableState;
  const selectedRows = table.getSelectedRowModel().rows;

  const onDeleteSelected = () => onDeleteFields(selectedRows.map((row) => row.id));

  return (
    <Group justify='end' p='tn'>
      <ActionButton
        label={t('wizard.steps.settings.metadata.table.deleteSelected')}
        disabled={!selectedRows.length}
        onClick={onDeleteSelected}
        data-testid={testIds.deleteSelected.button}
      >
        <IconTrash data-testid={testIds.deleteSelected.icon} />
      </ActionButton>
      <Button
        leftSection={<IconPlus data-testid={testIds.newField.icon} />}
        variant='outline'
        onClick={onNewField}
        data-testid={testIds.newField.button}
      >
        {t('wizard.steps.settings.metadata.table.newFieldButton')}
      </Button>
    </Group>
  );
};
