import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components';
import { Policy } from 'stores/userMgmt';

export const testIds = { wrapper: 'policy-table-empty-state' };

export const PolicyTableEmptyState: NonNullable<MRT_TableOptions<Policy>['renderEmptyRowsFallback']> = () => {
  const { t } = useTranslation();

  return (
    <EmptyState
      title={t('userMgmt.policies.table.emptyState.title')}
      message={t('userMgmt.policies.table.emptyState.message')}
      data-testid={testIds.wrapper}
    />
  );
};
