import { useTranslation } from 'react-i18next';
import { ElementProps, Text, TextProps } from '@mantine/core';

interface StepsCounterProps extends TextProps, ElementProps<'div', keyof TextProps> {
  currentStep: number;
  numberOfSteps: number;
}

export const testIds = {
  wrapper: 'steps-counter-wrapper',
  currentStep: 'steps-counter-current-step',
  totalSteps: 'steps-counter-total-steps',
};

export const StepsCounter = ({ currentStep, numberOfSteps, ...props }: StepsCounterProps) => {
  const { t } = useTranslation();

  return (
    <Text size='sm' fw={400} c='dark.2' data-testid={testIds.wrapper} {...props}>
      {t('common.stepsCount', { current: currentStep, total: numberOfSteps })}
    </Text>
  );
};
