import { useEffect, useState } from 'react';
import { Box, Button, Flex } from '@mantine/core';
import { IconArrowLeft, IconDeviceFloppy } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate, useParams, useBlocker } from 'react-router-dom';
import { ROUTES, WizardRouteParams } from 'routes/routes.config';
import { notifications } from '@mantine/notifications';
import { useDisclosure } from '@mantine/hooks';
import { WizardStepper, testIds as stepperTestIds } from './WizardStepper/WizardStepper';
import { WizardActions, testIds as actionsTestIds } from './WizardActions/WizardActions';
import { useStepDraft, useStepLoader } from '../hooks';
import { WizardStep, wizardSteps } from '../WizardLayout.steps';
import { UnsavedChangesModal, unsavedChangesModalTestIds } from '../modals';

export const testIds = {
  wrapper: 'wizard-header-wrapper',
  backButton: {
    icon: 'wizard-header-back-button-icon',
    button: 'wizard-header-back-button',
  },
  actions: actionsTestIds,
  stepper: stepperTestIds,
  unsavedChangesModal: unsavedChangesModalTestIds,
};

export const WizardHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { customerId, flowId } = useParams() as WizardRouteParams;
  const match = useMatch(ROUTES.WIZARD_STEP());
  const step = match?.params.step as WizardStep;
  const loading = useStepLoader(step);
  const { dirty, saveDraft, revertChanges, validate } = useStepDraft(step);
  const [opened, { close, open }] = useDisclosure();
  const [saving, setSaving] = useState(false);
  const navigationBlocker = useBlocker((transition) => {
    if (!dirty) return false;
    if (transition.currentLocation.pathname === transition.nextLocation.pathname) return false;
    return true;
  });
  const isFinalStep = step === wizardSteps.at(-1)?.step;

  const onClickNext = () => {
    const currentStepIndex = wizardSteps.findIndex((s) => s.step === step);
    const nextStep = wizardSteps[currentStepIndex + 1]?.step;
    const nextRoute = nextStep ? ROUTES.WIZARD_STEP(customerId, flowId, nextStep) : ROUTES.FLOW(customerId, flowId);
    navigate(nextRoute);
  };

  const handleSaveError = () => {
    notifications.show({
      color: 'red',
      icon: <IconDeviceFloppy />,
      title: t('wizard.saveError.title'),
      message: t('wizard.saveError.message'),
    });
  };

  const onClickSave = async () => {
    setSaving(true);
    const validationErrors = validate();
    const validationPassed = !validationErrors.length;
    if (validationPassed) {
      const response = await saveDraft(customerId, flowId);
      if (!response) handleSaveError();
    }
    setSaving(false);
    return validationErrors;
  };

  const onClickBack = () => navigate(ROUTES.FLOW(customerId, flowId));

  const onDiscard = () => {
    revertChanges();
    navigationBlocker.proceed?.();
  };

  useEffect(() => {
    if (navigationBlocker.state === 'blocked') open();
  }, [navigationBlocker.state]);

  return (
    <>
      <Box component='header' m='xl' data-testid={testIds.wrapper}>
        <Flex gap='sm'>
          <Button
            variant='subtle'
            leftSection={<IconArrowLeft data-testid={testIds.backButton.icon} />}
            onClick={onClickBack}
            data-testid={testIds.backButton.button}
          >
            {t('wizard.header.backButton')}
          </Button>
          <WizardStepper />
          <WizardActions
            dirty={dirty}
            isFinalStep={isFinalStep}
            loading={loading}
            saving={saving}
            onNext={onClickNext}
            onRevert={revertChanges}
            onSave={onClickSave}
          />
        </Flex>
      </Box>
      <UnsavedChangesModal opened={opened} onCancel={navigationBlocker.reset} onClose={close} onDiscard={onDiscard} />
    </>
  );
};
