import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconFileOff, IconUpload, IconUsers } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { UserMgmtCSVResponse } from 'stores/userMgmt';
import { UserTableUploadCSVModal, testIds as csvModalTestIds } from './UserTableUploadCSVModal';

interface UserTableUploadFileActionProps {
  onUpload: (file: File) => Promise<{ response: UserMgmtCSVResponse; createdUsers: number }>;
}

export const testIds = {
  button: 'user-table-upload-file',
  icon: 'user-table-upload-file-icon',
  csvModal: csvModalTestIds,
};

export const UserTableUploadFileAction = ({ onUpload }: UserTableUploadFileActionProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [opened, { open, close }] = useDisclosure();

  const onSuccess = (count: number) =>
    notifications.show({
      color: 'green',
      icon: <IconUsers />,
      title: t('userMgmt.users.table.actions.uploadFile.success.title'),
      message: t('userMgmt.users.table.actions.uploadFile.success.message', { count }),
    });

  const onDuplicates = (count: number) =>
    notifications.show({
      color: 'blue',
      icon: <IconUsers />,
      title: t('userMgmt.users.table.actions.uploadFile.successWithDuplicates.title'),
      message: t('userMgmt.users.table.actions.uploadFile.successWithDuplicates.message', { count }),
    });

  const onMissingColumns = () =>
    notifications.show({
      color: 'red',
      icon: <IconFileOff />,
      title: t('userMgmt.users.table.actions.uploadFile.missingColumnsError.title'),
      message: t('userMgmt.users.table.actions.uploadFile.missingColumnsError.message'),
    });

  const onError = () =>
    notifications.show({
      color: 'red',
      icon: <IconFileOff />,
      title: t('userMgmt.users.table.actions.uploadFile.genericErrorTitle'),
      message: t('common.tryAgain'),
    });

  const responseHandlers = {
    [UserMgmtCSVResponse.OK]: onSuccess,
    [UserMgmtCSVResponse.DUPLICATE_VALUES]: onDuplicates,
    [UserMgmtCSVResponse.MISSING_COLUMNS]: onMissingColumns,
    [UserMgmtCSVResponse.CSV_ERROR]: onError,
    [UserMgmtCSVResponse.API_ERROR]: onError,
  };

  const onUploadCSV = async (file: File) => {
    setLoading(true);
    const { response, createdUsers } = await onUpload(file);
    responseHandlers[response](createdUsers);
    setLoading(false);
  };

  return (
    <>
      <ActionButton
        label={t('userMgmt.users.table.actions.uploadFile.tooltip')}
        color='green'
        loading={loading}
        onClick={open}
        data-testid={testIds.button}
      >
        <IconUpload data-testid={testIds.icon} />
      </ActionButton>
      <UserTableUploadCSVModal opened={opened} onClose={close} onUpload={onUploadCSV} />
    </>
  );
};
