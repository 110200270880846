import { ElementProps, Text, Radio, RadioGroupProps } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { Label } from 'stores/wizard';

interface CreateContainerLabelSelectProps
  extends Omit<RadioGroupProps, 'children'>,
    Omit<ElementProps<'div', keyof RadioGroupProps>, 'children'> {
  labels: Label[];
}

export const testIds = {
  getRadioTestId: (labelId: string) => `create-container-menu-radio-${labelId}`,
  radioRegex: /create-container-menu-radio-.*/,
  noStaticGroupsLabel: 'create-container-menu-no-static-groups-label',
};

export const CreateContainerLabelSelect = ({ labels, ...props }: CreateContainerLabelSelectProps) => {
  const { t } = useTranslation();

  const staticLabels = labels.filter((label) => !label.isDynamic);

  return (
    <Radio.Group label={t('wizard.steps.labeling.containerTable.addItemMenu.labelList.title')} required {...props}>
      {staticLabels.length > 0 ? (
        staticLabels.map((label) => (
          <Radio
            key={label.id}
            label={label.name}
            value={label.id}
            c={label.attributes?.color}
            color={label.attributes?.color}
            mt='sm'
            size='xs'
            data-testid={testIds.getRadioTestId(label.id)}
          />
        ))
      ) : (
        <Text fz='xs' c='red' style={{ whiteSpace: 'pre-line' }} data-testid={testIds.noStaticGroupsLabel}>
          {t('wizard.steps.labeling.containerTable.addItemMenu.labelList.noStaticGroups')}
        </Text>
      )}
    </Radio.Group>
  );
};
