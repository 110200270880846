import { useTranslation } from 'react-i18next';
import { Box, Button, Group as MantineGroup, Text, TextInput } from '@mantine/core';
import { ModalBase, modalBaseTestIds } from 'components';
import { StepsCounter } from 'components/StepsCounter/StepsCounter';
import { useCreateGroupForm } from './GroupTableCreateAction';
import { CREATE_GROUP_NAME_STEP, CREATE_GROUP_STEPS } from './GroupTableCreateAction.const';

interface AddGroupNameModalProps {
  opened: boolean;
  onClose: () => void;
  onNext: () => void;
}

export const testIds = {
  title: modalBaseTestIds.title,
  closeButton: modalBaseTestIds.close,
  stepName: 'add-group-name-modal',
  nameInput: 'add-group-name-modal-name-input',
  note: 'add-group-name-modal-note',
  stepper: 'add-group-name-modal-stepper',
  nextButton: 'add-group-name-modal-next-button',
};

export const AddGroupNameModal = ({ opened, onClose, onNext }: AddGroupNameModalProps) => {
  const { t } = useTranslation();
  const { key, getInputProps, errors, getValues } = useCreateGroupForm();

  return (
    <ModalBase
      opened={opened}
      title={t('userMgmt.groups.table.actions.create.title')}
      headerProps={{ mb: 'none', pb: '0' }}
      onClose={onClose}
    >
      <Box>
        <Text size='sm' fw={400} c='dark.2' data-testid={testIds.stepName} mb='md'>
          {t('userMgmt.groups.table.actions.create.addNameModal.subtitle')}
        </Text>
        <TextInput
          placeholder={t('userMgmt.groups.table.actions.create.addNameModal.nameInput.placeholder')}
          required
          key={key('name')}
          {...getInputProps('name')}
          data-testid={testIds.nameInput}
          data-autofocus
        />
        <Text size='xs' fw={400} c='dark.2' data-testid={testIds.note}>
          {t('userMgmt.groups.table.actions.create.addNameModal.nameInput.note')}
        </Text>
        <MantineGroup mt='lg' justify='space-between'>
          <StepsCounter
            currentStep={CREATE_GROUP_NAME_STEP}
            numberOfSteps={CREATE_GROUP_STEPS}
            data-testid={testIds.stepper}
          />
          <Button
            disabled={!getValues().name || Boolean(errors.name)}
            onClick={onNext}
            data-testid={testIds.nextButton}
          >
            {t('common.next')}
          </Button>
        </MantineGroup>
      </Box>
    </ModalBase>
  );
};
