import { Button, Flex, Paper, Stack, Text } from '@mantine/core';
import { IconPlayerPause, IconPlayerPlay, IconRefresh, IconSquareX } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface StepCommonContentProps {
  title: string;
  message: string;
  className?: string;
  onAbort?: () => void;
  onPause?: () => void;
  onResume?: () => void;
  onReset?: () => void;
}

export const testIds = {
  title: 'step-common-content-title',
  message: 'step-common-content-message',
  abortButton: 'step-common-content-abort-button',
  pauseButton: 'step-common-content-pause-button',
  resetButton: 'step-common-content-reset-button',
  resumeButton: 'step-common-content-resume-button',
};

export const StepCommonContent = ({
  title,
  message,
  className,
  onAbort,
  onPause,
  onResume,
  onReset,
}: StepCommonContentProps) => {
  const { t } = useTranslation();
  return (
    <Paper p='lg' withBorder className={className}>
      <Stack gap='lg' py='lg'>
        <Stack align='center' gap={0}>
          <Text data-testid={testIds.title}>{title}</Text>
          <Text size='xs' c='dark.2' data-testid={testIds.message}>
            {message}
          </Text>
        </Stack>
        <Flex justify='center' gap='md'>
          {onAbort && (
            <Button
              variant='filled'
              color='red'
              leftSection={<IconSquareX />}
              onClick={onAbort}
              data-testid={testIds.abortButton}
            >
              {t('common.abort')}
            </Button>
          )}
          {onPause && (
            <Button
              variant='filled'
              color='yellow'
              leftSection={<IconPlayerPause />}
              onClick={onPause}
              data-testid={testIds.pauseButton}
            >
              {t('common.pause')}
            </Button>
          )}
          {onResume && (
            <Button
              variant='filled'
              leftSection={<IconPlayerPlay />}
              onClick={onResume}
              data-testid={testIds.resumeButton}
            >
              {t('common.resume')}
            </Button>
          )}
          {onReset && (
            <Button variant='filled' leftSection={<IconRefresh />} onClick={onReset} data-testid={testIds.resetButton}>
              {t('common.reset')}
            </Button>
          )}
        </Flex>
      </Stack>
    </Paper>
  );
};
