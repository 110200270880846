import { Box, Button, Group, Text } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconArchive, IconArchiveOff } from '@tabler/icons-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import i18n from 'services/i18n';
import { useFlow, useFlowStore } from 'stores/flows';
import { OpenGlobalModalProps, useModal } from 'stores/globalModal';

interface ArchiveFlowModalProps {
  flowId: string;
  archive?: boolean;
}

export const testIds = {
  container: 'archive-flow-modal',
  submit: 'archive-flow-submit-button',
  cancel: 'archive-flow-cancel-button',
  message: 'archive-flow-message',
};

export const ArchiveFlowModal = ({ flowId, archive = true }: ArchiveFlowModalProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const flow = useFlow(flowId);
  const [loading, setLoading] = useState(false);
  const { close } = useModal();
  const { upsertFlow } = useFlowStore(['upsertFlow']);

  const onSubmit = async () => {
    setLoading(true);
    const response = await upsertFlow({
      customerId,
      action: archive ? 'archive' : 'unarchive',
      flowId,
    });
    if (response) {
      notifications.show({
        color: 'violet',
        title: archive
          ? t('flowsPage.modals.archiveFlow.successMessage', { name: flow.name })
          : t('flowsPage.modals.unarchiveFlow.successMessage', { name: flow.name }),
        message: null,
        icon: archive ? <IconArchive /> : <IconArchiveOff />,
      });
      close();
    } else {
      notifications.show({
        color: 'red',
        title: archive
          ? t('flowsPage.modals.archiveFlow.errorMessage', { name: flow.name })
          : t('flowsPage.modals.unarchiveFlow.errorMessage', { name: flow.name }),
        message: t('common.tryAgain'),
        icon: archive ? <IconArchive /> : <IconArchiveOff />,
      });
    }
    setLoading(false);
  };

  const message = archive
    ? t('flowsPage.modals.archiveFlow.message', { name: flow?.name })
    : t('flowsPage.modals.unarchiveFlow.message', { name: flow?.name });

  const submitLabel = archive ? t('flowsPage.modals.archiveFlow.submit') : t('flowsPage.modals.unarchiveFlow.submit');

  return (
    <Box data-testid={testIds.container}>
      <Text c='gray.6' data-testid={testIds.message}>
        {message}
      </Text>
      <Group justify='end' mt='lg'>
        <Button variant='subtle' onClick={close} disabled={loading} data-testid={testIds.cancel}>
          {t('common.cancel')}
        </Button>
        <Button onClick={onSubmit} loading={loading} data-autofocus data-testid={testIds.submit}>
          {submitLabel}
        </Button>
      </Group>
    </Box>
  );
};

export const archiveFlowGlobalModalProps = (flowId: string, archive = true): OpenGlobalModalProps => ({
  title: archive ? i18n.t('flowsPage.modals.archiveFlow.title') : i18n.t('flowsPage.modals.unarchiveFlow.title'),
  children: <ArchiveFlowModal flowId={flowId} archive={archive} />,
  color: 'violet',
});
