import { Box, Loader, Button, Flex, Stack, FileButton } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { WizardRouteParams } from 'routes/routes.config';
import { notifications } from '@mantine/notifications';
import { IconFileOff, IconFilePlus } from '@tabler/icons-react';
import { IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone';
import { useContainerStore, useFileStore } from 'stores/wizard';
import { WizardStep, useStepLoader } from 'layouts';
import { FlowPageTitle } from 'components';
import { useFlow } from 'stores/flows';
import { Annotator, ContainerTable, FileDropzone, LabelManagementDrawer } from './components';

export const testIds = {
  loader: 'labeling-page-loader',
  drawerButton: 'label-management-drawer-button',
  uploadFiles: {
    button: 'upload-files-button',
    icon: 'upload-files-icon',
  },
};

const stringifiedMimeType = [...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE].join(',');

export const LabelingPage = () => {
  const { t } = useTranslation();
  const [params, setParams] = useSearchParams();
  const opened = Boolean(params.get('labelsOpen'));
  const [uploading, setUploading] = useState(false);
  const { customerId, flowId } = useParams() as WizardRouteParams;
  const flow = useFlow(flowId);
  const loading = useStepLoader(WizardStep.LABELING);
  const { files, uploadFiles } = useFileStore(['files', 'uploadFiles']);
  const { startDraft, endDraft } = useContainerStore(['startDraft', 'endDraft']);

  const onOpenLabelDrawer = () => {
    params.set('labelsOpen', 'true');
    setParams(params, {});
  };

  const onCloseLabelDrawer = () => {
    params.delete('labelsOpen');
    setParams(params);
  };

  const onDropFiles = async (droppedFiles: File[]) => {
    setUploading(true);
    await uploadFiles(customerId, flowId, droppedFiles);
    setUploading(false);
  };

  const onRejectDroppedFiles = () => {
    notifications.show({
      color: 'blue',
      icon: <IconFileOff />,
      title: t('wizard.steps.labeling.fileManagement.formatError.title'),
      message: t('wizard.steps.labeling.fileManagement.formatError.message'),
    });
  };

  useEffect(() => {
    if (!loading) startDraft();
    return endDraft;
  }, [loading]);

  return loading || !flow ? (
    <Loader type='bars' m='auto' data-testid={testIds.loader} />
  ) : (
    <>
      <LabelManagementDrawer opened={opened} onClose={onCloseLabelDrawer} />
      <Stack className='grow' pl='xl' gap='xl'>
        <Flex gap='sm'>
          <FlowPageTitle flow={flow} title={t('flowPage.applications.labeling')} />
          <Button variant='outline' onClick={onOpenLabelDrawer} data-testid={testIds.drawerButton}>
            {t('wizard.steps.labeling.labelManagement.title')}
          </Button>
          <FileButton accept={stringifiedMimeType} multiple onChange={onDropFiles}>
            {(props) => (
              <Button
                leftSection={<IconFilePlus data-testid={testIds.uploadFiles.icon} />}
                loading={uploading}
                variant='outline'
                data-testid={testIds.uploadFiles.button}
                {...props}
              >
                {t('wizard.steps.labeling.fileManagement.input')}
              </Button>
            )}
          </FileButton>
        </Flex>
        <Flex className='grow' pb='xl'>
          <Box className='grow-[2]' h='100%' pos='relative'>
            {files.length ? (
              <Annotator />
            ) : (
              <FileDropzone loading={uploading} onDrop={onDropFiles} onReject={onRejectDroppedFiles} />
            )}
          </Box>
          <Box h='100%' miw={400} maw={500}>
            <ContainerTable />
          </Box>
        </Flex>
      </Stack>
    </>
  );
};
