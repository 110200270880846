import { Stack, Title } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GroupTable, testIds as tableTestIds } from './GroupTable/GroupTable';

export const testIds = {
  title: 'groups-page-title',
  table: tableTestIds,
};

export const GroupsPage = () => {
  const { t } = useTranslation();

  return (
    <Stack h='100%'>
      <Title order={5} h={40} className='flex items-center' fw={400} data-testid={testIds.title}>
        {t('userMgmt.navbar.groups')}
      </Title>
      <GroupTable />
    </Stack>
  );
};
