import { Badge, Blockquote, Flex, MantineColor, Text, useComputedColorScheme } from '@mantine/core';
import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface ApplicationCardProps {
  icon: React.ReactNode;
  title: string;
  color?: MantineColor;
  onClick?: () => void;
  tbd?: boolean;
}

export const testIds = {
  card: 'application-card',
  title: 'application-card-title',
  tbdBadge: 'application-card-tbd-badge',
};

export const ApplicationCard = ({ icon, title, color, onClick, tbd = false, ...props }: ApplicationCardProps) => {
  const { t } = useTranslation();
  const colorScheme = useComputedColorScheme('light');

  return (
    <Blockquote
      color={tbd ? 'gray' : color}
      icon={icon}
      iconSize={30}
      className={cn('hover:-translate-y-1 transition-transform', {
        'pointer-events-none opacity-70': tbd,
        'shadow-md': !tbd,
        'cursor-pointer': !!onClick,
        'cursor-not-allowed': !onClick,
      })}
      bg={colorScheme === 'light' ? 'gray.0' : 'gray.8'}
      onClick={onClick}
      data-testid={testIds.card}
      {...props}
    >
      <Flex direction='column' h='100%' justify='center'>
        <Text fw={600} data-testid={testIds.title}>
          {title}
        </Text>
        {tbd && (
          <Badge color='gray' data-testid={testIds.tbdBadge}>
            {t('flowPage.comingSoon')}
          </Badge>
        )}
      </Flex>
    </Blockquote>
  );
};
