import { Card, Checkbox, Stack, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { CommonValueType, useFlowSettingStore } from 'stores/wizard';

export const testIds = {
  wrapper: 'flow-settings-features-properties-wrapper',
  title: 'flow-settings-features-title',
  imageCheckbox: 'flow-settings-features-image-checkbox',
  applicabilityCheckbox: 'flow-settings-features-applicability-checkbox',
};

export const FeaturesProperties = () => {
  const { t } = useTranslation();
  const { supportedEvents, update } = useFlowSettingStore((state) => ({
    supportedEvents: state.data.flow.supportedCommonEvents ?? [],
    update: state.updateGeneralSettings,
  }));

  const checkIfSupported = (valueType: CommonValueType) => supportedEvents.includes(valueType);
  const isImageSupported = checkIfSupported(CommonValueType.IMAGE);
  const isApplicabilitySupported = checkIfSupported(CommonValueType.APPLICABILITY);

  const handleChange = (valueType: CommonValueType, isCurrentlySupported: boolean) => () => {
    const newValue = isCurrentlySupported
      ? supportedEvents.filter((v) => v !== valueType)
      : [...supportedEvents, valueType];
    update({ supportedCommonEvents: newValue });
  };

  return (
    <Card p='lg' data-testid={testIds.wrapper}>
      <Stack gap='xl'>
        <Text size='md' fw={600} data-testid={testIds.title}>
          {t('wizard.steps.settings.features.flowFeatures.title')}
        </Text>
        <Checkbox
          checked={isImageSupported}
          label={t('wizard.steps.settings.features.flowFeatures.image.title')}
          description={t('wizard.steps.settings.features.flowFeatures.image.info')}
          onChange={handleChange(CommonValueType.IMAGE, isImageSupported)}
          data-testid={testIds.imageCheckbox}
        />
        <Checkbox
          checked={isApplicabilitySupported}
          label={t('wizard.steps.settings.features.flowFeatures.applicability.title')}
          description={t('wizard.steps.settings.features.flowFeatures.applicability.info')}
          onChange={handleChange(CommonValueType.APPLICABILITY, isApplicabilitySupported)}
          data-testid={testIds.applicabilityCheckbox}
        />
      </Stack>
    </Card>
  );
};
