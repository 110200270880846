import i18n from 'services/i18n';
import { GroupDetailsPanel } from './components/GroupDetailsPanel/GroupDetailsPanel';
import { GroupPoliciesPanel, GroupUsersPanel } from './components';

export enum GroupPanel {
  DETAILS = 'details',
  USERS = 'users',
  POLICIES = 'policies',
}

export const panels = [
  {
    key: GroupPanel.DETAILS,
    title: i18n.t('userMgmt.groups.groupDetails.panels.details.title'),
    panel: <GroupDetailsPanel />,
  },
  {
    key: GroupPanel.USERS,
    title: i18n.t('userMgmt.groups.groupDetails.panels.users.title'),
    panel: <GroupUsersPanel />,
  },
  {
    key: GroupPanel.POLICIES,
    title: i18n.t('userMgmt.groups.groupDetails.panels.policies.title'),
    panel: <GroupPoliciesPanel />,
  },
];
