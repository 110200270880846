import { config } from 'services/config';
import { hermes } from 'services/hermes';
import {
  Folder as ApiFolder,
  CreateFolderRequest,
  GetFoldersResponse,
  UpsertFolderRequest,
} from '@jargonic/folder-types';
import { HermesStatusCode } from '@aiola/frontend';
import { Folder } from './folders.types';

const foldersUrl = (customerId: string) => `${config.jargonicApiUrl}/${customerId}/folders`;

export const foldersApi = {
  getFolders: async (customerId: string): Promise<Folder[]> => {
    const { data } = await hermes.get<GetFoldersResponse>(foldersUrl(customerId), { fallback: { items: [] } });
    return data.items;
  },

  getFolderById: async (customerId: string, folderId: string): Promise<Folder | undefined> => {
    const url = `${foldersUrl(customerId)}/${folderId}`;
    const { data } = await hermes.get<ApiFolder>(url);
    return data;
  },

  createFolder: async (customerId: string, folderName: string): Promise<Folder | undefined> => {
    const url = `${foldersUrl(customerId)}`;
    const body: CreateFolderRequest = { name: folderName, order: 0 };
    const { data } = await hermes.post<ApiFolder>(url, body);
    return data;
  },

  updateFolder: async (
    customerId: string,
    folderId: string,
    partialFolder: Omit<Partial<Folder>, 'id'>,
  ): Promise<Folder | undefined> => {
    const url = `${foldersUrl(customerId)}/${folderId}`;
    const { status } = await hermes.patch<void>(url, partialFolder);
    if (status !== HermesStatusCode.Ok) return undefined;
    // TODO replace once backend returns updated folder from PATCH
    const { data } = await hermes.get<ApiFolder>(url);
    return data;
  },

  bulkUpdateFolders: async (customerId: string, folders: UpsertFolderRequest[]): Promise<Folder[]> => {
    const url = `${foldersUrl(customerId)}/bulk`;
    const { data } = await hermes.post<ApiFolder[]>(url, folders);
    return data;
  },

  deleteFolder: async (customerId: string, folderId: string, includeFlows = false) => {
    const query = new URLSearchParams();
    query.append('includeFlows', includeFlows.toString());
    const url = `${foldersUrl(customerId)}/${folderId}?${query.toString()}`;
    const { data } = await hermes.delete(url);
    return data;
  },
};
