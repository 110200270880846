import { Stack, TextInput } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useFlowSettingStore } from 'stores/wizard';
import { MetadataTable, testIds as tableTestIds } from '../MetadataTable/MetadataTable';
import { useMetadataActions } from '../../hooks';

export const testIds = {
  wrapper: 'flow-settings-pre-flow-settings',
  titleInput: 'flow-settings-pre-title-input',
  table: tableTestIds,
};

export const PreFlowSettings = () => {
  const { t } = useTranslation();
  const metadataActions = useMetadataActions('pre');
  const { preInspectionFields, uniqueIdentifierFieldId, title } = useFlowSettingStore((state) => state.data.metadata);
  const updateMetadataTitle = useFlowSettingStore((state) => state.updateMetadataTitle);

  return (
    <Stack gap='xl' data-testid={testIds.wrapper}>
      <TextInput
        label={t('wizard.steps.settings.preFlow.metadataTitle.label')}
        placeholder={t('wizard.steps.settings.preFlow.metadataTitle.placeholder')}
        defaultValue={title}
        onBlur={(event) => updateMetadataTitle(event.currentTarget.value)}
        data-testid={testIds.titleInput}
      />
      <MetadataTable fields={preInspectionFields} uniqueIdFieldId={uniqueIdentifierFieldId} {...metadataActions} />
    </Stack>
  );
};
