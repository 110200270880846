import { EventDefElement, ReportedEventDefValue, ReportedEventDefValueId, useEventDefStore } from 'stores/wizard';
import { useMemo } from 'react';
import {
  DefaultValueSelect,
  ElementSelect,
  defaultValueSelectTestIds,
  ValueList,
  NewValueInput,
  elementSelectTestIds,
  valueListTestIds,
  newValueInputTestIds,
} from './components';
import classes from './SelectTypeLayout.module.css';

interface SelectTypeLayoutProps {
  eventDefId: string;
}

export const testIds = {
  elementSelect: elementSelectTestIds,
  defaultValue: defaultValueSelectTestIds,
  newEventInput: newValueInputTestIds,
  valueList: valueListTestIds,
};

export const SelectTypeLayout = ({ eventDefId }: SelectTypeLayoutProps) => {
  const { data, updateEventDef, updateEventValue, createValuesFromFile } = useEventDefStore([
    'data',
    'updateEventDef',
    'updateEventValue',
    'createValuesFromFile',
  ]);
  const eventDef = data[eventDefId];
  const eventValues = eventDef?.values ?? [];
  const existingValueNames = useMemo(() => new Set(eventValues.map((value) => value.name)), [eventValues]);

  if (!eventDef) return null;

  const onChangeUIElement = (elementType: EventDefElement) => updateEventDef(eventDef.id, { elementType });
  const onChangeDefaultValue = (value?: ReportedEventDefValueId) => updateEventDef(eventDef.id, { default: value });
  const onNewValue = (valueName: string) => updateEventValue(eventDef.id, { action: 'create', payload: valueName });
  const onUpdateValue = (valueId: ReportedEventDefValueId, payload: Partial<ReportedEventDefValue>) => {
    const eventValue = eventValues.find((v) => v.id === valueId);
    const nameDidChange = payload.name !== eventValue?.name;
    if (nameDidChange) {
      const nameExists = existingValueNames.has(payload.name!);
      if (nameExists) return false;
    }
    updateEventValue(eventDefId, { action: 'update', payload: { id: valueId, ...payload } });
    return true;
  };
  const onReorderValues = (valueIds: ReportedEventDefValueId[]) =>
    updateEventValue(eventDefId, { action: 'reorder', payload: valueIds });
  const onDeleteValue = (valueId: ReportedEventDefValueId) =>
    updateEventValue(eventDefId, { action: 'delete', payload: { id: valueId } });
  const onUploadCSV = (file: File) => createValuesFromFile(eventDefId, file);

  return (
    <>
      <ElementSelect value={eventDef.elementType} rootClassName={classes.elementSelect} onChange={onChangeUIElement} />
      <DefaultValueSelect
        eventValues={eventDef.values ?? []}
        value={eventDef.default}
        rootClassName={classes.defaultInput}
        onChange={onChangeDefaultValue}
      />
      <NewValueInput
        valueCount={eventValues.length}
        existingNameSet={existingValueNames}
        rootClassName={classes.newValueInput}
        onSubmit={onNewValue}
        onUploadCSV={onUploadCSV}
      />
      <ValueList
        eventValues={eventValues}
        rootClassName={classes.valueList}
        onChange={onUpdateValue}
        onDelete={onDeleteValue}
        onReorder={onReorderValues}
      />
    </>
  );
};
