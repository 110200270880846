import { Box } from '@mantine/core';
import { EventDefType, useEventDefStore } from 'stores/wizard';
import { EventDefTypeSelect } from './components';
import classes from './EventDefTypeSettings.module.css';
import { typeToLayoutMap } from './layouts';

interface EventDefTypeSettingsProps {
  eventDefId: string;
}

export const testIds = {
  wrapper: 'event-def-type-settings',
};

export const EventDefTypeSettings = ({ eventDefId }: EventDefTypeSettingsProps) => {
  const { data, updateEventDefType } = useEventDefStore(['data', 'updateEventDefType']);
  const eventDef = data[eventDefId];

  if (!eventDef) return null;

  const onChangeType = (type: EventDefType) => updateEventDefType(eventDef.id, type);
  const Layout = typeToLayoutMap[eventDef.valueType];

  return (
    <Box className={classes.grid} data-testid={testIds.wrapper}>
      <EventDefTypeSelect value={eventDef.valueType} onChange={onChangeType} />
      <Layout eventDefId={eventDefId} />
    </Box>
  );
};
