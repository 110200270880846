import { ModelType } from '@ai-platform/common-types';
import { Button, Flex, Stack } from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { IconCircleCheckFilled, IconMoodSad, IconWand, IconError404 } from '@tabler/icons-react';
import { EmptyState } from 'components';
import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';
import { DataGenerationRouteParams, ROUTES } from 'routes/routes.config';
import {
  fullGenSamplesNumberKey,
  isModelType,
  useAiDataSourcesStore,
  useLastAiDataGenSamples,
  useLatestAiDataSourceByType,
} from 'stores/aiPlatform';
import { DownloadError } from 'stores/aiPlatform/aiDataSources/api/aiDataSources.api';
import { useFlow } from 'stores/flows';
import { PageTitle } from '../components/PageTitle/PageTitle';
import { useCompletionModal } from './CompletionModal/useCompletionModal';
import { DataGenerationCard, cardTestIds } from './components';
import { DataGenerationActions } from './components/DataGenerationActions/DataGenerationActions';
import { TextDataGenerationSettingsModal } from './TextDataGenerationSettingsModal/TextDataGenerationSettingsModal';
import { VoiceDataGenerationSettingsModal } from './VoiceDataGenerationSettingsModal/VoiceDataGenerationSettingsModal';

export const testIds = {
  wrapper: 'data-generation-page',
  generateButton: 'data-generation-page-new-generation-button',
  card: cardTestIds,
  downloadNotificationSuccess: 'data-generation-page-notification-success',
  downloadNotification400: 'data-generation-page-notification-400',
  downloadNotification500: 'data-generation-page-notification-500',
};

export const DataGenerationPage = memo(() => {
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const modelTypeParam = params.get('modelType') ?? '';
  const modelType = modelTypeParam.toUpperCase() as ModelType;
  const { customerId, flowId } = useParams() as DataGenerationRouteParams;
  const flow = useFlow(flowId);
  const isValidModelType = isModelType(modelTypeParam);
  const aiDataSource = useLatestAiDataSourceByType(modelType);
  const [settingsOpened, { open: openSettings, close: closeSettings }] = useDisclosure(false);
  const samples = useLastAiDataGenSamples(aiDataSource?.id, modelType);
  const pageTitle = useMemo(() => t(`dataGenerationPage.pageTitle.${modelType}`), [t, modelType]);
  const { createAiDataSource, downloadSamples } = useAiDataSourcesStore(['createAiDataSource', 'downloadSamples']);
  const [fullGenerationSamplesNumber] = useLocalStorage<number | null>({
    key: fullGenSamplesNumberKey,
    defaultValue: null,
    getInitialValueInEffect: false,
  });
  const createFullDataSource = useCallback(async () => {
    const {
      id,
      isSamplesGeneration,
      onlyTextSamples,
      settings: {
        language,
        customerConfigLocation,
        customerConfigPromptsLocation,
        customerPredefinedTemplateLocation,
        externalTextFileLocation,
        textGenSettings,
        voiceGenSettings,
      },
    } = aiDataSource;

    // TODO: Temporary fix for this tenant until properly fixed in BE
    const tenantId = customerId.replace(/^aiola$/, 'internal-dev');

    const response = await createAiDataSource({
      flowId,
      flowVersion: flow.activeVersion!,
      tenantId,
      sampleExecutionId: isSamplesGeneration ? id : undefined,
      dataGenSettings: {
        __typename: 'DataGenerationSettings',
        customerConfigPromptsLocation,
        customerConfigLocation,
        customerPredefinedTemplateLocation,
        externalTextFileLocation,
        language,
        samplesCount: fullGenerationSamplesNumber!,
        textGenSettings,
        voiceGenSettings,
        onlyTextSamples,
      },
    });

    return !!response;
  }, [flow, aiDataSource]);

  const CompletionModal = useCompletionModal({
    isSamplesGeneration: aiDataSource?.isSamplesGeneration,
    status: aiDataSource?.status,
    onRetry: createFullDataSource,
  });

  const downloadSamplesCB = useCallback(async () => {
    const sampleType = aiDataSource?.isSamplesGeneration ? 'sample' : 'full';
    let title = t(`dataGenerationPage.downloadNotification.success.${sampleType}.title`);
    let message = t(`dataGenerationPage.downloadNotification.success.${sampleType}.message`);
    let icon = <IconCircleCheckFilled />;
    let color = 'green';
    let id = testIds.downloadNotificationSuccess;
    try {
      await downloadSamples(aiDataSource);
    } catch (error) {
      const is404 = (error as DownloadError).statusCode === 404;
      const errType = is404 ? 'notFound' : 'internalError';
      console.log('downloadSamples->error: ', error);
      title = t(`dataGenerationPage.downloadNotification.failure.${errType}.title`);
      message = t(`dataGenerationPage.downloadNotification.failure.${errType}.message`);
      icon = is404 ? <IconError404 /> : <IconMoodSad />;
      color = 'red';
      id = is404 ? testIds.downloadNotification400 : testIds.downloadNotification500;
    }
    notifications.show({
      icon,
      title,
      message,
      color,
      autoClose: 5000,
      id,
    });
  }, [aiDataSource]);
  const mainView = useMemo(
    () =>
      aiDataSource ? (
        <>
          <DataGenerationCard aiDataSource={aiDataSource} samples={samples} onDownload={downloadSamplesCB} />
          <DataGenerationActions
            isSamplesGeneration={aiDataSource.isSamplesGeneration}
            generationID={aiDataSource.id}
            onApproveAndGenerate={createFullDataSource}
            onFullDataGenFlow={openSettings}
            fullGenerationSamplesNumber={fullGenerationSamplesNumber}
            status={aiDataSource.status}
            samplesType={aiDataSource.samplesType}
            onDownload={downloadSamplesCB}
          />
        </>
      ) : (
        <EmptyState
          title={t('dataGenerationPage.emptyState.title')}
          message={t('dataGenerationPage.emptyState.message')}
          py={80}
          flex={1}
          withBorder
        />
      ),
    [
      aiDataSource,
      JSON.stringify(samples),
      modelType,
      openSettings,
      createFullDataSource,
      fullGenerationSamplesNumber,
      t,
    ],
  );

  const redirect = useMemo(
    () => (
      <Navigate
        to={{
          pathname: ROUTES.AI_PLATFORM(customerId, flowId).DATA_GENERATION,
          search: `?modelType=${ModelType.NLP}`,
        }}
        replace
      />
    ),
    [customerId, flowId],
  );

  if (!isValidModelType) {
    return redirect;
  }

  const dataGenerationPage = (
    <Stack p='xl' gap='md' className='h-full' pos='relative' pb={50} data-testid={testIds.wrapper}>
      <Flex align='center' justify='space-between'>
        <PageTitle title={pageTitle} subtitle={flow.name} />
        <Button leftSection={<IconWand />} onClick={openSettings} data-testid={testIds.generateButton}>
          {t('dataGenerationPage.generateButton')}
        </Button>
      </Flex>
      {mainView}
      {modelType === ModelType.NLP && (
        <TextDataGenerationSettingsModal opened={settingsOpened} onClose={closeSettings} />
      )}
      {modelType === ModelType.ASR && (
        <VoiceDataGenerationSettingsModal opened={settingsOpened} onClose={closeSettings} />
      )}
      {CompletionModal}
    </Stack>
  );

  return dataGenerationPage;
});
