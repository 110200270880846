import { Flex, HoverCard, Text, ThemeIcon } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { CLASSIC_DATE_TIME_FORMAT } from 'consts';
import dayjs from 'dayjs';
import { FC } from 'react';
import i18n from 'services/i18n';
import { AiModelDataTooltip } from 'stores/aiPlatform/aiPublishedModels';
import { testIds } from '../AiModelDataTable';

type TooltipModalProps = { data: AiModelDataTooltip };

type DataTooltipHeaderProps = TooltipModalProps & { headerTitle: string; id: string };

export const DataTooltipHeader: FC<DataTooltipHeaderProps> = ({ data, headerTitle, id }) => (
  <Flex gap={8} align='center' data-testid={testIds.getTooltipTestId(id)}>
    {headerTitle}
    <HoverCard closeDelay={200}>
      <HoverCard.Target>
        <ThemeIcon color='red' size='sm' variant='transparent'>
          <IconInfoCircle cursor='pointer' data-testid={`${testIds.dataTooltip}-icon-${id}`} color='grey' />
        </ThemeIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Flex
          mih={50}
          gap='md'
          justify='flex-start'
          align='flex-start'
          direction='column'
          wrap='wrap'
          data-testid={testIds.dataTooltipModal}
        >
          <Flex gap={8}>
            <Text fw={500}>{i18n.t('publishPage.modelDataTable.headers.tooltip.createdBy')}:</Text>
            <Text>{data.triggeredBy}</Text>
          </Flex>
          <Flex gap={8}>
            <Text fw={500}>
              <span>{i18n.t('publishPage.modelDataTable.headers.tooltip.creationDate')}:</span>
            </Text>
            <Text>{dayjs(data.startTimestamp).format(CLASSIC_DATE_TIME_FORMAT)}</Text>
          </Flex>
        </Flex>
      </HoverCard.Dropdown>
    </HoverCard>
  </Flex>
);
