import { createContext, useCallback } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { FlowRouteParams } from 'routes/routes.config';
import { useCurrentUser } from 'stores/auth';
import { espionageApi } from './espionage.api';

type SpyState = () => Promise<boolean>;

const initialState: SpyState = () => Promise.resolve(true);

export const SpyContext = createContext<SpyState>(initialState);

export const SpyProvider = () => {
  const { customerId, flowId } = useParams<FlowRouteParams>();
  const currentUser = useCurrentUser();

  const spy = useCallback(async () => {
    await espionageApi.sendBiEvent({});
    return true;
  }, [customerId, flowId, currentUser?.userId]);

  return (
    <SpyContext.Provider value={spy}>
      <Outlet />
    </SpyContext.Provider>
  );
};
