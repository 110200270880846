import { Button, ButtonProps, ElementProps } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

type CreateContainerSubmitProps = ButtonProps & ElementProps<'button', keyof ButtonProps>;

export const testIds = {
  button: 'create-container-menu-process-button',
  icon: 'create-container-menu-process-icon',
};

export const CreateContainerSubmit = (props: CreateContainerSubmitProps) => {
  const { t } = useTranslation();

  return (
    <Button
      rightSection={<IconChevronRight data-testid={testIds.icon} />}
      size='xs'
      type='submit'
      data-testid={testIds.button}
      {...props}
    >
      {t('wizard.steps.labeling.containerTable.addItemMenu.submitButton')}
    </Button>
  );
};
