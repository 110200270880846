import { EventDefElement, EventDefType } from 'stores/wizard';
import { Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { InfoLabel } from 'components';
import { useMemo } from 'react';

interface TextEventBehaviorProps {
  value: EventDefElement;
  rootClassName?: string;
  onChange: (values: { elementType: EventDefElement; valueType: EventDefType }) => void;
}

export const testIds = {
  label: 'component-behavior-label',
  select: 'component-behavior-select',
};

export const TextEventBehavior = ({ value, rootClassName, onChange }: TextEventBehaviorProps) => {
  const { t } = useTranslation();

  const options = useMemo(
    () => [
      {
        label: t('wizard.steps.reportedEvents.typeSettings.textEventBehavior.append'),
        value: EventDefElement.APPENDABLE_STRING,
      },
      {
        label: t('wizard.steps.reportedEvents.typeSettings.textEventBehavior.override'),
        value: EventDefElement.STRING,
      },
    ],
    [],
  );

  const eventValueType = useMemo(
    () => ({
      [EventDefElement.APPENDABLE_STRING]: EventDefType.APPEND_TEXT_EVENT,
      [EventDefElement.STRING]: EventDefType.FREE_TEXT,
    }),
    [],
  );

  const handleOnChange = (option: string | null) => {
    onChange({
      elementType: option as EventDefElement,
      valueType: eventValueType[option as EventDefElement.APPENDABLE_STRING | EventDefElement.STRING],
    });
  };

  return (
    <Select
      allowDeselect={false}
      classNames={{ root: rootClassName }}
      data={options}
      label={
        <InfoLabel
          infoText={t('wizard.steps.reportedEvents.typeSettings.textEventBehavior.tooltip')}
          data-testid={testIds.label}
        >
          {t('wizard.steps.reportedEvents.typeSettings.textEventBehavior.title')}
        </InfoLabel>
      }
      value={value}
      onChange={handleOnChange}
      data-testid={testIds.select}
    />
  );
};
