import { MRT_TableOptions } from 'mantine-react-table';
import { AiDataVoiceSample } from 'stores/aiPlatform';

export const dataGenVoiceSamplesTableDefs = {
  getRowId: (original) => original.id,
  enableSorting: false,
  enablePagination: false,
  enableBottomToolbar: false,
  enableStickyHeader: true,
  enableColumnActions: false,
  enableTopToolbar: false,
  enableRowActions: false,
  layoutMode: 'grid',
} satisfies Partial<MRT_TableOptions<AiDataVoiceSample>>;
