import i18n from 'services/i18n';
import { MantineColor } from '@mantine/core';
import { AiDataSourceStatus } from 'stores/aiPlatform';

export const statusLabels: Record<AiDataSourceStatus, string> = {
  [AiDataSourceStatus.ABORTED]: i18n.t('common.aborted'),
  [AiDataSourceStatus.DECLINED]: i18n.t('common.declined'),
  [AiDataSourceStatus.FAILED]: i18n.t('common.failed'),
  [AiDataSourceStatus.IN_PROGRESS]: i18n.t('common.inProgress'),
  [AiDataSourceStatus.COMPLETED]: i18n.t('common.completed'),
} as const;

export const statusColors: Record<AiDataSourceStatus, MantineColor> = {
  [AiDataSourceStatus.ABORTED]: 'red',
  [AiDataSourceStatus.DECLINED]: 'red',
  [AiDataSourceStatus.FAILED]: 'red',
  [AiDataSourceStatus.IN_PROGRESS]: 'orange',
  [AiDataSourceStatus.COMPLETED]: 'green',
} as const;
