import { Checkbox } from '@mantine/core';
import { useTranslation } from 'react-i18next';

export interface EventDefFilterableInputProps {
  checked: boolean;
  rootClassName?: string;
  onChange: (checked: boolean) => void;
}

export const testIds = {
  checkbox: 'filterable-checkbox',
};

export const EventDefFilterableInput = ({ checked, rootClassName, onChange }: EventDefFilterableInputProps) => {
  const { t } = useTranslation();

  return (
    <Checkbox
      classNames={{ root: rootClassName }}
      label={t('wizard.steps.reportedEvents.typeSettings.filterableCheckbox.title')}
      description={t('wizard.steps.reportedEvents.typeSettings.filterableCheckbox.tooltip')}
      checked={checked}
      onChange={(event) => onChange(event.currentTarget.checked)}
      data-testid={testIds.checkbox}
    />
  );
};
