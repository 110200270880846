import { useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { MRT_TableOptions } from 'mantine-react-table';
import { EmptyState } from 'components';
import { FlowMetadataField } from 'stores/wizard';
import { MetadataTableState } from '../MetadataTable.types';

export const testIds = {
  wrapper: 'metadata-table-empty-state',
  button: 'metadata-table-empty-state-button',
};

export const MetadataTableEmptyState: NonNullable<MRT_TableOptions<FlowMetadataField>['renderEmptyRowsFallback']> = ({
  table,
}) => {
  const { t } = useTranslation();

  const { onNewField } = table.getState() as MetadataTableState;

  return (
    <EmptyState
      title={t('wizard.steps.settings.metadata.table.emptyState.title')}
      message={t('wizard.steps.settings.metadata.table.emptyState.message')}
      data-testid={testIds.wrapper}
    >
      <Button onClick={onNewField} data-testid={testIds.button}>
        {t('wizard.steps.settings.metadata.table.emptyState.button')}
      </Button>
    </EmptyState>
  );
};
