/* eslint-disable @typescript-eslint/no-unsafe-call */
import { FlowExpirationType } from '@jargonic/flows-types';
import { Box, Card, Flex, InputDescription, NumberInput, Radio, Stack, Text } from '@mantine/core';
import { convertTODtoMinutes, TimeMultipleInput } from 'components/TimeMultipleInput/TimeMultipleInput';
import { FocusEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFlowSettingStore } from 'stores/wizard';
import { minutesToTimeString, timeStringToMinutes } from 'utils';

export const testIds = {
  wrapper: 'flow-settings-expiration-properties-wrapper',
  title: 'flow-settings-expiration-title',
  description: 'flow-settings-expiration-description',
  options: {
    never: {
      radio: 'flow-settings-expiration-never',
    },
    hours: {
      radio: 'flow-settings-expiration-hours',
      input: 'flow-settings-expiration-hours-input',
    },
    tod: {
      radio: 'flow-settings-expiration-tod',
      input: 'flow-settings-expiration-tod-input',
    },
  },
};

const { NEVER, ELAPSED_TIME, LIST_TIME_OF_DAY } = FlowExpirationType;

export const ExpirationProperties = () => {
  const { t } = useTranslation();
  const hourInputRef = useRef<HTMLInputElement>(null);
  const [timeValues, setTimeValues] = useState<string[]>([]);
  const { expiration, update } = useFlowSettingStore((state) => ({
    expiration: state.data.flow.expiration,
    update: state.updateGeneralSettings,
  }));

  useEffect(() => {
    if (expiration?.type === LIST_TIME_OF_DAY && expiration.values) {
      const convertedTimes = expiration.values.map(minutesToTimeString) as string[];
      setTimeValues(convertedTimes);
    }
  }, [expiration]);

  const type = expiration?.type ?? NEVER;

  const onUpdate = (newType: FlowExpirationType, value?: number | number[]) => {
    if (Array.isArray(value)) {
      update({ expiration: { type: newType, values: value } });
    } else {
      update({ expiration: { type: newType, value } });
    }
  };

  const onChangeType = (newType: string) => {
    if (newType === ELAPSED_TIME) onUpdate(newType, Number(hourInputRef.current?.value));
    if (newType === LIST_TIME_OF_DAY) {
      if (timeValues) {
        onUpdate(newType, convertTODtoMinutes(timeValues));
      } else {
        onUpdate(newType, timeStringToMinutes('00:00'));
      }
    }
    if (newType === NEVER) onUpdate(newType, undefined);
  };

  const onBlurHours: FocusEventHandler<HTMLInputElement> = ({ target }) => {
    const { value } = target;
    if (value === '') {
      onUpdate(FlowExpirationType.ELAPSED_TIME, undefined);
    } else {
      onUpdate(FlowExpirationType.ELAPSED_TIME, Number(value));
    }
  };

  return (
    <Card p='lg' data-testid={testIds.wrapper} h={350} style={{ overflow: 'auto' }}>
      <Stack gap='xs'>
        <Box>
          <Text size='md' fw={600} data-testid={testIds.title}>
            {t('wizard.steps.settings.features.expiration.title')}
          </Text>
          <InputDescription size='md' data-testid={testIds.description}>
            {t('wizard.steps.settings.features.expiration.description')}
          </InputDescription>
        </Box>

        <Radio.Group value={type ?? NEVER} onChange={onChangeType}>
          <Flex h={41} gap='md' direction='column'>
            <Radio
              label={t('wizard.steps.settings.features.expiration.never.title')}
              value={NEVER}
              data-testid={testIds.options.never.radio}
            />

            <Radio
              value={ELAPSED_TIME}
              label={t('wizard.steps.settings.features.expiration.hours.title')}
              data-testid={testIds.options.hours.radio}
            />
            <NumberInput
              ref={hourInputRef}
              ml={25}
              defaultValue={type === ELAPSED_TIME ? expiration?.value : undefined}
              disabled={type !== ELAPSED_TIME}
              placeholder='0'
              w='90%'
              onBlur={onBlurHours}
              data-testid={testIds.options.hours.input}
            />

            <Radio
              value={LIST_TIME_OF_DAY}
              label={t('wizard.steps.settings.features.expiration.tod.title')}
              data-testid={testIds.options.tod.radio}
            />
            <Box ml={25}>
              <TimeMultipleInput
                value={timeValues}
                onChange={(newValues) => setTimeValues(newValues)}
                onBlur={(minutesValues) => onUpdate(LIST_TIME_OF_DAY, minutesValues)}
                disabled={type !== LIST_TIME_OF_DAY}
                data-testid={testIds.options.tod.input}
              />
            </Box>
          </Flex>
        </Radio.Group>
      </Stack>
    </Card>
  );
};
