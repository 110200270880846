import { Menu } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { MRT_TableOptions } from 'mantine-react-table';
import { useTranslation } from 'react-i18next';
import { notifications } from '@mantine/notifications';
import { IconDots, IconTrash, IconTrashOff } from '@tabler/icons-react';
import { ActionButton } from 'components';
import { Policy } from '@jargonic/customer-types';
import { DeletePolicyFromGroupModal, testIds as deleteModalTestIds } from './DeletePolicyFromGroupModal';
import { PoliciesTableState } from '../../PoliciesTable.types';

export const testIds = {
  getButtonTestId: (id: string) => `policies-table-row-actions-button-${id}`,
  icon: 'policies-table-row-actions-icon',
  options: {
    delete: {
      button: 'policies-table-row-actions-delete',
      modal: deleteModalTestIds,
    },
  },
};

export const PoliciesTableRowActions: NonNullable<MRT_TableOptions<Policy>['renderRowActions']> = ({ row, table }) => {
  const { t } = useTranslation();
  const [deleteModalOpened, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure();
  const { group, onChangePolicies } = table.getState() as PoliciesTableState;

  const onDelete = async () => {
    const filteredPoliciesIds = group.policies.filter((policy) => policy.id !== row.id).map((policy) => policy.id);
    const response = await onChangePolicies(filteredPoliciesIds);

    if (response)
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.groupDetails.panels.policies.table.deletePolicy.success.title'),
        message: null,
        icon: <IconTrash />,
      });
    else
      notifications.show({
        color: 'red',
        title: t('userMgmt.groups.groupDetails.panels.policies.table.deletePolicy.error.title'),
        message: t('common.tryAgain'),
        icon: <IconTrashOff />,
      });
  };

  return (
    <>
      <Menu>
        <Menu.Target>
          <ActionButton label={t('common.actions')} data-testid={testIds.getButtonTestId(row.id)}>
            <IconDots data-testid={testIds.icon} />
          </ActionButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={openDeleteModal} data-testid={testIds.options.delete.button}>
            {t('common.delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <DeletePolicyFromGroupModal opened={deleteModalOpened} onClose={closeDeleteModal} onDelete={onDelete} />
    </>
  );
};
