import {
  ElementProps,
  MantineColor,
  MantineProvider,
  Modal,
  ModalBodyProps,
  ModalContentProps,
  ModalHeaderProps,
  ModalRootProps,
  useComputedColorScheme,
} from '@mantine/core';
import { PropsWithChildren } from 'react';

export interface ModalCommonProps {
  opened: boolean;
  onClose: () => void;
}

const variants = ['warning', 'info', 'creation', 'free'] as const;
interface ModalBaseProps
  extends ModalCommonProps,
    ModalRootProps,
    PropsWithChildren,
    ElementProps<'div', keyof ModalRootProps> {
  title: string;
  variant?: (typeof variants)[number];
  closable?: boolean;
  color?: MantineColor;
  size?: number | string;
  headerProps?: ModalHeaderProps & ElementProps<'div', keyof ModalHeaderProps>;
  bodyProps?: ModalBodyProps & ElementProps<'div', keyof ModalBodyProps>;
  contentProps?: ModalContentProps & ElementProps<'div', keyof ModalContentProps>;
}

const getSelector = (color: MantineColor) => `.mantine-Modal-root[color="${color}"]`;

interface ModalColorProviderProps extends PropsWithChildren {
  color: MantineColor;
  scheme?: 'light' | 'dark';
}

/**
 * Nested provider to change the primary color for modal content.
 * This works by injecting new styles into the target of `getRootElement`, then reading them from the target of `cssVariablesSelector`.
 * Only effects internal modal content - not the title or confirm buttons.
 */
const ModalColorProvider = ({ children, color, scheme }: ModalColorProviderProps) => (
  <MantineProvider
    theme={{ primaryColor: color }}
    getRootElement={() => document.querySelector(getSelector(color)) as HTMLElement}
    cssVariablesSelector={getSelector(color)}
    forceColorScheme={scheme}
  >
    {children}
  </MantineProvider>
);

const variantColors: Record<(typeof variants)[number], MantineColor> = {
  warning: 'red',
  info: 'blue',
  creation: 'green',
  free: 'green',
};

export const testIds = {
  root: 'modal-base-root',
  title: 'modal-base-title',
  close: 'modal-base-close-button',
  body: 'modal-base-body',
  overlay: 'modal-base-overlay',
};

export const ModalBase = ({
  title,
  children,
  variant = 'free',
  closable = true,
  color,
  size,
  headerProps = {},
  bodyProps = {},
  contentProps = {},
  ...props
}: ModalBaseProps) => {
  const colorScheme = useComputedColorScheme('light');
  const c = color ?? variantColors[variant];

  return (
    <Modal.Root
      closeOnClickOutside={closable}
      closeOnEscape={closable}
      color={c}
      centered
      size={size}
      data-testid={testIds.root}
      {...props}
    >
      <Modal.Overlay data-testid={testIds.overlay} />
      <ModalColorProvider color={c} scheme={colorScheme}>
        <Modal.Content {...contentProps}>
          <Modal.Header mb='md' {...headerProps}>
            <Modal.Title c={c} fw={500} fs='lg' data-testid={testIds.title}>
              {title}
            </Modal.Title>
            <Modal.CloseButton disabled={!closable} data-testid={testIds.close} />
          </Modal.Header>
          <Modal.Body data-testid={testIds.body} {...bodyProps}>
            {children}
          </Modal.Body>
        </Modal.Content>
      </ModalColorProvider>
    </Modal.Root>
  );
};
