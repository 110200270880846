import { GetEventDefinitionsResponse, UpsertEventDefinitionRequest } from '@jargonic/event-definition-types';
import { config } from 'services/config';
import { hermes } from 'services/hermes';
import { ApiReportedEventDefinition } from './eventDefs.types';
import { filterUserEvents } from './eventDefs.utils';

const flowUrl = (customerId: string, flowId: string) =>
  `${config.jargonicApiUrl}/${customerId}/event_definition/${flowId}`;

export const eventDefApi = {
  getEventDefs: async (customerId: string, flowId: string) => {
    const url = flowUrl(customerId, flowId);
    const { data } = await hermes.get<GetEventDefinitionsResponse>(url);
    return data?.items && filterUserEvents(data.items);
  },

  upsertEventDefs: async (customerId: string, flowId: string, events: UpsertEventDefinitionRequest[]) => {
    const url = flowUrl(customerId, flowId);
    const { data } = await hermes.post<ApiReportedEventDefinition[]>(url, events);
    return data;
  },
};
