import { HTMLPropsRef, MantineReactTable, useMantineReactTable } from 'mantine-react-table';
import { AiModelDataTooltip, PublishedModelTrainingData } from 'stores/aiPlatform/aiPublishedModels';
import { getColumns } from './AiModelDataTable.columns';
import { metricsTableDefs } from './AiModelDataTable.defs';

interface AiModelDataTableProps {
  data: PublishedModelTrainingData[];
  classNames?: {
    table?: string;
    paper?: string;
  };
  trainingInfo: AiModelDataTooltip;
  dataGenerationInfo: AiModelDataTooltip;
}

export const testIds = {
  table: 'ai-model-data-table',
  dataTooltip: 'data-tooltip',
  dataTooltipModal: 'data-tooltip-modal',
  getCopyCellId: (id: string) => `ai-model-data-copy-cell-${id}`,
  getCopyButtonId: (id: string) => `ai-model-data-copy-button-${id}`,
  getRowTestId: (id: string) => `ai-model-data-table-row-${id}`,
  getTooltipTestId: (id: string) => `${testIds.dataTooltip}-${id}`,
};

export const AiModelDataTable = ({ data, classNames, trainingInfo, dataGenerationInfo }: AiModelDataTableProps) => {
  const table = useMantineReactTable({
    data,
    columns: getColumns({ trainingInfo, dataGenerationInfo }),
    ...metricsTableDefs,
    mantineTableContainerProps: { className: classNames?.table },
    mantineTableBodyRowProps: ({ row }) => ({
      bg: row.getIsPinned() ? 'gray.1' : '',
      'data-testid': testIds.getRowTestId(row.id),
    }),
    mantineTableProps: () => ({ 'data-testid': testIds.table }) as HTMLPropsRef<HTMLTableElement>,
    mantinePaperProps: { className: classNames?.paper },
  });

  return <MantineReactTable table={table} />;
};
