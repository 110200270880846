import { Text } from '@mantine/core';
import cn from 'classnames';

interface ValidationOopsieItemProps {
  text: string;
  onClick?: () => void;
}

export const testIds = {
  item: 'validation-error-item',
};

export const ValidationOopsieItem = ({ text, onClick }: ValidationOopsieItemProps) => (
  <Text
    c='red'
    className={cn('hover:bg-[var(--mantine-color-red-light)] transition-colors rounded-md', {
      'cursor-pointer': Boolean(onClick),
      'select-none': !onClick,
    })}
    p='tn'
    size='xs'
    onClick={onClick}
    data-testid={testIds.item}
  >
    {text}
  </Text>
);
