import { Box, Button, Flex, FocusTrap, Group, Stack, TextInput } from '@mantine/core';
import { isNotEmpty, matches, useForm } from '@mantine/form';
import { useDebouncedValue } from '@mantine/hooks';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerRouteParams } from 'routes/routes.config';
import { ModalBase, modalBaseTestIds } from 'components';
import { EMAIL_REGEX, MAX_NAME_LENGTH } from 'consts';
import { CreateUserPayload, useUserMgmtStore, uniquifyUserName, generateUserName } from 'stores/userMgmt';
import { sanitizeStr } from 'utils';

interface UserTableManualCreateModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (data: CreateUserPayload) => Promise<void>;
}

export const testIds = {
  modal: 'user-table-manual-create',
  title: modalBaseTestIds.title,
  firstNameInput: 'user-table-manual-create-first-name-input',
  lastNameInput: 'user-table-manual-create-last-name-input',
  usernameInput: 'user-table-manual-create-username-input',
  emailInput: 'user-table-manual-create-email-input',
  submit: 'user-table-manual-create-submit',
  cancel: 'user-table-manual-create-cancel',
};

export const UserTableManualCreateModal = ({ opened, onClose, onSubmit }: UserTableManualCreateModalProps) => {
  const { t } = useTranslation();
  const { customerId } = useParams() as CustomerRouteParams;
  const [loading, setLoading] = useState(false);
  const { filterUsers } = useUserMgmtStore(['filterUsers']);
  const form = useForm<CreateUserPayload>({
    initialValues: { givenName: '', familyName: '', username: '', email: '' },
    validate: {
      givenName: isNotEmpty(),
      familyName: isNotEmpty(),
      username: isNotEmpty(),
      email: matches(EMAIL_REGEX, t('common.invalidEmail')),
    },
  });
  const [debouncedGivenName] = useDebouncedValue(form.values.givenName, 500);
  const [debouncedFamilyName] = useDebouncedValue(form.values.familyName, 500);

  const isReadyToSubmit = !loading && form.isValid();

  const createUsername = async (givenName: string, familyName: string) => {
    const filteredUsers = await filterUsers(customerId, { givenName, familyName });
    const existingUsernames = new Set(filteredUsers?.map((user) => user.username) || []);
    const username = uniquifyUserName(generateUserName(givenName, familyName), existingUsernames);
    form.setFieldValue('username', username);
  };

  const onCloseModal = () => {
    onClose();
    form.reset();
  };

  const onSubmitForm = async ({ email, familyName, givenName, username }: CreateUserPayload) => {
    setLoading(true);
    const sanitizedGivenName = sanitizeStr(givenName).trim();
    const sanitizedFamilyName = sanitizeStr(familyName).trim();
    await onSubmit({ email, familyName: sanitizedFamilyName, givenName: sanitizedGivenName, username });
    setLoading(false);
    onCloseModal();
  };

  useEffect(() => {
    if (debouncedGivenName && debouncedFamilyName) {
      const sanitizedGivenName = sanitizeStr(debouncedGivenName).trim();
      const sanitizedFamilyName = sanitizeStr(debouncedFamilyName).trim();
      createUsername(sanitizedGivenName, sanitizedFamilyName);
    } else form.setFieldValue('username', '');
  }, [debouncedGivenName, debouncedFamilyName]);

  return (
    <ModalBase
      title={t('userMgmt.users.table.actions.createUsers.modal.title')}
      opened={opened}
      onClose={onCloseModal}
      data-testid={testIds.modal}
    >
      <Box component='form' onSubmit={form.onSubmit(onSubmitForm)}>
        <Stack>
          <FocusTrap active>
            <Flex gap='md' w='100%'>
              <TextInput
                classNames={{ root: 'grow' }}
                label={t('common.firstName')}
                required
                maxLength={MAX_NAME_LENGTH}
                {...form.getInputProps('givenName')}
                data-testid={testIds.firstNameInput}
                data-autofocus
              />
              <TextInput
                classNames={{ root: 'grow' }}
                label={t('common.lastName')}
                required
                maxLength={MAX_NAME_LENGTH}
                {...form.getInputProps('familyName')}
                data-testid={testIds.lastNameInput}
              />
            </Flex>
            <TextInput
              classNames={{ root: 'grow' }}
              label={t('userMgmt.users.table.actions.createUsers.modal.username')}
              required
              disabled
              {...form.getInputProps('username')}
              data-testid={testIds.usernameInput}
            />
            <TextInput
              label={t('common.email')}
              required
              type='email'
              {...form.getInputProps('email')}
              data-testid={testIds.emailInput}
            />
          </FocusTrap>
          <Group justify='end'>
            <Button variant='subtle' loading={loading} onClick={onCloseModal} data-testid={testIds.cancel}>
              {t('common.cancel')}
            </Button>
            <Button type='submit' loading={loading} disabled={!isReadyToSubmit} data-testid={testIds.submit}>
              {t('common.submit')}
            </Button>
          </Group>
        </Stack>
      </Box>
    </ModalBase>
  );
};
