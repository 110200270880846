import { useTranslation } from 'react-i18next';
import { Box, Button, Group, Text } from '@mantine/core';
import { ModalBase } from 'components';

interface UnsavedChangesModalProps {
  opened?: boolean;
  onCancel?: () => void;
  onClose: () => void;
  onDiscard: () => void;
}

export const testIds = {
  container: 'unsaved-changes-modal',
  message: 'unsaved-changes-message',
  revert: 'unsaved-changes-revert-button',
  cancel: 'unsaved-changes-cancel-button',
};

export const UnsavedChangesModal = ({ opened = false, onCancel, onClose, onDiscard }: UnsavedChangesModalProps) => {
  const { t } = useTranslation();

  const onRevert = () => {
    onDiscard();
    onClose();
  };

  const onCloseWithoutReverting = () => {
    onCancel?.();
    onClose();
  };

  return (
    <ModalBase
      color='red'
      onClose={onCloseWithoutReverting}
      opened={opened}
      title={t('wizard.modals.unsavedChanges.title')}
    >
      <Box data-testid={testIds.container}>
        <Text c='gray.6' data-testid={testIds.message}>
          {t('wizard.modals.unsavedChanges.message')}
        </Text>
        <Group justify='space-between' mt='lg' wrap='nowrap'>
          <Button variant='subtle' size='xs' onClick={onCloseWithoutReverting} data-testid={testIds.cancel}>
            {t('wizard.modals.unsavedChanges.cancel')}
          </Button>
          <Button onClick={onRevert} data-testid={testIds.revert}>
            {t('wizard.modals.unsavedChanges.revertButton')}
          </Button>
        </Group>
      </Box>
    </ModalBase>
  );
};
